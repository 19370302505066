import { Loader } from '@/components/Common'
import { FieldMessage, TextField } from '@/components/Form/Fields'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useFormForgotPassword, useSendForgotPassword } from '@/pages/Password/hooks'
import { useEffect } from 'react'

export const ForgotPasswordForm = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { dataForgotPassword, loadingForgotPassword, errorForgotPassword, forgotPassword } = useSendForgotPassword()
  const { values, handleSubmit, handleChange, errors, setFieldValue, setFieldError } = useFormForgotPassword({
    onSubmit: values => {
      const { email, recaptcha } = values
      forgotPassword({ email, recaptcha })
    },
  })

  useEffect(() => {
    if (!dataForgotPassword) return
    Swal.fire({
      title: t('global.successTitle'),
      text: t('forgotPassword.successSendEmail'),
      icon: 'success',
      confirmButtonText: t('global.ok'),
    }).then(() => navigate('/home', { replace: true }))
  }, [dataForgotPassword])

  useEffect(() => {
    if (!errorForgotPassword) return
    if (errorForgotPassword.error === 'recordNotFound') setFieldError('email', t('signIn.emailNotRegistered'))
  }, [errorForgotPassword])

  return (
    <form className='rounded-3 p-3 sm:p-5 sm:w-1/2 sm:border border-white/30 max-w-md' onSubmit={handleSubmit}>
      <h2 className='text-xl font-Kaleko-bold'>{t('global.companyName')}</h2>
      <h1 className='text-xl mt-5 font-semibold'>{t('signIn.resetYourPassword')}</h1>
      <p className='text-base mb-4'>{t('forgotPassword.textForgotPasswordNew')}</p>
      <TextField
        name='email'
        label={t('global.email')}
        value={values.email}
        onChange={handleChange}
        errorMessage={errors.email}
        placeholder={t('profile.placeholderEmail')}
        themeDark
      />
      <div className='flex flex-col items-center'>
        <ReCAPTCHA
          name='recaptcha'
          render='explicit'
          onChange={value => setFieldValue('recaptcha', value)}
          sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
        />
        {errors.recaptcha && <FieldMessage message={errors.recaptcha} />}
      </div>
      <div className='flex flex-col gap-2 justify-center mt-3'>
        <button
          type='submit'
          className='rounded-full bg-primary-400 py-3 px-3text-base font-bold w-full hover:bg-primary transition duration-200'
        >
          {loadingForgotPassword ? (
            <div className='flex items-center justify-center'>
              <Loader />
            </div>
          ) : (
            t('global.send')
          )}
        </button>
        <Link
          to='/home'
          className='rounded-full text-center bg-gray-400 p-3 text-base font-bold w-full hover:bg-gray-500 transition duration-200'
        >
          {t('global.cancel')}
        </Link>
      </div>
    </form>
  )
}
