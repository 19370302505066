import vetorDeposito from '@/assets/images/vetorDepositPage.svg'
import { Loading } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { useAuth, useBalance } from '@/contexts'
import { useCotation } from '@/hooks'
import { ButtonTypeDeposit, CriptoForm, HistoricCard, PixForm, QRForm } from '@/pages/Deposit/components'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaEthereum } from 'react-icons/fa'
import { FiAlertCircle } from 'react-icons/fi'
import { MdOutlinePix } from 'react-icons/md'
import { SiBitcoinsv, SiTether } from 'react-icons/si'
import { VscRefresh } from 'react-icons/vsc'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { twMerge } from 'tailwind-merge'
import { APPROVED, BTC, ETH, EXPIRED, PIX, REFUSED, SKYT, USDT } from './constants'
import { useCancelDeposit, useDeposit, useDepositSkyt, useFetchDepositHistory, useRefreshQrCode } from './hooks'

export const Deposit = () => {
  const { user } = useAuth()
  const { refreshBalance, companyCoin } = useBalance()
  const { dataDepositHistory, depositHistoryRequest, loadingDepositHistory } = useFetchDepositHistory()
  const { cancelDepositRequest, dataCancelDeposit, loadingCancelDeposit } = useCancelDeposit()
  const { bitcoinValue, dollarValue, cotation, ethereumValue, loadingCotation } = useCotation()
  const { dataRefreshQrCode, refreshQrCodeRequest } = useRefreshQrCode()
  const { dataDeposit, depositRequest, errorDeposit, loadingDeposit } = useDeposit()
  const { dataDepositSkyt, depositSkytRequest, errorDepositSkyt, loadingDepositSkyt } = useDepositSkyt()
  const [isSelectPix, setIsSelectPix] = useState(true)
  const [idDeposit, setIdDeposit] = useState()
  const [valueBtc, setValueBtc] = useState()
  const [verifyQrCode, setVerifyQrCode] = useState(false)
  const [isQRFormVisible, setIsQRFormVisible] = useState(false)
  const [qrCodeData, setQrCodeData] = useState({})
  const [crytoType, setCrytoType] = useState(BTC)
  const [isSelectBTC, setIsSelectBTC] = useState(false)
  const [isSelectEther, setIsSelectEther] = useState(true)
  const [isSelectUSTD, setIsSelectUSTD] = useState(true)
  // const [isSelectPaypal, setIsSelectPaypal] = useState(true)
  // const [isSelectSKYT, setIsSelectSKYT] = useState(true)
  const [selectedIcon, setSelectedIcon] = useState(<SiBitcoinsv />)
  const [isForm, setIsForm] = useState(2)
  const [selectedValue, setSelectedValue] = useState('20')
  const [cpf, setCpf] = useState('')
  const [email, setEmail] = useState('')
  const { t } = useTranslation()

  const setStates = (value, form = 2) => {
    let pix = true
    let btc = true
    let ether = true
    let ustd = true
    // let skyt = true
    // let paypal = true

    switch (value) {
      case 'pix':
        pix = false
        setSelectedIcon(<MdOutlinePix />)
        setCrytoType(PIX)
        break
      case 'btc':
        btc = false
        setSelectedIcon(<SiBitcoinsv />)
        setCrytoType(BTC)
        break
      case 'ether':
        ether = false
        setSelectedIcon(<FaEthereum />)
        setCrytoType(ETH)
        break
      case 'ustd':
        ustd = false
        setSelectedIcon(<SiTether />)
        setCrytoType(USDT)
        break
      // case 'skyt':
      //   skyt = false
      //   setSelectedIcon(<RiCloudLine />)
      //   setCrytoType(SKYT)
      //   break
      // case 'paypal':
      //   paypal = false
      //   setSelectedIcon(<FaPaypal />)
      //   break
      default:
        pix = false
        setSelectedIcon(null)
        break
    }
    setSelectedValue('20')
    setIsSelectPix(pix)
    setIsSelectBTC(btc)
    setIsSelectEther(ether)
    setIsSelectUSTD(ustd)
    // setIsSelectSKYT(skyt)
    // setIsSelectPaypal(paypal)
    setIsForm(form)
  }

  useEffect(() => {
    setEmail(user.email)
    if (user.cpf?.length === 11) setCpf(user.cpf)
    const valueBtcStorage = window.localStorage.getItem('valueDeposit')
    const type = window.localStorage.getItem('deposit:type')
    const initialValue = window.localStorage.getItem('selectedValue')
    if (initialValue) setSelectedValue(initialValue)
    if (valueBtcStorage) setValueBtc(valueBtcStorage)
    if (type) {
      let pix = true
      let btc = true
      let ether = true
      let ustd = true
      setCrytoType(type)

      switch (Number(type)) {
        case 1:
          pix = false
          setSelectedIcon(<MdOutlinePix />)
          setIsForm(1)
          break
        case 2:
          btc = false
          setSelectedIcon(<SiBitcoinsv />)
          setCrytoType(BTC)
          break
        case 3:
          ether = false
          setSelectedIcon(<FaEthereum />)
          setCrytoType(ETH)
          break
        case 4:
          ustd = false
          setSelectedIcon(<SiTether />)
          setCrytoType(USDT)
          break
        default:
          pix = false
          setSelectedIcon(null)
          break
      }
      setIsSelectPix(pix)
      setIsSelectBTC(btc)
      setIsSelectEther(ether)
      setIsSelectUSTD(ustd)
    }

    cotation()
    depositHistoryRequest()
    const getQrCode = window.localStorage.getItem('deposit:qrCode')
    const userId = window.localStorage.getItem('deposit:id')
    if (getQrCode && userId === user.id) {
      setVerifyQrCode(true)
      setIsQRFormVisible(true)
      const objectData = JSON.parse(getQrCode)
      setQrCodeData(objectData)
      setIdDeposit(objectData.id)
    }
  }, [])

  useEffect(() => {
    if (!errorDeposit) return
    if (errorDeposit.error === 'depositMinimumAmount') {
      toast.error(`${t('deposit.minimumDepositAmount')} ${user.settings.DEPOSIT_MINIMUM_AMOUNT}`, {
        position: 'bottom-center',
        autoClose: 2500,
      })
      return
    }
    toast.error(t('deposit.errorDeposit'), {
      autoClose: 3500,
    })
  }, [errorDeposit])

  useEffect(() => {
    if (!dataCancelDeposit) return
    setIsQRFormVisible(false)
    setVerifyQrCode(false)
    setQrCodeData({})
    window.localStorage.removeItem('deposit:qrCode')
    window.localStorage.removeItem('selectedValue')
    window.localStorage.removeItem('deposit:type')
    window.localStorage.removeItem('deposit:id')
    window.localStorage.removeItem('valueDeposit')
    toast.success(t('global.cancelDepositSuccess'), {
      autoClose: 3500,
    })
    depositHistoryRequest()
  }, [dataCancelDeposit])

  useEffect(() => {
    if (dataDeposit.length === 0 || !dataDeposit) return
    window.localStorage.setItem('deposit:qrCode', JSON.stringify(dataDeposit))
    window.localStorage.setItem('deposit:id', user.id)
    setQrCodeData(dataDeposit)
    setIsQRFormVisible(true)
    setVerifyQrCode(true)
    setIdDeposit(dataDeposit.id)
    depositHistoryRequest()
  }, [dataDeposit])

  useEffect(() => {
    if (dataDepositSkyt.length === 0 || !dataDepositSkyt) return
    Swal.fire({
      title: t('global.successTitle'),
      confirmButtonText: 'Ok',
      icon: 'success',
      text: t('deposit.successDeposit'),
    }).then(result => {
      if (result.isConfirmed) {
        depositHistoryRequest()
        refreshBalance()
      }
    })
  }, [dataDepositSkyt])

  useEffect(() => {
    if (!errorDepositSkyt) return
    if (errorDepositSkyt.error === 'insufficientBalance') {
      toast.error(t('p2p.errorInsufficientBalance'))
      return
    }
    if (errorDepositSkyt.error === 'depositMinimumAmount') {
      toast.error(`${t('deposit.minimumDepositAmount')} ${user.settings.DEPOSIT_MINIMUM_AMOUNT}`, {
        position: 'bottom-center',
        autoClose: 2500,
      })
      return
    }
    toast.error(t('deposit.errorDeposit'), {
      autoClose: 3500,
    })
    depositHistoryRequest()
  }, [errorDepositSkyt])

  useEffect(() => {
    if (verifyQrCode) {
      const intervalId = setInterval(() => {
        refreshQrCodeRequest(idDeposit)
      }, 1000)
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [verifyQrCode])

  useEffect(() => {
    if (!dataRefreshQrCode) return
    if (dataRefreshQrCode.status === APPROVED) {
      setVerifyQrCode(false)
      setIsQRFormVisible(false)
      setQrCodeData({})
      window.localStorage.removeItem('deposit:qrCode')
      window.localStorage.removeItem('selectedValue')
      window.localStorage.removeItem('deposit:type')
      window.localStorage.removeItem('deposit:id')
      window.localStorage.removeItem('valueDeposit')
      Swal.fire({
        title: t('global.successTitle'),
        confirmButtonText: 'Ok',
        icon: 'success',
        text: t('deposit.successDeposit'),
      }).then(result => {
        if (result.isConfirmed) {
          depositHistoryRequest()
          refreshBalance()
        }
      })
    }
    if (dataRefreshQrCode.status === REFUSED || dataRefreshQrCode.status === EXPIRED) {
      if (dataRefreshQrCode.status === EXPIRED) toast.error(t('deposit.expiredDeposit'))
      setVerifyQrCode(false)
      setIsQRFormVisible(false)
      setQrCodeData({})
      window.localStorage.removeItem('deposit:qrCode')
      window.localStorage.removeItem('selectedValue')
      window.localStorage.removeItem('deposit:type')
      window.localStorage.removeItem('deposit:id')
      window.localStorage.removeItem('valueDeposit')
      // depositHistoryRequest(isSelectSKYT)
    }
  }, [dataRefreshQrCode])

  if (loadingDepositHistory || loadingCotation) return <Loading />

  return (
    <>
      <main
        className={twMerge(classNames('flex flex-col lg:grid lg:grid-cols-2 lg:gap-4', { 'lg:grid-cols-1': isQRFormVisible }))}
      >
        {!isQRFormVisible && (
          <section>
            <section className='text-white'>
              <h1 className='font-extrabold text-3xl mb-2'>{t('deposit.depositScreenTitle')}</h1>
              <p>{t('deposit.tagSelection')}</p>
            </section>

            <section className='flex flex-col gap-2 my-2 w-full'>
              <div className='grid grid-cols-2 gap-4'>
                <ButtonTypeDeposit
                  icon={<SiBitcoinsv />}
                  text={t('deposit.buttonBTC')}
                  isSelect={isSelectBTC}
                  onClick={() => setStates('btc')}
                />
                <ButtonTypeDeposit
                  icon={<FaEthereum />}
                  text={t('deposit.buttonEther')}
                  isSelect={isSelectEther}
                  onClick={() => setStates('ether')}
                />
                <ButtonTypeDeposit
                  icon={<SiTether />}
                  text={t('deposit.buttonUSTD')}
                  usdt='TRON'
                  isSelect={isSelectUSTD}
                  onClick={() => setStates('ustd')}
                />
                <ButtonTypeDeposit
                  icon={<MdOutlinePix />}
                  text={t('deposit.buttonPix')}
                  isSelect={isSelectPix}
                  onClick={() => setStates('pix', 1)}
                />
                {/* <ButtonTypeDeposit
                  icon={<FaPaypal />}
                  text={t('deposit.buttonPaypal')}
                  isSelect={isSelectPaypal}
                  onClick={() => setStates('paypal')}
                />
                <ButtonTypeDeposit
                  icon={<RiCloudLine />}
                  text={t('deposit.buttonSKYT')}
                  isSelect={isSelectSKYT}
                  onClick={() => setStates('skyt')}
                /> */}
              </div>

              {crytoType !== SKYT && (
                <div className='flex items-center my-2 gap-1 text-xs'>
                  <span className='text-base mb-1 text-white'>
                    <FiAlertCircle />
                  </span>
                  <p className='text-white font-semibold'>
                    {t('deposit.minimumDepositAmount')} <MoneyAsText value={user.settings.DEPOSIT_MINIMUM_AMOUNT} />
                  </p>
                </div>
              )}
            </section>

            <div className='hidden lg:flex'>
              <img
                src={vetorDeposito}
                alt={t('deposit.imgAlt')}
                className='hidden lg:flex lg:m-auto lg:mt-[2.438rem] lg:max-w-[450px] xl:max-w-[500px]'
              />
            </div>
          </section>
        )}

        <section>
          <p className='text-white lg:mt-[3.5rem]'>
            {t('deposit.depositInformations')}{' '}
            {crytoType === 4 && <span className='text-sm'>({t('deposit.informationUsdt')})</span>}
          </p>
          <div className='bg-white rounded-3 my-2 p-4 text-primary-700'>
            <div className='flex justify-between gap-2'>
              <p className='text-sm lg:text-base'>
                {isForm === 1 ? (
                  <>{t('deposit.estimationUSD')} </>
                ) : crytoType === BTC ? (
                  <>{t('deposit.estimationBTC')} </>
                ) : crytoType === ETH ? (
                  <>{t('deposit.estimationETH')} </>
                ) : (
                  crytoType === 4 && <span className='font-bold'>{t('deposit.informationUsdt')}</span>
                )}
                <span className='font-semibold'>
                  {isForm === 1 ? (
                    <MoneyAsText prefix='R$ ' value={dollarValue} />
                  ) : crytoType === BTC ? (
                    <MoneyAsText value={bitcoinValue} />
                  ) : crytoType === ETH ? (
                    <MoneyAsText value={ethereumValue} />
                  ) : (
                    crytoType === USDT || (crytoType === SKYT && <></>)
                  )}
                </span>

                {crytoType === SKYT && (
                  <span className='flex gap-1 items-center p-2 px-3 rounded bg-green-500/40 text-sm ml-auto'>
                    {t('p2p.SkytBallance')}
                    <span>
                      <MoneyAsText prefix=' ' value={companyCoin} />
                    </span>
                  </span>
                )}
              </p>
              <span className='text-4xl text-blue-700'>{selectedIcon}</span>
            </div>
            <form>
              {!isQRFormVisible && isForm === 2 && (
                <CriptoForm
                  loadingDeposit={loadingDeposit}
                  loadingDepositSkyt={loadingDepositSkyt}
                  setValueBtc={setValueBtc}
                  dollarValue={dollarValue}
                  ethereumValue={ethereumValue}
                  bitcoinValue={bitcoinValue}
                  crytoType={crytoType}
                  depositRequest={depositRequest}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                  depositSkytRequest={depositSkytRequest}
                  companyCoin={companyCoin}
                />
              )}
              {!isQRFormVisible && isForm === 1 && (
                <PixForm
                  dollarValue={dollarValue}
                  depositRequest={depositRequest}
                  loadingDeposit={loadingDeposit}
                  cpf={cpf}
                  isForm={isForm}
                  email={email}
                  setCpf={setCpf}
                  setEmail={setEmail}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                />
              )}
              {isQRFormVisible && (
                <div className='w-full'>
                  <QRForm
                    dollarValue={dollarValue}
                    selectedValue={selectedValue}
                    crytoType={crytoType}
                    valueBtc={valueBtc}
                    isForm={isForm}
                    setIsQRFormVisible={setIsQRFormVisible}
                    setVerifyQrCode={setVerifyQrCode}
                    data={qrCodeData}
                    setQrCodeData={setQrCodeData}
                  />
                </div>
              )}
            </form>
          </div>
        </section>
      </main>
      <section className='mt-4'>
        <div className='flex items-center gap-1 text-white'>
          <span>
            <VscRefresh />
          </span>
          <p>{t('deposit.depositHistory')}</p>
        </div>

        <section>
          <HistoricCard
            setIdDeposit={setIdDeposit}
            dataDepositHistory={dataDepositHistory}
            cancelDepositRequest={cancelDepositRequest}
            idDeposit={idDeposit}
            loadingCancelDeposit={loadingCancelDeposit}
          />
        </section>
      </section>
    </>
  )
}
