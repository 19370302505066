import { useViewport } from '@/hooks'
import { useArbitrage } from '../contexts'
import { DateAsText, Loader, PercentAsText } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { StatusBadge } from '.'
import { useTranslation } from 'react-i18next'
import { FiAlertCircle } from 'react-icons/fi'
import { OperationsBadge } from './OperationsBadge'
import { useState } from 'react'
import { BsArrowsExpand, BsArrowsCollapse } from 'react-icons/bs'
import { Tooltip } from '@chakra-ui/react'
import classNames from 'classnames'

export const RecentOperations = () => {
  const { t } = useTranslation()
  const { isMoreXl } = useViewport()
  const { loadingOperationsArbitration, dataOperationsArbitration, loading } = useArbitrage()
  const [isDetail, setIsDetail] = useState(false)
  const [id, setIsId] = useState(null)
  const [filterOperation, setFilterOperation] = useState('process')
  const OperationData =
    dataOperationsArbitration &&
    dataOperationsArbitration.filter(item => {
      if (filterOperation === 'history' && item.status > 2) {
        return item
      }
      if (filterOperation === 'process' && item.status <= 2) {
        return item
      }
    })

  const handleOpenCollapse = itemId => {
    if (itemId === id) {
      setIsId(null)
      setIsDetail(false)
      return
    }

    setIsId(itemId)
    setIsDetail(true)
  }

  return (
    <section className='flex flex-col p-3 gap-3 lg:gap-0'>
      <div className='flex gap-1'>
        <p
          onClick={() => setFilterOperation('process')}
          className={classNames('text-base font-medium text-center cursor-pointer p-2 md:px-3 rounded-2 lg:rounded-b-none', {
            'text-yellow-400 dark:text-yellow-500 font-semibold bg-primary-400 dark:bg-primary-600 lg:border-b-2 border-gray-400':
              filterOperation === 'process',
          })}
        >
          {t('arbitrage.openOperations')}
        </p>

        <p
          onClick={() => setFilterOperation('history')}
          className={classNames('text-base font-medium text-center cursor-pointer p-2 md:px-3 rounded-2 lg:rounded-b-none', {
            'text-yellow-400 dark:text-yellow-500 font-semibold bg-primary-400 dark:bg-primary-600 lg:border-b-2 border-gray-400':
              filterOperation === 'history',
          })}
        >
          {t('arbitrage.operationsHistory')}
        </p>
      </div>

      {!isMoreXl && (
        <>
          {!loadingOperationsArbitration && !loading && OperationData.length === 0 && (
            <div className='flex text-sm mt-3 gap-1 justify-center'>
              <FiAlertCircle />
              <p>{t('arbitrage.notRecordsFound')}</p>
            </div>
          )}
          {OperationData.map((item, index) => (
            <div key={index} className='border dark:border-gray-200 border-gray-800 p-2 rounded-2 lg:hidden'>
              <p className='whitespace-nowrap'>
                {t('arbitrage.broker')}:{' '}
                <span className='font-bold '>
                  {item.buyingBroker.name.split(' ')[0] + ' / ' + item.salesBroker.name.split(' ')[0]}
                </span>
              </p>
              <p className='whitespace-nowrap'>
                {t('arbitrage.purchasePrice')}:{' '}
                <span className='text-positive font-bold'>
                  <MoneyAsText value={item.purchasePrice} />
                </span>
              </p>
              <p className='whitespace-nowrap'>
                {t('arbitrage.salesOffers')}:{' '}
                <span className='text-negative font-bold'>
                  <MoneyAsText value={item.salePrice} />
                </span>
              </p>
              <p className='whitespace-nowrap'>
                {' '}
                {t('arbitrage.percentage')}: <PercentAsText value={item.arbitragePercent} />
              </p>
              <p>
                {t('arbitrage.purchaseSaleDate')}: <DateAsText type='datetime' date={item.purchaseDate} />
              </p>
              <p className='whitespace-nowrap'>
                {t('arbitrage.amountsent')}: <MoneyAsText value={item.amount} />
              </p>
              <p className='whitespace-nowrap truncate'>
                {t('arbitrage.transaction')}:{' '}
                <a
                  target='_blank'
                  className='cursor-pointer whitespace-nowrap truncate underline'
                  href={item.hashUrl ?? `https://blockchain.com/explorer/transactions/btc/${item.transactionHash}`}
                  rel='noreferrer'
                >
                  {item.transactionHash ?? '-'}
                </a>
              </p>
              <p className='whitespace-nowrap'>
                {t('arbitrage.status')}: <StatusBadge status={item.status} />
              </p>
              <p>
                {t('arbitrage.automaticOperations')}: <OperationsBadge operation={item.isAutomaticOperation} />
              </p>
            </div>
          ))}
        </>
      )}
      {!isMoreXl && (loadingOperationsArbitration || loading) && (
        <div className='flex justify-center items-center my-5'>
          <Loader />
        </div>
      )}
      <div className='hidden lg:flex overflow-x-auto scrollbar-hide'>
        <table className='table-auto w-full'>
          <thead>
            <tr className='bg-primary-400 dark:bg-primary-600 text-white text-start text-sm'>
              <th className='p-2 text-start'></th>
              <th className='p-2 text-start'>{t('arbitrage.broker')}</th>
              <th className='p-2 whitespace-nowrap text-right'>{t('arbitrage.purchasePrice')}</th>
              <th className='p-2 whitespace-nowrap text-right'>{t('arbitrage.salesOffers')}</th>
              <th className='p-2 text-right'>{t('arbitrage.percentage')}</th>
              <th className='p-2 whitespace-nowrap text-center'>{t('arbitrage.purchaseSaleDate')}</th>
              <th className='p-2 whitespace-nowrap text-right'>{t('arbitrage.amountsent')}</th>
              <th className='p-2 w-10'>{t('arbitrage.status')}</th>
              <th className='p-2 w-10 whitespace-nowrap'>{t('arbitrage.automaticOperations')}</th>
              <th className='p-2'>{t('arbitrage.transaction')}</th>
            </tr>
          </thead>
          <tbody>
            {!loadingOperationsArbitration && !loading && OperationData.length === 0 && (
              <tr>
                <td colSpan='10'>
                  <div className='flex text-sm mt-3 gap-1 justify-center'>
                    <FiAlertCircle />
                    <p>{t('arbitrage.notRecordsFound')}</p>
                  </div>
                </td>
              </tr>
            )}
            {!loadingOperationsArbitration &&
              OperationData.map((item, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td className='p-2 whitespace-nowrap font-bold'>
                        <Tooltip hasArrow label='Detalhes da Transação'>
                          <button
                            onClick={() => {
                              handleOpenCollapse(item.id)
                            }}
                            className='focus:text-inherit'
                          >
                            {item.id === id && isDetail ? (
                              <BsArrowsCollapse size={24} className='hover:animate-pulse' />
                            ) : (
                              <BsArrowsExpand size={24} className='hover:animate-pulse' />
                            )}
                          </button>
                        </Tooltip>
                      </td>
                      <td className='p-2 whitespace-nowrap font-bold text-start'>
                        {item.buyingBroker.name.split(' ')[0] + ' / ' + item.salesBroker.name.split(' ')[0]}
                      </td>
                      <td className='p-2 text-positive font-bold whitespace-nowrap text-right'>
                        <MoneyAsText value={item.purchasePrice} />
                      </td>
                      <td className='p-2 text-negative font-bold whitespace-nowrap text-right'>
                        <MoneyAsText value={item.salePrice} />
                      </td>
                      <td className='p-2 text-right'>
                        {item.transactionHash ? <PercentAsText value={item.arbitragePercent} /> : '-'}
                      </td>
                      <td className='p-2 text-center whitespace-nowrap'>
                        <DateAsText type='datetime' date={item.purchaseDate} />
                      </td>
                      <td className='p-2 text-right'>
                        <MoneyAsText value={item.amount} />
                      </td>
                      <td className='p-2'>
                        <StatusBadge status={item.status} />
                      </td>
                      <td className='text-center p-2'>
                        <OperationsBadge operation={item.isAutomaticOperation} />
                      </td>
                      <td style={{ maxWidth: '250px' }} className='p-2 truncate underline'>
                        <a
                          href={item.hashUrl ?? `https://blockchain.com/explorer/transactions/btc/${item.transactionHash}`}
                          target='_blank'
                          className='cursor-pointer'
                          rel='noreferrer'
                        >
                          {item.transactionHash}
                        </a>
                      </td>
                    </tr>

                    <tr
                      className={classNames('-translate-y-1 opacity-0 transition-all duration-700', {
                        'translate-y-0 opacity-100': item.id === id && isDetail,
                      })}
                    >
                      <td colSpan={10}>
                        {item.id === id && isDetail && (
                          <div className='dark:bg-primary-700 bg-primary-400 text-white flex flex-col gap-2 pl-[55px] py-2 rounded-2 text-base'>
                            <span>
                              {t('arbitrage.refund')}:{' '}
                              <MoneyAsText value={item.arbitrationTransaction.amount} className=' font-semibold' />
                            </span>
                            <span>
                              {t('arbitrage.profit')}:{' '}
                              <MoneyAsText value={item.profitTransaction.amount} className='text-positive font-semibold' />
                            </span>
                            <span>
                              {t('arbitrage.tax')}:{' '}
                              <MoneyAsText value={item.operationTaxAmount} className='text-negative font-semibold' />
                            </span>
                          </div>
                        )}
                      </td>
                    </tr>
                  </>
                )
              })}
            {(loadingOperationsArbitration || loading) && (
              <tr>
                <td colSpan='10' align='center'>
                  <div className='mt-3'>
                    <Loader />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </section>
  )
}
