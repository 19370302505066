import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { InputCpfCnpj, Label } from '@/components/Form'
import { FieldMessage } from '@/components/Form/Fields/FieldMessage'

export const CpfCnpjField = ({
  name,
  onChange,
  noMargins,
  label,
  value,
  className,
  id,
  isDisabled,
  errorMessage,
  format,
  mask,
  ...rest
}) => {
  const isInvalid = errorMessage !== ''
  const labelClasses = isInvalid ? 'text-negative' : ''

  return (
    <div className={classNames({ 'mb-3': !noMargins }, className)}>
      <Label className={classNames(labelClasses)} text={label} htmlFor={id ?? name} isInvalid={isInvalid} />
      <InputCpfCnpj
        onChange={onChange}
        value={value}
        id={id ?? name}
        name={name}
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        format={format}
        mask={mask}
        {...rest}
      />
      {isInvalid && <FieldMessage message={errorMessage} />}
    </div>
  )
}

CpfCnpjField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  noMargins: PropTypes.bool,
  format: PropTypes.string,
  mask: PropTypes.string,
}

CpfCnpjField.defaultProps = {
  className: '',
  isDisabled: false,
  errorMessage: '',
  noMargins: false,
}
