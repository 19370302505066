import React from 'react'
import { useTranslation } from 'react-i18next'
import { SelectField } from './SelectFieldValue'
import { RiErrorWarningFill } from 'react-icons/ri'
import { Loader } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import Swal from 'sweetalert2'
import { BTC, ETH, SKYT, USDT } from '../constants'
import { toast } from 'react-toastify'

export const CriptoForm = ({
  setSelectedValue,
  selectedValue,
  depositRequest,
  crytoType,
  bitcoinValue,
  dollarValue,
  ethereumValue,
  setValueBtc,
  loadingDeposit,
  depositSkytRequest,
  loadingDepositSkyt,
  companyCoin,
}) => {
  const { t } = useTranslation()

  const handleSelectChange = value => {
    setSelectedValue(value)
  }

  const calc = Number(
    (
      Number(selectedValue) /
      (crytoType === BTC ? bitcoinValue : crytoType === ETH ? ethereumValue : crytoType === USDT && dollarValue)
    ).toFixed(8)
  )

  const handleSubmitCrypto = () => {
    if (crytoType === SKYT) {
      if (companyCoin < Number(selectedValue)) {
        toast.error(t('p2p.errorInsufficientBalance'))
        return
      }
      Swal.fire({
        title: t('deposit.attention'),
        text: t('deposit.confirmDepositSkyt'),
        icon: 'warning',
        confirmButtonText: t('deposit.continue'),
        showCancelButton: true,
        cancelButtonText: t('global.cancel'),
        cancelButtonColor: '#D92D20',
        confirmButtonColor: '#039855',
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          depositSkytRequest({ amount: Number(selectedValue) })
        }
      })
      return
    }
    setValueBtc(crytoType == USDT ? selectedValue : calc)
    window.localStorage.setItem('valueDeposit', crytoType == USDT ? selectedValue : calc)
    window.localStorage.setItem('deposit:type', crytoType)
    depositRequest({
      amount: selectedValue,
      amountCrypto: calc,
      type: crytoType,
    })
  }

  return (
    <>
      <div className='flex flex-col justify-center items-center'>
        <SelectField value={selectedValue} onChange={handleSelectChange} skyt={crytoType === SKYT} />
        <div className='flex flex-col gap-2 px-4 py-3 my-4 justify-center items-center bg-gray-400 text-white rounded-2 w-full'>
          <p>{t('deposit.youDeposit')}</p>
          <span className='font-extrabold'>
            {crytoType === USDT || crytoType === SKYT ? (
              <MoneyAsText value={!selectedValue ? 0 : selectedValue} />
            ) : !calc ? (
              0
            ) : (
              calc
            )}
          </span>
        </div>
        <button
          onClick={() => handleSubmitCrypto()}
          type='button'
          disabled={loadingDeposit || loadingDepositSkyt}
          className='bg-primary-300 text-white font-bold p-4 w-full rounded-2 lg:font-normal lg:p-3'
        >
          {loadingDeposit || loadingDepositSkyt ? (
            <Loader className='inline-block' />
          ) : crytoType === SKYT ? (
            t('deposit.depositSkyt')
          ) : (
            t('deposit.pixDetailButton')
          )}
        </button>
        {crytoType === USDT && (
          <p className='flex gap-2 mt-3 text-sm text-yellow-500 font-bold'>
            <RiErrorWarningFill className='h-3 w-3' /> {t('deposit.networkUSDT')}
          </p>
        )}
        <hr className='border border-gray-200 w-full mt-3' />
        <div className='flex flex-col gap-2 text-xs text-yellow-500 mt-3'>
          <p className='flex gap-2'>
            <RiErrorWarningFill /> {t('deposit.rate')}
          </p>
          <p className='flex gap-2'>
            <RiErrorWarningFill />
            {t('deposit.blockchain')}
          </p>
          <p className='flex gap-2'>
            <RiErrorWarningFill />
            {t('deposit.awaitDeposit')}
          </p>
        </div>
      </div>
    </>
  )
}
