import { useTranslation } from 'react-i18next'
import { Button, CheckBox } from '@/components/Form'
import { FieldMessage, PasswordField, PhoneField, TextField } from '@/components/Form/Fields'
import ReCAPTCHA from 'react-google-recaptcha'
import SignUpIcon from '@/assets/images/signUp-icon.png'
import { useNavigate, useParams } from 'react-router-dom'
import { useCreateCustomer, useIndicationCode, useSignUpForm } from '@/pages/SignUp/hooks'
import { useEffect } from 'react'
import Swal from 'sweetalert2'

export const SignUpForm = () => {
  const navigate = useNavigate()
  const { indicationCode } = useParams()
  const { t } = useTranslation()

  const { data, error, loading, createCustomer } = useCreateCustomer()
  const { data: dataIndicatedBy, indicatedByRequest } = useIndicationCode()

  const { handleSubmit, handleChange, setFieldValue, values, errors, setFieldError } = useSignUpForm({
    onSubmit: async values => {
      await createCustomer({ ...values, phone: values.phone.replace(/[^0-9]+/g, '') })
    },
  })

  useEffect(() => {
    if (!indicationCode) return
    indicatedByRequest(indicationCode)
  }, [])

  useEffect(() => {
    if (!dataIndicatedBy) return
    setFieldValue('indicatedBy', dataIndicatedBy.id)
  }, [dataIndicatedBy])

  useEffect(() => {
    if (!data) return
    Swal.fire({
      title: `${t('signUp.successMessageTitle')}`,
      text: `${t('signUp.successMessageText')}`,
      icon: 'success',
      confirmButtonText: 'Ok',
    }).then(() => navigate('/sign-in'))
  }, [data])

  useEffect(() => {
    if (!error) return
    if (error.error === 'emailAlreadyUsed') setFieldError('email', `${t('signUp.validation.validationEmail')}`)
    if (error.error === 'recaptchaValidation') setFieldError('recaptcha', `${t('global.validation.validationRecaptcha')}`)
    if (error.error === 'phoneAlreadyUsed') setFieldError('phone', t('global.validation.validationPhoneSignUp'))
    if (error[0]?.message === `The 'email' must be a valid email`)
      setFieldError('email', `${t('signUp.validation.invalidEmail')}`)
  }, [error])

  return (
    <>
      <main className='flex lg:grid lg:grid-cols-2 gap-3'>
        <form className='flex flex-col gap-2 w-full' onSubmit={handleSubmit}>
          <div className='fixed bottom-[16px] md:bottom-[32px] right-[8px] md:right-[16px] z-[1000]'>
            {!dataIndicatedBy ? (
              <span className='hidden bg-gradient-to-r from-primary-200 to-primary-400 text-white font-semibold py-2 px-3 md:py-3 md:px-4 rounded-full'>
                {t('signUp.IndicatedBy')}:
              </span>
            ) : (
              <span className='bg-gradient-to-r from-primary-200 to-primary-400 text-white font-semibold py-2 px-3 md:py-3 md:px-4 rounded-full'>
                {t('signUp.IndicatedBy')}: {dataIndicatedBy?.fullName}
              </span>
            )}
          </div>
          <section className='flex flex-col gap-2 md:grid md:grid-cols-2 md:gap-3'>
            <TextField
              label={t('signUp.firstNameLabel')}
              className='text-white'
              name='firstName'
              placeholder={t('profile.placeholderName')}
              value={values.firstName}
              errorMessage={errors.firstName}
              onChange={handleChange}
              isDisabled={loading}
              noMargins
            />
            <TextField
              label={t('signUp.lastNameLabel')}
              className='text-white'
              name='lastName'
              placeholder={t('profile.placeholderLastName')}
              value={values.lastName}
              errorMessage={errors.lastName}
              onChange={handleChange}
              isDisabled={loading}
              noMargins
            />
          </section>
          <PhoneField
            label={t('signUp.phone')}
            className='text-white'
            signUp
            name='phone'
            placeholder={t('profile.placeholderPhone')}
            value={values.phone}
            errorMessage={errors.phone}
            onChange={e => setFieldValue('phone', e)}
            isDisabled={loading}
            noMargins
          />
          <TextField
            label={t('signIn.emailLabel')}
            className='text-white'
            name='email'
            placeholder={t('profile.placeholderEmail')}
            value={values.email}
            errorMessage={errors.email}
            onChange={handleChange}
            isDisabled={loading}
            noMargins
          />
          <PasswordField
            label={t('signIn.passwordLabel')}
            className='text-white'
            name='password'
            placeholder='******'
            value={values.password}
            errorMessage={errors.password}
            onChange={handleChange}
            isDisabled={loading}
            noMargins
          />
          <PasswordField
            label={t('signIn.confirmPasswordLabel')}
            className='text-white'
            name='confirmPassword'
            placeholder='******'
            value={values.confirmPassword}
            errorMessage={errors.confirmPassword}
            onChange={handleChange}
            isDisabled={loading}
            noMargins
          />
          <div className='flex flex-col items-center mt-2'>
            <ReCAPTCHA
              name='recaptcha'
              render='explicit'
              onChange={value => setFieldValue('recaptcha', value)}
              sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
            />
            {errors.recaptcha && <FieldMessage message={errors.recaptcha} />}
          </div>

          <CheckBox
            labelClassName='text-white'
            label={t('signIn.termsLabel')}
            name='check'
            value={values.check}
            onChange={e => {
              setFieldValue('check', e.target.checked)
            }}
            isDisabled={loading}
            checked={values.check}
            className='mt-2'
          />
          <a className='text-xs text-gray-400 underline text-end' target='_blank' href='/terms.pdf'>
            {t('signIn.termsAndConditionsLabel')}
          </a>
          <Button
            className='w-full py-3 bg-primary/80 font-bold mt-1'
            text={t('signUp.signUpNow')}
            type='submit'
            isDisabled={loading || !values.check}
          />
        </form>
        <section className='hidden lg:flex lg:flex-col lg:gap-2 ml-4'>
          <div className='text-white'>
            <span className='lg:text-md xl:text-lg font-bold'>{t('signUp.titleSignUp')}</span>
            <p className='lg:text-sm xl:text-md'>{t('signUp.subTitleSignUp')}</p>
          </div>

          <img src={SignUpIcon} alt='Homem sentado utilizando um Notebook' className='xl:max-w-[585px]' />
        </section>
      </main>
      <hr className='border w-full mt-3' />
    </>
  )
}
