import MoneyAsText from '@/components/Common/MoneyAsText'
import { useTranslation } from 'react-i18next'
import { RiErrorWarningFill } from 'react-icons/ri'
import { BTC, ETH, USDT } from '../constants'
import PixLink from './PixLink'
import btcCode from '/images/BTC_CODE.jpeg'
import ethCode from '/images/ETH_CODE.jpeg'
import usdtCode from '/images/USDT_CODE.jpeg'

export const QRForm = ({ data, isForm, valueBtc, selectedValue, crytoType, dollarValue }) => {
  const { t } = useTranslation()
  const qrCodeCrypto = BTC ? btcCode : ETH ? ethCode : USDT ? usdtCode : ''

  const paymentCodeCrypto = BTC
    ? 'bc1qke4jcn0gjwdarnlpmvl4nl4hy2zhmp3xm2xnqk'
    : ETH
    ? '0xC6ab9a3a0F2Ef0f3F83AA83A116dE51B956aBeFd'
    : USDT
    ? 'TYQxFiWsJb4kUS4dkStB6xZuMy4mrmujAU'
    : ''
  return (
    <>
      <div className='flex flex-col justify-center items-center py-3 gap-3'>
        <img src={isForm === 2 ? qrCodeCrypto : data.paymentQrCode} className='w-96 h-9w-96' />
      </div>
      {isForm === 1 && (
        <div className='flex justify-center pb-3'>
          <p className='text-slate-700 font-bold'>{t('deposit.pixKeyLabel')}</p>
        </div>
      )}
      {isForm === 2 && (
        <div className='flex justify-center pb-3'>
          <p className='text-slate-700 font-bold'>{t('deposit.wallet')}</p>
        </div>
      )}
      <div className='flex justify-center pb-4 w-full'>
        <PixLink code={isForm === 2 ? paymentCodeCrypto : data.paymentCode} />
      </div>
      {isForm === 2 && (
        <div>
          <p className='mx-auto w-fit'>{t('deposit.confirmPaymentEmail')}</p>
        </div>
      )}
      {/* {isForm === 1 && (
        <p className='w-full text-gray-400 text-center'>
          {t('gateway.payment')}: {data.gatewayName ?? 'Orion Tecnologia'}
        </p>
      )} */}
      <div className='flex flex-col gap-2 px-4 py-3 my-4 justify-center items-center bg-gray-400 text-white rounded-2 w-full'>
        <p>{t('deposit.youDeposit')}</p>
        <span className='font-extrabold'>
          {crytoType === USDT ? (
            <MoneyAsText value={valueBtc} />
          ) : isForm === 1 ? (
            <MoneyAsText value={selectedValue * dollarValue} prefix='R$ ' />
          ) : (
            valueBtc
          )}
        </span>
      </div>
      <hr className='border border-gray-200 w-full mt-3' />
      <div className='flex flex-col gap-2 text-xs text-yellow-500 mt-3'>
        <p className='flex gap-2'>
          <RiErrorWarningFill />
          {t('deposit.awaitDeposit')}
        </p>
      </div>
    </>
  )
}
