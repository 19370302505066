import { Label } from '@/components/Form/Label'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FieldMessage } from '@/components/Form/Fields'

export const RadioGroup = ({
  name,
  options,
  inline,
  onChange,
  value,
  className,
  isDisabled,
  errorMessage,
  labelClassName,
  spanClassName,
  secText,
  mainClassName,
}) => {
  const isInvalid = errorMessage !== ''

  return (
    <div className='flex flex-col'>
      <div className={classNames('flex', mainClassName, { 'flex-row gap-4': inline, 'flex-col': !inline })}>
        {options.map((option, key) => {
          return (
            <Label
              isInvalid={isInvalid}
              key={key}
              htmlFor={`${name}_${key}`}
              className={classNames('flex gap-2 cursor-pointer', labelClassName)}
            >
              <input
                type='radio'
                id={`${name}_${key}`}
                disabled={isDisabled}
                checked={option.value == value}
                value={option.value}
                onChange={onChange}
                className={className}
              />
              <span className={spanClassName}>
                {option.label} {secText && <span className='text-base font-sans'>{secText}</span>}{' '}
              </span>
            </Label>
          )
        })}
      </div>
      {isInvalid && <FieldMessage message={errorMessage} />}
    </div>
  )
}

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  isDisabled: PropTypes.bool,
  errorMessage: PropTypes.string,
}

RadioGroup.defaultProps = {
  options: [],
  inline: true,
  isDisabled: false,
  isInvalid: false,
  optionPrimary: ':: Selecione ::',
  errorMessage: '',
}
