import ReCAPTCHA from 'react-google-recaptcha'
import { useFormImpersonate } from './hooks/useFormImpersonate'
import { FieldMessage, PasswordField } from '@/components/Form/Fields'
import { useFetchSignInImpersonate } from './hooks/useFetchSignInImpersonate'
import { useNavigate, useSearchParams } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loader } from '@/components/Common'
import { useAuth } from '@/contexts'
import Swal from 'sweetalert2'

export const Impersonate = () => {
  const [searchParams] = useSearchParams()
  const { refresh } = useAuth()
  const tokenParam = searchParams.get('token')
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { dataSignInImpersonate, errorSignInImpersonate, fetchSignInImpersonate } = useFetchSignInImpersonate()
  const { errors, values, handleChange, handleSubmit, setFieldValue } = useFormImpersonate({
    onSubmit: values => {
      setLoading(true)
      fetchSignInImpersonate(values)
    },
  })

  useEffect(() => {
    if (tokenParam) {
      window.localStorage.removeItem('auth:user')
      const decoded = jwt_decode(tokenParam)
      setFieldValue('email', decoded.email)
      window.localStorage.setItem('impersonate', true)
      return
    }
  }, [tokenParam])

  useEffect(() => {
    if (!dataSignInImpersonate) return
    window.localStorage.removeItem('impersonate')
    window.localStorage.setItem('auth:user', tokenParam)
    setTimeout(() => {
      refresh().finally(() => {
        setLoading(false)
        navigate(`/arbitrage?token=${tokenParam}`)
      })
    }, 1500)
  }, [dataSignInImpersonate])

  useEffect(() => {
    if (!errorSignInImpersonate) return
    setLoading(false)
    Swal.fire({
      title: t('forgotPassword.invalidPassword'),
      text: t('signIn.tryAgain'),
      icon: 'error',
      confirmButtonText: 'Ok',
    })
  }, [errorSignInImpersonate])

  return (
    <div className='flex justify-center items-center mt-28 lg:mt-36'>
      <form className='bg-white rounded-3 p-3 sm:w-1/2 lg:w-1/4 mx-3' onSubmit={handleSubmit}>
        <h1 className='text-xl mb-2'>{t('global.confirmAdmin')}</h1>
        <PasswordField
          value={values.password}
          onChange={handleChange}
          name='password'
          label={t('signUp.password')}
          placeholder='******'
          errorMessage={errors.password}
        />
        <div className='my-2 flex flex-col items-center'>
          <ReCAPTCHA
            name='recaptcha'
            render='explicit'
            onChange={value => setFieldValue('recaptcha', value)}
            sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
          />
          {errors.recaptcha && <FieldMessage message={errors.recaptcha} />}
        </div>
        <div className='flex justify-end mt-3'>
          <button type='submmit' className='rounded-2 bg-primary py-2 px-4 text-center text-white font-bold min-w-[150px] w-full'>
            {loading ? <Loader className='inline-block' /> : t('global.send')}
          </button>
        </div>
      </form>
    </div>
  )
}
