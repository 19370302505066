import { useTranslation } from 'react-i18next'

// Transaction Types
export const PENDING = 1
export const IN_PROCESS = 2
export const APPROVED = 3
export const CANCELED = 4

export const getTransactionTypeText = type => {
  const { t } = useTranslation()
  switch (type) {
    case PENDING:
      return t('status.pending')
    case IN_PROCESS:
      return t('status.pending')
    case APPROVED:
      return t('status.approved')
    case CANCELED:
      return t('status.refused')
    default:
      throw new Error(`Invalid Transaction type "${type}".`)
  }
}

export const currencyExhangeList = [
  { name: 'Upbit', icon: '/icons/upbit.png' },
  { name: 'Kraken', icon: '/icons/kraken.png' },
  { name: 'Binance', icon: '/icons/binance.png' },
  { name: 'Bithumb', icon: '/icons/bithumb.png' },
  { name: 'KuCoin', icon: '/icons/kucoin.png' },
  { name: 'Bitstamp', icon: '/icons/bitstamp.png' },
  { name: 'OKX', icon: '/icons/okx.png' },
  { name: 'Bybit', icon: '/icons/bybit.png' },
  { name: 'bitFlyer', icon: '/icons/bitflyer.png' },
  { name: 'Coincheck', icon: '/icons/coincheck.png' },
  { name: 'Bitfinex', icon: '/icons/bitfinex.png' },
  { name: 'LBank', icon: '/icons/lbank.png' },
  { name: 'Crypto.com Exchange', icon: '/icons/crypto.png' },
  { name: 'MEXC', icon: '/icons/mexc.png' },
  { name: 'Gate.io', icon: '/icons/gate.png' },
  { name: 'P2B', icon: '/icons/p2b.png' },
  { name: 'Gemini', icon: '/icons/gemini.png' },
  { name: 'Bitget', icon: '/icons/bitget.png' },
  { name: 'Coinbase Exchange', icon: '/icons/coinbase.png' },
  { name: 'Huobi', icon: '/icons/huobi.png' },
]
