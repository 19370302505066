import { DateField, SelectField } from '@/components/Form/Fields'
import { useTranslation } from 'react-i18next'
import { useFormCommissionFilter } from '../hooks'
import { getCommissionOptions } from '../constants'

export const CommissionFilter = () => {
  const { values, handleChange, handleSubmit } = useFormCommissionFilter({
    onSubmit: values => {
      console.log(values)
    },
  })

  const { t } = useTranslation()
  return (
    <form className='flex flex-col lg:grid lg:grid-cols-8 gap-2' onSubmit={handleSubmit}>
      <SelectField
        label={t('extract.type')}
        optionPrimary=':: Selecione o Tipo ::'
        options={getCommissionOptions()}
        noMargins
        className='w-full md:col-span-2'
        name='type'
        value={values.type}
        onChange={handleChange}
        darkMode
        hideBorder={true}
      />
      <DateField
        label={t('extract.startDate')}
        noMargins
        className='w-full md:col-span-2'
        name='createdAt'
        value={values.createdAt}
        onChange={handleChange}
        themeDark
      />
      <DateField
        label={t('extract.endDate')}
        noMargins
        className='w-full md:col-span-2'
        name='endDate'
        value={values.endDate}
        onChange={handleChange}
        themeDark
      />
      <div className='w-full md:col-span-2 flex items-end'>
        <button type='submit' className='w-full h-[39px] text-white bg-primary-400 rounded-2'>
          {t('extract.filter')}
        </button>
      </div>
    </form>
  )
}
