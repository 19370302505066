import { Button } from '@/components/Form'
import { FieldMessage, PasswordField, TextField } from '@/components/Form/Fields'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'
import { BiLogIn } from 'react-icons/bi'
import { Link } from 'react-router-dom'

export const SignInForm = ({ loading, handleSubmit, handleChange, values = {}, errors = {}, setFieldValue }) => {
  const { t } = useTranslation()

  return (
    <div className='md:my-20 flex flex-col gap-2 md:justify-center md:items-center'>
      <h2 className='font-bold text-center text-xl text-white'>{t('signIn.connectTourAccount')}</h2>
      <form className='md:max-w-md w-full flex flex-col gap-4' onSubmit={handleSubmit}>
        <TextField
          label={t('global.email')}
          className='text-white rounded-1'
          name='email'
          placeholder={t('profile.placeholderEmail')}
          value={values.email}
          errorMessage={errors.email}
          onChange={e => setFieldValue('email', e.target.value.trim())}
          isDisabled={loading}
          noMargins
        />

        <PasswordField
          label={t('signIn.password')}
          className='text-white'
          name='password'
          placeholder='******'
          value={values.password}
          errorMessage={errors.password}
          onChange={handleChange}
          isDisabled={loading}
          noMargins
        />

        <div className='flex flex-col my-2 items-center'>
          <ReCAPTCHA
            name='recaptcha'
            render='explicit'
            onChange={value => setFieldValue('recaptcha', value)}
            sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
          />
          {errors.recaptcha && <FieldMessage message={errors.recaptcha} />}
        </div>
        <Button
          text={t('signIn.login')}
          className='flex items-center justify-center gap-1 font-bold bg-primary-300 py-3'
          iconRight={<BiLogIn className='w-4 h-4' />}
          type='submit'
          isDisabled={loading}
        />
        <Link className='flex justify-center items-center' to='/sign-up'>
          <Button className='bg-transparent border py-1 w-full'>{t('signIn.CreateYourAccount')}</Button>
        </Link>
        <div className='flex justify-center items-center'>
          <Link
            to='/forgot-password'
            className='mt-3 text-center text-md underline text-white hover:cursor-pointer hover:opacity-70 w-fit'
          >
            {t('signIn.forgotMyPassword')}
          </Link>
        </div>
      </form>
    </div>
  )
}
