import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { getLanguage } from '@/constants'

export const useSignUpForm = ({ onSubmit }) => {
  const { t } = useTranslation()

  const Schema = Yup.object({
    firstName: Yup.string().required(t('global.validation.required')),
    lastName: Yup.string().required(t('global.validation.required')),
    phone: Yup.string().min(12, t('global.validation.required')).required(t('global.validation.required')),
    email: Yup.string().email(t('signUp.validation.invalidEmail')).required(t('global.validation.required')),
    password: Yup.string().required(t('global.validation.required')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('signUp.validation.validationRequiredConfirmPassword'))
      .required(t('signUp.validation.validationConfirmPassword')),
    check: Yup.boolean().oneOf([true]).required(t('global.validation.required')),
    language: Yup.string().required(t('global.validation.required')),
    recaptcha: Yup.string().required(t('global.validation.required')).nullable(t('global.validation.required')),
  })

  return useFormik({
    initialValues: {
      language: getLanguage(navigator.language || navigator.userLanguage),
      indicatedBy: '',
      firstName: '',
      lastName: '',
      phone: '',
      recaptcha: '',
      email: '',
      password: '',
      confirmPassword: '',
      check: false,
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
