/**
 * ~> Private Route Example:
 * {
 *   path: '/licenses',
 *   pageComponent: ComponentName,
 *   breadcrumb: [{ name: 'Licenças', to: '/licenses' }, { name: 'Consulta' }],
 *   layoutComponent: LoginComponentName, (default: Layout)
 *   enabled: true, (default: true)
 * }
 *
 * ~> Public Route Example:
 * {
 *   path: '/sign-in',
 *   pageComponent: SignIn,
 *   breadcrumb: [],
 *   isPublic: true, (default: false)
 *   layoutComponent: LoginComponentName, (default: Layout)
 *   enabled: true, (default: true)
 * }
 */

import { Extract } from '@/pages/Extract'
import { Deposit } from '@/pages/Deposit'
import { Profile } from '@/pages/Profile'
import { Withdraw } from '@/pages/Withdraw'
import { SignIn } from '@/pages/SignIn'
import { SignUp } from '@/pages/SignUp'
import { Network } from '@/pages/Network'
import { Logout } from '@/pages/Logout'
import { Home } from '@/pages/Home'
import { LoginLayout } from '@/pages/LoginLayout'
import { Layout } from '@/pages/Layout'
import { Arbitrage } from '@/pages/Arbitrage'
import { HomeLayout } from '@/pages/HomeLayout'
import { ForgotPassword } from '@/pages/Password/ForgotPassword'
import { ResetPassword } from '@/pages/Password/ResetPassword'
import { Notifications } from '@/pages/Notifications'
import { Security } from '@/pages/Security'
import { LayoutNotifications } from './pages/LayoutNotifications'
import { ArbitrageProvider } from './pages/Arbitrage/contexts'
// import { CareerPath } from './pages/CareerPath'
// import { P2P } from './pages/P2P'
import { Wallet } from '@/pages/Wallet'
import { CommissionMenu } from './pages/CommissionMenu'
import { Information } from './pages/Information'
import { Impersonate } from './pages/Impersonate'
// import { Matrix } from './pages/Matrix'

export const routes = [
  {
    path: '/',
    pageComponent: Home,
    layoutComponent: HomeLayout,
    isPublic: true,
  },
  {
    path: '/home',
    pageComponent: Home,
    layoutComponent: HomeLayout,
    isPublic: true,
  },
  {
    path: '/arbitrage',
    pageComponent: () => (
      <ArbitrageProvider>
        <Arbitrage />
      </ArbitrageProvider>
    ),
    isFullWidth: true,
    breadcrumb: [],
  },
  {
    path: '/extract',
    pageComponent: Extract,
    breadcrumb: [],
  },
  {
    path: '/deposit',
    pageComponent: Deposit,
    breadcrumb: [],
    layoutComponent: Layout,
  },
  {
    path: '/network',
    pageComponent: Network,
    breadcrumb: [],
  },
  // {
  //   path: '/career-path',
  //   pageComponent: CareerPath,
  //   breadcrumb: [],
  // },
  {
    path: '/profile',
    pageComponent: Profile,
    breadcrumb: [],
  },
  {
    path: '/withdraw',
    pageComponent: Withdraw,
    breadcrumb: [],
  },
  {
    path: '/sign-in',
    pageComponent: SignIn,
    breadcrumb: [],
    layoutComponent: LoginLayout,
    isPublic: true,
  },
  {
    path: '/sign-up/:indicatedCode',
    pageComponent: SignUp,
    breadcrumb: [],
    layoutComponent: LoginLayout,
    isPublic: true,
  },
  {
    path: '/sign-up',
    pageComponent: SignUp,
    breadcrumb: [],
    layoutComponent: LoginLayout,
    isPublic: true,
  },
  {
    path: '/logout',
    pageComponent: Logout,
    isPublic: true,
  },
  {
    path: '/forgot-password',
    pageComponent: ForgotPassword,
    layoutComponent: LoginLayout,
    isPublic: true,
  },
  {
    path: '/reset-password/:token',
    pageComponent: ResetPassword,
    layoutComponent: LoginLayout,
    isPublic: true,
  },
  {
    path: '/notifications',
    pageComponent: Notifications,
    layoutComponent: LayoutNotifications,
    notificationScreen: true,
    breadcrumb: [],
  },
  {
    path: '/security',
    pageComponent: Security,
    breadcrumb: [],
  },
  {
    path: '/wallet',
    pageComponent: Wallet,
    breadcrumb: [],
  },
  // {
  //   path: '/p2p',
  //   pageComponent: P2P,
  //   breadcrumb: [],
  // },
  {
    path: '/affiliated/:indicationCode',
    pageComponent: SignUp,
    breadcrumb: [],
    isPublic: true,
    layoutComponent: LoginLayout,
  },
  {
    path: '/commission-menu',
    pageComponent: CommissionMenu,
  },
  {
    path: '/information',
    pageComponent: Information,
    layoutComponent: LoginLayout,
  },
  // {
  //   path: '/matrix',
  //   pageComponent: Matrix,
  // },
  {
    path: '/impersonate',
    pageComponent: Impersonate,
    breadcrumb: [],
    layoutComponent: LoginLayout,
    isPublic: true,
  },
]
