import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

export const useExtractForm = ({ onSubmit }) => {
  const { t } = useTranslation()
  const Schema = Yup.object({
    type: Yup.string(),
    createdAt: Yup.string(),
    endDate: Yup.string().when('createdAt', {
      is: val => Boolean(val?.length),
      then: Yup.string().required(t('global.validation.required')),
      otherwise: Yup.string(),
    }),
  })

  return useFormik({
    initialValues: {
      createdAt: '',
      endDate: '',
      type: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
