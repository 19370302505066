export default {
  global: {
    confirmAdmin: 'Confirmar Senha do Admin',
    adminInform: 'Acesso limitado! Você está acessando esta conta no modo Suporte!',
    await: 'Aguarde:',
    accountNotVerified: 'Conta não verificada ',
    companyName: 'Botfy',
    successTitle: 'Tudo certo!',
    errorTitle: 'Ocorreu um erro',
    ok: 'OK',
    yes: 'Sim',
    no: 'Não',
    phone: 'Telefone',
    cryptocurrencyWallet: 'Carteira de criptomoeda',
    login: 'Login',
    name: 'Nome',
    email: 'E-mail',
    cnpj: 'CNPJ',
    cpf: 'CPF',
    randomKey: 'Chave Aleatória',
    createdAt: 'Data de Cadastro',
    activeAt: 'Data de Ativação',
    send: 'Enviar',
    balance: 'Saldo',
    profit: 'Lucro',
    poolLiquidity: 'Pool Liquidez',
    pool: 'Banca',
    screenLoadingText: 'Carregando...',
    price: 'Preço',
    change: 'Mudança',
    chart: 'Gráfico',
    trade: 'Negociação',
    cancel: 'Cancelar',
    edit: 'Editar',
    automaticOperations: 'Automatizar Operação',
    copSuccess: 'Copiado com sucesso!',
    deautomateOperations: 'Desautomatizar Operação',
    notMessage: 'Nenhuma mensagem.',
    emailRequired: 'E-mail obrigatório',
    titleMessages: 'Notificações',
    readMessages: 'Marcar como lidas',
    chartTitle: 'Mercado (BTC/USDT) 1D',
    sendEmailRequest: 'Enviar Token',
    verify: 'Verificar',
    languages: {
      english: 'Inglês',
      portuguese: 'Português (BR)',
      spanish: 'Espanhol',
      french: 'Francês',
    },
    validation: {
      validationRecaptcha: 'Recaptcha Inválido.',
      required: 'Este campo é obrigatório',
      validationCpf: 'O CPF deve conter 11 caracteres',
      validationCpfInvalid: 'Documento já está em uso.',
      validationPhone: 'O Telefone deve conter 11 caracteres',
      validationCpnjLength: 'O CNPJ deve conter 14 números',
      validationPhoneSignUp: 'Telefone já está em uso.',
    },
    aspectRatioError: 'A imagem não está na proporção adequada. Sugerimos uma imagem de proporção quadrada.',
    successUploadImage: 'Upload realizado com sucesso!',
    sendEmail: 'Enviamos um Token para seu e-mail!',
    errorEmail: 'Erro ao enviar o Token para seu e-mail.',
    sendPhone: 'Foi enviado um Token para seu SMS!',
    errorPhone: 'Erro ao enviar o Token para seu SMS.',
    successSendEmail: 'E-mail verificado com sucesso!',
    successSendPhone: 'Telefone verificado com sucesso!',
    errorSendEmail: 'Erro ao verificar Email.',
    errorSendPhone: 'Erro ao verificar Telefone.',
    errorVerification: 'É necessário preencher todos os campos',
    tokenInvalid: 'Token inválido.',
    cancelDepositSuccess: 'Depósito cancelado com sucesso!',
    cancelDeposit: 'Depósito cancelado.',
    exit: 'Sair',
    placeholderValue: 'Ex.: $ 100.00',
    information: {
      title: 'Bom Dia, Família botfy!',
      att: 'Atenciosamente, Equipe botfy',
      text1: 'Tivemos uma pequena atualização para melhoria de desempenho em nossos sistemas e servidores.',
      text2:
        'Sempre que alguma corretora faz uma atualização em suas APIs, precisamos atualizar nosso lado também para continuar obtendo cotações em tempo real. Por esse motivo, nosso modo automático ficou em atualização por aproximadamente 30 horas.',
      text3: 'Já está tudo atualizado e as operações foram normalizadas.',
      text4:
        'É importante acompanhar seus sistemas pelo menos uma vez por dia! E sempre que houver atualizações para quem usa o modo automático, DESLIGUE e LIGUE novamente e aguarde a próxima operação.',
      checkbox: 'Estou Ciente',
    },
  },
  dashboard: {
    $: 'R$',
    balance: 'Ver saldo',
    yourBalance: 'Seu saldo é:',
    yourLanguage: 'Escolha seu idioma',
    totalReceived: 'Total recebido',
    graduation: 'Graduação',
    operationsToday: 'Operações hoje',
    operationsYesterday: 'Operações ontem',
    performanceChart: 'Gráfico de desempenho',
    latestReturns: 'Últimas operações',
    creationDate: 'Data de criação',
    description: 'Descrição',
    percentage: 'Porcentagem',
    hello: 'Olá, ',
    linkIndication: 'Link de indicação',
    balanceActivationTitle: 'Ativação com Saldo',
    balanceActivationText: 'Você deseja realmente fazer uma ativação utilizando TODO O SEU SALDO?',
    successActivation: 'Sua ativação foi feita com sucesso.',
    validation: {
      zeroBalance: 'Você não tem saldo para poder fazer ativação por saldo',
    },
  },
  profile: {
    placeholderName: 'Ex.: João',
    placeholderLastName: 'Ex.: da Silva',
    placeholderUser: 'Ex.: joao_user',
    placeholderEmail: 'Ex.: joão@gmail.com',
    placeholderPhone: 'Ex.: (11) 98783-4343',
    placeholderCpf: 'Ex.: 012.345.678-90',
    placeholderCnpj: 'Ex.: 01.234.567/8901-01',
    placeholderAddress: 'Ex.: Rua dos Alpes, 1322',
    placeholderBitcoinWallet: 'Ex.: 5x1HUXrCNLbtMDqcw6o5GNn4xqX',
    placeholderRandomKey: 'Ex.: 5x3123fsdvc1HUXr4xqX',
    myProfile: 'Meu Perfil',
    userInfo: 'Dados do Usuário',
    pixInfo: 'Dados Bancários',
    btcInfo: 'Dados Bancários',
    pixKey: 'Chave Pix',
    pixKeyType: 'Tipo da Chave Pix',
    save: 'Salvar',
    saveDataSucess: 'Seus dados foram salvos.',
    imgAltProfile: 'Imagem de perfil',
    email: 'E-mail',
    bitcoinWallet: 'Carteira de Bitcoin',
    clickHere: 'Clique aqui para verificar',
    verifyEmail: 'Email não verificado',
    verifyPhone: 'Telefone não verificado',
    cryptoType: 'Tipo da Carteira de Crypto',
    walletEthereum: 'Carteira de Ethereum',
    walletUstd: 'Carteira de USDT',
    language: 'Idioma Padrão',
    titleConfigWithdraw: 'Configurações de Saque',
    awaitWithdrawOne: 'Após alterar o plano de saque, deverá esperar ',
    awaitWithdrawTwo: ' dias para uma nova alteração!',
    withdrawOptionWeekly: 'Semanal',
    withdrawOptionFortnightly: 'Quinzenal',
    withdrawOptionMonthly: 'Mensal',
    fee: 'de Taxa',
  },
  home: {
    openAccount: 'Abrir Conta',
    startYourPortfolio: 'O jeito mais fácil, rápido e descomplicado de lucrar com criptomoedas.',
    startYourPortfolioDescription: 'Seu caminho para lucros rápidos e fáceis em criptomoedas começa aqui.',
    whyBecomeSkyOperator: 'Por que se tornar um Operador BotFY?',
    innovationStep: 'Chega de contar com a sorte, conte com a maior inteligência artificial no Mercado de Arbitragem',
    transparencyStep: 'Nada fica oculto. Todas as operações são registradas e ficam disponíveis na BlockChain.',
    alreadyHaveAnAccount: 'Já possui conta?',
    toEnter: 'Entrar',
    brazilBotfyConventionTitle: 'Convenção botfy Brasil',
    brazilBotfyConvention: 'Dez 2023',
    botfyToken: 'Token botfy',
    botfyTokenDate: 'Jan 2024',
    matrizBotfy: 'Corretora e Sede Oficial em Dubai',
    dateMatrizBotfy: 'Mar 2024',
    participatingCountries: 'Países participantes',
    verifiedCustomers: 'Clientes verificados',
    innovationAndTechnology: 'INOVAÇÃO E TECNOLOGIA',
    transparency: 'TRANSPARÊNCIA',
    security: 'SEGURANÇA',
    securityStep: 'A tecnologia de IA mais segura, trazendo as análises e tomadas de decisões mais eficientes do mercado.',
    stepByStep: 'Seus próximos passos juntos a nossa plataforma!',
    firstStepLabel: '#01',
    firstStepDescription: 'REGISTRAR-SE',
    secondStepLabel: '#02',
    secondStepDescription: 'DEPOSITE SUA MESA DE OPERAÇÕES',
    thirdStepLabel: '#03',
    thirdStepDescription: 'ACOMPANHE SUAS OPERAÇÕES',
    fourthStepLabel: 'OPCIONAL',
    fourthStepDescription: 'INDIQUE A OUTROS CLIENTES E RECEBA COMISSIONAMENTO E PREMIAÇÕES',
    openMyAccount: 'Abrir minha Conta',
    buyCryptoAtRealCost: 'Compre e Venda Cripto com Altos Lucros',
    buyCryptoAtRealCostDescription: 'Negocie entre as 20 maiores corretodas do Mercado',
    buy: 'Comprar',
    screenshot: 'print da tela do site',
    createAnAccount: 'Registrar-se',
    linkYourBankAccount: 'Deposite sua Mesa de Operações',
    startBuyingAndSelling: 'Acompanhe suas Operações',
    bankIcon: 'ícone de banco',
    salesIcon: 'ícone de vendas',
    accountIcon: 'ícone de conta',
    titleStartInAFewMinutes: 'Seus Próximos Passos',
    textStartInAFewMinutes: 'Juntos a Nossa Plataforma',
    titleEarnCrypto: 'Lucre através do Pool de Arbitragem',
    textEarnCrypto: 'O fluxo mais fácil para comprar e vender criptomoedas. Abra sua conta e comece hoje mesmo.',
    startEarning: 'Comece a ganhar',
    cryptocurrencyMarket: 'mercado de criptomoedas',
  },
  license: {
    license: 'Investir',
    myLicenses: 'Meus Investimentos',
    formOfPayment: 'Forma de Pagamento',
    selectValue: 'Selecione um valor',
    sendProof: 'Enviar comprovante',
    sendProofText: 'O arquivo deve estar no formato jpg, jpeg ou png e ter no máximo 2 MB.',
    purchase: 'Comprar',
    copy: 'Copiar',
    purshaseRequestTitle: 'Sua solicitação de compra foi feita.',
    purshaseRequestText: 'Aguarde a nossa equipe fazer análise de sua solicitação.',
    purshaseRequestErrorTitle: 'Ocorreu um Erro',
    purshaseRequestErrorText: 'Houve um erro ao tentar solicitar seu pedido',
    datePurchase: 'Data da Compra',
    licenseEmptyMessage: 'Não há investimentos no momento',
  },
  status: {
    pending: 'Pendente',
    approved: 'Aprovado',
    underAnaLysis: 'Em análise',
    refused: 'Recusado',
    awaitingPayment: 'Aguardando Pgto.',
    active: 'Ativo',
    inactive: 'Inativo',
    activeVoucher: 'Ativo',
  },
  signIn: {
    nameLabel: 'Nome',
    emailLabel: 'Email',
    passwordLabel: 'Senha',
    termsLabel: 'Certifico que tenho 18 anos ou mais, concordo com os Termos e Condições de Uso.',
    termsAndConditionsLabel: 'Ver Termos e Condições de Uso.',
    confirmPasswordLabel: 'Confirme sua Senha',
    usernameLabel: 'Nome de Usuário',
    login: 'Entrar',
    nickname: 'Apelido',
    password: 'Senha',
    userName: 'Usuário',
    emailUserName: 'Usuário ou E-mail',
    IndicatedBy: 'Indicado por',
    CreateYourAccount: 'Crie sua conta agora!',
    accessAcount: 'Acessar conta',
    forgotMyPassword: 'Esqueci minha senha',
    enterUsername: 'Digite seu usuário',
    enterEmail: 'Digite seu email',
    enterPassword: 'Digite sua senha',
    resetPassword: 'Redefinir senha',
    invalidEmail: 'Email inválido',
    validEmail: 'Email valido',
    sendEmail: 'Email enviado com sucesso',
    sendEmailConfirmPassword: 'Enviamos um email para você alterar sua senha!',
    userOrPasswordInvalid: 'Username ou senha inválido.',
    errorChangePassword: 'Não foi possivel enviar um email alteração de senha, tente novamente!',
    tryAgain: 'Tentar novamente',
    signUp: 'Não tenho conta, quero me cadastrar',
    connectTourAccount: 'Conecte a sua Conta',
    resetYourPassword: 'Redefina sua senha',
    emailNotRegistered: 'E-mail não encontrado',
  },
  signUp: {
    firstNameLabel: 'Nome',
    lastNameLabel: 'Sobrenome',
    signUpNow: 'Cadastre Agora',
    fullName: 'Nome completo',
    email: 'Seu melhor e-mail',
    phone: 'Telefone',
    login: 'Login',
    password: 'Senha',
    passwordConfirm: 'Repita sua senha',
    signUp: 'Quero me cadastrar',
    signIn: 'Já possui uma conta? Conecte!',
    IndicatedBy: 'Indicado por',
    titleSignUp: 'Descubra a revolução financeira do século cadastrando-se na botfy!',
    subTitleSignUp: 'Sua liberdade financeira a um click!',
    validation: {
      validationUsername: 'Somente caracteres alfanuméricos, sublinhados e hífens são permitidos',
      validationPassword: 'A senha é obrigatória',
      validationConfirmPassword: 'A confirmação de senha é obrigatória',
      validationRequiredConfirmPassword: 'As senhas não coincidem',
      invalidEmail: 'Este não é um email válido.',
      validationEmail: 'Este e-mail já está em uso.',
    },
    usernameTip: 'Utilize apenas letras minúsculas, números, traço e underline.',
    phoneTip: 'DDD + Seu telefone',
    successMessageTitle: 'Seu Cadastro foi Realizado.',
    successMessageText: 'Você agora tem acesso a plataforma da botfy',
  },
  withdraw: {
    withdrawTax: 'Taxa de Saque',
    conversionToPercent: 'Valor Máximo para Saque',
    networkUSDTWithdraw: 'A rede para o Saque de USDT é: TRON',
    documentStatus: 'Verificação de Documentos é Necessária para Realizar o Saque.',
    invalidWithdraw: 'Saque indisponível',
    paymentMethodOff: 'Esta forma de pagamento está em atualização. Por favor, escolha outro método de recebimento!',
    blockedWithdraw: 'Saque Bloqueado. Entre em contato com o Suporte!',
    maxWithdraw: 'Você pode sacar até: ',
    notWithdraw: 'Hoje não é dia de Saque.',
    withdraw: 'Saque',
    withdrawDescription: 'Selecione a forma de recebimento',
    cryptocurrency: 'Criptomoeda',
    minimumValue: 'Saque mínimo: ',
    withdrawImage: 'imagem de saque',
    withdrawalInformation: 'Informações de Saque',
    withdrawalHistory: 'Histórico de Saques',
    failedWithdrawalHistory: 'Você ainda não tem solicitações de saque',
    random: 'Aleatória',
    withdrawalValue: 'Valor de saque',
    withdrawalAmount: 'Quantia para saque',
    conversionToUSD: 'Conversão para U$',
    keyType: 'Tipo de Chave',
    enterTheKey: 'Digite a chave',
    sameOwner: 'Mesma conta do dono?',
    period: '7 (sete) dias',
    pleaseWait: 'Por favor aguarde ',
    forNextOperation: ' para realizar a próxima operação. Este é o tempo que levará para processarmos sua retirada.',
    withdrawalRequest: 'Pedido de saque',
    resourceRelease: '* Liberação de recursos Semanal',
    yourCryptocurrencyWallet: 'Sua carteira de criptomoeda',
    successMessageTitle: 'Sucesso!',
    successMessageText: 'Seu Saque foi realizado.',
    deniedMessageTitle: 'Pedido Negado',
    deniedMessageText: 'Saldo Insuficiente',
    minimumValueError: 'Adicione um valor acima de ',
    nameValidation: 'Digite um nome válido',
  },
  network: {
    affiliateNetwork: 'Rede de Afiliados',
    direct: 'Diretos:',
    indirect: 'Indiretos:',
    yourEntireNetwork: 'Toda sua rede:',
    consult: 'Consultar',
    searchforLogin: 'Pesquise por um login ou nome...',
    toClean: 'Limpar',
    openNetwork: 'Rede',
    volumeSum: 'Volume Rede',
  },
  extract: {
    filters: 'Filtros',
    selectType: ':: Selecione o Tipo ::',
    extract: 'Extrato',
    dataTransaction: 'Data Transação',
    description: 'Descrição',
    type: 'Tipo',
    balanceBefore: 'Saldo Anterior',
    value: 'Valor',
    balanceAfter: 'Saldo Atual',
    noTransactionCell: 'Nenhuma movimentação foi encontrada',
    startDate: 'Data Inicial',
    endDate: 'Data Final',
    filter: 'Consultar',
    optionPrimary: 'Selecione o Tipo',
    deposit: 'Depósito',
    withdraw: 'Saque',
    affiliated: 'Afiliados',
    arbitration: 'Arbitragem',
    P2P: 'P2P',
    matrixBonus: 'Matrix Bônus',
    PL: 'PL',
    all: 'Todos',
    allRecords: 'Todos os Registros:',
    summation: 'Somatório:',
    volume: 'Volume',
  },
  transactionTypes: {
    allTypes: 'Todos os Tipos',
    contractPurchase: 'Compra de Licença',
    manualBonus: 'Bônus Manual',
    networkCommission: 'Comissão de Rede',
    manualReversing: 'Estorno Manual',
    withdraw: 'Saque',
    withdrawalTax: 'Taxa de Saque (U$)',
    withdrawalTaxPercent: 'Taxa de Saque (%)',
    profitability: 'Rentabilidade de operação',
    balanceActivation: 'Ativação por Saldo',
    arbitrationProfit: 'Lucro Arbitragem',
    arbitration: 'Arbitragem',
    deposit: 'Depósito',
    voucher: 'Voucher',
    residualCommission: 'Residual',
    operationProfit: 'Lucro Operação',
    dailyLimitReversal: 'Estorno Teto Diário',
    contractFees: 'Taxa Compra Licença',
  },

  deposit: {
    requestedAmount: 'Valor Requisitado',
    requestedTax: 'Taxa de Saque',
    withdrawNumber: 'Número',
    expiredDeposit: 'Deposito expirado',
    informationUsdt: 'Rede: TRON',
    networkUSDT: 'A rede para o Depósito de USDT é: TRON',
    depositSkyt: 'Depositar',
    confirmDepositSkyt: 'Esta ação é definitiva e não poderá ser desfeita. Por favor, confirme para prosseguir.',
    notCancel: 'Não Cancelar',
    continue: 'Continuar',
    attention: 'Atenção !',
    confirmPaymentEmail: 'Seu pedido ficará em análise e você receberá um e-mail quando aprovado!',
    textCancel:
      'Se você já estiver realizado o pagamento, o prazo para confirmação é de até 2 horas. Deseja realmente Cancelar esse pedido ?',
    actions: 'Ações',
    awaitDeposit: 'Prazo de confirmação do seu depósito pode levar de 1 minuto a 2hs.',
    depositScreenTitle: 'Depósito',
    tagSelection: 'Selecione a moeda e o método de pagamento',
    buttonPix: 'PIX',
    buttonBTC: 'BTC',
    buttonEther: 'Ethereum',
    buttonUSTD: 'USDT',
    buttonPaypal: 'Paypal',
    buttonSKYT: 'SKYT',
    minimumDepositAmount: 'Depósito mínimo: ',
    imgAlt: 'Vetor Depósito',
    depositInformations: 'Informações de Depósito',
    estimationBTC: 'Estimativa do BTC:',
    estimationETH: 'Estimativa do Ethereum:',
    estimationUSDT: 'Estimativa do USDT:',
    estimationUSD: 'Estimativa do Dólar:',
    pixLabelValue: 'Valor do Depósito',
    pixPlaceholderValue: 'Quantia (U$)',
    pixlabelCPF: 'Documento',
    pixPlaceholderCPF: 'Ex.: 123.456.789-00',
    pixlabelEmail: 'E-mail',
    pixPlaceholderEmail: 'Ex.: liminha_limas@bol.com.br',
    warningValueInReal: 'Você vai depositar em real:',
    depositButtonRequest: 'Pedido de Depósito',
    pixDetailButton: 'Gerar QRCode',
    pixKeyLabel: 'Copiar chave Pix',
    pixCancelButton: 'Cancelar',
    copyCodeSuccess: 'Código copiado para área de transferência',
    criptoLabelValue: 'Valor do Depósito',
    criptoPlaceholderValue: 'Quantia (U$)',
    criptoDetailButton: 'Detalhes das Carteiras',
    depositHistory: 'Histórico de Depósito',
    receptorName: 'Nome do Receptor',
    cpfNumber: 'Nº CPF',
    transactionType: 'Tipo',
    requestDate: 'Data da Solicitação',
    amount: 'Valor',
    successDeposit: 'Depósito realizado com sucesso!',
    errorDeposit: 'Erro ao realizar depósito.',
    youDeposit: 'Você vai depositar: ',
    status: 'Situação',
    wallet: 'Número da Carteira:',
    rate: 'Enviar sempre em taxa prioritária',
    blockchain: 'Seu depósito será ativo assim que as confirmações mínimas na blockchain forem atingidas',
    statusDeposit: {
      created: 'Criado',
      awaitingPayment: 'Esperando Pagamento',
      approved: 'Aprovado',
      voucher: 'Voucher',
      refused: 'Recusado',
      reversed: 'Estornado',
      expired: 'Expirado',
    },
  },
  sidebar: {
    matrix: 'Matrix',
    arbitrage: 'Arbitragem',
    deposit: 'Depósito',
    network: 'Rede',
    withdraw: 'Saque',
    extract: 'Extrato',
    wallet: 'Carteira',
    security: 'Segurança',
    profile: 'Perfil',
    logout: 'Sair',
    closeSidebar: 'Fechar',
    commissionMenu: 'Comissões',
    automaticOperations: 'Operações automáticas',
    transactionAlertOne: 'As transações serão realizadas automaticamente no período mínimo de ',
    transactionAlertTwo: ' horas e haverá uma taxa administrativa de 20% sobre a transação.',
    disableAutomatic: 'Você deseja desativar as Operações Automáticas?',
    disableNow: 'Desativar agora!',
    activateNow: 'Ativar agora!',
    cancel: 'Cancelar',
  },
  commission: {
    matrix: 'Matrix',
    recommendation: 'Indicação',
    th: {
      date: 'Data Comissão',
      affilliated: 'Afiliado',
      type: 'Tipo',
      valueDeposit: 'Valor Depósito',
      level: 'Nível',
      txCommission: 'Taxa Comissão',
      commission: 'Comissão',
    },
    numberCommission: 'Números de Comissões:',
    sumCommission: 'Somatório de Comissões:',
  },
  gateway: {
    payment: 'Gateway de Pagamento',
  },
  arbitrage: {
    automaticOperations: 'Operação Automática',
    textActiveOperations: 'Operações Automáticas Ativas',
    textCustomerNotAble: 'Operação não Realizada! Tempo de operação pendente, por favor consulte o seu tempo restante',
    successArbitrage: 'Arbitragem realizada com sucesso!',
    chartTitle: 'Mercado (BTC/USDT) 1D',
    BuyAndSellOffers: 'Ofertas de compra e venda',
    bestBuyPrice: 'Melhor preço de compra',
    bestSalePrice: 'Melhor preço de venda',
    purchaseOffers: 'Ofertas de compra',
    salesOffers: 'Ofertas de venda',
    updatingNewOffers: 'Atualizando novas ofertas',
    youWillBuyFrom: 'Você vai comprar de',
    youWillSellTo: 'Você vai vender para',
    selectBrokerTheSalesOrder: 'Selecione uma Corretora na seção de ordem de venda',
    selectBrokerThepurchaseOrder: 'Selecione uma Corretora na seção de ordem de compra',
    selectBrokerThepurchaseSalesOrder: 'Selecione uma Corretora na seção de ordem de compra e de venda',
    buyNow: 'Compre agora!',
    validValue: 'Certifique-se que o valor inserido é válido.',
    yourRecentOperations: 'Suas operações recentes',
    broker: 'Corretora',
    purchasePrice: 'Preço de compra',
    offerPrice: 'Preço de oferta',
    percentage: 'Porcentagem',
    purchaseSaleDate: 'Data de Compra/Venda',
    amountsent: 'Quantia enviada',
    transaction: 'Transação',
    status: 'Status',
    lastNegotiations: 'Últimas negociações',
    Price: 'Preço (USD)',
    Amount: 'Quantia (BTC)',
    Hour: 'Hora',
    total: 'Total',
    orderBook: 'Livro de Ofertas',
    robotMessage: 'O robô possui uma margem de erro de +/- 1% no valor da arbitragem.',
    notRecordsFound: 'Nenhum registro encontrado.',
    valueMinArbitrage: 'Valor mínimo de arbitragem é ',
    refund: 'Reembolso da Operação',
    profit: 'Lucro da Operação',
    tax: 'Taxa de Operação',
    operationsHistory: 'Histórico de Operações',
    openOperations: 'Operações abertas',
  },
  forgotPassword: {
    textForgotPassword:
      '*Para solicitar a mudança de senha, por favor, insira seu endereço de e-mail no campo abaixo e clique no botão "Enviar". Enviaremos um e-mail contendo um link seguro para redefinir sua senha.',
    passwordChange: 'Mudança de Senha',
    textResetPassword: 'Por favor, insira sua nova senha nos campos abaixo e confirme-a para concluir a alteração de senha.',
    newPassword: 'Nova senha',
    confirmNewPassword: 'Confirme a Nova Senha',
    successMessageTitle: 'Senha alterada com sucesso!',
    invalidPassword: 'Senha Inválida!',
    invalidConfirmPassword: 'As senhas não coincidem!',
    invalidToken: 'Token Inválido. Tente novamente mais tarde!',
    successSendEmail: 'Email enviado com sucesso',
    invalidEmail: 'Este não é um email válido.',
    textForgotPasswordNew: 'Para redefinir sua senha, use um dispositivo que tenha acessado sua conta botfy recentemente.',
  },
  security: {
    security: 'Segurança',
    emailPhoneText: 'Proteja suas transações e sua conta',
    verify: 'Verificar',
    accessPassword: 'Senha de Acesso',
    accessPasswordText: 'A senha de login é usada para acessar a sua conta.',
    change: 'Alterar',
    phoneNumberVerification: 'Verificação de Número de Telefone',
    emailVerification: 'Verificação de email',
    verificationCode: 'Insira seu código de verificação',
    changeAccessPassword: 'Alterar Senha de Acesso',
    currentPassword: 'Senha Atual',
    confirmPassword: 'Confirmar Senha',
    editPhone: 'Editar Telefone',
    newPhone: 'Novo telefone',
    editEmail: 'Editar Email',
    newEmail: 'Novo email',
    verifiedEmail: 'E-mail verificado',
    verifiedPhone: 'Telefone verificado',
    questionVerifyEmail: 'Para verificarmos o seu E-mail ',
    questionVerifyPhone: 'Para verificarmos o seu Telefone: ',
    textVerify: 'pertence a você mesmo, clique no botão abaixo para iniciar o processo',
    resendToken: 'Reenviar Token',
    documents: 'Documentos',
    PENDING: 'Pendente',
    UNDER_ANALYSIS: 'Em Análise',
    APPROVED: 'Aprovado',
    REFUSED: 'Recusado',
    REVOKED: 'Revogado',
    verifiedDocuments: 'Documentos verificados',
    sendDocuments: 'Enviar Documentos',
    frontDocuments: 'Frente do documento',
    backDocuments: 'Costas do documento',
    selfieDocuments: 'Documento próximo ao rosto',
    errorsDocuments: 'Erro ao enviar Documentos.',
    sucessDocuments: 'Documentos Enviados com Sucesso!',
    permForm: 'Formatos Permitidos:',
    maxTam: 'Tamanho Máximo:',
    invalidExtension: 'Extensão Inválida.',
  },
  p2p: {
    minimumAmountForOperation: 'Valor minimo para Realizar o P2P é ',
    confirmPassword: 'Confirmação de Senha',
    insertPassword: 'Insira sua senha para verificação de segurança.',
    dataTransfer: 'Dados para transferência',
    emailRecipient: 'Email Destinatário',
    search: 'Pesquisar',
    placeholderValue: 'Ex.: $100.00',
    valueTransfer: 'Valor em SKYT',
    transferSkyt: 'Você vai transferir em SKYT:',
    notFoundRecipient: 'Destinatário não encontrado',
    searchRecipient: 'Busque o destinatário',
    TransactionSuccessful: 'Transação realizada com sucesso.',
    TransactionError: 'Erro ao realizar a transação.',
    p2p: 'p2p',
    SkytBallance: 'SKYT Disponível:',
    transferTax: 'Taxa de Transferência',
    confirmTitle: 'Tem certeza?',
    confirmDescription: 'Essa ação é irreversível. Seu saldo será convertido para SKYT!',
    invalidValue: 'Valor Inválido.',
    errorConvert: 'Erro ao converter Saldo para SKYT.',
    errorInsufficientBalance: 'Saldo Insuficiente!',
    sucessConvert: 'Saldo convertido com sucesso!',
    convertSkyt: 'Conversor de SKYT',
    available: 'Disponível',
    amount: 'Saldo',
    convert: 'Converter',
  },
  wallet: {
    myWallet: 'Minha Carteira',
    deposit: 'Deposito',
    withdraw: 'Saque',
    p2p: 'P2P',
    extract: 'Extrato',
    arbitrationPool: 'Pool de Liquidez',
    earnings: 'Lucros de Afiliados',
    earningsOperations: 'Lucros de Operações',
    skyt: 'SKYT',
    banking: 'Banca Total',
    recentHistory: 'Histórico recente',
  },
  careerPath: {
    careerPath: 'Plano de Carreira',
    nextGraduation: 'Proxima graduação',
    nextGraduations: 'Proximas graduação',
    graduationsCheck: 'Graduações Atingidas',
    graduationsProgress: 'Graduação em andamento',
    hitGoal: 'Para bater a meta, você precisa: ',
  },
  extractConstants: {
    skytExchange: 'Skyt Convertido',
    ballanceSkytConvert: 'Conversão de Saldo para Skyt',
    skytDepositConvert: 'Conversão de SKYT em Saldo',
    withdrawRefund: 'Reembolso de Saque',
    skytDeposit: 'Deposito de SKYT',
    purchase: 'Compra',
    reverse: 'Reembolso',
    arbitration: 'Arbitragem',
    voucherDeposit: 'Voucher de Depósito',
    withdrawFixedAmountTax: 'Taxa de Retirada',
    arbitrationProfit: 'Lucro de Arbitragem',
    networkCommission: 'Comissão de Rede',
    residualCommission: 'Comissão Residual',
    withdrawRequest: 'Pedido de Saque',
    withdraw: 'Saque',
    manualBonus: 'Bônus Manual',
    deposit: 'Depósito',
    refund: 'Estorno',
    arbitrageRequest: 'Arbitragem',
    arbitrageProfitRequest: 'Lucro de Arbitragem',
    transferTaxP2p: 'Taxa de Transferência P2P',
    skytTransferReceive: 'Transferência Recebida em SKYT',
    skytTransferSender: 'Transferência Enviada em SKYT',
    bonusMatrix: 'Bônus Matrix',
    unknown: 'Desconhecido',
    arbitrationTax: 'Taxa de Arbitragem',
  },
  matrix: {
    bonus: 'Qntd. Bônus:',
    lastPayment: 'Último Pgto.:',
    details: 'Detalhes',
    volPayment: 'Vol. Pago',
    date: 'Data',
  },
}
