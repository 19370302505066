import { useTranslation } from 'react-i18next'
import { CommissionFilter } from './components/CommissionFilter'
import { CommissionTable } from './components/CommissionTable'
import { BadgeRecords, BadgeSummation } from '../Extract/components'
import { useFetchCommission } from './hooks'
import { Loading } from '@/components/Common'
import { useState, useEffect } from 'react'

export const CommissionMenu = () => {
  const { t } = useTranslation()
  const { dataCommission, fetchCommissions, loadingCommission } = useFetchCommission()
  const [totalRecords, setTotalRecords] = useState(0)
  const [totalSum, setTotalSum] = useState(0)

  useEffect(() => {
    fetchCommissions()
  }, [])

  useEffect(() => {
    if (!dataCommission) return
    setTotalRecords(dataCommission.length)
    setTotalSum(dataCommission.reduce((total, transaction) => total + transaction.amount, 0))
  }, [dataCommission])

  if (loadingCommission) return <Loading />
  return (
    <main>
      <h1 className='font-extrabold text-3xl mb-3'>{t('sidebar.commissionMenu')}</h1>
      <CommissionFilter />
      <div className='flex flex-col lg:flex-row mt-[12px] mb-[10px] gap-2'>
        <BadgeRecords text={t('commission.numberCommission')} count={totalRecords} />
        <BadgeSummation text={t('commission.sumCommission')} sum={totalSum} />
      </div>
      <CommissionTable loading={loadingCommission} data={dataCommission} />
    </main>
  )
}
