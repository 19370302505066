import React from 'react'
import { NavHome } from './Home/components'

export const HomeLayout = ({ children }) => {
  return (
    <div className='flex flex-col justify-center items-center w-full h-full min-h-screen bg-primary-600 text-white font-Kaleko'>
      <div className='w-full bg-black'>
        <NavHome />
      </div>
      <div className='w-full h-full '>{children}</div>
    </div>
  )
}
