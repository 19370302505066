import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useFetchSignInImpersonate = () => {
  const { data, loading, error, makeRequest, meta } = useApi(values => {
    const { route, method } = getEndpoint('signInAdmin')
    return httpClient[method](route, values)
  })

  return {
    metaSignInImpersonate: meta,
    dataSignInImpersonate: data,
    loadingSignInImpersonate: loading,
    errorSignInImpersonate: error,
    fetchSignInImpersonate: makeRequest,
  }
}
