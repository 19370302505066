import { Badge } from '@/components/Common'
import { useTranslation } from 'react-i18next'

export const OperationsBadge = ({ operation }) => {
  const { t } = useTranslation()

  return (
    <Badge className='inline-block' bg={operation ? 'bg-positive' : 'bg-negative'}>
      {operation ? t('global.yes') : t('global.no')}
    </Badge>
  )
}
