import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useWithdraw = () => {
  const { data, error, makeRequest, loading } = useApi(params => {
    const { route, method } = getEndpoint('withdraw')
    return httpClient[method](route, { ...params })
  })

  return {
    data: data,
    error: error,
    loading: loading,
    withdraw: makeRequest,
  }
}
