import MoneyAsText from '@/components/Common/MoneyAsText'
import { useArbitrage } from '../contexts'
import { Button, MoneyAsInput } from '@/components/Form'
import { useAuth, useBalance } from '@/contexts'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { BsClockHistory } from 'react-icons/bs'
import classNames from 'classnames'

export const SelectOffersToBuy = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { deposit } = useBalance()
  const {
    selectBuy,
    selectSell,
    setValueInput,
    valueInput,
    loadingUseBuyOffers,
    loadingOperationsArbitration,
    handleBuySellOffer,
    countdownText,
    loading,
  } = useArbitrage()

  const isDisabledButton =
    loadingUseBuyOffers ||
    loadingOperationsArbitration ||
    valueInput < user.settings.ARBITRATION_MINIMUM_AMOUNT ||
    !user.isVerified ||
    !valueInput ||
    countdownText ||
    loading
  return (
    <section className='flex flex-col gap-2 text-center mt-3'>
      <div className='flex flex-col gap-3 text-center md:flex-row'>
        <div className='border-2 border-yellow-600 dark:border-yellow-300 rounded-2 px-3 py-4 w-full h-fit'>
          {selectBuy ? (
            <>
              <span className='text-gray-800 dark:text-gray-300'>
                {t('arbitrage.youWillBuyFrom')} <strong className='font-bold'>{selectBuy.broker.name}</strong>
              </span>
              <p className='text-2xl md:text-3xl font-extrabold text-positive'>
                <MoneyAsText value={selectBuy.price} />
              </p>
            </>
          ) : (
            <>
              <span className='text-gray-800 dark:text-gray-300 text-sm'>{t('arbitrage.youWillBuyFrom')}</span>
              <p className='italic text-sm'>{t('arbitrage.selectBrokerThepurchaseOrder')}</p>
            </>
          )}
        </div>

        <div className='border-2 border-yellow-600 dark:border-yellow-300 rounded-2 px-3 py-4 w-full h-fit'>
          {selectSell ? (
            <>
              <span className='text-gray-800 dark:text-gray-300'>
                {t('arbitrage.youWillSellTo')} <strong className='font-bold'>{selectSell.broker.name}</strong>
              </span>
              <p className='text-2xl md:text-3xl font-extrabold text-negative'>
                <MoneyAsText value={selectSell.price} />
              </p>
            </>
          ) : (
            <>
              <span className='text-gray-800 dark:text-gray-300 text-sm'>{t('arbitrage.youWillSellTo')}</span>
              <p className='italic text-sm'>{t('arbitrage.selectBrokerTheSalesOrder')}</p>
            </>
          )}
        </div>
      </div>

      <div className='flex flex-col lg:flex-row gap-3 mt-1'>
        <div className='relative font-bold lg:w-full'>
          <MoneyAsInput
            onChange={e => setValueInput(e.numberValue)}
            name='purchaseOrder'
            value={valueInput}
            className='lg:h-full'
            isDisabled={loadingUseBuyOffers || loadingOperationsArbitration}
          />
          <span
            className='text-gray-500 font-extrabold absolute top-2 lg:top-[13px] right-2 hover:cursor-pointer hover:opacity-70'
            onClick={() => setValueInput(deposit)}
          >
            + Max
          </span>
        </div>
        <Button
          isLoading={loadingUseBuyOffers || loadingOperationsArbitration || loading}
          isDisabled={isDisabledButton}
          onClick={handleBuySellOffer}
          className={classNames('py-3 px-0 rounded-none font-extrabold lg:w-full text-sm', { 'animate-ping': !isDisabledButton })}
          text={countdownText === '' ? t('arbitrage.buyNow') : countdownText}
          iconLeft={countdownText && <BsClockHistory className='mb-[2px]' />}
        />
      </div>
      <div className='flex flex-col md:flex-row justify-between gap-2 md:items-center px-1'>
        <span className='text-start text-xs flex items-center gap-2 mt-1'>
          {t('arbitrage.valueMinArbitrage')} {<MoneyAsText value={user.settings.ARBITRATION_MINIMUM_AMOUNT} />}
        </span>
        {!user.isVerified && (
          <span className='text-start text-xs flex items-center gap-2 mt-1 text-yellow-600'>
            {t('global.accountNotVerified')}
            <Link to='/security' className='underline'>
              {t('profile.clickHere')}
            </Link>
          </span>
        )}
      </div>
    </section>
  )
}
