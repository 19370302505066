import { useTranslation } from 'react-i18next'

export const PIX = 1
export const BTC = 2
export const ETH = 3
export const USDT = 4
export const SKYT = 6

export const CREATED = 1
export const AWAITING_PAYMENT = 2
export const APPROVED = 3
export const VOUCHER = 4
export const REFUSED = 5
export const REVERSED = 6
export const EXPIRED = 7

export const getDepositType = type => {
  switch (type) {
    case PIX:
      return 'PIX'
    case BTC:
      return 'Bitcoin'
    case ETH:
      return 'Ethereum'
    case USDT:
      return 'USDT'
    case SKYT:
      return 'SKYT'
    default:
      break
  }
}

export const getDepositStatusText = type => {
  const { t } = useTranslation()
  switch (type) {
    case CREATED:
      return t('deposit.statusDeposit.created')
    case AWAITING_PAYMENT:
      return t('deposit.statusDeposit.awaitingPayment')
    case APPROVED:
      return t('deposit.statusDeposit.approved')
    case VOUCHER:
      return t('deposit.statusDeposit.voucher')
    case REFUSED:
      return t('deposit.statusDeposit.refused')
    case REVERSED:
      return t('deposit.statusDeposit.reversed')
    case EXPIRED:
      return t('deposit.statusDeposit.expired')
    default:
      break
  }
}
