import React, { useEffect, useState } from 'react'
import ReactFlagsSelect from 'react-flags-select'
import { useTranslation } from 'react-i18next'
import './style.scss'

const SwitchLanguage = () => {
  const { i18n } = useTranslation()
  const languageLocal = window.localStorage.getItem('language')
  const [selected, setSelected] = useState(languageLocal)

  const customLabels = {
    BR: 'PT-BR',
    US: 'US',
    ES: 'ES',
    FR: 'FR',
  }

  useEffect(() => {
    const languageLocal = window.localStorage.getItem('language')
    if (languageLocal === 'US') {
      i18n.changeLanguage('en-US')
    } else if (languageLocal === 'BR') {
      i18n.changeLanguage('pt-BR')
    } else if (languageLocal === 'ES') {
      i18n.changeLanguage('es-ES')
    } else if (languageLocal === 'FR') {
      i18n.changeLanguage('fr')
    }
    setSelected(languageLocal ?? 'US')
  }, [])

  return (
    <div className='flex items-center justify-center text-white'>
      <ReactFlagsSelect
        selectButtonClassName='flex items-center justify-center py-1 px-2 text-white'
        selected={selected}
        selectedSize={13}
        customLabels={customLabels}
        countries={['US', 'BR', 'ES', 'FR']}
        onSelect={code => {
          const country = code
          if (country === 'US') {
            i18n.changeLanguage('en-US')
            window.localStorage.setItem('language', code)
            setSelected(code)
          } else if (country === 'BR') {
            i18n.changeLanguage('pt-BR')
            window.localStorage.setItem('language', code)
            setSelected(code)
          } else if (country === 'ES') {
            i18n.changeLanguage('es-ES')
            window.localStorage.setItem('language', code)
            setSelected(code)
          } else if (country === 'FR') {
            i18n.changeLanguage('fr')
            window.localStorage.setItem('language', code)
            setSelected(code)
          }
        }}
      />
    </div>
  )
}
export default SwitchLanguage
