import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'

export function useLoginCustomer() {
  const { route, method } = getEndpoint('sign-in')
  const { data, error, loading, makeRequest } = useApi(values => httpClient[method](route, values))

  return {
    data,
    error,
    loading,
    login: makeRequest,
  }
}
