import React from 'react'

export const NotificationCard = ({ icon, title, text, date }) => {
  return (
    <div className='flex justify-between mb-1'>
      <div className='flex items-center gap-3'>
        <img src={icon} alt='/' className='max-w-[50px] bg-primary rounded-full p-2' />
        <span>
          <h2 className='font-bold mb-1'>{title}</h2>
          <p className='text-xs'>{text}</p>
        </span>
      </div>
      <p className='text-xs'>{date}</p>
    </div>
  )
}
