import { VscCopy } from 'react-icons/vsc'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { useAuth } from '@/contexts'

const ReferenceLink = () => {
  const { user } = useAuth()
  const indicationUrl = `${import.meta.env.VITE_BASE_URL}/affiliated/${user.indicationCode}`

  return (
    <>
      <CopyToClipboard
        text={indicationUrl}
        onCopy={() =>
          toast.success('Copiado com sucesso!', {
            position: 'top-center',
            autoClose: 2000,
          })
        }
      >
        <div className='cursor-pointer flex py-1 px-2 rounded-full gap-2 items-center justify-center max-w-[245px] md:max-w-full text-white md:bg-surface border border-white w-full md:text-primary-700'>
          <div className='text-sm md:text-lg'>
            <VscCopy />
          </div>
          <div className='text-xs truncate'>{indicationUrl}</div>
        </div>
      </CopyToClipboard>
    </>
  )
}

export default ReferenceLink
