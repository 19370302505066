import { useTranslation } from 'react-i18next'

export const CPF = 2
export const CNPJ = 3
export const PHONE = 4
export const EMAIL = 5
export const RANDOM = 6

export const CREATED = 1
export const APPROVED = 2
export const REFUSED = 3

export const getWithdrawStatusText = status => {
  const { t } = useTranslation()
  switch (status) {
    case CREATED:
      return t('deposit.statusDeposit.created')
    case APPROVED:
      return t('deposit.statusDeposit.approved')
    case REFUSED:
      return t('deposit.statusDeposit.refused')
    default:
      break
  }
}

export const getPixType = type => {
  const { t } = useTranslation()
  switch (type) {
    case CPF:
      return t('global.cpf')
    case CNPJ:
      return t('global.cnpj')
    case PHONE:
      return t('global.phone')
    case EMAIL:
      return t('global.email')
    case RANDOM:
      return t('withdraw.random')
  }
}

export const getOptionPixType = () => {
  return [
    { value: CPF, label: getPixType(CPF) },
    { value: CNPJ, label: getPixType(CNPJ) },
    { value: PHONE, label: getPixType(PHONE) },
    { value: EMAIL, label: getPixType(EMAIL) },
    { value: RANDOM, label: getPixType(RANDOM) },
  ]
}
