import { useViewport } from '@/hooks'
import ProgressBar from '@ramonak/react-progress-bar'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export const ProgressBarComponent = ({
  maxCompleted,
  isLabelVisible,
  completed,
  borderRadius,
  bgColor,
  height,
  baseBgColor,
  className,
  animateOnRender,
}) => {
  const { isMoreXl } = useViewport()
  const heightValue = isMoreXl ? '20px' : height
  return (
    <div className='flex w-full justify-between items-center gap-[10px]'>
      <span className='text-[#ABABAB] text-sm'>0%</span>
      <div className='relative w-full'>
        <ProgressBar
          completed={completed}
          maxCompleted={maxCompleted}
          bgColor={bgColor}
          height={heightValue}
          borderRadius={borderRadius}
          baseBgColor={baseBgColor}
          isLabelVisible={isLabelVisible}
          labelClassName={classNames('text-white text-xs absolute right-[45%]', className)}
          animateOnRender={animateOnRender}
          className='border border-gray-light rounded-full'
        />
      </div>
      <span className='text-[#ABABAB] text-sm'>100%</span>
    </div>
  )
}

ProgressBarComponent.propTypes = {
  maxCompleted: PropTypes.number,
  completed: PropTypes.number,
  isLabelVisible: PropTypes.bool,
  bgColor: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.string,
  baseBgColor: PropTypes.string,
  animateOnRender: PropTypes.bool,
}

ProgressBarComponent.defaultProps = {
  maxCompleted: 100,
  isLabelVisible: true,
  completed: 0,
  bgColor: '#007BDB',
  height: '14px',
  borderRadius: '99999px',
  baseBgColor: '',
  animateOnRender: true,
}
