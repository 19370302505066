import enUs from './en-US'
import es from './es-ES'
import fr from './fr'
import ptBr from './pt-BR'

export default {
  'en-US': { translation: { ...enUs } },
  'pt-BR': { translation: { ...ptBr } },
  'es-ES': { translation: { ...es } },
  fr: { translation: { ...fr } },
}
