import React from 'react'
import { useTranslation } from 'react-i18next'
import { PhoneField, SelectField, TextField } from '@/components/Form/Fields'
import { getLanguageOptions } from '@/constants'
import { useAuth } from '@/contexts'
import { Link } from 'react-router-dom'

export const PersonalFormProfile = ({ handleChange, errors, values, loadingCustomer }) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  return (
    <section className='w-full'>
      <div className='flex gap-2'>
        <TextField
          type='text'
          label={t('signIn.nameLabel')}
          name='firstName'
          errorMessage={errors.firstName}
          onChange={handleChange}
          value={values.firstName}
          placeholder={t('profile.placeholderName')}
          className='w-full rounded'
          isDisabled={loadingCustomer}
        />
        <TextField
          type='text'
          label={t('signUp.lastNameLabel')}
          name='lastName'
          errorMessage={errors.lastName}
          onChange={handleChange}
          value={values.lastName}
          placeholder={t('profile.placeholderLastName')}
          className='w-full rounded'
          isDisabled={loadingCustomer}
        />
      </div>
      <TextField
        type='text'
        label={t('signIn.nickname')}
        name='nickname'
        errorMessage={errors.nickname}
        onChange={handleChange}
        value={values.nickname}
        placeholder={t('profile.placeholderUser')}
        className='w-full rounded'
        isDisabled={loadingCustomer}
      />
      <TextField
        label={t('deposit.pixlabelCPF')}
        name='cpfCnpj'
        onChange={e => {
          if (!e.target.value.match(/\d+/g) ^ !e.target.value) return
          handleChange(e)
        }}
        value={values.cpfCnpj}
        errorMessage={errors.cpfCnpj}
        isDisabled={loadingCustomer}
        placeholder={t('profile.placeholderCpf')}
        className='w-full rounded'
      />
      <SelectField
        label={t('profile.language')}
        name='language'
        errorMessage={errors.language}
        value={values.language}
        options={getLanguageOptions()}
        onChange={handleChange}
        isDisabled={loadingCustomer}
        optionPrimaryDisabled
      />
      <TextField
        type='text'
        label={t('global.email')}
        name='email'
        errorMessage={errors.email}
        onChange={handleChange}
        value={values.email}
        placeholder={t('profile.placeholderEmail')}
        className='w-full rounded'
        isDisabled={true}
        noMargins
      />
      {user.emailVerified === false && (
        <p className='text-sm mt-1 text-yellow-600'>
          {t('profile.verifyEmail')}{' '}
          <Link to='/security' className='underline'>
            {t('profile.clickHere')}
          </Link>
        </p>
      )}
      <PhoneField
        name='phone'
        label={t('signUp.phone')}
        errorMessage={errors.phone}
        onChange={handleChange}
        value={values.phone}
        isDisabled={true}
        placeholder={t('profile.placeholderPhone')}
        className='w-full rounded mt-3'
        noMargins
      />
      {user.phoneVerified === false && (
        <p className='text-sm mt-1 text-yellow-600'>
          {t('profile.verifyPhone')}{' '}
          <Link to='/security' className='underline'>
            {t('profile.clickHere')}
          </Link>
        </p>
      )}
    </section>
  )
}
