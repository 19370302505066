import React, { Fragment } from 'react'
import { Routes, Route } from 'react-router-dom'

import { PrivateRoute } from '@/components/Common/index'
import { routes } from '@/routes'
import { Layout } from '@/pages/Layout'
import { NotFoundPage } from '@/pages/NotFoundPage'

export const AppRoutes = () => {
  return (
    <Routes>
      {routes.map(route => {
        const {
          path,
          pageComponent,
          enabled = true,
          isPublic = false,
          layoutComponent = Layout,
          isFullWidth = false,
          breadcrumb = [],
          notificationScreen = false,
        } = route
        const AppLayout = layoutComponent
        const PageComponent = pageComponent
        const PrivateOrPublicRoute = isPublic ? Fragment : PrivateRoute
        return (
          enabled && (
            <Route
              key={path}
              path={path}
              element={
                <PrivateOrPublicRoute>
                  <AppLayout isFullWidth={isFullWidth} notificationScreen={notificationScreen} breadcrumb={breadcrumb}>
                    <PageComponent />
                  </AppLayout>
                </PrivateOrPublicRoute>
              }
            />
          )
        )
      })}
      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  )
}
