import { EditAvatarButton, UserImage } from '@/components/Common'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import ReferenceLink from './ReferenceLink'
import { useAuth } from '@/contexts'

export const Avatar = () => {
  const { user } = useAuth()
  const [avatar, setAvatar] = useState(null)
  const { t } = useTranslation()

  function handleChangeAvatar(e) {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = function () {
        const image = new Image()
        image.src = reader.result
        image.onload = function () {
          if (image.width !== image.height) {
            toast.error(t('global.aspectRatioError'), {
              position: 'bottom-center',
              autoClose: 3500,
            })
            setAvatar(reader.result)
          } else {
            toast.success(t('global.successUploadImage'), {
              position: 'bottom-center',
              autoClose: 2000,
            })
            setAvatar(reader.result)
          }
        }
      }
      reader.readAsDataURL(file)
    }
  }

  useEffect(() => {
    // setAvatar(user.avatar)
  }, [])

  return (
    <div className='flex flex-col items-center justify-center gap-2'>
      <div className='relative'>
        <UserImage
          icon={avatar ? avatar : '/images/avatarPlaceholder.jpeg'}
          imageClassName='rounded-full w-full'
          borderColor=''
        />
        <span className='absolute -bottom-1 right-0 h-5'>
          <EditAvatarButton isDisabled={true} handleChangeAvatar={handleChangeAvatar} />
        </span>
      </div>

      <div className='text-center'>
        <p className='text-white text-sm font-semibold'>{user.nickname}</p>
        <p className='text-white text-xs'>{user.email}</p>
        <div className='flex mt-2 md:hidden cursor-pointer hover:opacity-70'>
          <ReferenceLink />
        </div>
      </div>
    </div>
  )
}
