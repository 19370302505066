import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Label } from '@/components/Form'
import { FieldMessage } from '@/components/Form/Fields/FieldMessage'
import { twMerge } from 'tailwind-merge'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'

export const PhoneField = ({
  name,
  onChange,
  noMargins,
  label,
  value,
  className,
  id,
  isDisabled,
  errorMessage,
  background,
  ...rest
}) => {
  const isInvalid = errorMessage !== ''
  const labelClasses = isInvalid ? 'text-negative' : ''
  const inputClasses = isInvalid ? 'border border-negative rounded' : ''
  const isDisabledClassName = isDisabled ? 'text-gray-100 opacity-30' : ''
  const isDarkBackground = background ? 'bg-background text-white' : ''
  const style =
    'bg-white flex px-2 items-center border rounded-md outline-none transition ease-in-out duration-300 delay-100 focus:border-gray-300'

  return (
    <div className={classNames({ 'mb-3': !noMargins }, className)}>
      <Label className={classNames(labelClasses)} text={label} htmlFor={id ?? name} isInvalid={isInvalid} />
      <PhoneInput
        onChange={onChange}
        defaultCountry='br'
        value={value}
        name={name}
        disabled={isDisabled}
        isInvalid={isInvalid}
        id={id ?? name}
        inputStyle={{
          color: isInvalid ? 'red' : background ? '#FFFFFF' : '#1D2939',
          fontSize: '16px',
          border: 'none',
          height: '40px',
          background: 'transparent',
        }}
        inputClassName={twMerge('w-fit placeholder:text-gray-200')}
        className={twMerge(style, isDisabledClassName, isDarkBackground, inputClasses)}
        disableDialCodePrefill
        {...rest}
      />
      {isInvalid && <FieldMessage message={errorMessage} />}
    </div>
  )
}
PhoneField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  noMargins: PropTypes.bool,
  format: PropTypes.string,
  mask: PropTypes.string,
  background: PropTypes.bool,
}
PhoneField.defaultProps = {
  className: '',
  isDisabled: false,
  errorMessage: '',
  noMargins: false,
  background: false,
}
