import MoneyAsText from '@/components/Common/MoneyAsText'
import { MdAttachMoney } from 'react-icons/md'
// import { RiCloudLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { ExtractResume } from './components'
import { useTranslation } from 'react-i18next'
import { useBalance } from '@/contexts'
import { useFetchExtract } from '../Extract/hooks'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import classNames from 'classnames'
// import { MoneyField } from '@/components/Form/Fields'
// import { Button } from '@/components/Form'
// import { AiOutlineArrowDown, AiOutlineArrowRight } from 'react-icons/ai'
// import Swal from 'sweetalert2'
import { useConvertAmount } from './hooks'
import { toast } from 'react-toastify'
import { Loading } from '@/components/Common'

export const Wallet = () => {
  const { t } = useTranslation()
  const { refreshBalance } = useBalance()
  const [/*valueConvert,*/ setValueConvert] = useState(0)
  const { balance, arbitrageProfit, networkProfit, companyCoin, depositWithVoucher /*,profit*/ } = useBalance()
  const { fetchTransactions, transactions, loading } = useFetchExtract()
  const { /*convertAmountRequest,*/ dataConvertAmount, errorConvertAmount /*, loadingConvertAmount*/ } = useConvertAmount()

  // const handleSubmitConvert = () => {
  //   if (valueConvert === 0 || !valueConvert) {
  //     toast.error(t('p2p.invalidValue'), {
  //       position: 'top-center',
  //       autoClose: 3500,
  //     })
  //     return
  //   }
  //   Swal.fire({
  //     title: t('p2p.confirmTitle'),
  //     text: t('p2p.confirmDescription'),
  //     icon: 'warning',
  //     confirmButtonText: t('deposit.continue'),
  //     showCancelButton: true,
  //     cancelButtonText: t('global.cancel'),
  //     cancelButtonColor: '#D92D20',
  //     confirmButtonColor: '#00437A',
  //     reverseButtons: true,
  //   }).then(result => {
  //     if (result.isConfirmed) {
  //       convertAmountRequest({ amount: valueConvert })
  //     }
  //   })
  // }

  useEffect(() => {
    fetchTransactions()
  }, [])

  useEffect(() => {
    if (!errorConvertAmount) return
    if (errorConvertAmount.error === 'insufficientBalance') {
      toast.error(t('p2p.errorInsufficientBalance'), {
        position: 'top-center',
        autoClose: 3500,
      })
      return
    }
    toast.error(t('p2p.errorConvert'), {
      position: 'top-center',
      autoClose: 3500,
    })
  }, [errorConvertAmount])

  useEffect(() => {
    if (!dataConvertAmount) return
    refreshBalance()
    fetchTransactions()
    setValueConvert(0)
    toast.success(t('p2p.sucessConvert'), {
      position: 'top-center',
      autoClose: 2000,
    })
  }, [dataConvertAmount])

  if (loading) return <Loading />

  return (
    <>
      <nav className='sm:flex justify-between items-center'>
        <h1 className='font-bold text-3xl mb-2 md:mb-0'>{t('wallet.myWallet')}</h1>
        <div className='grid grid-cols-2 md:grid-cols-3 text-center items-center gap-2'>
          {/* <Link to='/p2p' className='bg-gray-600 py-2 px-3 rounded-full text-sm hover:bg-primary transition duration-200'>
            {t('wallet.p2p')}
          </Link> */}
          <Link to='/deposit' className='bg-gray-600 py-2 px-3 rounded-full text-sm hover:bg-primary transition duration-200'>
            {t('wallet.deposit')}
          </Link>
          <Link to='/withdraw' className='bg-gray-600 py-2 px-3 rounded-full text-sm hover:bg-primary transition duration-200'>
            {t('wallet.withdraw')}
          </Link>
          <Link to='/extract' className='bg-gray-600 py-2 px-3 rounded-full text-sm hover:bg-primary transition duration-200'>
            {t('wallet.extract')}
          </Link>
        </div>
      </nav>

      <div className=' rounded mt-3 grid lg:grid-cols-2 gap-3'>
        <div className='flex flex-col items-center gap-3 w-full'>
          <section className='flex flex-col gap-3 w-full bg-gray p-3 h-fit text-lg'>
            <div className='flex flex-row items-center justify-between'>
              <span className='flex justify-center items-center gap-1'>
                <MdAttachMoney color='yellow' className='w-4 h-4' />
                <h3 className='mt-1'>{t('wallet.arbitrationPool')}</h3>
              </span>
              <span className='flex justify-center'>
                <MoneyAsText value={depositWithVoucher ?? 0} />
              </span>
            </div>

            <div className='flex flex-row items-center justify-between'>
              <span className='flex justify-center items-center gap-1'>
                <MdAttachMoney color='yellow' className='w-4 h-4' />
                <h3 className='mt-1'>{t('wallet.earnings')}</h3>
              </span>
              <span className='flex justify-center'>
                <MoneyAsText value={networkProfit ?? 0} />
              </span>
            </div>

            <div className='flex flex-row items-center justify-between'>
              <span className='flex justify-center items-center gap-1'>
                <MdAttachMoney color='yellow' className='w-4 h-4' />
                <h3 className='mt-1'>{t('wallet.earningsOperations')}</h3>
              </span>
              <span className='flex justify-center'>
                <MoneyAsText value={arbitrageProfit ?? 0} />
              </span>
            </div>

            <div className='flex flex-row items-center justify-between'>
              <span className='flex justify-center items-center gap-1'>
                <MdAttachMoney color='yellow' className='w-4 h-4' />
                <h3 className='mt-1'>{t('wallet.banking')}</h3>
              </span>
              <span className='flex justify-center'>
                <MoneyAsText value={balance ?? 0} />
              </span>
            </div>

            <div className='flex flex-row items-center justify-between border-t border-white pt-3'>
              {/* <span className='flex justify-center items-center gap-1'>
                <RiCloudLine color='blue' className='w-4 h-4' />
                <h3 className='mt-1'>{t('wallet.skyt')}</h3>
              </span> */}
              <span className='flex justify-center gap-1'>
                <span className='text-primary-100'>{companyCoin}</span>≈
                <MoneyAsText value={companyCoin ?? 0} />
              </span>
            </div>
          </section>
          {/* <div className='flex flex-col gap-3 w-full bg-gray p-3 h-fit text-lg'> */}
          {/* <div className='flex flex-col sm:flex-row items-center justify-between gap-2'>
              <h3>{t('p2p.convertSkyt')}</h3>
              <span className='flex gap-1 items-center p-2 px-3 rounded bg-green-500/40 text-sm ml-auto w-full justify-center text-center sm:w-fit'>
                {t('p2p.available')}:
                <span>
                  <MoneyAsText value={profit} />
                </span>
              </span>
            </div> */}
          <div className='flex flex-col sm:flex-row justify-between gap-3 sm:gap-5'>
            {/* <MoneyField
                label={t('p2p.amount')}
                textWhite
                className='w-full bg-transparent rounded-none border-t-0 border-x-0 text-white'
                noMargins
                onChange={e => setValueConvert(e.numberValue ?? 0)}
                value={valueConvert}
              />
              <span className='text-center w-full flex justify-center items-center sm:w-fit sm:pt-5'>
                <AiOutlineArrowRight className='hidden sm:block' />
                <AiOutlineArrowDown className='sm:hidden mt-1 block' />
              </span> */}
            {/* <MoneyField
                currencyCode='SKYT'
                label='SKYT'
                isDisabled
                textWhite
                onChange={() => {}}
                className='cursor-not-allowed w-full bg-transparent rounded-none border-t-0 border-x-0 border-b text-white opacity-70'
                noMargins
                value={valueConvert}
              /> */}
          </div>
          {/* <Button
              isDisabled={loadingConvertAmount}
              isLoading={loadingConvertAmount}
              onClick={handleSubmitConvert}
              text={t('p2p.convert')}
              className='rounded-md'
            /> */}
          {/* </div> */}
        </div>

        <section>
          <div className='bg-surface rounded py-2 px-3 text-primary-700'>
            <h3 className='font-bold mb-2 text-xl'>{t('wallet.recentHistory')}</h3>
            <div className={twMerge(classNames('max-h-[500px]', { 'overflow-y-scroll': transactions.length > 0 }))}>
              <ExtractResume data={transactions} />
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
