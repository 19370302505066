import classNames from 'classnames'
import PropTypes from 'prop-types'
import { twMerge } from 'tailwind-merge'

export const Select = ({
  options,
  name,
  optionPrimary,
  id,
  value,
  prefix,
  onChange,
  className,
  isDisabled,
  isInvalid,
  optionPrimaryDisabled,
  darkMode,
  textWhite,
  ...rest
}) => {
  const classes = classNames(
    'font-sans w-full block bg-zinc-50 p-[9px] border rounded-md outline-none transition ease-in-out duration-300 delay-100 text-primary-700 focus:border-gray-300',
    className,
    {
      'text-primary-700': value === '',
      'border border-negative text-negative': isInvalid,
      'text-white': darkMode || textWhite,
    }
  )

  return (
    <select
      className={twMerge(classes)}
      id={id ?? name}
      name={name}
      value={value}
      onChange={onChange}
      disabled={isDisabled}
      {...rest}
    >
      {!optionPrimaryDisabled && (
        <option className={darkMode || textWhite ? 'text-white' : 'text-primary-700'}>{optionPrimary}</option>
      )}
      {options.map(item => (
        <option className={darkMode || textWhite ? 'text-white' : 'text-primary-700'} value={item.value} key={item.value}>
          {prefix && `${prefix} `}
          {item.label}
        </option>
      ))}
    </select>
  )
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  optionPrimary: PropTypes.string,
  noMargins: PropTypes.bool,
  optionPrimaryDisabled: PropTypes.bool,
}

Select.defaultProps = {
  options: [],
  isDisabled: false,
  optionPrimaryDisabled: false,
  isInvalid: false,
  optionPrimary: ':: Selecione ::',
}
