import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useSendVerificationEmailPhoneToken = () => {
  const { data, loading, makeRequest, error, meta } = useApi((type, numbers) => {
    const { route, method } = getEndpoint(type === 'email' ? 'sendVerificationEmail' : 'sendVerificationPhone')
    return httpClient[method](route, { token: numbers })
  })

  return {
    dataUseSendVerificationEmailPhoneToken: data,
    loadingUseSendVerificationEmailPhoneToken: loading,
    errorUseSendVerificationEmailPhoneToken: error,
    useSendVerificationEmailPhoneTokenRequest: makeRequest,
    metaUseSendVerificationEmailPhoneToken: meta,
  }
}
