import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useCancelDeposit = () => {
  const { data, loading, makeRequest, error } = useApi(id => {
    const { route, method } = getEndpoint('cancelQrCode', { id: id })
    return httpClient[method](route)
  })

  return {
    dataCancelDeposit: data,
    loadingCancelDeposit: loading,
    errorCancelDeposit: error,
    cancelDepositRequest: makeRequest,
  }
}
