import { React, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@/contexts'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { SignInForm } from './components/SignInForm'
import { useFormSignIn } from './hooks'
import { useLoginCustomer } from './hooks/useLoginCustomer'
import SwitchLanguage from '@/components/SwitchLanguage'

export const SignIn = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()
  // const location = useLocation()
  const { token, setToken } = useAuth()
  const { data, error, loading, login } = useLoginCustomer()

  const {
    handleSubmit: singInHandleSubmit,
    handleChange: singInHandleChange,
    setFieldValue: singInSetFieldValues,
    values: singInValues,
    errors: singInErrors,
  } = useFormSignIn({
    onSubmit: values => {
      login(values)
    },
  })

  useEffect(() => {
    if (token) navigate('/arbitrage')
  }, [token])

  useEffect(() => {
    if (!data) return
    setToken(data.token)
    // window.localStorage.setItem('information', true)
    // const from = location.state?.from?.pathname || '/information'
    // navigate(from, { replace: true })
  }, [data])

  useEffect(() => {
    if (!error) return
    Swal.fire({
      title: t('signIn.userOrPasswordInvalid'),
      text: t('signIn.tryAgain'),
      icon: 'error',
      confirmButtonText: 'Ok',
    })
  }, [error])

  const handleModalIsOpen = () => {
    setModalIsOpen(!modalIsOpen)
  }

  return (
    <div className='min-h-[32rem]'>
      <div className='my-3 flex md:hidden justify-end '>
        <SwitchLanguage />
      </div>
      <SignInForm
        loading={loading}
        handleSubmit={singInHandleSubmit}
        handleChange={singInHandleChange}
        setFieldValue={singInSetFieldValues}
        values={singInValues}
        errors={singInErrors}
        handleModalIsOpen={handleModalIsOpen}
      />
    </div>
  )
}
