import { VscCopy } from 'react-icons/vsc'
import CopyToClipboard from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const PixLink = ({ code }) => {
  const { t } = useTranslation()
  return (
    <CopyToClipboard
      text={code}
      onCopy={() =>
        toast.success(t('global.copSuccess'), {
          position: 'bottom-center',
          autoClose: 2000,
        })
      }
      className='flex justify-center gap-3 py-2 px-3 text-white bg-slate-400 w-4/5 rounded-full cursor-pointer'
    >
      <div>
        <div className=' text-lg'>
          <VscCopy />
        </div>
        <div className='text-sm truncate'>{code}</div>
      </div>
    </CopyToClipboard>
  )
}

export default PixLink
