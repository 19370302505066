import classNames from 'classnames'

export const UserImage = ({ className, imageClassName, icon, borderColor = '#379EEB' }) => {
  const avatarGradutationColor = `border-[${borderColor}]`

  return (
    <div className={classNames('border-[5px] rounded-full w-[82px] h-[82px] overflow-hidden', className, avatarGradutationColor)}>
      <img src={icon} alt='Avatar' className={classNames('w-full h-full object-cover', imageClassName)} />
    </div>
  )
}
