import PropTypes from 'prop-types'
import classNames from 'classnames'
import React from 'react'
import { RoundedSpinnerIcon } from '@/components/Common'
import { twMerge } from 'tailwind-merge'

export const ButtonTypeDeposit = ({ type, text, children, className, isSelect, onClick, icon, isDisabled, isLoading, usdt }) => {
  const buttonClasses = classNames('flex justify-center items-center gap-2 rounded-full py-3 px-4 text-primary-700', className, {
    'bg-gradient-to-r from-primary-200 to-primary-400 text-white font-semibold border border-white': !isSelect,
    'bg-white': isSelect,
    'opacity-40 cursor-not-allowed': isDisabled,
    'flex gap-2 items-center': isLoading,
    'whitespace-nowrap gap-1': usdt,
  })

  return (
    <>
      <button type={type} className={twMerge(buttonClasses)} onClick={onClick}>
        <span className='text-[18px]'>{icon}</span>
        {isLoading && <RoundedSpinnerIcon stroke='#fff' />}
        {text !== '' ? text : children}
        {usdt && <span className='text-sm'>({usdt})</span>}
      </button>
    </>
  )
}

ButtonTypeDeposit.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
}

ButtonTypeDeposit.defaultProps = {
  text: '',
  type: 'button',
  isLoading: false,
  isDisabled: false,
  onClick: () => {},
}
