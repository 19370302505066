export default {
  global: {
    confirmAdmin: "Confirmer le mot de passe de l'administrateur",
    adminInform: 'Accès limité ! Vous accédez à ce compte en mode Support !',
    accountNotVerified: 'Compte non vérifié',
    companyName: 'Botfy',
    successTitle: 'Tout est en ordre !',
    errorTitle: 'Une erreur est survenue',
    ok: 'OK',
    await: 'En attente :',
    yes: 'Oui',
    no: 'Non',
    phone: 'Téléphone',
    cryptocurrencyWallet: 'Portefeuille de crypto-monnaie',
    login: 'Connexion',
    name: 'Nom',
    email: 'E-mail',
    cnpj: 'CNPJ',
    cpf: 'CPF',
    randomKey: 'Clé aléatoire',
    createdAt: 'Date de création',
    activeAt: "Date d'activation",
    send: 'Envoyer',
    balance: 'Solde',
    screenLoadingText: 'Chargement en cours...',
    price: 'Prix',
    change: 'Changement',
    chart: 'Graphique',
    exit: 'Sortir',
    trade: 'Commerce',
    cancel: 'Annuler',
    edit: 'Modifier',
    automaticOperations: 'Opérations automatiques',
    deautomateOperations: "Désactiver l'automatisation des opérations",
    notMessage: 'Aucun message.',
    emailRequired: 'E-mail requis',
    titleMessages: 'Notifications',
    readMessages: 'Marquer comme lues',
    chartTitle: 'Marché (BTC/USDT) 1D',
    sendEmailRequest: 'Envoyer un Token',
    verify: 'Vérifier',
    languages: {
      english: 'Anglais',
      portuguese: 'Portugais (BR)',
      spanish: 'Espagnol',
      french: 'Français',
    },
    validation: {
      validationRecaptcha: 'Recaptcha invalide.',
      required: 'Ce champ est obligatoire',
      validationCpf: 'Le CPF doit contenir 11 caractères',
      validationCpfInvalid: 'Document déjà en utilisation.',
      validationPhone: 'Le téléphone doit contenir 11 caractères',
      validationCpnjLength: 'Le CNPJ doit contenir 14 chiffres',
      validationPhoneSignUp: 'Le numéro de téléphone est déjà utilisé.',
    },
    aspectRatioError: "L'image n'est pas au bon ratio. Nous recommandons une image au format carré.",
    successUploadImage: 'Téléchargement réussi !',
    sendEmail: 'Nous avons envoyé un jeton à votre e-mail !',
    errorEmail: "Erreur lors de l'envoi du jeton à votre e-mail.",
    sendPhone: 'Un jeton a été envoyé à votre SMS !',
    errorPhone: "Erreur lors de l'envoi du jeton à votre SMS.",
    successSendEmail: 'E-mail vérifié avec succès !',
    successSendPhone: 'Téléphone vérifié avec succès !',
    errorSendEmail: "Erreur lors de la vérification de l'e-mail.",
    errorSendPhone: 'Erreur lors de la vérification du téléphone.',
    errorVerification: 'Tous les champs doivent être remplis',
    tokenInvalid: 'Jeton invalide.',
    cancelDepositSuccess: 'Dépôt annulé avec succès !',
    cancelDeposit: 'Dépôt annulé.',
    placeholderValue: 'Ex.: $ 100.00',
    information: {
      title: 'Bonjour, Famille botfy !',
      att: 'Cordialement, Équipe botfy',
      text1: 'Nous avons apporté une petite mise à jour pour améliorer les performances de nos systèmes et serveurs.',
      text2:
        "Chaque fois qu'une société de courtage met à jour ses API, nous devons également mettre à jour notre côté pour continuer à obtenir des cotations en temps réel. Pour cette raison, notre mode automatique était en mise à jour pendant environ 30 heures.",
      text3: 'Tout est maintenant à jour, et les opérations ont été normalisées.',
      text4:
        "Il est important de surveiller vos systèmes au moins une fois par jour ! Et chaque fois qu'il y a des mises à jour pour ceux qui utilisent le mode automatique, ÉTEIGNEZ et RALLUMEZ et attendez la prochaine opération.",
      checkbox: 'Je Suis Conscient',
    },
  },
  signIn: {
    nameLabel: 'Nom',
    emailLabel: 'Email',
    passwordLabel: 'Mot de passe',
    termsLabel: "Je certifie avoir 18 ans ou plus et j'accepte les Conditions d'utilisation.",
    termsAndConditionsLabel: `Voir les Conditions d'utilisation`,
    confirmPasswordLabel: 'Confirmer le mot de passe',
    usernameLabel: `Nom d'utilisateur`,
    login: 'Se connecter',
    nickname: 'Pseudo',
    password: 'Mot de passe',
    userName: `Nom d'utilisateur`,
    emailUserName: `Nom d'utilisateur ou email`,
    IndicatedBy: 'Indiqué par',
    CreateYourAccount: 'Créez votre compte maintenant !',
    accessAcount: 'Accéder au compte',
    forgotMyPassword: `J'ai oublié mon mot de passe`,
    enterUsername: `Entrez votre nom d'utilisateur`,
    enterEmail: 'Entrez votre email',
    enterPassword: 'Entrez votre mot de passe',
    resetPassword: 'Réinitialiser le mot de passe',
    invalidEmail: 'Email invalide',
    validEmail: 'Email valide',
    sendEmail: 'Email envoyé avec succès',
    sendEmailConfirmPassword: 'Nous avons envoyé un email pour changer votre mot de passe !',
    userOrPasswordInvalid: `Nom d'utilisateur ou mot de passe invalide.`,
    errorChangePassword: `Impossible d'envoyer un email pour le changement de mot de passe, veuillez réessayer !`,
    tryAgain: 'Réessayer',
    signUp: `Je n'ai pas de compte, je veux m'inscrire`,
    connectTourAccount: 'Connectez votre compte',
    resetYourPassword: 'Réinitialisez votre mot de passe',
    emailNotRegistered: 'Email non enregistré',
  },
  signUp: {
    firstNameLabel: 'Prénom',
    lastNameLabel: 'Nom de famille',
    signUpNow: `S'inscrire maintenant`,
    fullName: 'Nom complet',
    email: 'Votre meilleur e-mail',
    phone: 'Téléphone',
    login: 'Connexion',
    password: 'Mot de passe',
    passwordConfirm: 'Confirmer le mot de passe',
    signUp: `S'inscrire`,
    signIn: 'Vous avez déjà un compte ? Se connecter !',
    IndicatedBy: 'Indiqué par',
    titleSignUp: 'Découvrez la révolution financière du siècle en vous inscrivant à botfy !',
    subTitleSignUp: 'Votre liberté financière à portée de clic !',
    validation: {
      validationUsername: 'Seuls les caractères alphanumériques, les traits de soulignement et les tirets sont autorisés',
      validationPassword: 'Le mot de passe est obligatoire',
      validationConfirmPassword: 'La confirmation du mot de passe est obligatoire',
      validationRequiredConfirmPassword: 'Les mots de passe ne correspondent pas',
      invalidEmail: `Ceci n'est pas une adresse e-mail valide.`,
      validationEmail: 'Cet e-mail est déjà utilisé.',
    },
    usernameTip: 'Utilisez uniquement des lettres minuscules, des chiffres, des tirets et des traits de soulignement.',
    phoneTip: 'DDD + Votre numéro de téléphone',
    successMessageTitle: 'Votre inscription a été effectuée.',
    successMessageText: 'Vous avez maintenant accès à la plateforme botfy.',
  },
  profile: {
    awaitWithdrawOne: 'Après avoir modifié le plan de retrait, vous devez attendre ',
    awaitWithdrawTwo: ' jours pour un nouveau changement !',
    placeholderName: 'Ex.: Érick',
    placeholderLastName: 'Ex.: Jacquin',
    placeholderUser: 'Ex.: erick_jacquin',
    placeholderEmail: 'Ex.: erick_jacquin@gmail.com',
    placeholderPhone: 'Ex.: (11) 98783-4343',
    placeholderCpf: 'Ex.: 012.345.678-90',
    placeholderCnpj: 'Ex.: 01.234.567/8901-01',
    placeholderAddress: 'Ex.: Rua dos Alpes, 1322',
    placeholderBitcoinWallet: 'Ex.: 5x1HUXrCNLbtMDqcw6o5GNn4xqX',
    placeholderRandomKey: 'Ex.: 5x3123fsdvc1HUXr4xqX',
    myProfile: 'Mon profil',
    userInfo: "Informations sur l'utilisateur",
    pixInfo: 'Informations de paiement Pix',
    btcInfo: 'Données Bancaires',
    pixKey: 'Clé Pix',
    pixKeyType: 'Type de clé Pix',
    save: 'Enregistrer',
    saveDataSucess: 'Vos données ont été enregistrées.',
    imgAltProfile: 'Image de profil',
    email: 'E-mail',
    bitcoinWallet: 'Portefeuille Bitcoin',
    clickHere: 'Cliquez ici pour vérifier',
    verifyEmail: 'Email non vérifié',
    verifyPhone: 'Téléphone non vérifié',
    cryptoType: 'Type de portefeuille de crypto',
    walletEthereum: 'Portefeuille Ethereum',
    walletUstd: 'Portefeuille USDT',
    language: 'Langue par défaut',
    titleConfigWithdraw: 'Paramètres de retrait',
    withdrawOptionWeekly: 'Hebdomadaire',
    withdrawOptionFortnightly: 'Bimensuel',
    withdrawOptionMonthly: 'Mensuel',
    fee: 'de Frais',
  },
  home: {
    openAccount: 'Ouvrir un Compte',
    startYourPortfolio: 'La manière la plus facile, rapide et simple de profiter des cryptomonnaies.',
    startYourPortfolioDescription: 'Votre chemin vers des profits rapides et faciles en cryptomonnaies commence ici.',
    whyBecomeSkyOperator: 'Pourquoi devenir un Opérateur BotFY?',
    innovationStep:
      "Fini de compter sur la chance, comptez sur la plus grande intelligence artificielle du Marché de l'Arbitrage.",
    transparencyStep: "Rien n'est caché. Toutes les opérations sont enregistrées et disponibles sur la Blockchain.",
    alreadyHaveAnAccount: 'Vous avez déjà un compte?',
    toEnter: 'Entrer',
    brazilBotfyConventionTitle: 'Convention BotFY Brésil',
    brazilBotfyConvention: 'Déc 2023',
    botfyToken: 'Jeton BotFY',
    botfyTokenDate: 'Jan 2024',
    matrizBotfy: 'Courtier et Siège Officiel à Dubaï',
    dateMatrizBotfy: 'Mar 2024',
    participatingCountries: 'Pays participants',
    verifiedCustomers: 'Clients vérifiés',
    innovationAndTechnology: 'INNOVATION ET TECHNOLOGIE',
    transparency: 'TRANSPARENCE',
    security: 'SÉCURITÉ',
    securityStep: "La technologie d'IA la plus sûre, offrant les analyses et prises de décisions les plus efficaces du marché.",
    stepByStep: 'Vos prochaines étapes avec notre plateforme!',
    firstStepLabel: '#01',
    firstStepDescription: "S'INSCRIRE",
    secondStepLabel: '#02',
    secondStepDescription: "DÉPOSEZ VOTRE TABLE D'OPÉRATION",
    thirdStepLabel: '#03',
    thirdStepDescription: 'SUIVEZ VOS OPÉRATIONS',
    fourthStepLabel: 'OPTIONNEL',
    fourthStepDescription: "RECOMMANDEZ À D'AUTRES CLIENTS ET RECEVEZ DES COMMISSIONS ET DES RÉCOMPENSES",
    openMyAccount: 'Ouvrir Mon Compte',
    buyCryptoAtRealCost: 'Achetez et Vendez des Cryptos avec de Grands Profits',
    buyCryptoAtRealCostDescription: 'Tradez parmi les 20 plus grandes bourses du marché',
    buy: 'Acheter',
    screenshot: "capture d'écran du site web",
    createAnAccount: "S'inscrire",
    linkYourBankAccount: "Déposez Votre Table d'Opération",
    startBuyingAndSelling: 'Suivez Vos Opérations',
    bankIcon: 'icône de banque',
    salesIcon: 'icône de ventes',
    accountIcon: 'icône de compte',
    titleStartInAFewMinutes: 'Vos Prochaines Étapes',
    textStartInAFewMinutes: 'Avec Notre Plateforme',
    titleEarnCrypto: "Profitez grâce au Pool d'Arbitrage",
    textEarnCrypto:
      "Le flux le plus facile pour acheter et vendre des cryptomonnaies. Ouvrez votre compte et commencez dès aujourd'hui.",
    startEarning: 'Commencez à Gagner',
    cryptocurrencyMarket: 'marché des cryptomonnaies',
  },
  arbitrage: {
    automaticOperations: 'Opération Automatique',
    textActiveOperations: 'Opérations Automatiques Actives',
    BuyAndSellOffers: "Offres d'achat et de vente",
    bestBuyPrice: "Meilleur prix d'achat",
    bestSalePrice: 'Meilleur prix de vente',
    purchaseOffers: "Offres d'achat",
    salesOffers: 'Offres de vente',
    updatingNewOffers: 'Mise à jour des nouvelles offres',
    youWillBuyFrom: 'Vous achèterez auprès de',
    youWillSellTo: 'Vous vendrez à',
    selectBrokerTheSalesOrder: 'Sélectionnez un courtier dans la section des ordres de vente',
    selectBrokerThepurchaseOrder: "Sélectionnez un courtier dans la section des ordres d'achat",
    buyNow: 'Acheter maintenant !',
    yourRecentOperations: 'Vos opérations récentes',
    broker: 'Courtier',
    purchasePrice: "Prix d'achat",
    offerPrice: "Prix de l'offre",
    percentage: 'Pourcentage',
    purchaseSaleDate: "Date d'achat/vente",
    amountsent: 'Montant envoyé',
    transaction: 'Transaction',
    status: 'Statut',
    lastNegotiations: 'Dernières négociations',
    Price: 'Prix (USDT)',
    Amount: 'Montant (BTC)',
    Hour: 'Heure',
    total: 'Total',
    orderBook: "Livre d'ordres",
    robotMessage: "Le robot a une marge d'erreur de +/- 1% sur la valeur de l'arbitrage.",
    notRecordsFound: 'Aucun enregistrement trouvé.',
    valueMinArbitrage: "La valeur minimale d'arbitrage est : ",
    refund: `Remboursement de l'opération`,
    profit: `Bénéfice de l'opération`,
    tax: `Taxe de l'opération`,
  },
  deposit: {
    requestedAmount: 'Montant demandé',
    requestedTax: 'Frais de retrait',
    confirmPaymentEmail: "Votre commande sera en cours d'examen et vous recevrez un e-mail une fois approuvée !",
    withdrawNumber: 'Numéro',
    expiredDeposit: 'Dépôt expiré',
    informationUsdt: 'Réseau: TRON',
    networkUSDT: 'Le réseau pour le dépôt de USDT est : TRON',
    depositSkyt: 'Déposer',
    continue: 'Continuez',
    confirmDepositSkyt: 'Cette action est définitive et ne peut pas être annulée. Veuillez confirmer pour continuer.',
    awaitDeposit: 'Le délai de confirmation de votre dépôt peut prendre de 1 minute à 2 heures.',
    depositScreenTitle: 'Dépôt',
    tagSelection: 'Sélectionnez la devise et le mode de paiement',
    buttonPix: 'PIX',
    buttonBTC: 'BTC',
    buttonEther: 'Ethereum',
    buttonUSTD: 'USDT',
    buttonPaypal: 'Paypal',
    buttonSKYT: 'SKYT',
    minimumDepositAmount: 'Montant minimum de dépôt : ',
    imgAlt: 'Vecteur de Dépôt',
    depositInformations: 'Informations de Dépôt',
    estimationBTC: 'Estimation du BTC :',
    estimationETH: "Estimation de l'Ethereum :",
    estimationUSDT: "Estimation de l'USDT :",
    estimationUSD: 'Estimation du Dollar :',
    pixLabelValue: 'Montant du Dépôt',
    pixPlaceholderValue: 'Quantité (U$)',
    pixlabelCPF: 'Document',
    pixPlaceholderCPF: 'Ex. : 123.456.789-00',
    pixlabelEmail: 'E-mail',
    pixPlaceholderEmail: 'Ex. : liminha_limas@bol.com.br',
    warningValueInReal: 'Vous allez déposer en réel :',
    depositButtonRequest: 'Demande de Dépôt',
    pixDetailButton: 'Générer le QR Code',
    pixKeyLabel: 'Copier la clé Pix',
    pixCancelButton: 'Annuler',
    copyCodeSuccess: 'Code copié dans le presse-papiers',
    criptoLabelValue: 'Montant du Dépôt',
    criptoPlaceholderValue: 'Quantité (U$)',
    criptoDetailButton: 'Détails des Portefeuilles',
    depositHistory: 'Historique de Dépôt',
    receptorName: 'Nom du Bénéficiaire',
    cpfNumber: 'N° CPF',
    transactionType: 'Type',
    requestDate: 'Date de la Demande',
    amount: 'Montant',
    successDeposit: 'Dépôt effectué avec succès !',
    errorDeposit: 'Erreur lors du dépôt.',
    youDeposit: 'Vous allez déposer : ',
    status: 'Statut',
    wallet: 'Numéro de Portefeuille :',
    rate: 'Toujours envoyer en priorité',
    blockchain: 'Votre dépôt sera actif dès que les confirmations minimales sur la blockchain seront atteintes',
    statusDeposit: {
      created: 'Créé',
      awaitingPayment: 'En attente de paiement',
      approved: 'Approuvé',
      voucher: 'Bon',
      refused: 'Refusé',
      reversed: 'Annulé',
      expired: 'Expiré',
    },
  },
  status: {
    pending: 'En attente',
    approved: 'Approuvé',
    underAnalysis: 'En analyse',
    refused: 'Refusé',
    awaitingPayment: 'En attente de paiement',
    active: 'Actif',
    inactive: 'Inactif',
    activeVoucher: 'Actif',
  },
  sidebar: {
    matrix: 'Matrice',
    arbitrage: 'Arbitrage',
    deposit: 'Dépôt',
    network: 'Réseau',
    withdraw: 'Retrait',
    extract: 'Extraire',
    wallet: 'Portefeuille',
    security: 'Sécurité',
    profile: 'Profil',
    logout: 'Quitter',
    closeSidebar: 'Fermer',
    automaticOperations: 'Opérations automatiques',
    transactionAlertOne: 'Les transactions seront effectuées automatiquement dans la période minimale de ',
    transactionAlertTwo: ' heures, et il y aura des frais administratifs de 20% sur la transaction.',
    disableAutomatic: 'Voulez-vous désactiver les opérations automatiques?',
    disableNow: 'Désactivez maintenant!',
    activateNow: 'Activer maintenant!',
    cancel: 'Annuler',
  },
  extract: {
    filters: 'Filtres',
    selectType: ':: Sélectionnez le Type ::',
    extract: 'Extraire',
    dataTransaction: 'Date de transaction',
    description: 'Description',
    type: 'Type',
    balanceBefore: 'Solde Avant',
    value: 'Valeur',
    balanceAfter: 'Solde Après',
    noTransactionCell: `Aucune transaction n'a été trouvée`,
    startDate: 'Date de début',
    endDate: 'Date de fin',
    filter: 'Consulter',
    optionPrimary: 'Sélectionner le type',
    deposit: 'Dépôt',
    withdraw: 'Retirer',
    affiliated: 'Affilié',
    arbitration: 'Arbitrage',
    P2P: 'P2P',
    matrixBonus: 'Matrice de bonus',
    PL: 'PL',
    all: 'Tous',
    allRecords: 'Tous les Enregistrements:',
    summation: 'Somme:',
    volume: 'Volume',
  },
  withdraw: {
    withdrawTax: 'Frais de Retrait',
    networkUSDTWithdraw: 'Le réseau pour le retrait de USDT est : TRON',
    documentStatus: 'La vérification des documents est nécessaire pour effectuer le retrait.',
    invalidWithdraw: 'Retrait non disponible',
    paymentMethodOff: 'Ce mode de paiement est en cours de mise à jour. Veuillez choisir une autre méthode de réception !',
    blockedWithdraw: 'Retrait bloqué. Veuillez contacter le support !',
    maxWithdraw: "Vous pouvez retirer jusqu'à : ",
    notWithdraw: "Aujourd'hui n'est pas un jour de retrait.",
    withdraw: 'Retrait',
    withdrawDescription: 'Sélectionnez le mode de retrait',
    cryptocurrency: 'Cryptomonnaie',
    minimumValue: 'Retrait minimum : ',
    withdrawImage: 'image de retrait',
    withdrawalInformation: 'Informations de retrait',
    withdrawalHistory: 'Historique des retraits',
    failedWithdrawalHistory: "Vous n'avez pas encore de demandes de retrait",
    random: 'Aléatoire',
    withdrawalValue: 'Valeur de retrait',
    withdrawalAmount: 'Montant du retrait',
    conversionToUSD: 'Conversion en USD',
    keyType: 'Type de clé',
    enterTheKey: 'Entrez la clé',
    sameOwner: 'Compte du même propriétaire ?',
    period: '7 (sept) jours',
    pleaseWait: 'Veuillez patienter ',
    forNextOperation: " pour la prochaine opération. C'est le temps nécessaire pour traiter votre retrait.",
    withdrawalRequest: 'Demande de retrait',
    resourceRelease: '* Libération de ressources hebdomadaire',
    yourCryptocurrencyWallet: 'Votre portefeuille de cryptomonnaie',
    successMessageTitle: 'Succès !',
    successMessageText: 'Votre retrait a été effectué.',
    deniedMessageTitle: 'Demande refusée',
    deniedMessageText: 'Solde insuffisant',
    minimumValueError: 'Ajoutez une valeur supérieure à ',
    nameValidation: 'Entrez un nom valide',
  },
  forgotPassword: {
    textForgotPassword:
      '*Pour demander la réinitialisation du mot de passe, veuillez saisir votre adresse e-mail dans le champ ci-dessous et cliquer sur le bouton "Envoyer". Nous vous enverrons un e-mail contenant un lien sécurisé pour réinitialiser votre mot de passe.',
    passwordChange: 'Changement de mot de passe',
    textResetPassword:
      'Veuillez saisir votre nouveau mot de passe dans les champs ci-dessous et le confirmer pour finaliser la modification du mot de passe.',
    newPassword: 'Nouveau mot de passe',
    confirmNewPassword: 'Confirmer le nouveau mot de passe',
    successMessageTitle: 'Mot de passe modifié avec succès !',
    invalidPassword: 'Mot de passe invalide !',
    invalidConfirmPassword: 'Les mots de passe ne correspondent pas !',
    invalidToken: 'Jeton invalide. Réessayez plus tard !',
    successSendEmail: 'Email envoyé avec succès',
    invalidEmail: "Ceci n'est pas une adresse e-mail valide.",
    textForgotPasswordNew:
      'Pour réinitialiser votre mot de passe, veuillez utiliser un appareil qui a récemment accédé à votre compte botfy.',
  },
  network: {
    affiliateNetwork: "Réseau d'affiliation",
    direct: 'Direct :',
    indirect: 'Indirect :',
    yourEntireNetwork: 'Votre réseau entier :',
    consult: 'Consulter',
    searchforLogin: 'Rechercher un login ou un nom...',
    toClean: 'Effacer',
    openNetwork: 'réseau',
    volumeSum: 'Volume du réseau',
  },
  security: {
    security: 'Sécurité',
    emailPhoneText: 'Protégez vos transactions et votre compte',
    verify: 'Vérifier',
    accessPassword: "Mot de passe d'accès",
    accessPasswordText: 'Le mot de passe de connexion est utilisé pour accéder à votre compte.',
    change: 'Changer',
    phoneNumberVerification: 'Vérification du numéro de téléphone',
    emailVerification: "Vérification de l'email",
    verificationCode: 'Entrez votre code de vérification',
    changeAccessPassword: "Changer le mot de passe d'accès",
    currentPassword: 'Mot de passe actuel',
    confirmPassword: 'Confirmer le mot de passe',
    editPhone: 'Modifier le téléphone',
    newPhone: 'Nouveau téléphone',
    editEmail: "Modifier l'email",
    newEmail: 'Nouvel email',
    verifiedEmail: 'Email vérifié',
    verifiedPhone: 'Téléphone vérifié',
    questionVerifyEmail: 'Pour vérifier votre e-mail',
    questionVerifyPhone: 'Pour vérifier votre téléphone : ',
    textVerify: 'appartient à vous, cliquez sur le bouton ci-dessous pour démarrer le processus',
    resendToken: 'Renvoyer le jeton',
    documents: 'Documents',
    PENDING: 'En attente',
    UNDER_ANALYSIS: 'En analyse',
    APPROVED: 'Approuvé',
    REFUSED: 'Refusé',
    REVOKED: 'Révoqué',
    verifiedDocuments: 'Documents vérifiés',
    sendDocuments: 'Envoyer des documents',
    frontDocuments: 'Face du document',
    backDocuments: 'Dos du document',
    selfieDocuments: 'Document près du visage',
    errorsDocuments: "Erreur lors de l'envoi des documents.",
    successDocuments: 'Documents envoyés avec succès !',
    permForm: 'Formats autorisés :',
    maxTam: 'Taille maximale :',
    invalidExtension: 'Extension invalide.',
  },
  p2p: {
    minimumAmountForOperation: 'Le montant minimum pour effectuer P2P est de ',
    confirmPassword: 'Confirmation du mot de passe',
    insertPassword: 'Entrez votre mot de passe pour vérification de sécurité.',
    dataTransfer: 'Données de transfert',
    emailRecipient: 'Email du destinataire',
    search: 'Rechercher',
    placeholderValue: 'Ex. : $100.00',
    valueTransfer: 'Montant en SKYT',
    transferSkyt: 'Vous allez transférer en SKYT :',
    notFoundRecipient: 'Destinataire non trouvé',
    searchRecipient: 'Rechercher le destinataire',
    TransactionSuccessful: 'Transaction réussie.',
    TransactionError: 'Erreur lors de la transaction.',
    p2p: 'p2p',
    SkytBallance: 'SKYT disponible :',
    transferTax: 'Frais de transfert',
    confirmTitle: 'Es-tu sûr(e) ?',
    confirmDescription: 'Cette action est irréversible. Votre solde sera converti en SKYT !',
    invalidValue: 'Valeur invalide.',
    errorConvert: 'Erreur lors de la conversion du solde en SKYT.',
    errorInsufficientBalance: 'Solde insuffisant!',
    sucessConvert: 'Solde converti avec succès !',
    convertSkyt: 'Convertisseur SKYT',
    available: 'Disponible',
    amount: 'Solde',
    convert: 'Convertir',
  },
  wallet: {
    myWallet: 'Mon Portefeuille',
    deposit: 'Dépôt',
    withdraw: 'Retrait',
    p2p: 'P2P',
    extract: 'Extraction',
    arbitrationPool: "Pool d'arbitrage",
    earnings: 'Gains',
    skyt: 'SKYT',
    banking: 'Banque',
    recentHistory: 'Historique récent',
  },
  careerPath: {
    careerPath: 'Plan de carrière',
    nextGraduation: 'Prochaine graduation',
    nextGraduations: 'Prochaines graduations',
    graduationsCheck: 'Graduations atteintes',
    graduationsProgress: 'Graduation en cours',
    hitGoal: "Pour atteindre l'objectif, vous avez besoin de : ",
  },
  extractConstants: {
    skytExchange: 'SKYT Converti',
    ballanceSkytConvert: 'Conversion de Solde en SKYT',
    withdrawRefund: 'Remboursement de Retrait',
    skytDepositConvert: 'Conversion de SKYT en Solde',
    refund: 'Renversement',
    skytDeposit: 'Dépôt de SKYT',
    purchase: 'Achat',
    reverse: 'Remboursement',
    arbitration: "Remboursement d'arbitrage",
    voucherDeposit: `Dépôt de Bon d'Achat`,
    withdrawFixedAmountTax: 'Taxe de Retrait',
    arbitrationProfit: `Profit d'Arbitrage`,
    networkCommission: 'Commission Réseau',
    residualCommission: 'Commission Résiduelle',
    withdrawRequest: 'Demande de Retrait',
    withdraw: 'Retrait',
    manualBonus: 'Bonus Manuel',
    deposit: 'Dépôt',
    arbitrageRequest: `Arbitrage`,
    arbitrageProfitRequest: `Retrait de Profit d'Arbitrage`,
    transferTaxP2p: 'Frais de Transfert P2P',
    skytTransferReceive: 'Transfert SKYT Reçu',
    skytTransferSender: 'Transfert SKYT Envoyé',
    unknown: 'Inconnu',
    arbitrationTax: "Taxe d'arbitrage",
  },
  matrix: {
    bonus: 'Montant du Bonus :',
    lastPayment: 'Dernier Paiement :',
    details: 'Détails',
    volPayment: 'Volume Payé',
    date: 'Date',
  },
}
