import React from 'react'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

export const ButtonsTabs = ({ value, form, text, onClick }) => {
  const tabsButton = classNames(
    'py-2 px-5 bg-gray-200 rounded-t-3 text-primary w-[52%] absolute border-b-2 font-bold focus:text-primary text-center',
    {
      'py-2 px-5 bg-white text-center border-primary z-10': form === value,
      'left-0': value === 1,
      'right-0': value === 2,
    }
  )
  return (
    <button className={twMerge(tabsButton)} onClick={onClick}>
      {text}
    </button>
  )
}
