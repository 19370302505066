import Swal from 'sweetalert2'
import { useEffect, useState } from 'react'
import {
  AiOutlineCloseCircle,
  AiOutlineFileText,
  // AiOutlineFileText
} from 'react-icons/ai'
import {
  // BsFillPeopleFill,
  // BsFillPeopleFill,
  BsFillPersonFill,
  BsPiggyBank,
} from 'react-icons/bs'
import { NavItem } from '.'
import { useTranslation } from 'react-i18next'
import { Avatar } from '@/components/Common'
import { useAuth } from '@/contexts'
// import { RiP2PFill } from 'react-icons/ri'
import {
  FaChartLine,
  FaNetworkWired,
  // FaNetworkWired
} from 'react-icons/fa'
// import { PiMedalBold } from 'react-icons/pi'
import { BiMoneyWithdraw, BiSolidBadge } from 'react-icons/bi'
import { IoExitSharp, IoWalletOutline } from 'react-icons/io5'
import SwitchLanguage from '@/components/SwitchLanguage'
import { MdComputer, MdSecurity } from 'react-icons/md'
import { GrStatusDisabledSmall } from 'react-icons/gr'
import { useOperationsAutomatic } from './hooks'
import { toast } from 'react-toastify'
import { ThemeSwitch } from './components/ThemeSwitch'

export const SideBar = ({ active }) => {
  const { t } = useTranslation()
  const { setToken } = useAuth()
  const { user, refresh, setNoLoading } = useAuth()
  const [isActive, setIsActive] = useState(user.automaticOperations)
  const { dataOperationsAutomatic, errorOperationsAutomatic, operationsAutomaticRequest } = useOperationsAutomatic()

  const handleChangeAutomatic = () => {
    Swal.fire({
      title: t('sidebar.automaticOperations'),
      text: isActive
        ? t('sidebar.disableAutomatic')
        : `${t('sidebar.transactionAlertOne')}${user.settings.ARBITRATION_DEFAULT_WAITING_TIME.split(':')[0]}${t(
            'sidebar.transactionAlertTwo'
          )}`,
      confirmButtonText: isActive ? t('sidebar.disableNow') : t('sidebar.activateNow'),
      showCancelButton: true,
      cancelButtonText: t('sidebar.cancel'),
      cancelButtonColor: '#D92D20',
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        operationsAutomaticRequest()
      }
    })
  }

  const closeSideBar = () => {
    active(false)
  }

  useEffect(() => {
    if (!errorOperationsAutomatic) return
    toast.error('Erro ao automatizar operações.')
  }, [errorOperationsAutomatic])

  useEffect(() => {
    if (!dataOperationsAutomatic) return
    setNoLoading(true)
    refresh().finally(() => {
      setNoLoading(false)
    })
    setIsActive(!isActive)
  }, [dataOperationsAutomatic])

  return (
    <div>
      <div
        className='bg-primary-700/80 w-full overflow-y-auto fixed inset-y-0 left-0 ease-in-out z-50'
        onClick={closeSideBar}
      ></div>
      <aside className='bg-primary-650 w-72 overflow-hidden p-4 rounded-l-2xl overflow-y-auto z-[999] scrollbar-hide fixed inset-y-0 text-white right-0 shadow-md ease-in-out my-2 animate-slide-in-right'>
        <div className='fixed right-[16.6rem] bg-primary-650 rounded-full p-1' onClick={closeSideBar}>
          <AiOutlineCloseCircle className='h-5 w-5 cursor-pointer' />
        </div>
        <div className='sm:hidden flex justify-end items-center gap-2'>
          <ThemeSwitch isSidebar />
          <SwitchLanguage />
        </div>

        <section className='my-3 pb-3 border-b border-gray-400'>
          <Avatar />
        </section>

        <section>
          <div className='mt-2'>
            <nav className='flex flex-col gap-[12px] font-Kaleko-light text-base'>
              {user.id && (
                <div onClick={() => handleChangeAutomatic()} className='flex gap-2 items-center hover:text-links'>
                  <MdComputer className='relative cursor-pointer hover:text-links' />
                  {isActive ? (
                    <BiSolidBadge className='absolute text-xs text-positive mb-2 ml-2' />
                  ) : (
                    <GrStatusDisabledSmall className='absolute text-xs text-negative mb-2 ml-2' />
                  )}
                  <div className='mt-1 cursor-pointer'>{t('global.automaticOperations')}</div>
                </div>
              )}
              <div className='flex gap-2 items-center'>
                <NavItem onClick={closeSideBar} path='/arbitrage' text={t('sidebar.arbitrage')} icon={<FaChartLine />} />
              </div>
              <div className='flex gap-2 items-center'>
                <NavItem onClick={closeSideBar} path='/profile' text={t('sidebar.profile')} icon={<BsFillPersonFill />} />
              </div>
              <div className='flex gap-2 items-center'>
                <NavItem onClick={closeSideBar} path='/security' text={t('sidebar.security')} icon={<MdSecurity />} />
              </div>
              <div className='flex gap-2 items-center'>
                <NavItem onClick={closeSideBar} path='/wallet' text={t('sidebar.wallet')} icon={<IoWalletOutline />} />
              </div>
              <div className='flex gap-2 items-center'>
                <NavItem onClick={closeSideBar} path='/extract' text={t('sidebar.extract')} icon={<AiOutlineFileText />} />
              </div>
              <div className='flex gap-2 items-center'>
                <NavItem onClick={closeSideBar} path='/deposit' text={t('sidebar.deposit')} icon={<BsPiggyBank />} />
              </div>
              {/*
              <div className='flex gap-2 items-center'>
                <NavItem onClick={closeSideBar} path='/matrix' text={t('sidebar.matrix')} icon={<BsFillPeopleFill />} />
              </div>
               <div className='flex gap-2 items-center'>
                <NavItem
                  onClick={closeSideBar}
                  path='/commission-menu'
                  text={t('sidebar.commissionMenu')}
                  icon={<BsFillPeopleFill />}
                />
              </div> */}
              <div className='flex gap-2 items-center'>
                <NavItem onClick={closeSideBar} path='/network' text={t('sidebar.network')} icon={<FaNetworkWired />} />
              </div>
              {/* {user.id && ( */}
              <>
                {/*
                  <div className='flex gap-2 items-center'>
                    <NavItem
                      onClick={closeSideBar}
                      path=/career-path'
                      text={t('careerPath.careerPath')}
                      icon={<PiMedalBold />}
                    />
                  </div> */}
                <div className='flex gap-2 items-center'>
                  <NavItem onClick={closeSideBar} path='/withdraw' text={t('sidebar.withdraw')} icon={<BiMoneyWithdraw />} />
                </div>
                {/* 
                
                <div className='flex gap-2 items-center'>
                  <NavItem onClick={closeSideBar} path='/P2P' text='P2P' icon={<RiP2PFill />} />
                </div>
                */}
                <div className='flex gap-2 items-center'>
                  <NavItem
                    onClick={() => {
                      closeSideBar()
                      setToken(null)
                    }}
                    path='/sign-in'
                    text={t('global.exit')}
                    icon={<IoExitSharp />}
                  />
                </div>
              </>
              {/* )} */}
            </nav>
          </div>
        </section>
      </aside>
    </div>
  )
}
