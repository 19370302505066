import React from 'react'
import { DateField, SelectField } from '@/components/Form/Fields'
import { useTranslation } from 'react-i18next'
import { getTransactionsOptions } from '../constants'
import { twMerge } from 'tailwind-merge'
import classNames from 'classnames'

export const ExtractForm = ({
  filterHandleSubmit,
  values,
  errors,
  filterHandleChange,
  // isLoading
}) => {
  const { t } = useTranslation()
  return (
    <form
      className='flex flex-col mt-3 border rounded-md p-4 h-fit md:w-[35rem] lg:w-[50rem] gap-2 relative'
      onSubmit={filterHandleSubmit}
    >
      <p className='bg-background absolute -top-[14px] text-lg px-2'>{t('extract.filters')}</p>
      <div className='flex flex-col lg:flex-row lg:items-center gap-3'>
        <DateField
          label={t('extract.startDate')}
          noMargins
          name='createdAt'
          className={twMerge(
            classNames('w-full md:col-span-2', {
              'lg:mb-[12px]': errors.endDate,
            })
          )}
          value={values.createdAt}
          onChange={filterHandleChange}
          themeDark
        />
        <DateField
          label={t('extract.endDate')}
          noMargins
          className='w-full md:col-span-2'
          name='endDate'
          min={values.createdAt && values.createdAt}
          isDisabled={!values.createdAt}
          value={values.createdAt ? values.endDate : ''}
          onChange={filterHandleChange}
          themeDark
          errorMessage={errors.endDate}
        />
      </div>
      <SelectField
        label={t('extract.type')}
        optionPrimary={t('extract.selectType')}
        options={getTransactionsOptions()}
        noMargins
        className='w-full md:col-span-2'
        name='type'
        value={values.type}
        onChange={filterHandleChange}
        darkMode
        hideBorder={true}
      />
      <div className='w-full md:mt-2 md:col-span-2 flex items-end'>
        <button type='submit' className='w-full h-[39px] text-white bg-primary-400 rounded-2'>
          {t('extract.filter')}
        </button>
      </div>
    </form>
  )
}
