import React, { useEffect } from 'react'
import { BadgeRecords, BadgeSummation, ExtractForm, TransactionList } from '@/pages/Extract/components'
import { useTranslation } from 'react-i18next'
import { useExtractForm, useFetchExtract } from './hooks'
import { FilterBuilder } from '@/utils'

export const Extract = () => {
  const { t } = useTranslation()
  const { fetchTransactions, loading, transactions } = useFetchExtract()

  const {
    values: filterValues,
    handleChange: filterHandleChange,
    handleSubmit: filterHandleSubmit,
    errors: errorsFilter,
  } = useExtractForm({
    onSubmit: values => {
      const { createdAt, endDate, type } = values
      const formattedDate = createdAt && endDate && `${createdAt},${endDate}`
      const filterOptions = FilterBuilder()
        .equal('type', Number(type) ? type : '')
        .between('createdAt', formattedDate)
        .build()

      fetchTransactions(filterOptions)
    },
  })

  useEffect(() => {
    fetchTransactions()
  }, [])

  const totalRecords = transactions.length
  const totalSum = transactions.reduce((total, transaction) => total + transaction.amount, 0)

  return (
    <>
      <section className='flex flex-col gap-3'>
        <div className='flex flex-col sm:flex-row sm:items-center justify-between'>
          <h1 className='font-extrabold text-white text-3xl text-start'>{t('extract.extract')}</h1>
          <div className='flex items-center flex-col sm:flex-row gap-2 '>
            <BadgeRecords count={totalRecords} />
            <BadgeSummation sum={totalSum} />
          </div>
        </div>
      </section>
      <section className='flex flex-col md:flex-row gap-4 mt-3'>
        <ExtractForm
          errors={errorsFilter}
          values={filterValues}
          filterHandleChange={filterHandleChange}
          filterHandleSubmit={filterHandleSubmit}
          transactions={transactions}
        />
        <hr className='flex lg:hidden' />
        <TransactionList transactions={transactions} loading={loading} />
      </section>
    </>
  )
}
