import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useDepositSkyt = () => {
  const { data, loading, makeRequest, error } = useApi(params => {
    const { route, method } = getEndpoint('depositSkyt')
    return httpClient[method](route, { ...params })
  })

  return {
    dataDepositSkyt: data || [],
    loadingDepositSkyt: loading,
    errorDepositSkyt: error,
    depositSkytRequest: makeRequest,
  }
}
