import { Header } from '@/components/Menu/Header'
import { ToastContainer } from 'react-toastify'
import classNames from 'classnames'
import { useAuth } from '@/contexts'
import { IoWarningOutline } from 'react-icons/io5'
import { MdHeadsetMic } from 'react-icons/md'
import { BsWhatsapp } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

export const Layout = ({ children, isFullWidth }) => {
  const { isUserImpersonate } = useAuth()
  const { t } = useTranslation()
  const { pathname } = useLocation()

  return (
    <div
      className={classNames('bg-background w-full min-h-screen font-Kaleko text-white', {
        'bg-lightMain dark:bg-background': pathname === '/arbitrage',
      })}
    >
      {isUserImpersonate && (
        <div
          className={classNames(
            'w-full md:h-11 z-[99] bg-yellow-500 text-gray-900 p-2 md:p-3 text-base flex justify-center items-center gap-2',
            {
              'sticky top-0': isUserImpersonate,
            }
          )}
        >
          <MdHeadsetMic className='shrink-0' size={20} />
          <p className='mb-[-2px] text-center'>{t('global.adminInform')}</p>
          <IoWarningOutline className='shrink-0' size={18} />
        </div>
      )}
      <ToastContainer />
      <Header />
      <main
        className={classNames(' md:mx-auto min-h-screen', {
          'md:max-w-[1440px]': !isFullWidth,
          'p-3': pathname !== '/arbitrage',
        })}
      >
        <a
          className='bg-[#25D366] hover:bg-[#128C7E] transition-colors z-10 p-3 rounded-full fixed bottom-4 right-4 cursor-pointer'
          href='http://wa.me/5511919577191'
          target='_blank'
          rel='noopener noreferrer'
        >
          <BsWhatsapp size={28} />
        </a>

        {children}
      </main>
    </div>
  )
}

Layout.defaultProps = {
  isFullWidth: false,
}
