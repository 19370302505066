import classNames from 'classnames'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

export const NavItem = ({ path, text = '', className, icon, onClick }) => {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const tokenParam = searchParams.get('token')

  return (
    <Link
      to={tokenParam ? `${path}?token=${tokenParam}` : path}
      onClick={onClick}
      className={twMerge(
        classNames(' hover:text-links mt-1', className, {
          'underline font-Kaleko-bold ': pathname === path,
          'flex items-center gap-2': icon,
        })
      )}
    >
      <span className='mb-1'>{icon}</span>
      {text}
    </Link>
  )
}
