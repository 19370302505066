import MoneyAsText from '@/components/Common/MoneyAsText'
import { useArbitrage } from '../contexts'
import { PercentAsText } from '@/components/Common'
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'
import { useState, useEffect } from 'react'

export const ChartBar = () => {
  const { cachedData } = useArbitrage()
  const [data, setData] = useState([])

  useEffect(() => {
    if (!cachedData) return
    setData([...cachedData, ...cachedData, ...cachedData, ...cachedData])
  }, [cachedData])

  return (
    <div className='flex flex-row items-center overflow-hidden py-2 text-sm border-y border-gray-500 bg-lightBg dark:bg-transparent text-gray dark:text-white'>
      {data.map((item, index) => (
        <div
          className='text-gray dark:text-white whitespace-nowrap flex items-center justify-center gap-2 animate-scroll w-fit pl-2 ml-2 border-l border-gray-500'
          key={index}
        >
          <strong>{item.name}</strong>
          <span className='dark:text-gray-200'>
            <MoneyAsText value={item.price} />
          </span>
          <span
            className={twMerge(
              classNames('px-[2px] py-[1px] rounded-1 flex gap-1 items-center justify-center text-white dark:text-black', {
                'bg-positive/70': item.change >= 0,
                'bg-negative/70': item.change < 0,
              })
            )}
          >
            {item.change >= 0 ? (
              <BiSolidUpArrow className='w-[10px] h-[10px]' />
            ) : (
              <BiSolidDownArrow className='w-[10px] h-[10px]' />
            )}
            <PercentAsText className='mb-[-3px]' value={item.change} />
          </span>
        </div>
      ))}
    </div>
  )
}
