import { useTranslation } from 'react-i18next'

// Customer Documents
export const PENDING = 1
export const UNDER_ANALYSIS = 2
export const APPROVED = 3
export const REFUSED = 4
export const REVOKED = 5

export const getStatusCustomerDocument = status => {
  const { t } = useTranslation()
  switch (status) {
    case PENDING:
      return t('security.PENDING')
    case UNDER_ANALYSIS:
      return t('security.UNDER_ANALYSIS')
    case APPROVED:
      return t('security.APPROVED')
    case REFUSED:
      return t('security.REFUSED')
    case REVOKED:
      return t('security.REVOKED')
    default:
      throw new Error(`Invalid Customer Document Status "${status}".`)
  }
}

export const getStatusCustomerDocumentOptions = () => {
  return [
    { value: PENDING, label: getStatusCustomerDocument(PENDING) },
    { value: UNDER_ANALYSIS, label: getStatusCustomerDocument(UNDER_ANALYSIS) },
    { value: APPROVED, label: getStatusCustomerDocument(APPROVED) },
    { value: REFUSED, label: getStatusCustomerDocument(REFUSED) },
    { value: REVOKED, label: getStatusCustomerDocument(REVOKED) },
  ]
}

export const getCustomerDocumentColor = status => {
  switch (status) {
    case PENDING:
      return 'gray-300'
    case UNDER_ANALYSIS:
      return 'orange-500'
    case APPROVED:
      return 'positive'
    case REFUSED:
      return 'negative'
    case REVOKED:
      return 'yellow-500'
  }
}
