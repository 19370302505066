import { useState } from 'react'

export const useBookOffers = () => {
  const [bids, setBids] = useState([])
  const [loadingOffers, setLoadingOffers] = useState(false)

  let channelId = null
  let calls = 0

  const consumeOrderBookWebSocket = message => {
    if (message.event && message.event === 'subscribed' && message.channel === 'book') {
      channelId = message.chanId
      return
    }

    if (channelId !== message[0]) return

    if (message[1] === 'hb') return

    if (calls === 0) {
      let seqBids = []
      for (const data of message[1]) {
        if (!Array.isArray(data)) continue
        const [price, count, amount] = data
        seqBids.push({ priceBtc: price, amount, count, type: amount < 0 ? 'sell' : 'buy' })
      }
      setBids(seqBids)
      calls += 1
      return
    }

    if (calls > 0) {
      setBids(prev => [
        {
          priceBtc: message[1][0],
          amount: message[1][2],
          count: message[1][1],
          type: message[1][2] < 0 ? 'sell' : 'buy',
        },
        ...prev.slice(0, 29),
      ])
    }

    calls += 1
  }

  return {
    bids,
    consumeOrderBookWebSocket,
    loadingOffers,
    setLoadingOffers,
  }
}
