import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useBuyOffers = () => {
  const { data, loading, makeRequest, error, meta } = useApi(params => {
    const { route, method } = getEndpoint('useBuyOffers')
    return httpClient[method](route, { ...params })
  })

  return {
    dataUseBuyOffers: data,
    loadingUseBuyOffers: loading,
    errorUseBuyOffers: error,
    useBuyOffersRequest: makeRequest,
    metaUseBuyOffers: meta,
  }
}
