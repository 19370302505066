import { dataNotifications } from '@/api'
import React, { useState } from 'react'
import { NotificationCard } from '@/pages/Notifications/components'
import notificationPush from '@/assets/images/notificationPush.png'
import classNames from 'classnames'
import { BiNotification } from 'react-icons/bi'

export const Notifications = () => {
  const [isRead, setIsRead] = useState(true)

  const readClassName = classNames('gap-4 flex flex-col lg:hidden bg-gray p-3', {
    'bg-transparent': !isRead,
  })

  const handleReadStatusChange = () => {
    setIsRead(!isRead)
  }
  return (
    <>
      <section className='text-white flex items-center justify-between px-3 gap-3 my-3'>
        <h1 className='font-extrabold text-3xl'>Notificações</h1>
        <button
          className='flex items-center justify-center gap-2 bg-gradient-to-r from-primary-200 to-primary-400 text-white text-[8px] font-semibold p-[12px] rounded-full'
          onClick={() => handleReadStatusChange()}
        >
          <BiNotification className='sm:w-[10px] sm:h-[10px] w-[18px] h-[18px]' />
          <span>Marcar como lidos</span>
        </button>
      </section>

      <section className='mt-4'>
        {!dataNotifications ? (
          <div className='flex flex-col items-center justify-center gap-3 w-full h-screen'>
            <img src={notificationPush} alt='/' />
            <p className='text-xl text-white text-center'>Não há notificações pendentes de leitura!</p>
          </div>
        ) : (
          dataNotifications.map((item, index) => (
            <div key={index} className={readClassName}>
              <NotificationCard icon={item.icon} title={item.title} text={item.text} date={item.date} />
            </div>
          ))
        )}
      </section>
    </>
  )
}
