import { DateAsText, Loader } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { Button } from '@/components/Form'
import { useTranslation } from 'react-i18next'
import { BiSolidCheckboxChecked } from 'react-icons/bi'
import { FaLevelDownAlt } from 'react-icons/fa'
import { FiAlertCircle } from 'react-icons/fi'

export const TableAffiliates = ({
  data = [],
  handleOpenNetwork,
  networkFlow = [],
  setData,
  setNetworkFlow,
  id,
  loadingNetworkId,
  setId,
  networkIdRequest,
  loadingFlow,
  setLoadingFlow,
  networkRequest,
  activeLevel,
  setActiveLevel,
}) => {
  const { t } = useTranslation()

  const handleClickNetworkFlow = id => {
    const fliterFlow = networkFlow.findIndex(item => item.id === id)

    setId(id)
    setLoadingFlow(true)
    setNetworkFlow(prev => prev.slice(0, fliterFlow + 1))
    networkIdRequest(id).then(() => {
      setActiveLevel(fliterFlow + 2)
      setLoadingFlow(false)
    })
  }

  return (
    <>
      <div className='flex items-center gap-2'>
        <button
          onClick={() => {
            setActiveLevel(1)
            setNetworkFlow([])
            setData([])
            networkRequest()
          }}
          className='bg-negative w-fit px-3 py-2 rounded mb-2'
        >
          {t('network.toClean')}
        </button>
        {networkFlow.map((item, index) => (
          <div
            key={index}
            onClick={() => handleClickNetworkFlow(item.id)}
            className='py-2 px-4 flex justify-center items-center bg-primary-300 mb-2 rounded-1 cursor-pointer hover:opacity-70'
          >
            <span>{item.name}</span>
          </div>
        ))}
      </div>
      <div className='overflow-x-auto'>
        <table className='w-full table-auto whitespace-nowrap text-white'>
          <thead>
            <tr className='bg-primary-600'>
              <th className='w-[100px] p-2 text-start'>{t('signIn.nickname')}</th>
              <th className='p-2 text-start'>{t('global.name')}</th>
              <th className='w-[50px] p-2 text-center'>{t('extract.volume')}</th>
              <th className='w-[50px] p-2 text-center'>{t('global.createdAt')}</th>
              <th className='w-[50px] p-2'>{t('global.activeAt')}</th>
              <th className='w-[100px] p-2'>{t('global.phone')}</th>
              <th className='w-[50px] p-2'>{t('commission.th.level')}</th>
              <th className='w-[100px] p-2'>{t('deposit.actions')}</th>
            </tr>
          </thead>
          <tbody>
            {loadingFlow && (
              <tr>
                <td colSpan='7'>
                  <div className='flex justify-center items-center my-5'>
                    <Loader />
                  </div>
                </td>
              </tr>
            )}
            {!loadingFlow &&
              data.map((item, index) => (
                <tr key={index} className='hover:bg-primary-400/10'>
                  <td className='p-2 w-20'>{item.nickname}</td>
                  <td className='flex mt-2 gap-2 p-2'>
                    {item.activatedAt ? (
                      <BiSolidCheckboxChecked className='text-xl text-positive' />
                    ) : (
                      <BiSolidCheckboxChecked className='text-xl text-negative' />
                    )}
                    {item.fullName}
                  </td>
                  <td className='p-2 text-center w-20'>{<MoneyAsText value={item.depositSum || 0} />}</td>
                  <td className='p-2 text-center w-20'>{<DateAsText date={item.createdAt} />}</td>
                  <td className='p-2 text-center w-20'>{<DateAsText date={item.activatedAt} />}</td>
                  <td className='p-2 text-center w-20'>{item.phone}</td>
                  <td className='p-2 text-center w-20'>{activeLevel}</td>
                  <td className='p-2 text-center w-20'>
                    <Button
                      onClick={() => {
                        handleOpenNetwork(item.id, item.fullName, activeLevel)
                      }}
                      isLoading={id === item.id && loadingNetworkId}
                      text={
                        <>
                          {t('network.openNetwork')}
                          <FaLevelDownAlt size={18} />
                        </>
                      }
                      className='rounded-md min-w-[120px] flex items-center gap-2 px-4'
                    />
                  </td>
                </tr>
              ))}
            {!loadingFlow && data.length === 0 && (
              <tr>
                <td colSpan='7'>
                  <div className='flex text-sm mt-3 gap-1 justify-center'>
                    <FiAlertCircle />
                    <p>{t('arbitrage.notRecordsFound')}</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}
