import { useTranslation } from 'react-i18next'

export const PURCHASE = 1
export const REVERSE = 2
export const ARBITRATION = 3
export const VOUCHER_DEPOSIT = 4
export const WITHDRAW_FIXED_AMOUNT_TAX = 5
export const ARBITRATION_PROFIT = 6
export const NETWORK_COMMISSION = 7
export const RESIDUAL_COMMISSION = 8
export const WITHDRAW_REQUEST = 9
export const WITHDRAW = 10
export const MANUAL_BONUS = 11
export const DEPOSIT = 12
export const ARBITRATION_REQUEST = 13
export const ARBITRATION_PROFIT_REQUEST = 14
export const TRANSFER_TAX_P2P = 15
export const SKYT_TRANSFER_RECEIVE = 16
export const SKYT_TRANSFER_SENDER = 17
export const BONUS_MATRIX = 18
export const ARBITRATION_TAX = 19
export const SKYT_DEPOSIT = 20
export const SKYT_DEPOSIT_CONVERT = 21
export const WITHDRAW_REFUND = 22
export const MANUAL_REFUND = 23
export const BALLANCE_SKYT_CONVERT = 24
export const SKYT_EXCHANGE = 25

export const getTransactionsTypeLabel = type => {
  const { t } = useTranslation()
  switch (type) {
    case PURCHASE:
      return t('extractConstants.purchase')
    case REVERSE:
      return t('extractConstants.reverse')
    case ARBITRATION:
      return t('extractConstants.arbitration')
    case VOUCHER_DEPOSIT:
      return t('extractConstants.deposit')
    case WITHDRAW_FIXED_AMOUNT_TAX:
      return t('extractConstants.withdrawFixedAmountTax')
    case ARBITRATION_PROFIT:
      return t('extractConstants.arbitrationProfit')
    case NETWORK_COMMISSION:
      return t('extractConstants.networkCommission')
    case RESIDUAL_COMMISSION:
      return t('extractConstants.residualCommission')
    case WITHDRAW_REQUEST:
      return t('extractConstants.withdrawRequest')
    case WITHDRAW:
      return t('extractConstants.withdraw')
    case MANUAL_BONUS:
      return t('extractConstants.manualBonus')
    case DEPOSIT:
      return t('extractConstants.deposit')
    case ARBITRATION_REQUEST:
      return t('extractConstants.arbitrageRequest')
    case ARBITRATION_PROFIT_REQUEST:
      return t('extractConstants.arbitrageProfitRequest')
    case TRANSFER_TAX_P2P:
      return t('extractConstants.transferTaxP2p')
    case SKYT_TRANSFER_RECEIVE:
      return t('extractConstants.skytTransferReceive')
    case SKYT_TRANSFER_SENDER:
      return t('extractConstants.skytTransferSender')
    case BONUS_MATRIX:
      return t('extractConstants.bonusMatrix')
    case ARBITRATION_TAX:
      return t('extractConstants.arbitrationTax')
    case SKYT_DEPOSIT:
      return t('extractConstants.skytDeposit')
    case SKYT_DEPOSIT_CONVERT:
      return t('extractConstants.skytDepositConvert')
    case WITHDRAW_REFUND:
      return t('extractConstants.withdrawRefund')
    case MANUAL_REFUND:
      return t('extractConstants.refund')
    case BALLANCE_SKYT_CONVERT:
      return t('extractConstants.ballanceSkytConvert')
    case SKYT_EXCHANGE:
      return t('extractConstants.skytExchange')
    default:
      return t('extractConstants.unknown')
  }
}

export const getTransactionsOptions = () => {
  return [
    { label: getTransactionsTypeLabel(PURCHASE), value: PURCHASE },
    { label: getTransactionsTypeLabel(REVERSE), value: REVERSE },
    { label: getTransactionsTypeLabel(ARBITRATION), value: ARBITRATION },
    // { label: getTransactionsTypeLabel(VOUCHER_DEPOSIT), value: VOUCHER_DEPOSIT },
    { label: getTransactionsTypeLabel(WITHDRAW_FIXED_AMOUNT_TAX), value: WITHDRAW_FIXED_AMOUNT_TAX },
    { label: getTransactionsTypeLabel(ARBITRATION_PROFIT), value: ARBITRATION_PROFIT },
    { label: getTransactionsTypeLabel(NETWORK_COMMISSION), value: NETWORK_COMMISSION },
    // { label: getTransactionsTypeLabel(RESIDUAL_COMMISSION), value: RESIDUAL_COMMISSION },
    { label: getTransactionsTypeLabel(WITHDRAW_REQUEST), value: WITHDRAW_REQUEST },
    { label: getTransactionsTypeLabel(WITHDRAW), value: WITHDRAW },
    { label: getTransactionsTypeLabel(MANUAL_BONUS), value: MANUAL_BONUS },
    { label: getTransactionsTypeLabel(DEPOSIT), value: DEPOSIT },
    { label: getTransactionsTypeLabel(ARBITRATION_REQUEST), value: ARBITRATION_REQUEST },
    { label: getTransactionsTypeLabel(ARBITRATION_PROFIT_REQUEST), value: ARBITRATION_PROFIT_REQUEST },
    // // { label: getTransactionsTypeLabel(TRANSFER_TAX_P2P), value: TRANSFER_TAX_P2P },
    // { label: getTransactionsTypeLabel(SKYT_TRANSFER_RECEIVE), value: SKYT_TRANSFER_RECEIVE },
    // { label: getTransactionsTypeLabel(SKYT_TRANSFER_SENDER), value: SKYT_TRANSFER_SENDER },
    // { label: getTransactionsTypeLabel(BONUS_MATRIX), value: BONUS_MATRIX },
    // { label: getTransactionsTypeLabel(ARBITRATION_TAX), value: ARBITRATION_TAX },
    // { label: getTransactionsTypeLabel(SKYT_DEPOSIT), value: SKYT_DEPOSIT },
    // { label: getTransactionsTypeLabel(SKYT_DEPOSIT_CONVERT), value: SKYT_DEPOSIT_CONVERT },
    // { label: getTransactionsTypeLabel(WITHDRAW_REFUND), value: WITHDRAW_REFUND },
    // { label: getTransactionsTypeLabel(MANUAL_REFUND), value: MANUAL_REFUND },
    // { label: getTransactionsTypeLabel(BALLANCE_SKYT_CONVERT), value: BALLANCE_SKYT_CONVERT },
    // { label: getTransactionsTypeLabel(SKYT_EXCHANGE), value: SKYT_EXCHANGE },
  ]
}

export const getIsSkytSuffix = type => {
  switch (type) {
    case SKYT_DEPOSIT:
      return true
    case SKYT_EXCHANGE:
      return true
    case SKYT_TRANSFER_SENDER:
      return true
    case SKYT_TRANSFER_RECEIVE:
      return true

    default:
      return false
  }
}
