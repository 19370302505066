import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useSendForgotPassword = () => {
  const { data, loading, makeRequest, error } = useApi(params => {
    const { route, method } = getEndpoint('forgotPassword')
    return httpClient[method](route, params)
  })

  return {
    dataForgotPassword: data,
    loadingForgotPassword: loading,
    errorForgotPassword: error,
    forgotPassword: makeRequest,
  }
}
