import { Badge, DateAsText, Loader } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { formatCpfCnpj } from '@/utils/stringHelpers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StatusDepositBadge } from '.'
import { CREATED, getDepositType } from '../constants'
import { FiAlertCircle } from 'react-icons/fi'
import Swal from 'sweetalert2'

export const HistoricCard = ({ dataDepositHistory, cancelDepositRequest, loadingCancelDeposit, idDeposit, setIdDeposit }) => {
  const { t } = useTranslation()

  const handleCancelButtonClick = id => {
    setIdDeposit(id)
    Swal.fire({
      title: t('deposit.attention'),
      text: t('deposit.textCancel'),
      icon: 'warning',
      confirmButtonText: t('deposit.continue'),
      showCancelButton: true,
      cancelButtonText: t('deposit.notCancel'),
      cancelButtonColor: '#D92D20',
      confirmButtonColor: '#039855',
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        cancelDepositRequest(id)
      }
    })
  }

  if (dataDepositHistory?.length === 0)
    return (
      <div className='flex text-sm mt-3 gap-1 justify-center'>
        <FiAlertCircle />
        <p>{t('arbitrage.notRecordsFound')}</p>
      </div>
    )

  return dataDepositHistory.map((item, index) => (
    <main
      key={index}
      className='my-2 text-sm py-2 md:py-0 bg-white px-4 rounded lg:flex lg:items-center lg:justify-between text-primary-700'
    >
      <section className='flex justify-between lg:gap-5'>
        <div className='flex flex-col'>
          <p className='text-primary-200'>{t('deposit.receptorName')}</p>
          <span>{item.name}</span>
        </div>

        <div className='flex flex-col'>
          <p className='text-primary-200'>{t('deposit.pixlabelCPF')}</p>
          <span>{formatCpfCnpj(item.cpf ?? '') ?? '-'}</span>
        </div>
      </section>

      <section className='flex justify-between my-3 lg:gap-5'>
        <div className='flex flex-col'>
          <p className='text-primary-200'>{t('deposit.transactionType')}</p>
          <span>{getDepositType(item.type)}</span>
        </div>

        <div className='flex flex-col'>
          <p className='text-primary-200'>{t('deposit.requestDate')}</p>
          <span>{<DateAsText type='datetime' date={item.createdAt} />}</span>
        </div>

        <div className='flex flex-col'>
          <p className='text-primary-200'>{t('deposit.amount')}</p>
          <span>{<MoneyAsText value={item.amount} />}</span>
        </div>
      </section>

      <section className='flex justify-between'>
        <div className='flex flex-col'>
          <p className='text-primary-200'>{t('deposit.status')}</p>
          <span className='text-white rounded-2'>
            <StatusDepositBadge status={item.status} />
          </span>
        </div>
      </section>

      <section className='flex justify-between'>
        <div className='flex flex-col'>
          <p className='text-primary-200'>{t('deposit.actions')}</p>
          {item.status === CREATED ? (
            <Badge
              className='cursor-pointer hover:opacity-70 min-w-[90px]'
              text={
                loadingCancelDeposit && idDeposit === item.id ? (
                  <Loader className='inline-block w-[10px] h-[10px]' />
                ) : (
                  t('deposit.pixCancelButton')
                )
              }
              bg='bg-negative'
              onClick={() => handleCancelButtonClick(item.id)}
            />
          ) : (
            '-'
          )}
        </div>
      </section>
    </main>
  ))
}
