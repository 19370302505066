import { Header } from '@/components/Menu/Header'
import { ToastContainer } from 'react-toastify'
import React from 'react'
import classNames from 'classnames'

export const LayoutNotifications = ({ children, isFullWidth, notificationScreen }) => {
  return (
    <div className='bg-background w-full min-h-screen font-Kaleko text-white'>
      <ToastContainer />
      <Header />
      <main
        className={classNames(' md:mx-auto p-3 min-h-screen', {
          'md:max-w-[1440px]': !isFullWidth,
          'px-0': notificationScreen,
        })}
      >
        {children}
      </main>
    </div>
  )
}

LayoutNotifications.defaultProps = {
  isFullWidth: false,
  notificationScreen: false,
}
