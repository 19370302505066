import React, { createContext, useContext, useEffect, useState } from 'react'
import httpClient from '@/utils/httpClient'
import { useApi } from '@/hooks/useApi'
import { useBalance } from '.'
import { useNavigate, useSearchParams } from 'react-router-dom'

const AuthContext = createContext({
  token: null,
  user: null,
  noLoading: false,
  refresh: () => {},
  setNoLoading: () => {},
  isUserImpersonate: false,
})

export const AuthProvider = ({ children }) => {
  const [searchParams] = useSearchParams()
  const tokenParam = searchParams.get('token')

  const navigate = useNavigate()
  const [token, setToken] = useState(window.localStorage.getItem('auth:user'))
  const [isUserImpersonate, setUserImpersonate] = useState(false)
  const [user, setUser] = useState(null)
  const [noLoading, setNoLoading] = useState(false)
  const { refreshBalance } = useBalance()
  const { data, makeRequest, loading } = useApi(() => httpClient.get('/me'))

  useEffect(() => {
    if (tokenParam) setUserImpersonate(true)
  }, [])

  useEffect(() => {
    if (!token && !tokenParam) {
      setUser(null)
      window.localStorage.removeItem('auth:user')
      return
    }

    if (tokenParam) {
      setUserImpersonate(true)
    }

    if (!token && tokenParam) {
      window.localStorage.setItem('auth:user', tokenParam)
    }

    if (token && !tokenParam) {
      window.localStorage.setItem('auth:user', token)
    }

    const impersonate = window.localStorage.getItem('impersonate')
    if (impersonate) return navigate(`/impersonate?token=${tokenParam}`)
    makeRequest()
    refreshBalance()
    const information = window.localStorage.getItem('information')
    if (information) return navigate('/information')
  }, [token])

  useEffect(() => {
    if (!data) return
    setUser(data)
  }, [data])

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        user,
        loading,
        noLoading,
        refresh: makeRequest,
        setNoLoading,
        isUserImpersonate,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => ({ ...useContext(AuthContext) })
