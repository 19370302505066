import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

export const useFormResetPassword = ({ onSubmit }) => {
  const { t } = useTranslation()
  const Schema = Yup.object({
    password: Yup.string().required(t('global.validation.required')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('signUp.validation.validationRequiredConfirmPassword'))
      .required(t('global.validation.required')),
    recaptcha: Yup.string().required(t('global.validation.required')),
  })

  return useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      recaptcha: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
