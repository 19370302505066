const endpoints = [
  { name: 'customerByDocument', route: '/person-by-document/{document}', method: 'get' },
  { name: 'editCustomer', route: '/customer/update-profile', method: 'put' },
  { name: 'newCustomer', route: '/customers', method: 'post' },
  { name: 'transactions', route: '/transactions/{month}', method: 'get' },
  { name: 'franchisees', route: '/franchisees', method: 'get' },
  { name: 'lots', route: '/lots', method: 'get' },
  { name: 'detailsLots', route: '/lots/{id}', method: 'get' },
  { name: 'lotsSendCustomer', route: '/lots/{id}/send-to-customer', method: 'post' },
  { name: 'lotsSendAssociation', route: '/lots/{id}/send-to-association', method: 'post' },
  { name: 'deleteLot', route: '/lots/{id}', method: 'delete' },
  { name: 'customer', route: '/customers', method: 'get' },
  { name: 'createFranchisees', route: 'franchisees', method: 'post' },
  { name: 'franchiseesOptions', route: '/franchisees/options', method: 'get' },
  { name: 'editFranchisees', route: '/franchisees/{franchiseesId}', method: 'put' },
  { name: 'detailFranchisees', route: '/franchisees/{franchiseesId}', method: 'get' },
  { name: 'monthlyBonus', route: '/sellers/bonus-commissions', method: 'get' },
  { name: 'lastSalesSeller', route: '/sellers/dashboard/last-sales', method: 'get' },
  { name: 'lastCommissionsSeller', route: '/sellers/dashboard/last-commissions', method: 'get' },
  { name: 'monthSalesSeller', route: '/sellers/dashboard/month-sales', method: 'get' },
  { name: 'goalProgression', route: '/sellers/dashboard/goal-progression', method: 'get' },
  { name: 'resetPassword', route: '/customer/change-password-by-token', method: 'put' },
  { name: 'buySellOffers', route: '/arbitration/buy-sell-offers', method: 'get' },
  { name: 'useBuyOffers', route: '/arbitration', method: 'post' },
  { name: 'operationsArbitration', route: '/arbitration/operation-history', method: 'get' },
  { name: 'indicatedId', route: '/customer/indication-code/{indicatedId}', method: 'get' },
  { name: 'notifications', route: '/customer/notifications', method: 'get' },
  { name: 'depositHistory', route: '/financial/customer/deposit-history', method: 'get' },
  { name: 'operationsAutomatic', route: '/customer/automatic-operations', method: 'put' },
  { name: 'verificationEmail', route: '/customer/send-email-verification', method: 'post' },
  { name: 'verificationPhone', route: '/customer/send-phone-verification', method: 'post' },
  { name: 'sendVerificationEmail', route: '/customer/verify-email-by-token', method: 'put' },
  { name: 'sendVerificationPhone', route: '/customer/verify-phone-by-token', method: 'put' },
  { name: 'deposit', route: '/financial/deposit/request', method: 'post' },
  { name: 'depositSkyt', route: '/financial/deposit/request/skyt', method: 'post' },
  { name: 'refreshQrCode', route: '/financial/deposit/{id}/verify-payment', method: 'get' },
  { name: 'cancelQrCode', route: '/financial/deposit/{id}/cancel', method: 'put' },
  { name: 'fetchAddressee', route: '/get-customer-by-email/{email}', method: 'get' },
  { name: 'cotation', route: '/financial/currency-quotations', method: 'get' },
  { name: 'useP2p', route: '/transfer', method: 'post' },
  { name: 'convertAmount', route: '/financial/convert-to-skyt', method: 'post' },
  { name: 'fetchExtract', route: '/financial/customer/extract', method: 'get' },
  { name: 'fetchCommission', route: '/financial/customer/commissions', method: 'get' },
  { name: 'fetchNetwork', route: '/customer/network', method: 'get' },
  { name: 'fetchNetworkId', route: '/customer/network/{id}', method: 'get' },
  { name: 'fetchMatrix', route: '/customer/matrices', method: 'get' },
  { name: 'fetchMatrixDetail', route: '/customer/matrices/{id}', method: 'get' },
  { name: 'signInAdmin', route: '/sign-in/admin', method: 'post' },
  { name: 'sendDocuments', route: '/customer/upload-documents', method: 'post' },
  {
    name: 'sign-in',
    route: '/sign-in',
    method: 'post',
  },
  {
    name: 'sign-up',
    route: '/customer/register',
    method: 'post',
  },
  {
    name: 'signInCustomer',
    route: '/sign-in/customer',
    method: 'post',
  },
  {
    name: 'me',
    route: '/me',
    method: 'get',
  },
  {
    name: 'states',
    route: '/states',
    method: 'get',
  },
  {
    name: 'zipCode',
    route: '/zip-code/{zipCode}',
    method: 'get',
  },
  {
    name: 'cities',
    route: '/cities/{uf}',
    method: 'get',
  },
  {
    name: 'terms',
    route: '/lots/{lotId}/beneficiaries-terms/{contractId}',
    method: 'post',
  },
  {
    name: 'forgotPassword',
    route: '/customer/new-password-email',
    method: 'post',
  },
  {
    name: 'withdraw',
    route: '/financial/withdraw/request',
    method: 'post',
  },
  {
    name: 'withdrawHistory',
    route: '/financial/customer/withdrawal-history',
    method: 'get',
  },
]

export const getEndpoint = (routeName, routeParams = {}) => {
  const endpointsCopy = JSON.parse(JSON.stringify(endpoints))
  const endpoint = endpointsCopy.find(row => row.name === routeName)
  if (!endpoint) throw new Error(`Invalid endpoint name '${routeName}'.`)
  for (let paramName in routeParams) {
    endpoint.route = endpoint.route.replace(`{${paramName}}`, routeParams[paramName])
  }
  return endpoint
}
