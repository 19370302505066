import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useFetchOperationsArbitration = () => {
  const { data, loading, makeRequest, error } = useApi(() => {
    const { route, method } = getEndpoint('operationsArbitration')
    return httpClient[method](route)
  })

  return {
    dataOperationsArbitration: data || [],
    loadingOperationsArbitration: loading,
    errorOperationsArbitration: error,
    operationsArbitrationRequest: makeRequest,
  }
}
