export default {
  global: {
    confirmAdmin: 'Confirm Admin Password',
    adminInform: 'Limited Access! You are accessing this account in Support mode!',
    accountNotVerified: 'Unverified Account',
    companyName: 'Botfy',
    successTitle: 'All Set!',
    errorTitle: 'An Error Occurred',
    ok: 'OK',
    yes: 'Yes',
    no: 'No',
    phone: 'Phone',
    cryptocurrencyWallet: 'Cryptocurrency Wallet',
    login: 'Login',
    name: 'Name',
    email: 'Email',
    cnpj: 'CNPJ',
    cpf: 'CPF',
    randomKey: 'Random Key',
    await: 'Wait:',
    createdAt: 'Creation Date',
    activeAt: 'Activation Date',
    send: 'Send',
    balance: 'Balance',
    profit: 'Profit',
    poolLiquidity: 'Pool Liquidity',
    pool: 'Pool',
    screenLoadingText: 'Loading...',
    price: 'Price',
    change: 'Change',
    chart: 'Chart',
    trade: 'Trade',
    cancel: 'Cancel',
    edit: 'Edit',
    automaticOperations: 'Automate Operation',
    copSuccess: 'Successfully Copied!',
    deautomateOperations: 'Deautomate Operation',
    notMessage: 'No messages.',
    emailRequired: 'Email required',
    titleMessages: 'Notifications',
    readMessages: 'Mark as Read',
    chartTitle: 'Market (BTC/USDT) 1D',
    sendEmailRequest: 'Send Token',
    verify: 'Verify',
    languages: {
      english: 'English',
      portuguese: 'Portuguese (BR)',
      spanish: 'Spanish',
      french: 'French',
    },
    validation: {
      validationRecaptcha: 'Invalid Recaptcha.',
      required: 'This field is required',
      validationCpf: 'CPF must contain 11 characters',
      validationCpfInvalid: 'Document is already in use.',
      validationPhone: 'Phone must contain 11 characters',
      validationCpnjLength: 'The CNPJ must contain 14 digits',
      validationPhoneSignUp: 'Phone number is already in use.',
    },
    aspectRatioError: 'The image is not in the proper aspect ratio. We suggest a square image.',
    successUploadImage: 'Upload successful!',
    sendEmail: 'We have sent a Token to your email!',
    errorEmail: 'Error sending Token to your email.',
    sendPhone: 'A Token has been sent to your SMS!',
    errorPhone: 'Error sending Token to your SMS.',
    successSendEmail: 'Email successfully verified!',
    successSendPhone: 'Phone successfully verified!',
    errorSendEmail: 'Error verifying Email.',
    errorSendPhone: 'Error verifying Phone.',
    errorVerification: 'All fields are required',
    tokenInvalid: 'Invalid Token.',
    cancelDepositSuccess: 'Deposit successfully canceled!',
    cancelDeposit: 'Deposit canceled.',
    exit: 'Exit',
    placeholderValue: 'Ex.: $ 100.00',
    information: {
      title: 'Good Morning, botfy Family!',
      att: 'Sincerely, botfy Team',
      text1: 'We had a minor update to improve the performance of our systems and servers.',
      text2:
        'Whenever a broker updates its APIs, we need to update our side as well to continue getting real-time quotes. For this reason, our automatic mode was in update for approximately 30 hours.',
      text3: 'Everything is now up to date, and operations have been normalized.',
      text4:
        "It's important to monitor your systems at least once a day! And whenever there are updates for those using the automatic mode, TURN OFF and TURN ON again and wait for the next operation.",
      checkbox: 'I Am Aware',
    },
  },
  dashboard: {
    $: 'R$',
    balance: 'View Balance',
    yourBalance: 'Your balance is:',
    yourLanguage: 'Choose your language',
    totalReceived: 'Total received',
    graduation: 'Graduation',
    operationsToday: 'Operations today',
    operationsYesterday: 'Operations yesterday',
    performanceChart: 'Performance Chart',
    latestReturns: 'Latest operations',
    creationDate: 'Creation Date',
    description: 'Description',
    percentage: 'Percentage',
    hello: 'Hello, ',
    linkIndication: 'Indication Link',
    balanceActivationTitle: 'Activation with Balance',
    balanceActivationText: 'Do you really want to activate using ALL YOUR BALANCE?',
    successActivation: 'Your activation was successful.',
    validation: {
      zeroBalance: "You don't have enough balance to perform activation by balance",
    },
  },
  profile: {
    awaitWithdrawOne: 'After changing the withdrawal plan, you must wait ',
    awaitWithdrawTwo: ' days for a new change!',
    placeholderName: 'Ex.: John',
    placeholderLastName: 'Ex.: Marston',
    placeholderUser: 'Ex.: john_marston',
    placeholderEmail: 'Ex.: john_marston@gmail.com',
    placeholderPhone: 'Ex.: (11) 98783-4343',
    placeholderCpf: 'Ex.: 012.345.678-90',
    placeholderCnpj: 'Ex.: 01.234.567/8901-01',
    placeholderAddress: 'Ex.: Rua dos Alpes, 1322',
    placeholderBitcoinWallet: 'Ex.: 5x1HUXrCNLbtMDqcw6o5GNn4xqX',
    placeholderRandomKey: 'Ex.: 5x3123fsdvc1HUXr4xqX',
    myProfile: 'My Profile',
    userInfo: 'User Information',
    pixInfo: 'Pix Payment Information',
    btcInfo: 'Payment Information',
    pixKey: 'Pix Key',
    pixKeyType: 'Pix Key Type',
    save: 'Save',
    saveDataSucess: 'Your data has been saved.',
    imgAltProfile: 'Profile Image',
    email: 'Email',
    bitcoinWallet: 'Bitcoin Wallet',
    clickHere: 'Click here to verify',
    verifyEmail: 'Email not verified',
    verifyPhone: 'Phone number not verified',
    cryptoType: 'Crypto Wallet Type',
    walletEthereum: 'Ethereum Wallet',
    walletUstd: 'USDT Wallet',
    language: 'Default Language',
    titleConfigWithdraw: 'Withdrawal Settings',
    withdrawOptionWeekly: 'Weekly',
    withdrawOptionFortnightly: 'Fortnightly',
    withdrawOptionMonthly: 'Monthly',
    fee: 'Fee',
  },
  home: {
    openAccount: 'Open Account',
    startYourPortfolio: 'The easiest, fastest, and simplest way to profit with cryptocurrencies.',
    startYourPortfolioDescription: 'Your path to quick and easy cryptocurrency profits starts here.',
    whyBecomeSkyOperator: 'Why become a BotFY Operator?',
    innovationStep: 'No more relying on luck, count on the greatest artificial intelligence in the Arbitrage Market.',
    transparencyStep: 'Nothing is hidden. All operations are recorded and available on the Blockchain.',
    alreadyHaveAnAccount: 'Already have an account?',
    toEnter: 'Enter',
    brazilBotfyConventionTitle: 'BotFY Brazil Convention',
    brazilBotfyConvention: 'Dec 2023',
    botfyToken: 'BotFY Token',
    botfyTokenDate: 'Jan 2024',
    matrizBotfy: 'Official Brokerage and Headquarters in Dubai',
    dateMatrizBotfy: 'Mar 2024',
    participatingCountries: 'Participating Countries',
    verifiedCustomers: 'Verified Customers',
    innovationAndTechnology: 'INNOVATION AND TECHNOLOGY',
    transparency: 'TRANSPARENCY',
    security: 'SECURITY',
    securityStep: 'The safest AI technology, providing the most efficient market analysis and decision-making.',
    stepByStep: 'Your next steps with our platform!',
    firstStepLabel: '#01',
    firstStepDescription: 'REGISTER',
    secondStepLabel: '#02',
    secondStepDescription: 'DEPOSIT YOUR OPERATION TABLE',
    thirdStepLabel: '#03',
    thirdStepDescription: 'TRACK YOUR OPERATIONS',
    fourthStepLabel: 'OPTIONAL',
    fourthStepDescription: 'REFER TO OTHER CUSTOMERS AND RECEIVE COMMISSIONS AND REWARDS',
    openMyAccount: 'Open My Account',
    buyCryptoAtRealCost: 'Buy and Sell Crypto with High Profits',
    buyCryptoAtRealCostDescription: 'Trade among the top 20 market exchanges',
    buy: 'Buy',
    screenshot: 'website screenshot',
    createAnAccount: 'Register',
    linkYourBankAccount: 'Deposit Your Operation Table',
    startBuyingAndSelling: 'Track Your Operations',
    bankIcon: 'bank icon',
    salesIcon: 'sales icon',
    accountIcon: 'account icon',
    titleStartInAFewMinutes: 'Your Next Steps',
    textStartInAFewMinutes: 'With Our Platform',
    titleEarnCrypto: 'Profit through the Arbitrage Pool',
    textEarnCrypto: 'The easiest flow to buy and sell cryptocurrencies. Open your account and start today.',
    startEarning: 'Start Earning',
    cryptocurrencyMarket: 'cryptocurrency market',
  },
  signIn: {
    nameLabel: 'Name',
    emailLabel: 'Email',
    passwordLabel: 'Password',
    termsLabel: 'I certify that I am 18 years of age or older, and I agree to the Terms and Conditions of Use.',
    termsAndConditionsLabel: 'View Terms and Conditions of Use',
    confirmPasswordLabel: 'Confirm Password',
    usernameLabel: 'Username',
    login: 'Log in',
    nickname: 'Nickname',
    password: 'Password',
    userName: 'Username',
    emailUserName: 'Username or Email',
    IndicatedBy: 'Indicated by',
    CreateYourAccount: 'Create your account now!',
    accessAcount: 'Access account',
    forgotMyPassword: 'Forgot my password',
    enterUsername: 'Enter your username',
    enterEmail: 'Enter your email',
    enterPassword: 'Enter your password',
    resetPassword: 'Reset password',
    invalidEmail: 'Invalid email',
    validEmail: 'Valid email',
    sendEmail: 'Email sent successfully',
    sendEmailConfirmPassword: 'We have sent an email to you to change your password!',
    userOrPasswordInvalid: 'Invalid username or password.',
    errorChangePassword: 'Unable to send an email for password change, please try again!',
    tryAgain: 'Try again',
    signUp: "I don't have an account, I want to sign up",
    connectTourAccount: 'Connect your Account',
    resetYourPassword: 'Reset your password',
    emailNotRegistered: 'Email not registered',
  },
  signUp: {
    firstNameLabel: 'First Name',
    lastNameLabel: 'Last Name',
    signUpNow: 'Sign Up Now',
    fullName: 'Full Name',
    email: 'Your Best Email',
    phone: 'Phone',
    login: 'Login',
    password: 'Password',
    passwordConfirm: 'Confirm Password',
    signUp: 'Sign Up',
    signIn: 'Already have an account? Sign In!',
    IndicatedBy: 'Indicated by',
    titleSignUp: 'Discover the financial revolution of the century by signing up for botfy!',
    subTitleSignUp: 'Your financial freedom at a click!',
    validation: {
      validationUsername: 'Only alphanumeric characters, underscores, and hyphens are allowed',
      validationPassword: 'Password is required',
      validationConfirmPassword: 'Confirm password is required',
      validationCpfInvalid: 'CPF is already in use.',
      validationRequiredConfirmPassword: 'Passwords do not match',
      invalidEmail: 'This is not a valid email.',
      validationEmail: 'This email is already in use.',
    },
    usernameTip: 'Use only lowercase letters, numbers, dash, and underscore.',
    phoneTip: 'DDD + Your phone number',
    successMessageTitle: 'Your Registration has been Completed.',
    successMessageText: 'You now have access to the botfy platform.',
  },
  deposit: {
    requestedAmount: 'Requested Amount',
    requestedTax: 'Withdrawal Tax',
    withdrawNumber: 'Number',
    expiredDeposit: 'Expired Deposit',
    informationUsdt: 'Network: TRON',
    networkUSDT: 'The network for USDT Deposit is: TRON',
    depositSkyt: 'Deposit',
    confirmPaymentEmail: 'Your order will be under review and you will receive an email when approved!',
    confirmDepositSkyt: 'This action is final and cannot be undone. Please confirm to proceed.',
    notCancel: 'Do Not Cancel',
    continue: 'Continue',
    attention: 'Attention!',
    textCancel:
      'If you have already made the payment, the confirmation period is up to 2 hours. Do you really want to cancel this order?',
    actions: 'Actions',
    awaitDeposit: 'Confirmation period for your deposit may take from 1 minute to 2 hours.',
    depositScreenTitle: 'Deposit',
    tagSelection: 'Select the currency and payment method',
    buttonPix: 'PIX',
    buttonBTC: 'BTC',
    buttonEther: 'Ethereum',
    buttonUSTD: 'USDT',
    buttonPaypal: 'Paypal',
    buttonSKYT: 'SKYT',
    minimumDepositAmount: 'Minimum deposit: ',
    imgAlt: 'Deposit Vector',
    depositInformations: 'Deposit Information',
    estimationBTC: 'BTC Estimate:',
    estimationETH: 'Ethereum Estimate:',
    estimationUSDT: 'USDT Estimate:',
    estimationUSD: 'Dollar Estimate:',
    pixLabelValue: 'Deposit Value',
    pixPlaceholderValue: 'Amount (U$)',
    pixlabelCPF: 'Document',
    pixPlaceholderCPF: 'E.g.: 123.456.789-00',
    pixlabelEmail: 'Email',
    pixPlaceholderEmail: 'E.g.: liminha_limas@bol.com.br',
    warningValueInReal: 'You will deposit in real:',
    depositButtonRequest: 'Deposit Request',
    pixDetailButton: 'Generate QRCode',
    pixKeyLabel: 'Copy Pix Key',
    pixCancelButton: 'Cancel',
    copyCodeSuccess: 'Code copied to clipboard',
    criptoLabelValue: 'Deposit Value',
    criptoPlaceholderValue: 'Amount (U$)',
    criptoDetailButton: 'Wallet Details',
    depositHistory: 'Deposit History',
    receptorName: 'Receptor Name',
    cpfNumber: 'CPF Number',
    transactionType: 'Type',
    requestDate: 'Request Date',
    amount: 'Amount',
    successDeposit: 'Deposit successfully made!',
    errorDeposit: 'Error making deposit.',
    youDeposit: 'You will deposit: ',
    status: 'Status',
    wallet: 'Wallet Number:',
    rate: 'Always send at priority rate',
    blockchain: 'Your deposit will be active as soon as the minimum confirmations on the blockchain are reached',
    statusDeposit: {
      created: 'Created',
      awaitingPayment: 'Awaiting Payment',
      approved: 'Approved',
      voucher: 'Voucher',
      refused: 'Refused',
      reversed: 'Reversed',
      expired: 'Expired',
    },
  },
  sidebar: {
    matrix: 'Matrix',
    arbitrage: 'Arbitrage',
    deposit: 'Deposit',
    network: 'Network',
    withdraw: 'Withdraw',
    extract: 'Extract',
    wallet: 'Wallet',
    security: 'Security',
    profile: 'Profile',
    logout: 'Logout',
    closeSidebar: 'Close',
    commissionMenu: 'Commissions',
    automaticOperations: 'Automatic Operations',
    transactionAlertOne: 'Transactions will be conducted automatically within the minimum period of ',
    transactionAlertTwo: ' hours, and there will be an administrative fee of 20% on the transaction.',
    disableAutomatic: 'Do you want to disable Automatic Operations?',
    disableNow: 'Disable now!',
    activateNow: 'Activate now!',
    cancel: 'Cancel',
  },
  status: {
    pending: 'Pending',
    approved: 'Approved',
    underAnalysis: 'Under Analysis',
    refused: 'Refused',
    awaitingPayment: 'Awaiting Payment',
    active: 'Active',
    inactive: 'Inactive',
    activeVoucher: 'Active',
  },
  commission: {
    matrix: 'Matrix',
    recommendation: 'Recommendation',
    th: {
      date: 'Commission Date',
      affilliated: 'Affiliated',
      type: 'Type',
      valueDeposit: 'Deposit Value',
      level: 'Level',
      txCommission: 'Transaction Fee',
      commission: 'Commission',
    },
    numberCommission: 'Number of Commissions:',
    sumCommission: 'Sum of Commissions:',
  },
  gateway: {
    payment: 'Payment Gateway',
  },
  arbitrage: {
    automaticOperations: 'Automatic Operation',
    textActiveOperations: 'Active Automatic Operations',
    textCustomerNotAble: 'Operation not completed! Pending operation time, please check your remaining time',
    successArbitrage: 'Arbitrage successfully performed!',
    chartTitle: 'Market (BTC/USDT) 1D',
    BuyAndSellOffers: 'Buy and Sell Offers',
    bestBuyPrice: 'Best buying price',
    bestSalePrice: 'Best selling price',
    purchaseOffers: 'Purchase offers',
    salesOffers: 'Sales offers',
    updatingNewOffers: 'Updating new offers',
    youWillBuyFrom: 'You will buy from',
    youWillSellTo: 'You will sell to',
    selectBrokerTheSalesOrder: 'Select a Broker in the sales order section',
    selectBrokerThepurchaseOrder: 'Select a Broker in the purchase order section',
    selectBrokerThepurchaseSalesOrder: 'Select a Broker in the purchase and sales order section',
    buyNow: 'Buy now!',
    validValue: 'Make sure the entered value is valid.',
    yourRecentOperations: 'Your recent operations',
    broker: 'Broker',
    purchasePrice: 'Purchase price',
    offerPrice: 'Offer price',
    percentage: 'Percentage',
    purchaseSaleDate: 'Purchase/Sale Date',
    amountsent: 'Amount sent',
    transaction: 'Transaction',
    status: 'Status',
    lastNegotiations: 'Last negotiations',
    Price: 'Price (USD)',
    Amount: 'Amount (BTC)',
    Hour: 'Hour',
    total: 'Total',
    orderBook: 'Order Book',
    robotMessage: 'The robot has a margin of error of +/- 1% in the arbitrage value.',
    notRecordsFound: 'No records found.',
    valueMinArbitrage: 'Minimum arbitrage value is ',
    refund: 'Operation Refund',
    profit: 'Operation Profit',
    tax: 'Operation Tax',
    operationsHistory: 'Operations History',
    openOperations: 'Open Operations',
  },
  extract: {
    filters: 'Filters',
    selectType: ':: Select the Type ::',
    extract: 'Extract',
    dataTransaction: 'Transaction Date',
    description: 'Description',
    type: 'Type',
    balanceBefore: 'Balance Before',
    value: 'Value',
    balanceAfter: 'Balance After',
    noTransactionCell: 'No transactions were found',
    startDate: 'Start Date',
    endDate: 'End Date',
    filter: 'Consult',
    optionPrimary: 'Select Type',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    affiliated: 'Affiliated',
    arbitration: 'Arbitration',
    P2P: 'P2P',
    matrixBonus: 'Bonus Matrix ',
    PL: 'PL',
    all: 'All',
    allRecords: 'All Records:',
    summation: 'Summation:',
    volume: 'Volume',
  },
  withdraw: {
    withdrawTax: 'Withdrawal Tax',
    conversionToPercent: 'Valor Máximo para Saque',
    networkUSDTWithdraw: 'The network for USDT withdrawal is: TRON',
    documentStatus: 'Document Verification is Required to Withdraw.',
    invalidWithdraw: 'Withdrawal not available',
    paymentMethodOff: 'This payment method is under maintenance. Please choose another receiving method!',
    blockedWithdraw: 'Withdrawal Blocked. Please Contact Support!',
    maxWithdraw: 'You can withdraw up to: ',
    notWithdraw: 'Today is not a withdrawal day.',
    withdraw: 'Withdraw',
    withdrawDescription: 'Select the withdrawal method',
    cryptocurrency: 'Cryptocurrency',
    minimumValue: 'Minimum withdrawal: ',
    withdrawImage: 'withdrawal image',
    withdrawalInformation: 'Withdrawal Information',
    withdrawalHistory: 'Withdrawal History',
    failedWithdrawalHistory: 'You do not have any withdrawal requests yet',
    random: 'Random',
    withdrawalValue: 'Withdrawal value',
    withdrawalAmount: 'Amount for withdrawal',
    conversionToUSD: 'Conversion to USD',
    keyType: 'Key Type',
    enterTheKey: 'Enter the key',
    sameOwner: "Same owner's account?",
    period: '7 (seven) days',
    pleaseWait: 'Please wait ',
    forNextOperation: ' for the next operation. This is the time it will take to process your withdrawal.',
    withdrawalRequest: 'Request Withdrawal',
    resourceRelease: '* Weekly Resource Release',
    yourCryptocurrencyWallet: 'Your cryptocurrency wallet',
    successMessageTitle: 'Success!',
    successMessageText: 'Your withdrawal has been completed.',
    deniedMessageTitle: 'Request Denied',
    deniedMessageText: 'Insufficient Balance',
    minimumValueError: 'Add a value above ',
    nameValidation: 'Enter a valid name',
  },
  forgotPassword: {
    textForgotPassword:
      '*To request a password change, please enter your email address in the field below and click the "Send" button. We will send you an email containing a secure link to reset your password.',
    passwordChange: 'Password Change',
    textResetPassword: 'Please enter your new password in the fields below and confirm it to complete the password change.',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    successMessageTitle: 'Password changed successfully!',
    invalidPassword: 'Invalid Password!',
    invalidConfirmPassword: 'Passwords do not match!',
    invalidToken: 'Invalid Token. Try again later!',
    successSendEmail: 'Email sent successfully',
    invalidEmail: 'This is not a valid email.',
    textForgotPasswordNew: 'To reset your password, please use a device that has recently accessed your botfy account.',
  },
  network: {
    affiliateNetwork: 'Affiliate Network',
    direct: 'Direct:',
    indirect: 'Indirect:',
    yourEntireNetwork: 'Your Entire Network:',
    consult: 'Consult',
    searchforLogin: 'Search for a login or name...',
    toClean: 'Clear',
    openNetwork: 'Network',
    volumeSum: 'Network Amount:',
  },
  security: {
    security: 'Security',
    emailPhoneText: 'Secure your transactions and your account',
    verify: 'Verify',
    accessPassword: 'Access Password',
    accessPasswordText: 'The login password is used to access your account.',
    change: 'Change',
    phoneNumberVerification: 'Phone Number Verification',
    emailVerification: 'Email Verification',
    verificationCode: 'Enter your verification code',
    changeAccessPassword: 'Change Access Password',
    currentPassword: 'Current Password',
    confirmPassword: 'Confirm Password',
    editPhone: 'Edit Phone',
    newPhone: 'New Phone',
    editEmail: 'Edit Email',
    newEmail: 'New Email',
    verifiedEmail: 'Verified Email',
    verifiedPhone: 'Verified Phone',
    questionVerifyEmail: 'To verify your Email',
    questionVerifyPhone: 'To verify your Phone: ',
    textVerify: 'belongs to you, click the button below to start the process',
    resendToken: 'Resend Token',
    documents: 'Documents',
    PENDING: 'Pending',
    UNDER_ANALYSIS: 'Under Analysis',
    APPROVED: 'Approved',
    REFUSED: 'Refused',
    REVOKED: 'Revoked',
    verifiedDocuments: 'Verified Documents',
    sendDocuments: 'Send Documents',
    frontDocuments: 'Front of the document',
    backDocuments: 'Back of the document',
    selfieDocuments: 'Document close to the face',
    errorsDocuments: 'Error sending Documents.',
    successDocuments: 'Documentos Enviados com Sucesso!',
    permForm: 'Allowed Formats:',
    maxTam: 'Maximum Size:',
    invalidExtension: 'Invalid Extension.',
  },
  p2p: {
    minimumAmountForOperation: 'Minimum amount to perform P2P is ',
    confirmPassword: 'Password Confirmation',
    insertPassword: 'Enter your password for security verification.',
    dataTransfer: 'Data for Transfer',
    emailRecipient: 'Recipient Email',
    search: 'Search',
    placeholderValue: 'E.g.: $100.00',
    valueTransfer: 'Value in SkyT',
    transferSkyt: 'You will transfer in SkyT:',
    notFoundRecipient: 'Recipient not found',
    searchRecipient: 'Search for the recipient',
    TransactionSuccessful: 'Transaction completed successfully.',
    TransactionError: 'Error performing the transaction.',
    p2p: 'p2p',
    SkytBallance: 'SkyT Balance:',
    transferTax: 'Transfer Tax',
    confirmTitle: 'Are you sure?',
    confirmDescription: 'This action is irreversible. Your balance will be converted to SkyT!',
    invalidValue: 'Invalid Value.',
    errorConvert: 'Error converting balance to SkyT.',
    errorInsufficientBalance: 'Insufficient Balance!',
    sucessConvert: 'Balance converted successfully!',
    convertSkyt: 'SkyT Converter',
    available: 'Available',
    amount: 'Balance',
    convert: 'Convert',
  },
  careerPath: {
    careerPath: 'Career Path',
    nextGraduation: 'Next graduation',
    nextGraduations: 'Upcoming graduations',
    graduationsCheck: 'Attained graduations',
    graduationsProgress: 'Graduation in progress',
    hitGoal: 'To reach the goal, you need: ',
  },
  wallet: {
    myWallet: 'My Wallet',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    p2p: 'P2P',
    extract: 'Extract',
    arbitrationPool: 'Arbitration Pool',
    earnings: 'Earnings',
    earningsOperations: 'Earnings from Operations',
    skyt: 'SKYT',
    banking: 'Banking',
    recentHistory: 'Recent History',
  },
  extractConstants: {
    skytExchange: 'Skyt Converted',
    ballanceSkytConvert: 'Balance to SKYT Conversion',
    withdrawRefund: 'Withdrawal Refund',
    refund: 'Reversal',
    skytDepositConvert: 'SKYT to Balance Conversion',
    skytDeposit: 'SKYT Deposit',
    purchase: 'Purchase',
    reverse: 'Reversal',
    arbitration: 'Arbitration Refund',
    voucherDeposit: 'Deposit Voucher',
    withdrawFixedAmountTax: 'Withdrawal Tax',
    arbitrationProfit: 'Arbitration Profit',
    networkCommission: 'Network Commission',
    residualCommission: 'Residual Commission',
    withdrawRequest: 'Withdrawal Request',
    withdraw: 'Withdrawal',
    manualBonus: 'Manual Bonus',
    deposit: 'Deposit',
    arbitrageRequest: 'Arbitrage',
    arbitrageProfitRequest: 'Arbitration Profit Request',
    transferTaxP2p: 'P2P Transfer Fee',
    skytTransferReceive: 'Received Transfer in SKYT',
    skytTransferSender: 'Sent Transfer in SKYT',
    bonusMatrix: 'Bonus Matrix',
    unknown: 'Unknown',
    arbitrationTax: 'Arbitration Fee',
  },
  matrix: {
    bonus: 'Bonus Amount:',
    lastPayment: 'Last Payment:',
    details: 'Details',
    volPayment: 'Volume Paid',
    date: 'Date',
  },
}
