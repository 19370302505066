import { Badge } from '@/components/Common'
import { APPROVED, CREATED, REFUSED, getWithdrawStatusText } from '../constants'

export const StatusWithdrawBadge = ({ status }) => {
  let color
  switch (status) {
    case CREATED:
      color = 'bg-gray-400'
      break
    case APPROVED:
      color = 'bg-positive'
      break
    case REFUSED:
      color = 'bg-negative'
      break
  }
  return <Badge text={getWithdrawStatusText(status)} textColor={color} />
}
