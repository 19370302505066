import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'
import { getEndpoint } from '@/endpoints'

export const useSendDocuments = () => {
  const { data, loading, makeRequest, error } = useApi(params => {
    const { route, method } = getEndpoint('sendDocuments')
    return httpClient[method](route, params)
  })
  return {
    dataSendDocuments: data,
    loadingSendDocuments: loading,
    errorSendDocuments: error,
    makeRequestSendDocuments: makeRequest,
  }
}
