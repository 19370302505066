import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useNetworkId = () => {
  const { data, loading, makeRequest, error } = useApi(id => {
    const { route, method } = getEndpoint('fetchNetworkId', { id: id })
    return httpClient[method](`${route}?sort=-createdAt`)
  })

  return {
    dataNetworkId: data,
    loadingNetworkId: loading,
    errorNetworkId: error,
    networkIdRequest: makeRequest,
  }
}
