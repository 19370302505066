import { useAuth } from '@/contexts'
import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useVerificationEmailPhone = () => {
  const { user } = useAuth()
  const { data, loading, makeRequest, error, meta } = useApi((type, newEmail = false) => {
    const { route, method } = getEndpoint(type === 'email' ? 'verificationEmail' : 'verificationPhone')
    const payloadEmail = {
      newEmail: user.email,
    }
    const payloadPhone = {
      newPhone: user.phone,
    }
    return httpClient[method](route, newEmail ? newEmail : type === 'email' ? payloadEmail : payloadPhone)
  })

  return {
    dataVerificationEmailPhone: data,
    loadingVerificationEmailPhone: loading,
    errorVerificationEmailPhone: error,
    verificationEmailPhoneRequest: makeRequest,
    metaVerificationEmailPhone: meta,
  }
}
