import { Badge } from '@/components/Common'
import { getCustomerDocumentColor, getStatusCustomerDocument } from '../constants'

export const BadgeDocument = ({ status }) => (
  <Badge
    variant='solid'
    rounded='10px'
    className={`px-2 py-1 bg-${getCustomerDocumentColor(status)}`}
    text={getStatusCustomerDocument(status)}
  />
)
