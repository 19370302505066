import { React } from 'react'
import { Link } from 'react-router-dom'
import { SignUpForm } from './components/'
import SwitchLanguage from '@/components/SwitchLanguage'
import { useTranslation } from 'react-i18next'

export const SignUp = () => {
  const { t } = useTranslation()

  return (
    <main className='flex flex-col gap-3'>
      <div className='md:hidden flex justify-end mt-4 px-3'>
        <SwitchLanguage />
      </div>
      <div className='flex flex-col gap-3 md:justify-center md:items-center w-full px-3'>
        <h1 className='font-bold text-xl lg:text-2xl xl:text-3xl text-white md:mt-3 lg:mt-5'>{t('signIn.CreateYourAccount')}</h1>
        <SignUpForm />

        <Link className='text-white w-full my-3 text-center' to={'/sign-in'}>
          <span className='hover:cursor-pointer'>{t('signUp.signIn')}</span>
        </Link>
      </div>
    </main>
  )
}
