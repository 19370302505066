import { useTranslation } from 'react-i18next'
import { useFormResetPassword, useResetPassword } from './hooks'
import { FieldMessage, PasswordField } from '@/components/Form/Fields'
import { useParams, useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import { Loader } from '@/components/Common'

export const ResetPassword = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { token } = useParams()
  const { resetPassword, dataResetPassword, errorResetPassword, loadingPassword } = useResetPassword()
  const { values, handleChange, handleSubmit, errors, setFieldValue, setFieldError } = useFormResetPassword({
    onSubmit: values => {
      resetPassword(values, token)
    },
  })

  useEffect(() => {
    if (!dataResetPassword) return
    Swal.fire({
      title: `${t('forgotPassword.successMessageTitle')}`,
      icon: 'success',
      confirmButtonText: 'Ok',
    }).then(() => navigate('/sign-in'))
  }, [dataResetPassword])

  useEffect(() => {
    if (!errorResetPassword) return
    if (errorResetPassword.error === 'password') setFieldError('password', `${t('forgotPassword.invalidPassword')}`)
    if (errorResetPassword.error === 'confirmPassword')
      setFieldError('confirmPassword', `${t('forgotPassword.invalidConfirmPassword')}`)
    if (errorResetPassword.error === 'recordNotFound') {
      setFieldError('password', t('forgotPassword.invalidToken'))
      setFieldError('confirmPassword', t('forgotPassword.invalidToken'))
      return
    }
    if (errorResetPassword.error === 'invalidToken') {
      setFieldError('password', t('forgotPassword.invalidToken'))
      setFieldError('confirmPassword', t('forgotPassword.invalidToken'))
      return
    }
  }, [errorResetPassword])

  return (
    <div className='flex justify-center items-center mt-28 lg:mt-36'>
      <form className='bg-white rounded-3 p-3 sm:w-1/2 mx-3' onSubmit={handleSubmit}>
        <h1 className='text-xl'>{t('forgotPassword.passwordChange')}</h1>
        <div className='bg-primary p-2 my-2 rounded-3 leading-tight'>
          <span className='text-white text-sm '>{t('forgotPassword.textResetPassword')}</span>
        </div>
        <PasswordField
          value={values.password}
          onChange={handleChange}
          name='password'
          label={t('forgotPassword.newPassword')}
          placeholder='******'
          errorMessage={errors.password}
        />
        <PasswordField
          value={values.confirmPassword}
          onChange={handleChange}
          name='confirmPassword'
          label={t('forgotPassword.confirmNewPassword')}
          placeholder='******'
          errorMessage={errors.confirmPassword}
        />
        <div className='my-2 flex flex-col items-center'>
          <ReCAPTCHA
            name='recaptcha'
            render='explicit'
            onChange={value => setFieldValue('recaptcha', value)}
            sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
          />
          {errors.recaptcha && <FieldMessage message={errors.recaptcha} />}
        </div>
        <div className='flex justify-end mt-3'>
          <button type='submmit' className='rounded-2 bg-primary py-2 px-4 text-center text-white font-bold min-w-[150px]'>
            {loadingPassword ? <Loader className='inline-block' /> : t('global.send')}
          </button>
        </div>
      </form>
    </div>
  )
}
