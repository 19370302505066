import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

export const useFormWithdrawPix = ({ onSubmit }) => {
  const { t } = useTranslation()
  const Schema = Yup.object({
    amountConverted: Yup.string().required(t('global.validation.required')),
    receiverName: Yup.string()
      .required(t('global.validation.required'))
      .matches(/^[^0-9]*$/, t('withdraw.nameValidation')),

    receiverCpf: Yup.string().test('lengthMin', t('global.validation.validationCpf'), value => {
      const clearValue = String(value).replace(/[^0-9]/g, '')
      return clearValue.length === 11
    }),

    pix: Yup.string().required(t('global.validation.required')),
    amount: Yup.string(),
    type: Yup.number(),
    receiverEmail: Yup.string().required(t('global.validation.required')).email(t('signIn.invalidEmail')),
  })

  return useFormik({
    initialValues: {
      amountConverted: '',
      amount: '',
      receiverName: '',
      receiverCpf: '',
      receiverEmail: '',
      pix: '',
      type: '1',
      sameOwnership: false,
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
