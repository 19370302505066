import { LoginHeader } from '@/components/Menu'
import React from 'react'

export const LoginLayout = ({ children }) => {
  return (
    <main className='overflow-hidden bg-background'>
      <LoginHeader />
      <div className='flex flex-col gap-2 px-3 w-full  text-text'>
        <div className='w-full text-text'>{children}</div>
      </div>
    </main>
  )
}
