import { useViewport } from '@/hooks'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import {
  BestBuySellOffers,
  BookOffersLastNegotiations,
  BuySellOffers,
  Chart,
  ChartBar,
  RecentOperations,
  SelectOffersToBuy,
} from './components'
import { useCandlesChart } from './hooks/useCandleChart'
import { useEffect } from 'react'
import { useBookOffers } from './hooks'
import { useLastTrades } from './hooks/useLastTrades'
import { Loading } from '@/components/Common'
import { useArbitrage } from './contexts'
import { useAuth, useBalance } from '@/contexts'
import { FaRobot } from 'react-icons/fa'

export const Arbitrage = () => {
  const { t } = useTranslation()
  const { refreshBalance } = useBalance()
  const { isMoreXl } = useViewport()
  const { user } = useAuth()
  const { consumeCandleChartWebSocket, dataChart } = useCandlesChart()
  const { consumeOrderBookWebSocket, bids, loadingOffers, setLoadingOffers } = useBookOffers()
  const { lastTradesData, consumeLastTradesWebSocket, setLoadingLastTrades, loadingLastTrades } = useLastTrades()
  const { loadingCryptos, loadingUseBuyOffers } = useArbitrage()

  useEffect(() => {
    refreshBalance()
    const webSocket = new WebSocket('wss://api-pub.bitfinex.com/ws/2')
    setLoadingLastTrades(true)
    setLoadingOffers(true)

    webSocket.onopen = () => {
      webSocket.send(JSON.stringify({ event: 'subscribe', channel: 'candles', key: 'trade:1D:tBTCUSD' }))
      webSocket.send(JSON.stringify({ event: 'subscribe', channel: 'book', symbol: 'tBTCUSD' }))
      webSocket.send(JSON.stringify({ event: 'subscribe', channel: 'trades', symbol: 'tBTCUSD' }))
      setLoadingLastTrades(false)
      setLoadingOffers(false)

      webSocket.onmessage = msg => {
        const message = JSON.parse(msg.data)
        consumeCandleChartWebSocket(message)
        consumeOrderBookWebSocket(message)
        consumeLastTradesWebSocket(message)
      }
    }

    return () => webSocket.close()
  }, [])

  if (loadingCryptos) return <Loading />

  return (
    <>
      <ChartBar />
      <main
        className={classNames(
          'max-w-7xl mx-auto xl:border-b xl:border-x border-gray-500 flex flex-col bg-lightBg dark:bg-transparent text-black dark:text-white',
          {
            'flex flex-row w-full justify-between': isMoreXl,
          }
        )}
      >
        <div className='w-full flex border-b border-gray-500'>
          <div className={classNames('w-full lg:w-[75%]')}>
            <Chart dataChart={dataChart} />

            <div className='p-3'>
              <p className='font-bold mb-3 text-[18px]'>{t('arbitrage.BuyAndSellOffers')}</p>
              <BestBuySellOffers />
              <BuySellOffers />
              {!user.automaticOperations ? (
                <SelectOffersToBuy />
              ) : (
                loadingUseBuyOffers === false && (
                  <div className='flex justify-center items-center my-4 gap-2 italic border border-yellow-800 dark:border-yellow-600 py-3 text-yellow-300 rounded-2'>
                    <FaRobot className='w-4 h-4 mb-1' /> {t('arbitrage.textActiveOperations')}
                  </div>
                )
              )}
            </div>
          </div>

          <BookOffersLastNegotiations
            bids={bids}
            lastTradesData={lastTradesData}
            loadingOffers={loadingOffers}
            loadingLastTrades={loadingLastTrades}
          />
        </div>
        <RecentOperations />
      </main>
    </>
  )
}
