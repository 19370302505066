import { Badge } from '@/components/Common'
import { getCommissionType } from '../constants'

export const CommissionBadge = ({ type }) => {
  let color

  switch (type) {
    case 1:
      color = 'bg-positive'
      break
    case 2:
      color = 'bg-blue-400'
      break
  }

  return <Badge text={getCommissionType(type)} bg={color} />
}
