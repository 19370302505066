import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import resources from './languages'

i18n.use(initReactI18next).init({
  lng: 'en-US',
  fallbackLng: 'en-US',
  supportedLngs: ['en-US', 'pt-BR', 'es-ES', 'fr'],
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  resources,
})
