import { SideBar } from '@/components/Menu'
import SwitchLanguage from '@/components/SwitchLanguage'
import { useAuth } from '@/contexts'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineMenu } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import logo from '/images/logo-name.png'

export const NavHome = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [sidebar, setSideBar] = useState(false)

  const showSideBar = () => setSideBar(!sidebar)
  return (
    <nav className='flex bg-primary-700 p-3 justify-between border border-black'>
      <div className='flex justify-center items-center'>
        <img src={logo} alt='Logo botfy' width='120' className='md:w-36' />
        <div className='hidden pl-3 md:flex'>
          <SwitchLanguage />
        </div>
      </div>
      <div className='flex items-center gap-2 md:gap-3 text-sm md:text-base'>
        {user ? (
          <>
            <a className='ml-2 sm:ml-0'>
              <AiOutlineMenu className='w-4 h-4 cursor-pointer' onClick={showSideBar} />
            </a>
            {sidebar && <SideBar active={setSideBar} />}
          </>
        ) : (
          <>
            <Link to='/sign-in' className='hover:underline'>
              {t('home.toEnter')}
            </Link>

            <Link
              to='/sign-up'
              className='flex items-center justify-center rounded-full py-1 px-2 border text-center border-white md:py-2 md:px-3 hover:bg-white hover:text-primary-700 transition duration-200'
            >
              {t('home.openAccount')}
            </Link>
          </>
        )}
      </div>
    </nav>
  )
}
