export default {
  global: {
    confirmAdmin: 'Confirmar contraseña de administrador',
    adminInform: '¡Acceso limitado! Estás accediendo a esta cuenta en modo de Soporte!',
    accountNotVerified: 'Cuenta no verificada',
    companyName: 'Botfy',
    successTitle: '¡Todo correcto!',
    errorTitle: 'Ha ocurrido un error',
    ok: 'OK',
    yes: 'Sí',
    no: 'No',
    phone: 'Teléfono',
    cryptocurrencyWallet: 'Cartera de criptomonedas',
    login: 'Iniciar sesión',
    name: 'Nombre',
    email: 'Correo electrónico',
    cnpj: 'CNPJ',
    cpf: 'CPF',
    randomKey: 'Clave aleatoria',
    createdAt: 'Fecha de creación',
    activeAt: 'Fecha de activación',
    send: 'Enviar',
    balance: 'Saldo',
    screenLoadingText: 'Cargando...',
    price: 'Precio',
    await: 'Esperar:',
    change: 'Cambio',
    chart: 'Gráfico',
    trade: 'Negociación',
    cancel: 'Cancelar',
    edit: 'Editar',
    automaticOperations: 'Automatizar operación',
    deautomateOperations: 'Desautomatizar operación',
    notMessage: 'No hay mensajes.',
    emailRequired: 'Correo electrónico requerido',
    titleMessages: 'Notificaciones',
    readMessages: 'Marcar como leídas',
    chartTitle: 'Mercado (BTC/USDT) 1D',
    sendEmailRequest: 'Enviar Token',
    exit: 'Salir',
    verify: 'Verificar',
    languages: {
      english: 'Inglés',
      portuguese: 'Portugués (BR)',
      spanish: 'Español',
      french: 'Francés',
    },
    validation: {
      validationRecaptcha: 'Recaptcha inválido.',
      required: 'Este campo es obligatorio',
      validationCpf: 'El CPF debe contener 11 caracteres',
      validationCpfInvalid: 'Documento ya está en uso.',
      validationPhone: 'El teléfono debe contener 11 caracteres',
      validationCpnjLength: 'El CNPJ debe contener 14 dígitos',
      validationPhoneSignUp: 'El número de teléfono ya está en uso.',
    },
    aspectRatioError: 'La imagen no está en la proporción adecuada. Recomendamos una imagen cuadrada.',
    successUploadImage: '¡Carga exitosa!',
    sendEmail: '¡Hemos enviado un Token a su correo electrónico!',
    errorEmail: 'Error al enviar el Token a su correo electrónico.',
    sendPhone: '¡Se ha enviado un Token a su SMS!',
    errorPhone: 'Error al enviar el Token a su SMS.',
    successSendEmail: '¡Correo electrónico verificado con éxito!',
    successSendPhone: '¡Teléfono verificado con éxito!',
    errorSendEmail: 'Error al verificar el correo electrónico.',
    errorSendPhone: 'Error al verificar el teléfono.',
    errorVerification: 'Todos los campos son obligatorios',
    tokenInvalid: 'Token inválido.',
    cancelDepositSuccess: '¡Depósito cancelado con éxito!',
    cancelDeposit: 'Depósito cancelado.',
    placeholderValue: 'Ex.: $ 100.00',
    information: {
      title: '¡Buenos días, Familia botfy!',
      att: 'Atentamente, Equipo botfy',
      text1: 'Realizamos una pequeña actualización para mejorar el rendimiento de nuestros sistemas y servidores.',
      text2:
        'Siempre que una correduría actualiza sus API, necesitamos actualizar nuestro lado también para seguir obteniendo cotizaciones en tiempo real. Por esta razón, nuestro modo automático estuvo en actualización durante aproximadamente 30 horas.',
      text3: 'Todo está actualizado y las operaciones se han normalizado.',
      text4:
        '¡Es importante supervisar sus sistemas al menos una vez al día! Y cada vez que haya actualizaciones para quienes utilizan el modo automático, APAGUE y ENCIENDA de nuevo y espere la próxima operación.',
      checkbox: 'Estoy Consciente',
    },
  },
  home: {
    openAccount: 'Abrir Cuenta',
    startYourPortfolio: 'La forma más fácil, rápida y sencilla de ganar dinero con criptomonedas.',
    startYourPortfolioDescription: 'Tu camino hacia ganancias rápidas y fáciles con criptomonedas comienza aquí.',
    whyBecomeSkyOperator: '¿Por qué convertirse en un Operador de BotFY?',
    innovationStep: 'No más confiar en la suerte, cuenta con la mayor inteligencia artificial en el Mercado de Arbitraje.',
    transparencyStep: 'Nada se oculta. Todas las operaciones se registran y están disponibles en la Blockchain.',
    alreadyHaveAnAccount: '¿Ya tienes una cuenta?',
    toEnter: 'Entrar',
    brazilBotfyConventionTitle: 'Convención BotFY Brasil',
    brazilBotfyConvention: 'Dic 2023',
    botfyToken: 'Token BotFY',
    botfyTokenDate: 'Ene 2024',
    matrizBotfy: 'Corredora y Sede Oficial en Dubái',
    dateMatrizBotfy: 'Mar 2024',
    participatingCountries: 'Países participantes',
    verifiedCustomers: 'Clientes verificados',
    innovationAndTechnology: 'INNOVACIÓN Y TECNOLOGÍA',
    transparency: 'TRANSPARENCIA',
    security: 'SEGURIDAD',
    securityStep: 'La tecnología de IA más segura, brindando el análisis y la toma de decisiones más eficientes del mercado.',
    stepByStep: '¡Tus próximos pasos con nuestra plataforma!',
    firstStepLabel: '#01',
    firstStepDescription: 'REGÍSTRATE',
    secondStepLabel: '#02',
    secondStepDescription: 'DEPOSITA TU MESA DE OPERACIONES',
    thirdStepLabel: '#03',
    thirdStepDescription: 'SUPERVISA TUS OPERACIONES',
    fourthStepLabel: 'OPCIONAL',
    fourthStepDescription: 'RECOMIENDA A OTROS CLIENTES Y RECIBE COMISIONES Y PREMIOS',
    openMyAccount: 'Abrir Mi Cuenta',
    buyCryptoAtRealCost: 'Compra y Vende Cripto con Altos Beneficios',
    buyCryptoAtRealCostDescription: 'Opera entre las 20 principales corredoras del mercado',
    buy: 'Comprar',
    screenshot: 'captura de pantalla del sitio web',
    createAnAccount: 'Registrarse',
    linkYourBankAccount: 'Deposita Tu Mesa de Operaciones',
    startBuyingAndSelling: 'Supervisa Tus Operaciones',
    bankIcon: 'icono de banco',
    salesIcon: 'icono de ventas',
    accountIcon: 'icono de cuenta',
    titleStartInAFewMinutes: 'Tus Próximos Pasos',
    textStartInAFewMinutes: 'Con Nuestra Plataforma',
    titleEarnCrypto: 'Gana a través del Pool de Arbitraje',
    textEarnCrypto: 'El flujo más fácil para comprar y vender criptomonedas. Abre tu cuenta y comienza hoy.',
    startEarning: 'Comienza a Ganar',
    cryptocurrencyMarket: 'mercado de criptomonedas',
  },
  signIn: {
    nameLabel: 'Nombre',
    emailLabel: 'Correo electrónico',
    passwordLabel: 'Contraseña',
    termsLabel: 'Certifico que tengo 18 años o más, y acepto los Términos y Condiciones de Uso.',
    termsAndConditionsLabel: 'Ver Términos y Condiciones de Uso',
    confirmPasswordLabel: 'Confirmar contraseña',
    usernameLabel: 'Nombre de usuario',
    login: 'Iniciar sesión',
    nickname: 'Apodo',
    password: 'Contraseña',
    userName: 'Nombre de usuario',
    emailUserName: 'Nombre de usuario o correo electrónico',
    IndicatedBy: 'Indicado por',
    CreateYourAccount: '¡Crea tu cuenta ahora!',
    accessAcount: 'Acceder a la cuenta',
    forgotMyPassword: 'Olvidé mi contraseña',
    enterUsername: 'Ingresa tu nombre de usuario',
    enterEmail: 'Ingresa tu correo electrónico',
    enterPassword: 'Ingresa tu contraseña',
    resetPassword: 'Restablecer contraseña',
    invalidEmail: 'Correo electrónico inválido',
    validEmail: 'Correo electrónico válido',
    sendEmail: 'Correo electrónico enviado exitosamente',
    sendEmailConfirmPassword: '¡Hemos enviado un correo electrónico para cambiar tu contraseña!',
    userOrPasswordInvalid: 'Nombre de usuario o contraseña inválidos.',
    errorChangePassword: 'No se pudo enviar un correo electrónico para el cambio de contraseña, ¡inténtalo de nuevo!',
    tryAgain: 'Intentar de nuevo',
    signUp: 'No tengo una cuenta, quiero registrarme',
    connectTourAccount: 'Conecta tu cuenta',
    resetYourPassword: 'Restablece tu contraseña',
    emailNotRegistered: 'Correo electrónico no registrado',
  },
  signUp: {
    firstNameLabel: 'Nombre',
    lastNameLabel: 'Apellido',
    signUpNow: 'Registrarse ahora',
    fullName: 'Nombre completo',
    email: 'Tu mejor correo electrónico',
    phone: 'Teléfono',
    login: 'Inicio de sesión',
    password: 'Contraseña',
    passwordConfirm: 'Confirmar contraseña',
    signUp: 'Registrarse',
    signIn: '¿Ya tienes una cuenta? ¡Inicia sesión!',
    IndicatedBy: 'Indicado por',
    titleSignUp: '¡Descubre la revolución financiera del siglo registrándote en botfy!',
    subTitleSignUp: '¡Tu libertad financiera a un clic!',
    validation: {
      validationUsername: 'Solo se permiten caracteres alfanuméricos, guiones bajos y guiones',
      validationPassword: 'Se requiere contraseña',
      validationConfirmPassword: 'Se requiere confirmar contraseña',
      validationCpfInvalid: 'El CPF ya está en uso.',
      validationRequiredConfirmPassword: 'Las contraseñas no coinciden',
      invalidEmail: 'Este no es un correo electrónico válido.',
      validationEmail: 'Este correo electrónico ya está en uso.',
    },
    usernameTip: 'Utiliza solo letras minúsculas, números, guiones y guiones bajos.',
    phoneTip: 'DDD + Tu número de teléfono',
    successMessageTitle: 'Se ha completado tu registro.',
    successMessageText: 'Ahora tienes acceso a la plataforma de botfy.',
  },
  deposit: {
    requestedAmount: 'Monto Solicitado',
    requestedTax: 'Tarifa de Retiro',
    withdrawNumber: 'Número',
    expiredDeposit: 'Depósito caducado',
    confirmPaymentEmail: '¡Su pedido estará bajo revisión y recibirá un correo electrónico una vez aprobado!',
    informationUsdt: 'Red: TRON',
    networkUSDT: 'La red para el depósito de USDT es: TRON',
    depositSkyt: 'Depositar',
    confirmDepositSkyt: 'Esta acción es definitiva y no se puede deshacer. Por favor, confirma para proceder.',
    awaitDeposit: 'El tiempo de confirmación de su depósito puede tomar de 1 minuto a 2 horas.',
    depositScreenTitle: 'Depósito',
    tagSelection: 'Seleccione la moneda y el método de pago',
    buttonPix: 'PIX',
    buttonBTC: 'BTC',
    buttonEther: 'Ethereum',
    buttonUSTD: 'USDT',
    buttonPaypal: 'Paypal',
    buttonSKYT: 'SKYT',
    minimumDepositAmount: 'Monto mínimo de depósito: ',
    imgAlt: 'Vector de Depósito',
    depositInformations: 'Información de Depósito',
    estimationBTC: 'Estimación de BTC:',
    estimationETH: 'Estimación de Ethereum:',
    estimationUSDT: 'Estimación de USDT:',
    estimationUSD: 'Estimación de Dólares:',
    pixLabelValue: 'Valor del Depósito',
    pixPlaceholderValue: 'Cantidad (U$)',
    pixlabelCPF: 'Documento',
    pixPlaceholderCPF: 'Ej.: 123.456.789-00',
    pixlabelEmail: 'Correo Electrónico',
    pixPlaceholderEmail: 'Ej.: liminha_limas@bol.com.br',
    warningValueInReal: 'Estás depositando en reales:',
    depositButtonRequest: 'Solicitar Depósito',
    pixDetailButton: 'Generar Código QR',
    pixKeyLabel: 'Copiar clave Pix',
    pixCancelButton: 'Cancelar',
    copyCodeSuccess: 'Código copiado al portapapeles',
    criptoLabelValue: 'Valor del Depósito',
    criptoPlaceholderValue: 'Cantidad (U$)',
    criptoDetailButton: 'Detalles de las Carteras',
    depositHistory: 'Historial de Depósitos',
    receptorName: 'Nombre del Receptor',
    cpfNumber: 'Nº de CPF',
    transactionType: 'Tipo',
    requestDate: 'Fecha de Solicitud',
    amount: 'Monto',
    successDeposit: '¡Depósito realizado con éxito!',
    errorDeposit: 'Error al realizar el depósito.',
    youDeposit: 'Estás realizando un depósito de: ',
    status: 'Estado',
    wallet: 'Número de Cartera:',
    rate: 'Enviar siempre con tarifa prioritaria',
    blockchain: 'Tu depósito estará activo una vez que se alcancen las confirmaciones mínimas en la cadena de bloques',
    statusDeposit: {
      created: 'Creado',
      awaitingPayment: 'Esperando Pago',
      approved: 'Aprobado',
      voucher: 'Comprobante',
      refused: 'Rechazado',
      reversed: 'Anulado',
      expired: 'Expirado',
    },
  },
  sidebar: {
    matrix: 'Matriz',
    arbitrage: 'Arbitraje',
    deposit: 'Depósito',
    network: 'Red',
    withdraw: 'Retiro',
    extract: 'Extraer',
    wallet: 'Portafolio',
    security: 'security',
    profile: 'Perfil',
    logout: 'Salir',
    closeSidebar: 'Cerrar',
    automaticOperations: 'Operaciones automaticas',
    transactionAlertOne: 'Las transacciones se realizarán automáticamente en el período mínimo de ',
    transactionAlertTwo: ' horas, y habrá una tarifa administrativa del 20% sobre la transacción.',
    disableAutomatic: '¿Desea deshabilitar las operaciones automáticas?',
    disableNow: '¡Desactivar ahora!',
    activateNow: '¡Activar ahora!',
    cancel: 'Cancelar',
  },
  profile: {
    awaitWithdrawOne: 'Después de cambiar el plan de retiro, debe esperar ',
    awaitWithdrawTwo: ' días para un nuevo cambio.',
    placeholderName: 'Ej.: Juan',
    placeholderLastName: 'Ej.: Pérez',
    placeholderUser: 'Ej.: juan_usuario',
    placeholderEmail: 'Ej.: juan@gmail.com',
    placeholderPhone: 'Ej.: (11) 98783-4343',
    placeholderCpf: 'Ej.: 012.345.678-90',
    placeholderCnpj: 'Ej.: 01.234.567/8901-01',
    placeholderAddress: 'Ej.: Calle de los Alpes, 1322',
    placeholderBitcoinWallet: 'Ej.: 5x1HUXrCNLbtMDqcw6o5GNn4xqX',
    placeholderRandomKey: 'Ej.: 5x3123fsdvc1HUXr4xqX',
    myProfile: 'Mi Perfil',
    userInfo: 'Información del Usuario',
    pixInfo: 'Información de Pago Pix',
    btcInfo: 'Datos Bancarios',
    pixKey: 'Clave Pix',
    pixKeyType: 'Tipo de Clave Pix',
    save: 'Guardar',
    saveDataSucess: 'Tus datos se han guardado.',
    imgAltProfile: 'Imagen de perfil',
    email: 'Correo electrónico',
    bitcoinWallet: 'Billetera de Bitcoin',
    clickHere: 'Haz clic aquí para verificar',
    verifyEmail: 'Email no verificado',
    verifyPhone: 'Teléfono no verificado',
    cryptoType: 'Tipo de billetera de criptomonedas',
    walletEthereum: 'Billetera Ethereum',
    walletUstd: 'Billetera USDT',
    language: 'Idioma predeterminado',
    titleConfigWithdraw: 'Configuración de retiro',
    withdrawOptionWeekly: 'Semanal',
    withdrawOptionFortnightly: 'Quincenal',
    withdrawOptionMonthly: 'Mensual',
    fee: 'de Tarifa',
  },
  arbitrage: {
    automaticOperations: 'Operación Automática',
    textActiveOperations: 'Operaciones Automáticas Activas',
    BuyAndSellOffers: 'Ofertas de compra y venta',
    bestBuyPrice: 'Mejor precio de compra',
    bestSalePrice: 'Mejor precio de venta',
    purchaseOffers: 'Ofertas de compra',
    salesOffers: 'Ofertas de venta',
    updatingNewOffers: 'Actualizando nuevas ofertas',
    youWillBuyFrom: 'Comprarás de',
    youWillSellTo: 'Venderás a',
    selectBrokerTheSalesOrder: 'Selecciona un bróker en la sección de orden de venta',
    selectBrokerThepurchaseOrder: 'Selecciona un bróker en la sección de orden de compra',
    buyNow: '¡Comprar ahora!',
    yourRecentOperations: 'Tus operaciones recientes',
    broker: 'Bróker',
    purchasePrice: 'Precio de compra',
    offerPrice: 'Precio de oferta',
    percentage: 'Porcentaje',
    purchaseSaleDate: 'Fecha de compra/venta',
    amountsent: 'Cantidad enviada',
    transaction: 'Transacción',
    status: 'Estado',
    lastNegotiations: 'Últimas negociaciones',
    Price: 'Precio (USDT)',
    Amount: 'Cantidad (BTC)',
    Hour: 'Hora',
    total: 'Total',
    orderBook: 'Libro de órdenes',
    robotMessage: 'El robot tiene un margen de error de +/- 1% en el valor de arbitraje.',
    notRecordsFound: 'No se encontraron registros.',
    valueMinArbitrage: 'El valor mínimo de arbitraje: ',
    refund: 'Reembolso de la Operación',
    profit: 'Beneficio de la Operación',
    tax: 'Impuesto de la Operación',
  },
  status: {
    pending: 'Pendiente',
    approved: 'Aprobado',
    underAnalysis: 'En análisis',
    refused: 'Rechazado',
    awaitingPayment: 'Pendiente de pago',
    active: 'Activo',
    inactive: 'Inactivo',
    activeVoucher: 'Activo',
  },
  extract: {
    filters: 'Filtros',
    selectType: ':: Seleccione el Tipo ::',
    extract: 'Extraer',
    dataTransaction: 'Fecha de transacción',
    description: 'Descripción',
    type: 'Tipo',
    balanceBefore: 'Saldo Antes',
    value: 'Valor',
    balanceAfter: 'Saldo Después',
    noTransactionCell: 'No se encontraron transacciones',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha de finalización',
    filter: 'Consultar',
    optionPrimary: 'Seleccionar tipo',
    deposit: 'Depósito',
    withdraw: 'Retirar',
    affiliated: 'Asociado',
    arbitration: 'Arbitraje',
    P2P: 'P2P',
    matrixBonus: 'Bono Matriz',
    PL: 'PL',
    all: 'Todo',
    allRecords: 'Todos los Registros:',
    summation: 'Suma:',
    volume: 'Volumen',
  },
  withdraw: {
    withdrawTax: 'Tarifa de Retiro',
    networkUSDTWithdraw: 'La red para el retiro de USDT es: TRON',
    documentStatus: 'Se requiere verificación de documentos para realizar el retiro.',
    invalidWithdraw: 'Retiro no disponible',
    paymentMethodOff: 'Este método de pago está en proceso de actualización. Por favor, elija otro método de recepción.',
    blockedWithdraw: 'Retiro bloqueado. Por favor, póngase en contacto con el soporte.',
    maxWithdraw: 'Puede retirar hasta: ',
    notWithdraw: 'Hoy no es un día de retiro.',
    withdraw: 'Retirar',
    withdrawDescription: 'Seleccione el método de retiro',
    cryptocurrency: 'Criptomoneda',
    minimumValue: 'Retiro mínimo: ',
    withdrawImage: 'imagen de retiro',
    withdrawalInformation: 'Información de retiro',
    withdrawalHistory: 'Historial de retiros',
    failedWithdrawalHistory: 'Aún no tienes solicitudes de retiro',
    random: 'Aleatorio',
    withdrawalValue: 'Valor de retiro',
    withdrawalAmount: 'Monto para el retiro',
    conversionToUSD: 'Conversión a USD',
    keyType: 'Tipo de clave',
    enterTheKey: 'Ingrese la clave',
    sameOwner: '¿Misma cuenta del propietario?',
    period: '7 (siete) días',
    pleaseWait: 'Por favor espera ',
    forNextOperation: ' para la próxima operación. Este es el tiempo que tomará procesar tu retiro.',
    withdrawalRequest: 'Solicitud de retiro',
    resourceRelease: '* Liberación de recursos semanal',
    yourCryptocurrencyWallet: 'Tu billetera de criptomonedas',
    successMessageTitle: '¡Éxito!',
    successMessageText: 'Se ha realizado tu retiro.',
    deniedMessageTitle: 'Solicitud denegada',
    deniedMessageText: 'Saldo insuficiente',
    minimumValueError: 'Agrega un valor superior a ',
    nameValidation: 'Ingresa un nombre válido',
  },
  forgotPassword: {
    textForgotPassword:
      '*Para solicitar un cambio de contraseña, por favor ingrese su dirección de correo electrónico en el campo a continuación y haga clic en el botón "Enviar". Le enviaremos un correo electrónico con un enlace seguro para restablecer su contraseña.',
    passwordChange: 'Cambio de contraseña',
    textResetPassword:
      'Por favor, ingrese su nueva contraseña en los campos a continuación y confírmela para completar el cambio de contraseña.',
    newPassword: 'Nueva contraseña',
    confirmNewPassword: 'Confirmar nueva contraseña',
    successMessageTitle: '¡La contraseña se cambió correctamente!',
    invalidPassword: '¡Contraseña no válida!',
    invalidConfirmPassword: '¡Las contraseñas no coinciden!',
    invalidToken: 'Token no válido. ¡Inténtalo de nuevo más tarde!',
    successSendEmail: 'Email enviado con éxito',
    invalidEmail: 'Este no es un e-mail válido.',
    textForgotPasswordNew:
      'Para restablecer su contraseña, utilice un dispositivo que haya accedido recientemente a su cuenta de botfy.',
  },
  network: {
    affiliateNetwork: 'Red de Afiliados',
    direct: 'Directos:',
    indirect: 'Indirectos:',
    yourEntireNetwork: 'Toda tu red:',
    consult: 'Consultar',
    searchforLogin: 'Buscar un login o nombre...',
    toClean: 'Limpiar',
    openNetwork: 'Red',
    volumeSum: 'Volumen de red',
  },
  security: {
    security: 'Seguridad',
    emailPhoneText: 'Protege tus transacciones y tu cuenta',
    verify: 'Verificar',
    accessPassword: 'Contraseña de acceso',
    accessPasswordText: 'La contraseña de inicio de sesión se utiliza para acceder a tu cuenta.',
    change: 'Cambiar',
    phoneNumberVerification: 'Verificación de número de teléfono',
    emailVerification: 'Verificación de correo electrónico',
    verificationCode: 'Ingresa tu código de verificación',
    changeAccessPassword: 'Cambiar contraseña de acceso',
    currentPassword: 'Contraseña actual',
    confirmPassword: 'Confirmar contraseña',
    editPhone: 'Editar teléfono',
    newPhone: 'Nuevo teléfono',
    editEmail: 'Editar correo electrónico',
    newEmail: 'Nuevo correo electrónico',
    verifiedEmail: 'Correo electrónico verificado',
    verifiedPhone: 'Teléfono verificado',
    questionVerifyEmail: 'Para verificar tu correo electrónico',
    questionVerifyPhone: 'Para verificar tu teléfono: ',
    textVerify: 'pertenece a ti, haz clic en el botón a continuación para iniciar el proceso',
    resendToken: 'Reenviar token',
    documents: 'Documentos',
    PENDING: 'Pendiente',
    UNDER_ANALYSIS: 'En análisis',
    APPROVED: 'Aprobado',
    REFUSED: 'Rechazado',
    REVOKED: 'Revocado',
    verifiedDocuments: 'Documentos verificados',
    sendDocuments: 'Enviar documentos',
    frontDocuments: 'Frente del documento',
    backDocuments: 'Parte trasera del documento',
    selfieDocuments: 'Documento cerca del rostro',
    errorsDocuments: 'Error al enviar documentos.',
    successDocuments: '¡Documentos enviados con éxito!',
    permForm: 'Formatos permitidos:',
    maxTam: 'Tamaño máximo:',
    invalidExtension: 'Extensión inválida.',
  },
  p2p: {
    minimumAmountForOperation: 'El monto mínimo para realizar P2P es ',
    confirmPassword: 'Confirmación de contraseña',
    insertPassword: 'Ingrese su contraseña para verificación de seguridad.',
    dataTransfer: 'Datos de transferencia',
    emailRecipient: 'Correo electrónico del destinatario',
    search: 'Buscar',
    placeholderValue: 'Ej.: $100.00',
    valueTransfer: 'Valor en SKYT',
    transferSkyt: 'Vas a transferir en SKYT:',
    notFoundRecipient: 'Destinatario no encontrado',
    searchRecipient: 'Buscar al destinatario',
    TransactionSuccessful: 'Transacción realizada con éxito.',
    TransactionError: 'Error al realizar la transacción.',
    p2p: 'p2p',
    SkytBallance: 'SKYT disponible:',
    transferTax: 'Tarifa de transferencia',
    confirmTitle: '¿Estás seguro?',
    confirmDescription: 'Esta acción es irreversible. ¡Tu saldo se convertirá a SKYT!',
    invalidValue: 'Valor inválido.',
    errorConvert: 'Error al convertir el saldo a SKYT.',
    errorInsufficientBalance: 'Solicitud denegada!',
    sucessConvert: 'Saldo convertido con éxito.',
    convertSkyt: 'Convertidor de SKYT',
    available: 'Disponible',
    amount: 'Saldo',
    convert: 'Convertir',
  },
  wallet: {
    myWallet: 'Mi Billetera',
    deposit: 'Depósito',
    withdraw: 'Retirar',
    p2p: 'P2P',
    extract: 'Extracto',
    arbitrationPool: 'Pool de Arbitraje',
    earnings: 'Ganancias',
    skyt: 'SKYT',
    banking: 'Banca',
    recentHistory: 'Historial reciente',
  },
  careerPath: {
    careerPath: 'Plan de carrera',
    nextGraduation: 'Próxima graduación',
    nextGraduations: 'Próximas graduaciones',
    graduationsCheck: 'Graduaciones alcanzadas',
    graduationsProgress: 'Graduación en curso',
    hitGoal: 'Para alcanzar la meta, necesitas: ',
  },
  extractConstants: {
    skytExchange: 'Skyt Convertido',
    ballanceSkytConvert: 'Conversión de Saldo a Skyt',
    withdrawRefund: 'Reembolso de Retiro',
    refund: 'Inversión',
    skytDepositConvert: 'Conversión de SKYT a Saldo',
    skytDeposit: 'Depósito de SKYT',
    purchase: 'Compra',
    reverse: 'Reembolso',
    arbitration: 'Reembolso de arbitraje',
    voucherDeposit: 'Depósito de Voucher',
    withdrawFixedAmountTax: 'Impuesto de Retiro',
    arbitrationProfit: 'Ganancia de Arbitraje',
    networkCommission: 'Comisión de Red',
    residualCommission: 'Comisión Residual',
    withdrawRequest: 'Solicitud de Retiro',
    withdraw: 'Retiro',
    manualBonus: 'Bono Manual',
    deposit: 'Depósito',
    arbitrageRequest: 'Arbitraje',
    arbitrageProfitRequest: 'Retiro de Ganancia de Arbitraje',
    transferTaxP2p: 'Tarifa de Transferencia P2P',
    skytTransferReceive: 'Transferencia SKYT Recibida',
    skytTransferSender: 'Transferencia SKYT Enviada',
    unknown: 'Desconocido',
    arbitrationTax: 'Impuesto de arbitraje',
  },
  matrix: {
    bonus: 'Monto del Bono:',
    lastPayment: 'Último Pago:',
    details: 'Detalles',
    volPayment: 'Volumen Pagado',
    date: 'Fecha',
  },
}
