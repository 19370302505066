import { AiOutlineClose } from 'react-icons/ai'
import phoneImage from '@/assets/images/newPhone.png'
import emailImage from '@/assets/images/newEmail.png'
import { useTranslation } from 'react-i18next'
import { useFormEditPasswordPhoneEmail } from '../hooks/useFormEditPhoneEmail'
import { PhoneField, TextField } from '@/components/Form/Fields'

export const EditPhoneEmail = ({
  editModalPhoneEmail,
  handleEditPhoneEmail,
  isPhoneEmail,
  verificationEmailPhoneRequest,
  setEditModalPhoneEmail,
}) => {
  const { t } = useTranslation()
  const { values, handleChange, handleSubmit, resetForm, errors, setFieldValue } = useFormEditPasswordPhoneEmail({
    onSubmit: values => {
      verificationEmailPhoneRequest(
        isPhoneEmail,
        isPhoneEmail === 'phone' ? { newPhone: values.newPhone.replace(/[^0-9.]/g, '') } : { newEmail: values.newEmail }
      ).then(() => setEditModalPhoneEmail(false))
      resetForm(values)
    },
    isPhoneEmail,
  })
  return (
    editModalPhoneEmail && (
      <aside className='flex justify-center items-center fixed inset-0 z-20 backdrop-blur-md'>
        <div className='bg-gray border rounded w-[350px] shadow-sm h-fit'>
          <span className='flex justify-end p-2'>
            <AiOutlineClose
              className='h-4 w-4 cursor-pointer'
              onClick={() => {
                resetForm()
                handleEditPhoneEmail()
              }}
            />
          </span>
          <form onSubmit={handleSubmit} className='p-4 flex flex-col justify-center items-center gap-5 '>
            <div className='flex flex-col justify-center items-center gap-3 w-full'>
              {isPhoneEmail === 'phone' ? (
                <>
                  <div className='flex flex-col justify-center items-center gap-3 mb-3'>
                    <img src={phoneImage} width={70} alt={t('global.phone')} />
                    <h3 className='text-center text-lg'>{t('security.editPhone')}</h3>
                  </div>
                  <div className='w-full'>
                    <PhoneField
                      label={t('security.newPhone')}
                      name='newPhone'
                      placeholder={t('profile.placeholderPhone')}
                      value={values.newPhone}
                      onChange={e => setFieldValue('newPhone', e)}
                      errorMessage={errors.newPhone}
                      background
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className='flex flex-col justify-center items-center gap-3 mb-3'>
                    <img src={emailImage} width={70} alt={t('global.email')} />
                    <h1 className='text-center text-lg'>{t('security.editEmail')}</h1>
                  </div>
                  <div className='w-full'>
                    <TextField
                      className='w-full'
                      label={t('security.newEmail')}
                      name='newEmail'
                      placeholder={t('profile.placeholderEmail')}
                      value={values.newEmail}
                      onChange={handleChange}
                      errorMessage={errors.newEmail}
                      background
                    />
                  </div>
                </>
              )}
            </div>

            <div className='grid grid-cols-2 justify-center gap-3'>
              <button
                type='button'
                className='rounded-full text-center bg-gray-400 p-2 px-4 text-sm md:text-base hover:bg-gray-500 transition duration-200'
                onClick={() => {
                  resetForm()
                  handleEditPhoneEmail()
                }}
              >
                {t('global.cancel')}
              </button>
              <button
                type='submit'
                className='rounded-full bg-primary-400 p-2 text-sm  md:text-base font-bold hover:bg-primary transition duration-200 '
              >
                {t('security.change')}
              </button>
            </div>
          </form>
        </div>
      </aside>
    )
  )
}
