import { Loader } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { CpfCnpjField, TextField } from '@/components/Form/Fields'
import { useAuth } from '@/contexts'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { SelectField } from './SelectFieldValue'

export const PixForm = ({
  setSelectedValue,
  selectedValue,
  cpf,
  setCpf,
  email,
  setEmail,
  loadingDeposit,
  depositRequest,
  isForm,
  dollarValue,
}) => {
  const { t } = useTranslation()
  const { user } = useAuth()

  const handleSelectChange = value => {
    setSelectedValue(value)
  }

  const handleSubmitPix = () => {
    const clearCpf = String(cpf).replace(/[^0-9]/g, '')
    if (clearCpf.length !== 11) return toast.error(t('global.validation.validationCpf'))
    if (!cpf || !email || !selectedValue) return toast.error(t('global.errorVerification'))
    if (!email.match(/^[\w\W.-]+@[\w.-]+\.\w+$/, email)) return toast.error(t('signIn.invalidEmail'))
    window.localStorage.setItem('valueDeposit', selectedValue * dollarValue)
    window.localStorage.setItem('selectedValue', selectedValue)
    window.localStorage.setItem('deposit:type', 1)
    depositRequest({
      amount: Number(selectedValue),
      type: 1,
      pix: {
        cpf: clearCpf,
        email,
        name: user.fullName,
      },
    })
  }

  return (
    <>
      <div className='flex flex-col justify-center items-center gap-3'>
        <SelectField isForm={isForm} value={selectedValue} onChange={handleSelectChange} />
      </div>
      <CpfCnpjField
        value={cpf}
        onChange={e => setCpf(e.target.value)}
        label={t('global.cpf')}
        placeholder={t('deposit.pixPlaceholderCPF')}
      />
      <TextField
        value={email}
        onChange={e => setEmail(e.target.value)}
        label={t('deposit.pixlabelEmail')}
        placeholder={t('deposit.pixPlaceholderEmail')}
      />

      <div className='flex flex-col gap-2 px-4 py-3 my-4 justify-center items-center bg-gray-400 text-white rounded-2'>
        <p>{t('deposit.warningValueInReal')}</p>
        <span className='font-extrabold'>
          <MoneyAsText value={Number(selectedValue) * dollarValue} prefix='R$ ' />{' '}
        </span>
      </div>
      {/* <div className='flex flex-col gap-3 lg:grid lg:grid-cols-2 lg:gap-3'> */}
      {/* <button type='submit' className='bg-primary-100 text-white font-bold p-4 w-full rounded-full lg:font-normal lg:p-3'>
          {t('deposit.depositButtonRequest')}
        </button> */}
      <button
        type='button'
        className='bg-primary-300 text-white font-bold p-4 w-full text-center rounded-3 lg:font-normal lg:p-3'
        onClick={() => handleSubmitPix()}
        disabled={loadingDeposit}
      >
        {loadingDeposit ? <Loader className='inline-block' /> : t('deposit.pixDetailButton')}
      </button>
      {/* </div> */}
    </>
  )
}
