export const OpeningVariant1 = {
  hidden: {
    opacity: 0,
    x: '-50%',
  },
  visible: {
    opacity: 1,
    x: '0%',
    transition: {
      type: 'spring',
      mass: 0.85,
      delay: 0.15,
    },
  },
}

export const OpeningVariant2 = {
  hidden: {
    opacity: 0,
    x: '50%',
  },
  visible: {
    opacity: 1,
    x: '0%',
    transition: {
      type: 'spring',
      mass: 1,
    },
  },
}

export const TitleVariant = {
  hidden: {
    opacity: 0,
    scale: 0.45,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      mass: 0.75,
    },
  },
}

export const CardsVariant = {
  visible: {
    opacity: 1,
    transition: {
      when: 'beforeChildren',
      staggerChildren: 0.25,
      delay: 0.15,
    },
  },
  hidden: {
    opacity: 0.5,
    transition: {
      when: 'afterChildren',
    },
  },
}

export const CardsItemVariant = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      mass: 0.45,
    },
  },
  hidden: {
    opacity: 0,
    scale: 0.65,
  },
}

export const TableVariant = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      mass: 0.45,
      delay: 0.25,
    },
  },
  hidden: {
    opacity: 0,
    scale: 0.5,
  },
}
