import React from 'react'
import { NumericFormat } from 'react-number-format'
import classNames from 'classnames'
import { InputText } from '@/components/Form/InputText'
import { twMerge } from 'tailwind-merge'

export const MoneyAsInput = ({
  name,
  value,
  onChange,
  isInvalid = false,
  precision = 2,
  currencyCode = 'USD',
  textWhite,
  ...rest
}) => {
  const { className, suffix = '', placeholder, allowNegative = false, isDisabled = false } = rest

  let prefix = 'R$ '
  let decimalSeparator = ','
  let thousandSeparator = '.'

  if (['USD', 'EUR'].includes(currencyCode)) {
    decimalSeparator = '.'
    thousandSeparator = ','
  }

  if (currencyCode === 'USD') prefix = '$ '
  if (currencyCode === 'EUR') prefix = '€ '
  // if (currencyCode === 'SKYT') prefix = ' '

  return (
    <NumericFormat
      isInvalid={isInvalid}
      allowNegative={allowNegative}
      placeholder={placeholder}
      thousandsGroupStyle='thousand'
      decimalScale={precision}
      onClick={e => e.target.select()}
      fixedDecimalScale={true}
      value={value}
      displayType='input'
      name={name}
      disabled={isDisabled}
      onValueChange={({ floatValue }, sourceInfo) => {
        onChange({ ...sourceInfo.event, numberValue: floatValue })
      }}
      customInput={InputText}
      decimalSeparator={decimalSeparator}
      thousandSeparator={thousandSeparator}
      prefix={prefix}
      suffix={suffix}
      className={twMerge(
        classNames(className, {
          'text-negative': value < 0,
          'text-white': textWhite,
        })
      )}
      textWhite={textWhite}
    />
  )
}
