import { Loading } from '@/components/Common'
import { Button, CheckBox } from '@/components/Form'
import { useAuth } from '@/contexts'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

export const Information = () => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { t } = useTranslation()
  const [mark, setMark] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const dataAtual = new Date()
    const dataAlvo = new Date('2023-09-21T18:00:00')
    if (dataAtual > dataAlvo || !user.userActive || !user.automaticOperations) {
      window.localStorage.removeItem('information')
      navigate('/arbitrage')
    }
    setLoading(false)
  }, [])

  if (loading) return <Loading />

  return (
    <main className='grid-cols-1 lg:grid-cols-2 grid h-[calc(100vh-200px)] xl:px-32'>
      <div className='text-white px-3 flex justify-center items-center'>
        <div className='flex flex-col gap-2 lg:max-w-[600px]'>
          <h2 className='text-2xl text-center mb-2 mt-5 font-semibold'>{t('global.information.title')}</h2>
          <p className='text-base lg:text-[16px] font-light leading-relaxed'>• {t('global.information.text1')}</p>
          <p className='text-base lg:text-[16px] font-light leading-relaxed'>• {t('global.information.text2')}</p>
          <p className='text-base lg:text-[16px] font-light leading-relaxed'>• {t('global.information.text3')}</p>
          <p className='text-base lg:text-[16px] font-light leading-relaxed'>• {t('global.information.text4')}</p>
          <p className='text-sm my-2 opacity-80'>{t('global.information.att')}</p>

          <CheckBox label={t('global.information.checkbox')} checked={mark} onChange={() => setMark(!mark)} />

          <Button
            className='rounded-md w-full mt-2 mb-5'
            text={t('deposit.continue')}
            onClick={() => {
              window.localStorage.removeItem('information')
              navigate('/arbitrage', { replace: true })
            }}
            isDisabled={!mark}
          />
        </div>
      </div>
      <div className='h-full w-full hidden lg:flex justify-center items-center'>
        <img src='/images/atention.png' className='object-cover' />
      </div>
    </main>
  )
}
