import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useNetwork = () => {
  const { data, loading, makeRequest, error } = useApi(params => {
    const { route, method } = getEndpoint('fetchNetwork')
    return httpClient[method](`${route}?sort=-createdAt`, { ...params })
  })

  return {
    dataNetwork: data,
    loadingNetwork: loading,
    errorNetwork: error,
    networkRequest: makeRequest,
  }
}
