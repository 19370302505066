import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useFetchWithdrawHistory = () => {
  const { data, error, makeRequest, loading } = useApi(params => {
    const { route, method } = getEndpoint('withdrawHistory')
    return httpClient[method](route, { ...params })
  })

  return {
    dataHistory: data || [],
    errorHistory: error,
    loadingHistory: loading,
    withdrawHistory: makeRequest,
  }
}
