import { useAuth, useBalance } from '@/contexts'
import { useFetchCrypto } from '@/pages/Home/hooks/useFetchCrypto'
import { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useBuyOffers, useFetchBuySellOffersffers, useFetchOperationsArbitration } from '../hooks'

const ArbitrageContext = createContext({
  purchase: [],
  sales: [],
  valueInput: 0,
  counter: undefined,
  selectSell: undefined,
  selectBuy: undefined,
  dataBuySellOffers: [],
  dataOperationsArbitration: [],
  cachedData: [],
  loadingOperationsArbitration: false,
  loading: false,
  buttonDisabled: false,
  cryptos: [],
  loadingCryptos: false,
  countdownText: '',
  loadingUseBuyOffers: false,
  markOfferPurchase: () => {},
  markOfferSales: () => {},
  handleBuySellOffer: () => {},
  setValueInput: () => {},
  fetchCryptos: () => {},
})

export const ArbitrageProvider = ({ children }) => {
  const [purchase, setPurchase] = useState([])
  const { t } = useTranslation()
  const { user } = useAuth()
  const [sales, setSales] = useState([])
  const [valueInput, setValueInput] = useState(0)
  const [counter, setCounter] = useState()
  const [loading, setLoading] = useState(false)
  const [selectSell, setSelectSell] = useState()
  const [selectBuy, setSelectBuy] = useState()
  const [cachedData, setCachedData] = useState([])
  const time = 20
  const { refreshBalance } = useBalance()
  const { buySellOffersRequest, dataBuySellOffers } = useFetchBuySellOffersffers()
  const { cryptos, fetchCryptos, loadingCryptos } = useFetchCrypto()
  const { dataOperationsArbitration, loadingOperationsArbitration, operationsArbitrationRequest } =
    useFetchOperationsArbitration()
  const { dataUseBuyOffers, errorUseBuyOffers, loadingUseBuyOffers, useBuyOffersRequest, metaUseBuyOffers } = useBuyOffers()

  useEffect(() => {
    if (counter === undefined) return
    if (counter === 0) {
      setCounter(time)
      buySellOffersRequest()
      return
    }
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
  }, [counter])

  useEffect(() => {
    if (!dataUseBuyOffers) return
    toast.success(t('arbitrage.successArbitrage'))
    setValueInput(0)
    setLoading(true)
    setTimeout(() => {
      operationsArbitrationRequest().then(() => setLoading(false))
    }, 5000)
    refreshBalance()
  }, [dataUseBuyOffers])

  useEffect(() => {
    buySellOffersRequest()
    operationsArbitrationRequest()
    const cachedValue = localStorage.getItem('cachedData')
    if (cachedValue) {
      const { data, expiration } = JSON.parse(cachedValue)
      if (Date.now() < expiration) {
        setCachedData(data)
      } else {
        localStorage.removeItem('cachedData')
        fetchCryptos()
      }
      return
    }
    fetchCryptos()
  }, [])

  useEffect(() => {
    if (!errorUseBuyOffers) return
    if (errorUseBuyOffers.error === 'insufficientBalance') {
      toast.error(t('withdraw.deniedMessageText'))
      return
    }
    if (errorUseBuyOffers.error === 'customerIsNotAbleToCreateOrder') {
      toast.error(t('arbitrage.textCustomerNotAble'))
      return
    }
    if (errorUseBuyOffers.error === 'arbitrationMinimumAmount') {
      toast.error(`${t('arbitrage.valueMinArbitrage')} ${metaUseBuyOffers.message}`)
      return
    }
  }, [errorUseBuyOffers])

  useEffect(() => {
    if (!dataBuySellOffers.buyOffers) return

    const copyPurchase = dataBuySellOffers.buyOffers.map(item => {
      if (dataBuySellOffers.bestBuyOffer.broker.id === item.broker.id) return { ...item, bestBuy: true, checked: false }
      return { ...item, checked: false }
    })
    const copySales = dataBuySellOffers.sellOffers.map(item => {
      if (dataBuySellOffers.bestSaleOffer.broker.id === item.broker.id) return { ...item, bestSell: true, checked: false }
      return { ...item, checked: false }
    })
    setSales(copySales)
    setPurchase(copyPurchase)
    setCounter(time)
    setSelectBuy()
    setSelectSell()
  }, [dataBuySellOffers])

  //Functions

  const markOfferPurchase = (item, index) => {
    const markPurchase = purchase.map((item, i) => {
      if (i === index) return { ...item, checked: true }
      return { ...item, checked: false }
    })
    setPurchase(markPurchase)
    setSelectBuy(item)
  }

  const markOfferSales = (item, index) => {
    const markSales = sales.map((item, i) => {
      if (i === index) return { ...item, checked: true }
      return { ...item, checked: false }
    })
    setSales(markSales)
    setSelectSell(item)
  }

  const handleBuySellOffer = () => {
    if (!selectBuy && !selectSell) {
      toast.error(t('arbitrage.selectBrokerThepurchaseSalesOrder'))
      return
    }
    if (!selectBuy) {
      toast.error(t('arbitrage.selectBrokerThepurchaseOrder'))
      return
    }
    if (!selectSell) {
      toast.error(t('arbitrage.selectBrokerTheSalesOrder'))
      return
    }
    if (!valueInput || valueInput <= 0) {
      toast.error(t('arbitrage.validValue'))
      return
    }
    const params = {
      amount: valueInput,
      sellHash: selectSell.hash,
      buyHash: selectBuy.hash,
      btcUsdPrice: dataBuySellOffers.btdUsdTicker,
    }
    setSelectBuy()
    setSelectSell()
    const markSales = sales.map(item => {
      return { ...item, checked: false }
    })
    setSales(markSales)
    const markPurchase = purchase.map(item => {
      return { ...item, checked: false }
    })
    setPurchase(markPurchase)

    useBuyOffersRequest(params)
  }

  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [countdownText, setCountdownText] = useState('')

  useEffect(() => {
    const dataTimer =
      dataOperationsArbitration.length > 0 &&
      dataOperationsArbitration.reduce((mostRecent, currentItem) => {
        return new Date(currentItem.createdAt) > new Date(mostRecent.createdAt) ? currentItem : mostRecent
      })
    if (!dataTimer) return
    const createdAtDate = new Date(dataTimer?.createdAt)
    const targetTime = new Date(createdAtDate)
    const hours = user.settings.ARBITRATION_DEFAULT_WAITING_TIME.split(':')[0]
    const minutes = user.settings.ARBITRATION_DEFAULT_WAITING_TIME.split(':')[1] ?? 0
    targetTime.setHours(createdAtDate.getHours() + Number(hours))
    targetTime.setMinutes(createdAtDate.getMinutes() + Number(minutes))

    const interval = setInterval(() => {
      const currentTime = new Date()
      if (currentTime >= targetTime) {
        setButtonDisabled(false)
        setCountdownText('')
      } else {
        const timeDiff = new Date(targetTime - currentTime)
        const hours = timeDiff.getUTCHours()
        const minutes = timeDiff.getUTCMinutes()
        const seconds = timeDiff.getUTCSeconds()
        setButtonDisabled(true)
        setCountdownText(`${t('global.await')} ${hours}h ${minutes}m ${seconds}s`)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [dataOperationsArbitration])

  useEffect(() => {
    if (cryptos.length !== 0 && JSON.stringify(cryptos) !== JSON.stringify(cachedData)) {
      setCachedData(cryptos)
    }
  }, [cryptos])

  useEffect(() => {
    if (cachedData.length !== 0 && cryptos.length !== 0) {
      const expiration = Date.now() + 5 * 60 * 1000
      localStorage.setItem('cachedData', JSON.stringify({ data: cryptos, expiration }))
    }
  }, [cachedData])

  return (
    <ArbitrageContext.Provider
      value={{
        purchase,
        sales,
        valueInput,
        counter,
        selectSell,
        selectBuy,
        dataBuySellOffers,
        dataOperationsArbitration,
        loadingOperationsArbitration,
        loadingUseBuyOffers,
        loading,
        buttonDisabled,
        countdownText,
        cryptos,
        cachedData,
        loadingCryptos,
        fetchCryptos,
        markOfferPurchase,
        markOfferSales,
        handleBuySellOffer,
        setValueInput,
      }}
    >
      {children}
    </ArbitrageContext.Provider>
  )
}

export const useArbitrage = () => {
  return { ...useContext(ArbitrageContext) }
}
