import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export function useCreateCustomer() {
  const { route, method } = getEndpoint('sign-up')
  const { data, error, loading, makeRequest } = useApi(values => httpClient[method](route, values))

  return {
    data,
    error,
    loading,
    createCustomer: makeRequest,
  }
}
