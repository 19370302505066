import { DateAsText } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { getTransactionsTypeLabel } from '@/pages/Extract/constants'
import { useTranslation } from 'react-i18next'
import { FiAlertCircle } from 'react-icons/fi'
import { MdAttachMoney } from 'react-icons/md'
import { getIsSkytSuffix } from '../../Extract/constants'
import classNames from 'classnames'

export const ExtractResume = ({ data }) => {
  const { t } = useTranslation()

  if (data.length === 0)
    return (
      <div className='flex text-sm my-3 gap-1 justify-center'>
        <FiAlertCircle className='mt-[2px]' />
        <p>{t('arbitrage.notRecordsFound')}</p>
      </div>
    )

  return data.map((item, index) => (
    <section className='px-2' key={index}>
      <hr />
      <div className='flex justify-between my-2'>
        <div className='flex gap-3'>
          {item.value < 0 ? (
            <MdAttachMoney color='red' className=' hidden md:flex h-5 w-5' />
          ) : (
            <MdAttachMoney color='green' className=' hidden md:flex h-5 w-5' />
          )}
          <div>
            <h3 className='font-bold'>{getTransactionsTypeLabel(item.type)}</h3>
            <p className='text-sm font-semibold text-gray-600'>
              <DateAsText type='datetime' date={item.createdAt} />
            </p>
          </div>
        </div>
        <div
          className={classNames('flex justify-end col-span-2 break-all font-bold whitespace-nowrap', {
            'text-positive': item.amount > 0,
            'text-negative': item.amount < 0,
          })}
        >
          <MoneyAsText
            positive
            value={item.amount}
            prefix={getIsSkytSuffix(item.type) ? ' ' : '$ '}
            suffix={getIsSkytSuffix(item.type) ? ' SKYT' : ''}
          />
        </div>
      </div>
    </section>
  ))
}
