import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useFetchDepositHistory = () => {
  const { data, loading, makeRequest, error } = useApi(() => {
    const { route, method } = getEndpoint('depositHistory')
    return httpClient[method](route)
  })

  return {
    dataDepositHistory: data || [],
    loadingDepositHistory: loading,
    errorDepositHistory: error,
    depositHistoryRequest: makeRequest,
  }
}
