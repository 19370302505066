import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useOperationsAutomatic = () => {
  const { data, loading, makeRequest, error, meta } = useApi(() => {
    const { route, method } = getEndpoint('operationsAutomatic')
    return httpClient[method](route)
  })

  return {
    dataOperationsAutomatic: data,
    loadingOperationsAutomatic: loading,
    errorOperationsAutomatic: error,
    operationsAutomaticRequest: makeRequest,
    metaOperationsAutomatic: meta,
  }
}
