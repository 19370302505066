import { useFormik } from 'formik'

export const useFormCommissionFilter = ({ onSubmit }) => {
  return useFormik({
    initialValues: {
      createdAt: '',
      endDate: '',
      type: '',
    },
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
