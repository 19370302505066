import { useState } from 'react'

export const useLastTrades = () => {
  const [lastTradesData, setLastTradesData] = useState([])
  const [loadingLastTrades, setLoadingLastTrades] = useState(false)
  let wsChannelId = null

  const convertRecord = record => {
    const [orderId, timestamp, amount, price] = record
    const orderDate = new Date(timestamp)
    return {
      orderId,
      type: amount < 0 ? 'sell' : 'buy',
      time: `${orderDate.getHours()}:${orderDate.getMinutes()}:${
        orderDate.getSeconds() < 10 ? `0${orderDate.getSeconds()}` : orderDate.getSeconds()
      }`,
      amount,
      price,
    }
  }

  const consumeLastTradesWebSocket = message => {
    if (message.event && message.event === 'subscribed' && message.channel === 'trades') {
      wsChannelId = message.chanId
      return
    }

    if (wsChannelId !== message[0]) return
    if (message[1] === 'hb') return

    // snapshot
    if (Array.isArray(message[1])) {
      const snapshot = []
      for (let trade of message[1]) {
        snapshot.push(convertRecord(trade))
      }
      setLastTradesData(snapshot)
    }

    // te -> trade executed
    if (message[1] === 'te') {
      setLastTradesData(prevState => [convertRecord(message[2]), ...prevState].slice(0, 30))
    }
  }

  return {
    lastTradesData,
    loadingLastTrades,
    setLoadingLastTrades,
    consumeLastTradesWebSocket,
  }
}
