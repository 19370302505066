import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/contexts'

export const useFormProfile = ({ onSubmit }) => {
  const { user } = useAuth()
  const { t } = useTranslation()
  const Schema = Yup.object({
    phone: Yup.string().nullable(),
    cpfCnpj: Yup.string().nullable(),
    nickname: Yup.string().required(t('global.validation.required')),
    email: Yup.string().nullable(),
    pixKeyType: Yup.string().nullable(),
    pixKey: Yup.string()
      .when('pixKeyType', {
        is: value => value == 5,
        then: Yup.string().email(t('signIn.invalidEmail')).required(t('global.validation.required')),
        otherwise: Yup.string().nullable(),
      })
      .when('pixKeyType', {
        is: value => value == 2,
        then: Yup.string()
          .test('lengthMinCpf', t('global.validation.validationCpf'), value => {
            const clearValue = String(value).replace(/[^0-9]/g, '')
            return clearValue.length === 11 || clearValue.length === 0
          })
          .required(t('global.validation.required'))
          .nullable(t('global.validation.required')),
        otherwise: Yup.string().nullable(),
      })
      .when('pixKeyType', {
        is: value => value == 3,
        then: Yup.string()
          .test('lengthMinCnpj', t('global.validation.validationCpnjLength'), value => {
            const clearValue = String(value).replace(/[^0-9]/g, '')
            return clearValue.length === 14 || clearValue.length === 0
          })
          .required(t('global.validation.required'))
          .nullable(t('global.validation.required')),
        otherwise: Yup.string().nullable(),
      })
      .when('pixKeyType', {
        is: value => value == 4 || value == 6,
        then: Yup.string().nullable(t('global.validation.required')).required(t('global.validation.required')),
        otherwise: Yup.string().nullable(),
      }),
    walletBitcoin: Yup.string().nullable(),
    firstName: Yup.string().required(t('global.validation.required')),
    lastName: Yup.string().required(t('global.validation.required')),
    cryptoType: Yup.string().nullable(),
    walletEthereum: Yup.string().nullable(),
    walletUstd: Yup.string().nullable(),
    configWithdraw: Yup.string().nullable(),
    language: Yup.string().nullable(t('global.validation.required')).required(t('global.validation.required')),
  })

  return useFormik({
    initialValues: {
      phone: '',
      cpfCnpj: '',
      nickname: '',
      email: '',
      pixKey: '',
      pixKeyType: '',
      walletBitcoin: '',
      firstName: '',
      lastName: '',
      cryptoType: '1',
      walletEthereum: '',
      walletUstd: '',
      configWithdraw: '',
      language: user.language,
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
