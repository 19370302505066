import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useDeposit = () => {
  const { data, loading, makeRequest, error } = useApi(params => {
    const { route, method } = getEndpoint('deposit')
    return httpClient[method](route, { ...params })
  })

  return {
    dataDeposit: data || [],
    loadingDeposit: loading,
    errorDeposit: error,
    depositRequest: makeRequest,
  }
}
