import React from 'react'
import { CpfCnpjField, PhoneField, SelectField, TextField } from '@/components/Form/Fields'
import { useTranslation } from 'react-i18next'
// import { ConfigWithdraw } from '.'

export const WalletFormProfile = ({
  handleChange,
  errors,
  values,
  loadingCustomer,
  optionsPix,
  setFieldValue,
  setFieldError,
}) => {
  const { t } = useTranslation()
  return (
    <section className='w-full'>
      <div className='flex flex-col'>
        <SelectField
          label={t('profile.pixKeyType')}
          name='pixKeyType'
          errorMessage={errors.pixKeyType}
          value={values.pixKeyType}
          options={optionsPix}
          onChange={e => {
            setFieldValue('pixKey', '')
            setFieldError('pixKey', '')
            handleChange(e)
          }}
          isDisabled={loadingCustomer}
        />
        {(!values.pixKeyType || values.pixKeyType == 6 || values.pixKeyType == 5) && (
          <TextField
            name='pixKey'
            label='Chave Pix'
            placeholder={values.pixKeyType == 5 ? t('profile.placeholderEmail') : t('profile.placeholderRandomKey')}
            isDisabled={loadingCustomer}
            value={values.pixKey}
            onChange={handleChange}
            errorMessage={errors.pixKey}
          />
        )}
        {values.pixKeyType == 2 && (
          <CpfCnpjField
            name='pixKey'
            label='Chave Pix'
            format='cpf'
            placeholder={t('profile.placeholderCpf')}
            isDisabled={loadingCustomer}
            value={values.pixKey}
            onChange={handleChange}
            errorMessage={errors.pixKey}
          />
        )}
        {values.pixKeyType == 3 && (
          <CpfCnpjField
            name='pixKey'
            label='Chave Pix'
            format='cnpj'
            placeholder={t('profile.placeholderCnpj')}
            isDisabled={loadingCustomer}
            value={values.pixKey}
            onChange={handleChange}
            errorMessage={errors.pixKey}
          />
        )}
        {values.pixKeyType == 4 && (
          <PhoneField
            name='pixKey'
            label='Chave Pix'
            placeholder={t('profile.placeholderPhone')}
            isDisabled={loadingCustomer}
            value={values.pixKey}
            onChange={e => setFieldValue('pixKey', e)}
            errorMessage={errors.pixKey}
          />
        )}
        <TextField
          type='text'
          label={t('profile.bitcoinWallet')}
          name='walletBitcoin'
          errorMessage={errors.walletBitcoin}
          onChange={handleChange}
          value={values.walletBitcoin}
          isDisabled={loadingCustomer}
          placeholder={t('profile.placeholderBitcoinWallet')}
          className='w-full rounded'
        />
        <TextField
          type='text'
          label={t('profile.walletEthereum')}
          name='walletEthereum'
          errorMessage={errors.walletEthereum}
          onChange={handleChange}
          value={values.walletEthereum}
          isDisabled={loadingCustomer}
          placeholder={t('profile.placeholderBitcoinWallet')}
          className='w-full rounded'
        />

        <TextField
          type='text'
          label={t('profile.walletUstd')}
          name='walletUstd'
          errorMessage={errors.walletUstd}
          onChange={handleChange}
          value={values.walletUstd}
          isDisabled={loadingCustomer}
          placeholder={t('profile.placeholderBitcoinWallet')}
          className='w-full rounded'
        />
        {/* <ConfigWithdraw
          errorMessage={errors.configWithdraw}
          handleChange={e => setFieldValue('configWithdraw', e.target.value)}
          isDisabled={loadingCustomer}
          name='configWithdraw'
          value={values.configWithdraw}
        /> */}
      </div>
    </section>
  )
}
