import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

export const InputText = ({ name, value, onChange, type, className, isDisabled, isInvalid, themeDark, textWhite, ...rest }) => {
  const inputClassName = classNames(
    'w-full block p-2 border rounded-md text-base outline-none transition ease-in-out duration-300 delay-100 focus:border-gray-300 placeholder:text-gray-200',
    className,
    {
      ' text-gray-700 border-gray-100': !isInvalid,
      'text-negative border-negative': isInvalid,
      'text-gray-400 opacity-50': isDisabled,
      'bg-[#122C52] text-white border-none placeholder:text-gray-400': themeDark,
      'text-white placeholder:text-gray-400': textWhite,
    }
  )
  return (
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      disabled={isDisabled}
      className={twMerge(inputClassName)}
      themeDark={themeDark}
      {...rest}
    />
  )
}

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  themeDark: PropTypes.bool,
}

InputText.defaultProps = {
  type: 'text',
  isDisabled: false,
  isInvalid: false,
  themeDark: false,
}
