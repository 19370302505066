import { Badge, DateAsText, Loader } from '@/components/Common'
import { TypeLabel } from '.'
import { twMerge } from 'tailwind-merge'
import classNames from 'classnames'
import MoneyAsText from '@/components/Common/MoneyAsText'
import {
  BsCashCoin,
  BsFillCalendarWeekFill,
  BsFillEnvelopeFill,
  BsFillPiggyBankFill,
  BsGraphUpArrow,
  // BsPeopleFill,
} from 'react-icons/bs'
// import { RiMoneyDollarCircleFill } from 'react-icons/ri'
import {
  ARBITRATION,
  ARBITRATION_REQUEST,
  ARBITRATION_TAX,
  // BONUS_MATRIX,
  DEPOSIT,
  MANUAL_REFUND,
  PURCHASE,
  REVERSE,
  // SKYT_DEPOSIT,
  // SKYT_DEPOSIT_CONVERT,
  // SKYT_TRANSFER_RECEIVE,
  // SKYT_TRANSFER_SENDER,
  TRANSFER_TAX_P2P,
  VOUCHER_DEPOSIT,
  WITHDRAW,
  WITHDRAW_FIXED_AMOUNT_TAX,
  WITHDRAW_REFUND,
  WITHDRAW_REQUEST,
  getIsSkytSuffix,
} from '../constants'
import { FaCashRegister, FaMoneyBillWave } from 'react-icons/fa'
import { MdOutlineAttachMoney } from 'react-icons/md'
import { BiMoneyWithdraw } from 'react-icons/bi'
import { FiAlertCircle } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

export const TransactionList = ({ transactions, loading }) => {
  const { t } = useTranslation()

  const getTransactionIcon = type => {
    switch (type) {
      // case SKYT_DEPOSIT_CONVERT:
      // case SKYT_DEPOSIT:
      // case SKYT_TRANSFER_SENDER:
      // case SKYT_TRANSFER_RECEIVE:
      //   return <RiMoneyDollarCircleFill />
      case ARBITRATION:
      case ARBITRATION_REQUEST:
        return <BsGraphUpArrow />
      case WITHDRAW_FIXED_AMOUNT_TAX:
      case TRANSFER_TAX_P2P:
      case ARBITRATION_TAX:
        return <MdOutlineAttachMoney />
      case DEPOSIT:
        return <BsFillPiggyBankFill />
      case PURCHASE:
        return <BsCashCoin />
      case REVERSE:
      case WITHDRAW_REFUND:
      case MANUAL_REFUND:
        return <FaCashRegister />
      // case BONUS_MATRIX:
      //   return <BsPeopleFill />
      case WITHDRAW:
      case WITHDRAW_REQUEST:
        return <BiMoneyWithdraw />
      case VOUCHER_DEPOSIT:
        return <BsFillEnvelopeFill />
      default:
        return <FaMoneyBillWave />
    }
  }

  const groupTransactionsByDay = () => {
    const groupedTransactions = {}
    transactions.map(transaction => {
      const date = transaction.createdAt.split(' ')[0]
      if (!groupedTransactions[date]) {
        groupedTransactions[date] = []
      }
      groupedTransactions[date].push(transaction)
    })
    return groupedTransactions
  }

  const groupedTransactions = groupTransactionsByDay()

  if (loading)
    return (
      <div className='flex border-none justify-center items-center mt-5 w-full'>
        <Loader />
      </div>
    )

  return (
    <>
      {transactions.length === 0 ? (
        <div className='flex text-sm mt-6 gap-1 justify-center w-full'>
          <FiAlertCircle className='mt-[2px]' />
          <p>{t('arbitrage.notRecordsFound')}</p>
        </div>
      ) : (
        <div className='container md:mt-3'>
          {Object.entries(groupedTransactions).map(([date, transactions]) => (
            <div key={date} className='mb-4'>
              <div className='px-3 py-2 bg-primary-600 rounded-t-lg'>
                <h3 className='text-lg font-bold flex items-center gap-[12px]'>
                  <BsFillCalendarWeekFill className='mb-[6px]' />
                  <DateAsText date={`${date} 00:00:00`} />
                </h3>
              </div>
              <div className='px-3 py-2 bg-primary-700 rounded-b-lg'>
                {transactions.map((transaction, index) => (
                  <div key={index} className='flex items-center py-2 gap-3 text-white'>
                    <span className='w-[42px] h-[42px] bg-primary-600 rounded-full flex items-center justify-center'>
                      {getTransactionIcon(transaction.type)}
                    </span>
                    <div className='flex items-center justify-between w-full gap-2'>
                      <div className='flex flex-col'>
                        <p className='flex items-center gap-2'>
                          <TypeLabel type={transaction.type} />
                        </p>
                        <p className='text-gray-300 text-sm'>{transaction.createdAt.split(' ')[1].slice(0, 5)}</p>
                      </div>
                      <Badge
                        className={twMerge(
                          classNames('bg-positive/70', {
                            'bg-negative/70': transaction.amount < 0,
                            'bg-gray-300': transaction.amount === 0,
                          })
                        )}
                        text={
                          <MoneyAsText
                            className='text-white font-bold whitespace-nowrap'
                            value={transaction.amount}
                            prefix={getIsSkytSuffix(transaction.type) ? ' ' : '$ '}
                            // suffix={getIsSkytSuffix(transaction.type) ? ' SKYT' : ''}
                          />
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
