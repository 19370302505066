import { Link, useLocation } from 'react-router-dom'
import ReferenceLink from '@/components/Common/ReferenceLink'
import logo from '/images/logo-name.png'
import SwitchLanguage from '@/components/SwitchLanguage'
import { useState } from 'react'
import { SideBar } from '.'
import { BalanceComponent } from './components'
// import { Office } from './Office/Office'
// import { Messages } from './Office/Messages'
import { AiOutlineMenu } from 'react-icons/ai'
import classNames from 'classnames'
import { ThemeSwitch } from './components/ThemeSwitch'
// import { dataNotifications } from '@/api'

export const Header = () => {
  const [sidebar, setSideBar] = useState(false)
  const { pathname } = useLocation()
  // const [messages, setMessages] = useState([])
  // const [isModalOpen, setIsModalOpen] = useState(false)

  const showSideBar = () => setSideBar(!sidebar)

  // const handleOfficeClick = () => {
  //   setIsModalOpen(!isModalOpen)
  // }
  // const handleNewMessage = newMessage => {
  //   if (messages.length < 10) {
  //     setMessages(prevMessages => [...prevMessages, newMessage])
  //   }
  // }
  return (
    <nav
      className={classNames('flex flex-col md:pt-0 border-b w-full text-white', {
        'bg-primary-300 dark:bg-primary-700': pathname === '/arbitrage',
        'bg-primary-700': pathname !== '/arbitrage',
      })}
    >
      <section className='flex justify-between px-3 py-2 md:py-0'>
        <div className='flex justify-center items-center gap-4'>
          <Link to='/arbitrage'>
            <img src={logo} alt='Logo' width='140' />
          </Link>
          <div className='hidden sm:flex'>
            <SwitchLanguage />
          </div>

          {pathname === '/arbitrage' && <ThemeSwitch />}
        </div>
        <div className='flex justify-center items-center'>
          {/* <section className='flex items-center relative w-fit'>
            <div className='hidden md:flex'>
              <Office
                handleOfficeClick={handleOfficeClick}
                className='text-2xl text-white'
                newMessageCount={dataNotifications.length}
              />
              <Messages
                isOpen={isModalOpen}
                onClose={handleOfficeClick}
                messages={dataNotifications.slice(0, 10)}
                handleNewMessage={handleNewMessage}
              />
            </div>
            <Link to='/notifications' className='flex md:hidden'>
              <Office
                handleOfficeClick={handleOfficeClick}
                className='text-2xl text-white'
                newMessageCount={dataNotifications.length}
              />
            </Link>
          </section> */}
          <div className='hidden md:flex justify-end items-center px-3'>
            <div className='w-36 lg:w-fit'>
              <ReferenceLink />
            </div>
          </div>
          <section className='flex items-center justify-center px-1 mr-3 md:p-3'>
            <BalanceComponent bgClassNames={pathname === '/arbitrage' ? 'bg-primary-400 dark:bg-primary-300' : ''} />
          </section>
          <a className='ml-2 sm:ml-0'>
            <AiOutlineMenu className='w-4 h-4 cursor-pointer' onClick={showSideBar} />
          </a>
          {sidebar && <SideBar active={setSideBar} />}
        </div>
      </section>
    </nav>
  )
}
