import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

export const useFormDocuments = ({ onSubmit }) => {
  const { t } = useTranslation()
  const Schema = Yup.object({
    documentFront: Yup.mixed().required(t('global.validation.required')),
    documentBack: Yup.mixed().required(t('global.validation.required')),
    picture: Yup.mixed().required(t('global.validation.required')),
  })
  return useFormik({
    initialValues: {
      documentFront: '',
      documentBack: '',
      picture: '',
    },
    onSubmit,
    validationSchema: Schema,
    validateOnBlur: false,
    validateOnChange: false,
  })
}
