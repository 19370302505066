import { AiOutlineClose } from 'react-icons/ai'
import documents from '@/assets/images/documents.png'
import { useTranslation } from 'react-i18next'
import { TextField } from '@/components/Form/Fields'
import { useFormDocuments } from '../hooks'
import { useSendDocuments } from '../hooks/useSendDocuments'
import { Loader } from '@/components/Common'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { Tooltip } from '@chakra-ui/react'
import { FaTrashAlt } from 'react-icons/fa'
import { Label } from '@/components/Form'
import { useAuth } from '@/contexts'

export const ModalDocuments = ({ modalDocuments, handleChangeModalDocuments }) => {
  const { t } = useTranslation()
  const { refresh, setNoLoading } = useAuth()
  const { dataSendDocuments, errorSendDocuments, loadingSendDocuments, makeRequestSendDocuments } = useSendDocuments()
  const { handleSubmit, resetForm, errors, setFieldValue, values, setFieldError } = useFormDocuments({
    onSubmit: values => {
      const { documentFront, documentBack, picture } = values
      const formData = new FormData()
      formData.append('documents[front]', documentFront)
      formData.append('documents[back]', documentBack)
      formData.append('documents[person]', picture)
      makeRequestSendDocuments(formData)
    },
  })

  const handleClose = () => {
    resetForm()
    handleChangeModalDocuments()
  }

  const handleRemoveFile = name => {
    setFieldValue(name, '')
  }

  useEffect(() => {
    if (!dataSendDocuments) return
    setNoLoading(true)
    refresh().finally(() => {
      setNoLoading(false)
    })
    toast.success(t('security.successDocuments'), {
      position: 'bottom-center',
      autoClose: 2000,
    })
    handleClose()
  }, [dataSendDocuments])

  useEffect(() => {
    if (!errorSendDocuments) return
    if (errorSendDocuments.error === 'frontInvalidExtension') {
      setFieldValue('documentFront', '')
      setFieldError('documentFront', t('security.invalidExtension'))
      return
    }
    if (errorSendDocuments.error === 'backInvalidExtension') {
      setFieldValue('documentBack', '')
      setFieldError('documentBack', t('security.invalidExtension'))
      return
    }
    if (errorSendDocuments.error === 'personInvalidExtension') {
      setFieldValue('picture', '')
      setFieldError('picture', t('security.invalidExtension'))
      return
    }
    toast.error(t('security.errorsDocuments'), {
      position: 'bottom-center',
      autoClose: 2000,
    })
  }, [errorSendDocuments])

  return (
    modalDocuments && (
      <aside className='flex justify-center items-center fixed inset-0 z-20 backdrop-blur-md'>
        <div className='bg-gray border rounded max-w-[450px] mx-3 shadow-sm h-fit'>
          <span className='flex justify-end p-2'>
            <AiOutlineClose
              className='h-4 w-4 cursor-pointer'
              onClick={() => {
                handleClose()
              }}
            />
          </span>
          <form onSubmit={handleSubmit} className='p-4 flex flex-col justify-center items-center gap-5 '>
            <div className='flex flex-col justify-center items-center gap-3 w-full'>
              <div className='flex flex-col justify-center items-center gap-3 mb-2'>
                <img src={documents} width={70} alt={t('global.phone')} />
                <h3 className='text-center text-lg'>{t('security.sendDocuments')}</h3>
              </div>
              <div className='mb-2 flex flex-col items-start justify-start w-full text-sm'>
                <span>{t('security.permForm')} png, jpeg, jpg</span>
                <span>{t('security.maxTam')} 5 MB</span>
              </div>
              <div className='w-full'>
                {values.documentFront ? (
                  <>
                    <Label text={t('security.frontDocuments')} />
                    <div className='flex items-center gap-2 mb-3 justify-between break-all'>
                      <p>{values.documentFront.name}</p>
                      <Tooltip hasArrow label='Remover arquivo'>
                        <div>
                          <FaTrashAlt
                            className='text-red-500 cursor-pointer'
                            onClick={() => handleRemoveFile('documentFront')}
                            size={17}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </>
                ) : (
                  <TextField
                    accept='.png,.jpeg,.jpg'
                    label={t('security.frontDocuments')}
                    name='documentFront'
                    type='file'
                    placeholder={t('profile.placeholderPhone')}
                    onChange={e => setFieldValue('documentFront', e.target.files[0])}
                    errorMessage={errors.documentFront}
                    background
                  />
                )}
                {values.documentBack ? (
                  <>
                    <Label text={t('security.backDocuments')} />
                    <div className='flex items-center justify-between gap-2 mb-3 break-all'>
                      <p>{values.documentBack.name}</p>
                      <Tooltip hasArrow label='Remover arquivo'>
                        <div>
                          <FaTrashAlt
                            className='text-red-500 cursor-pointer'
                            onClick={() => handleRemoveFile('documentBack')}
                            size={17}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </>
                ) : (
                  <TextField
                    accept='.png,.jpeg,.jpg'
                    label={t('security.backDocuments')}
                    name='documentBack'
                    type='file'
                    placeholder={t('profile.placeholderPhone')}
                    onChange={e => setFieldValue('documentBack', e.target.files[0])}
                    errorMessage={errors.documentBack}
                    background
                  />
                )}
                {values.picture ? (
                  <>
                    <Label text={t('security.selfieDocuments')} />
                    <div className='flex items-center gap-2 mb-3 justify-between break-all'>
                      <p>{values.picture.name}</p>
                      <Tooltip hasArrow label='Remover arquivo'>
                        <div>
                          <FaTrashAlt
                            className='text-red-500 cursor-pointer'
                            onClick={() => handleRemoveFile('picture')}
                            size={17}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </>
                ) : (
                  <TextField
                    accept='.png,.jpeg,.jpg'
                    label={t('security.selfieDocuments')}
                    name='picture'
                    type='file'
                    placeholder={t('profile.placeholderPhone')}
                    onChange={e => setFieldValue('picture', e.target.files[0])}
                    errorMessage={errors.picture}
                    background
                  />
                )}
              </div>
            </div>
            <div className='grid grid-cols-2 justify-center gap-3'>
              <button
                type='button'
                className='rounded-full text-center bg-gray-400 p-2 px-4 text-sm md:text-base hover:bg-gray-500 transition duration-200'
                onClick={() => {
                  handleClose()
                }}
              >
                {t('global.cancel')}
              </button>
              <button
                type='submit'
                className='rounded-full bg-primary-400 p-2 text-sm  md:text-base font-bold hover:bg-primary transition duration-200 '
              >
                {loadingSendDocuments ? (
                  <div className='flex items-center justify-center w-full'>
                    <Loader />
                  </div>
                ) : (
                  t('global.send')
                )}
              </button>
            </div>
          </form>
        </div>
      </aside>
    )
  )
}
