import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export function useIndicationCode() {
  const { data, error, loading, makeRequest } = useApi(indicatedCode => {
    const { route, method } = getEndpoint('indicatedId', { indicatedId: indicatedCode })
    return httpClient[method](route, indicatedCode ?? 'unknown')
  })

  return {
    data,
    error,
    loading,
    indicatedByRequest: makeRequest,
  }
}
