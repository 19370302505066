import SwitchLanguage from '@/components/SwitchLanguage'
import { ForgotPasswordForm } from './containers/ForgotPasswordForm'

export const ForgotPassword = () => {
  return (
    <div className='flex flex-col mt-2 sm:my-20 text-white'>
      <div className='md:hidden flex justify-end px-3'>
        <SwitchLanguage />
      </div>
      <div className='flex justify-center items-center'>
        <ForgotPasswordForm />
      </div>
    </div>
  )
}
