import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useRefreshQrCode = () => {
  const { data, loading, makeRequest, error } = useApi(id => {
    const { route, method } = getEndpoint('refreshQrCode', { id: id })
    return httpClient[method](route)
  })

  return {
    dataRefreshQrCode: data,
    loadingRefreshQrCode: loading,
    errorRefreshQrCode: error,
    refreshQrCodeRequest: makeRequest,
  }
}
