import { DateAsText, Loader, PercentAsText } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { CommissionBadge } from './CommissionBadge'
import { FiAlertCircle } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

export const CommissionTable = ({ loading, data }) => {
  const { t } = useTranslation()
  return (
    <div className='hidden lg:flex justify-center lg:overflow-x-auto w-full'>
      <table className='table-auto whitespace-nowrap w-full'>
        <thead>
          <tr className='bg-[#0B0B0B]'>
            <th className=' text-center px-3 py-2 w-10'>{t('commission.th.date')}</th>
            <th className=' text-left px-3 py-2'>{t('commission.th.affilliated')}</th>
            <th className=' text-center px-3 py-2 w-10'>{t('commission.th.type')}</th>
            <th className=' text-right px-3 py-2 w-10'>{t('commission.th.valueDeposit')}</th>
            <th className=' text-center px-3 py-2 w-10'>{t('commission.th.level')}</th>
            <th className=' text-right px-3 py-2 w-10'>{t('commission.th.txCommission')}</th>
            <th className=' text-right px-3 py-2 w-10'>{t('commission.th.commission')}</th>
          </tr>
        </thead>
        <tbody className='bg-transparent text-center w-full'>
          {loading && (
            <tr>
              <td colSpan={3}>
                <div className='flex justify-center items-center my-5'>
                  <Loader />
                </div>
              </td>
            </tr>
          )}
          {!loading && data.length === 0 ? (
            <tr>
              <td colSpan='7'>
                <div className='flex text-sm mt-3 gap-1 justify-center'>
                  <FiAlertCircle className='mt-[2px]' />
                  <p>{t('arbitrage.notRecordsFound')}</p>
                </div>
              </td>
            </tr>
          ) : (
            !loading &&
            data.map((commission, index) => (
              <tr key={commission.id} className={`text-base ${index % 2 === 0 ? 'bg-gray-900' : 'bg-gray-800'} hover:opacity-70`}>
                <td className='text-center px-3 py-2'>
                  <DateAsText date={commission.createdAt} />
                </td>
                <td className='text-left px-3 py-2'>{commission.customer.fullName}</td>
                <td className='text-center px-3 py-2'>
                  <CommissionBadge type={commission.type} />
                </td>
                <td className='text-right px-3 py-2'>
                  <MoneyAsText className='text-positive' value={commission.baseAmount} />
                </td>
                <td className='text-center px-3 py-2'>{commission.level ?? 1}</td>
                <td className='text-right px-3 py-2'>
                  <PercentAsText className='text-positive' value={(commission.commissionAmount * 100) / commission.baseAmount} />
                </td>
                <td className='text-right px-3 py-2'>
                  <MoneyAsText className='text-positive' value={commission.commissionAmount} />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  )
}
