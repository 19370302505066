import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useFetchExtract = () => {
  const { data, loading, makeRequest, error } = useApi(params => {
    const { route, method } = getEndpoint('fetchExtract')
    return httpClient[method](`${route}?sort=-createdAt&skipPagination=1`, { params })
  })

  return {
    transactions: data || [],
    loading: loading,
    errorExtract: error,
    fetchTransactions: makeRequest,
  }
}
