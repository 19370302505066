import { Link } from 'react-router-dom'
import { Logo } from '../Common'
import SwitchLanguage from '../SwitchLanguage'

export const LoginHeader = () => {
  return (
    <nav className='md:grid md:grid-cols-3 py-2 bg-primary-600'>
      <div className='hidden md:flex items-center px-2 md:px-4'>
        <SwitchLanguage />
      </div>
      <div className='flex justify-center items-center pt-2'>
        <Link to='/home'>
          <Logo src='/images/logo-name.png' width={200} />
        </Link>
      </div>
    </nav>
  )
}
