import MoneyAsText from '@/components/Common/MoneyAsText'
import { useBalance } from '@/contexts'
import classNames from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { twMerge } from 'tailwind-merge'

export const BalanceComponent = ({ bgClassNames }) => {
  const { t } = useTranslation()
  const { balance, profit, depositWithVoucher } = useBalance()
  const [showDropdown, setShowDropdown] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  return (
    <div className='relative inline-block text-left'>
      <main
        className={classNames(
          'flex items-center justify-center gap-3 bg-primary-300 text-white text-sm rounded-full py-2 px-3 w-full',
          bgClassNames
        )}
      >
        <p className='hidden md:inline-block mt-[2px] md:mt-0'>{t('global.balance')}</p>
        <p onClick={() => setShowDropdown(!showDropdown)} className='md:hidden inline-block mt-[2px] md:mt-0'>
          {t('global.balance')}
        </p>
        {!isVisible ? (
          <MoneyAsText className='hidden md:inline-block whitespace-nowrap font-extrabold blur-sm' value={balance} />
        ) : (
          <MoneyAsText className='hidden md:inline-block whitespace-nowrap font-extrabold' value={balance} />
        )}
        <button className='hidden md:inline-block' type='button' onClick={() => setIsVisible(!isVisible)}>
          {isVisible ? (
            <AiOutlineEye className='text-white' size={25} />
          ) : (
            <AiOutlineEyeInvisible className='text-white' size={25} />
          )}
        </button>
        <MdKeyboardArrowDown
          onClick={() => setShowDropdown(!showDropdown)}
          className={twMerge(
            classNames('w-4 h-4 transition-all duration-300 hover:opacity-70 cursor-pointer', { 'rotate-180 ': showDropdown })
          )}
        />
      </main>
      {showDropdown && (
        <div
          className={classNames(
            'z-30 origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-primary-300 text-white ring-1 ring-black ring-opacity-5',
            bgClassNames
          )}
        >
          <div className='py-1' role='menu' aria-orientation='vertical' aria-labelledby='options-menu'>
            <div className='md:hidden block px-4 py-2 text-sm hover:opacity-70 hover:bg-primary-400' role='menuitem'>
              {t('global.pool')}: <MoneyAsText className='whitespace-nowrap font-extrabold' value={balance} />
            </div>
            <div className='px-4 py-2 text-sm hover:opacity-70 hover:bg-primary-400' role='menuitem'>
              {t('global.profit')}: <MoneyAsText className='whitespace-nowrap font-extrabold' value={profit} />
            </div>
            <div className='px-4 py-2 text-sm hover:opacity-70 hover:bg-primary-400' role='menuitem'>
              {t('global.poolLiquidity')}: <MoneyAsText className='whitespace-nowrap font-extrabold' value={depositWithVoucher} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
