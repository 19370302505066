import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import SwitchLanguage from '@/components/SwitchLanguage'
import { motion } from 'framer-motion'
import { OpeningVariant1, OpeningVariant2 } from '../animations'

export const Opening = () => {
  const { t } = useTranslation()

  return (
    <section className='md:flex lg:grid grid-cols-2 md:p-28 md:max-w-[1440px] z-10'>
      <div className='md:hidden flex justify-end p-3'>
        <SwitchLanguage />
      </div>

      <motion.div variants={OpeningVariant1} initial='hidden' animate='visible' className='flex flex-col p-4'>
        <h2 className='text-3xl md:text-5xl font-Kaleko-serif-bold mb-2'>{t('home.startYourPortfolio')}</h2>
        <p className='text-base lg:my-3 '>{t('home.startYourPortfolioDescription')}</p>
        <div className='flex items-center justify-center mt-5'>
          <Link
            to='/sign-up'
            className='text-sm md:text-base mt-1 rounded-full p-3 bg-white text-primary-700 text-center font-Kaleko-bold md:w-4/5 w-3/5 hover:bg-white/80 transition duration-200'
          >
            {t('home.openAccount')}
          </Link>
        </div>
      </motion.div>

      <motion.div variants={OpeningVariant2} initial='hidden' animate='visible' className='hidden lg:flex justify-end gap-4'>
        <img
          src='/images/celular-com-mais-res.png'
          alt={t('home.screenshot')}
          className='drop-shadow-xl-white w-[380px] h-[500px] object-cover'
        />
      </motion.div>
    </section>
  )
}
