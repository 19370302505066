import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useResetPassword = () => {
  const { data, loading, makeRequest, error } = useApi((values, token) => {
    const { route, method } = getEndpoint('resetPassword', { token: token })
    return httpClient[method](route, { ...values, token })
  })
  return {
    dataResetPassword: data,
    loadingPassword: loading,
    resetPassword: makeRequest,
    errorResetPassword: error,
  }
}
