import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useFetchBuySellOffersffers = () => {
  const { data, loading, makeRequest, error } = useApi(() => {
    const { route, method } = getEndpoint('buySellOffers')
    return httpClient[method](route)
  })

  return {
    dataBuySellOffers: data || [],
    loadingBuySellOffers: loading,
    errorBuySellOffers: error,
    buySellOffersRequest: makeRequest,
  }
}
