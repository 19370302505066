import imageLg from '@/assets/images/withdraw-image.png'
import { Loader, Loading } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { useAuth, useBalance } from '@/contexts'
import { useCotation } from '@/hooks'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaEthereum } from 'react-icons/fa'
import { FiAlertCircle } from 'react-icons/fi'
import { MdMonetizationOn, MdOutlinePix } from 'react-icons/md'
import { SiBitcoinsv, SiTether } from 'react-icons/si'
import Swal from 'sweetalert2'
import { ButtonTypeWithdraw, CriptoFormWithdraw, HistoricCard, PixFormWithdraw } from './components'
import { useFetchWithdrawHistory, useFormWithdrawCrypto, useFormWithdrawPix, useWithdraw } from './hooks'
// import { RiCloudLine } from 'react-icons/ri'
import { AiFillWarning } from 'react-icons/ai'
import { toast } from 'react-toastify'

export const Withdraw = () => {
  const network = '(TRON)'
  const { refresh, user } = useAuth()
  const { refreshBalance } = useBalance()
  const { bitcoinValue, dollarValue, ethereumValue, loadingCotation, cotation } = useCotation()
  const [isSelectPix, setIsSelectPix] = useState(true)
  const [isSelectBTC, setIsSelectBTC] = useState(false)
  const [isSelectEther, setIsSelectEther] = useState(true)
  const [isSelectUSTD, setIsSelectUSTD] = useState(true)
  // const [isSelectPaypal, setIsSelectPaypal] = useState(true)
  // const [isSelectSKYT, setIsSelectSKYT] = useState(true)
  const [selectedIcon, setSelectedIcon] = useState(<SiBitcoinsv />)
  const [isForm, setIsForm] = useState(2)
  const [crytoType, setCrytoType] = useState(2)
  const { dataHistory, loadingHistory, withdrawHistory } = useFetchWithdrawHistory()
  const { t } = useTranslation()

  const { data, loading, error, withdraw } = useWithdraw()
  const setStates = (value, form = 2) => {
    let pix = true
    let btc = true
    let ether = true
    let ustd = true
    // let paypal = true
    // let skyt = true

    switch (value) {
      case 'pix':
        pix = false
        setSelectedIcon(<MdOutlinePix />)
        break
      case 'btc':
        btc = false
        setSelectedIcon(<SiBitcoinsv />)
        setCrytoType(2)
        break
      case 'ether':
        ether = false
        setSelectedIcon(<FaEthereum />)
        setCrytoType(3)
        break
      case 'ustd':
        ustd = false
        setSelectedIcon(<SiTether />)
        setCrytoType(4)
        break
      // case 'paypal':
      //   paypal = false
      //   setSelectedIcon(<FaPaypal />)
      //   break
      // case 'skyt':
      //   skyt = false
      //   setSelectedIcon(<RiCloudLine />)
      //   break
      default:
        pix = false
        setSelectedIcon(null)
        break
    }
    setIsSelectPix(pix)
    setIsSelectBTC(btc)
    setIsSelectEther(ether)
    setIsSelectUSTD(ustd)
    // setIsSelectPaypal(paypal)
    // setIsSelectSKYT(skyt)
    setIsForm(form)
  }
  const {
    values: valuesCrypto,
    errors: errorsCrypto,
    handleSubmit: handleSubmitCrypto,
    handleChange: handleChangeCrypto,
    setFieldError: setFieldErrorCrypto,
  } = useFormWithdrawCrypto({
    onSubmit: values => {
      const { amountCrypto, cryptoCurrency } = values
      withdraw({
        amount: Number(amountCrypto.replace(/[^0-9.]/g, '')),
        type: crytoType,
        wallet: cryptoCurrency.trim(),
      })
    },
  })

  const { values, errors, setFieldValue, handleSubmit, handleChange, setFieldError } = useFormWithdrawPix({
    onSubmit: ({ amountConverted, receiverName, receiverCpf, pix, type, receiverEmail }) => {
      withdraw({
        amount: Number(amountConverted.replace(/[^0-9.]/g, '')),
        type: 1,
        pix: {
          key: pix.trim(),
          type: Number(type),
          name: receiverName.trim(),
          cpf: receiverCpf.replace(/[^0-9]/g, '').trim(),
          email: receiverEmail.trim(),
        },
      })
    },
  })

  useEffect(() => {
    cotation()
    withdrawHistory()
    if (user.blockWithdraw?.btc && user.blockWithdraw?.eth && user.blockWithdraw?.USDT) {
      setIsSelectBTC(true)
      setIsSelectEther(true)
      setIsSelectUSTD(true)
      setIsSelectPix(false)
      setCrytoType(null)
      setSelectedIcon(<MdOutlinePix />)
      return
    }
    if (user.blockWithdraw?.btc && user.blockWithdraw?.eth) {
      setIsSelectBTC(true)
      setIsSelectEther(true)
      setIsSelectUSTD(false)
      setSelectedIcon(<SiTether />)
      setCrytoType(4)
      return
    }
    if (user.blockWithdraw?.btc) {
      setIsSelectBTC(true)
      setIsSelectEther(false)
      setSelectedIcon(<FaEthereum />)
      setCrytoType(3)
      return
    }
  }, [])

  useEffect(() => {
    if (!data) return
    Swal.fire({
      title: t('withdraw.successMessageTitle'),
      text: t('withdraw.successMessageText'),
      icon: 'success',
      confirmButtonText: 'Ok',
    }).then(() => {
      refresh()
      refreshBalance()
      withdrawHistory()
    })
  }, [data])

  useEffect(() => {
    if (!error) return
    if (error.error === 'withdrawMinimumAmount') {
      setFieldErrorCrypto('amountCrypto', `${t('withdraw.minimumValue')} $ ${user.settings.WITHDRAWAL_MINIMUM_AMOUNT}`)
      setFieldError('amountConverted', `${t('withdraw.minimumValue')} $ ${user.settings.WITHDRAWAL_MINIMUM_AMOUNT}`)
      toast.error(`${t('withdraw.minimumValue')} $ ${user.settings.WITHDRAWAL_MINIMUM_AMOUNT}`)
      return
    }
    if (error.error === 'withdrawBlockToCustomer') {
      toast.error(t('withdraw.blockedWithdraw'))
      return
    }
    if (error.error === 'documentNotApprovedStatus') {
      toast.error(t('withdraw.documentStatus'))
      return
    }
    if (error.error === 'customerBlockedForWithdraw') {
      toast.error(t('withdraw.blockedWithdraw'))
      return
    }
    if (error.error === 'insufficientBalance')
      Swal.fire({
        title: `${t('withdraw.deniedMessageTitle')}`,
        text: `${t('withdraw.deniedMessageText')}. ${t('withdraw.maxWithdraw')} ${error.maxAmount.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })}`,
        icon: 'error',
        confirmButtonText: 'Ok',
      })
  }, [error])

  if (loadingCotation) return <Loading />
  if (user.blockWithdraw?.btc && user.blockWithdraw?.eth && user.blockWithdraw?.usdt && user.blockWithdraw?.pix)
    return (
      <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
        <div className='text-white flex items-center gap-2 text-lg p-4 bg-negative rounded-2'>
          <AiFillWarning className='text-white' />
          <span>{t('withdraw.invalidWithdraw')}</span>
        </div>
      </div>
    )

  return (
    <>
      <div className='lg:grid lg:grid-cols-2 gap-5'>
        <div>
          <div className='flex flex-col'>
            <h1 className='font-extrabold text-3xl mb-2'>{t('withdraw.withdraw')}</h1>
            <p className='mt-4 lg:mt-2'>{t('withdraw.withdrawDescription')}</p>
          </div>

          <section className='flex flex-col gap-2 my-2 w-full'>
            <div className='grid grid-cols-2 gap-4'>
              <ButtonTypeWithdraw
                isDisabled={user.blockWithdraw?.btc}
                icon={<SiBitcoinsv />}
                text={t('deposit.buttonBTC')}
                isSelect={isSelectBTC}
                onClick={() => {
                  if (!user.blockWithdraw?.btc) return setStates('btc')
                  toast.error(t('withdraw.paymentMethodOff'))
                }}
              />
              <ButtonTypeWithdraw
                isDisabled={user.blockWithdraw?.ether}
                icon={<FaEthereum />}
                text={t('deposit.buttonEther')}
                isSelect={isSelectEther}
                onClick={() => {
                  if (!user.blockWithdraw?.ether) return setStates('ether')
                  toast.error(t('withdraw.paymentMethodOff'))
                }}
              />
              <ButtonTypeWithdraw
                isDisabled={user.blockWithdraw?.ustd}
                icon={<SiTether />}
                text={`${t('deposit.buttonUSTD')} ${network}`}
                isSelect={isSelectUSTD}
                onClick={() => {
                  if (!user.blockWithdraw?.ustd) return setStates('ustd')
                  toast.error(t('withdraw.paymentMethodOff'))
                }}
              />
              <ButtonTypeWithdraw
                isDisabled={user.blockWithdraw?.pix}
                icon={<MdMonetizationOn />}
                text={t('deposit.buttonPix')}
                isSelect={isSelectPix}
                onClick={() => {
                  if (!user.blockWithdraw?.pix) return setStates('pix', 1)

                  toast.error(t('withdraw.paymentMethodOff'))
                }}
              />
              {/* <ButtonTypeWithdraw
                icon={<FaPaypal />}
                text={t('deposit.buttonPaypal')}
                isSelect={isSelectPaypal}
                onClick={() => setStates('paypal')}
              />
              <ButtonTypeWithdraw
                icon={<RiCloudLine />}
                text={t('deposit.buttonSKYT')}
                isSelect={isSelectSKYT}
                onClick={() => setStates('skyt')}
              /> */}
            </div>

            <div className='flex items-center my-2 gap-1 text-xs'>
              <span className='text-base mb-1 text-white'>
                <FiAlertCircle />
              </span>
              <p className='font-semibold'>
                {t('withdraw.minimumValue')} <MoneyAsText value={user.settings.WITHDRAWAL_MINIMUM_AMOUNT} />
              </p>
            </div>
          </section>
          <div className='hidden lg:flex justify-center items-center'>
            <img src={imageLg} alt={t('withdraw.withdrawImage')} />
          </div>
        </div>

        <section className='mt-5 lg:mt-6'>
          <p>
            {t('withdraw.withdrawalInformation')} {network}
          </p>

          <div className='bg-surface rounded-3 my-2 p-4 text-primary-700 mt-3'>
            <div className='flex items-center gap-3 justify-between'>
              <p className='text-sm lg:text-base flex flex-col'>
                <span>
                  {isForm === 1 ? (
                    <>{t('deposit.estimationUSD')} </>
                  ) : crytoType === 2 ? (
                    <>{t('deposit.estimationBTC')} </>
                  ) : crytoType === 3 ? (
                    <>{t('deposit.estimationETH')} </>
                  ) : (
                    crytoType === 4 && <span>{t('deposit.estimationUSDT')}</span>
                  )}
                  <span className='font-semibold ml-1'>
                    {isForm === 1 ? (
                      <MoneyAsText prefix='R$ ' value={dollarValue} />
                    ) : crytoType === 2 ? (
                      <MoneyAsText value={bitcoinValue} />
                    ) : crytoType === 3 ? (
                      <MoneyAsText value={ethereumValue} />
                    ) : (
                      crytoType === 4 && <MoneyAsText value={dollarValue} />
                    )}
                  </span>
                </span>
                {crytoType === 4 && isForm !== 1 && <span>{t('deposit.informationUsdt')}</span>}
              </p>

              <span className='text-4xl text-blue-700'>{selectedIcon}</span>
            </div>
            <div className='mt-3'>
              {isForm === 1 ? (
                <PixFormWithdraw
                  dollarValue={dollarValue}
                  errors={errors}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  loading={loading}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              ) : (
                <CriptoFormWithdraw
                  errorsCrypto={errorsCrypto}
                  handleChangeCrypto={handleChangeCrypto}
                  handleSubmitCrypto={handleSubmitCrypto}
                  loading={loading}
                  valuesCrypto={valuesCrypto}
                  usdt={crytoType === 4 && isForm !== 1}
                />
              )}
            </div>
          </div>
        </section>
      </div>

      <section className='mt-4'>
        {loadingHistory ? (
          <div className='my-4'>
            <Loader />
          </div>
        ) : (
          <HistoricCard data={dataHistory} />
        )}
      </section>
    </>
  )
}
