import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { PersonalFormProfile, WalletFormProfile } from '.'
import { ButtonsTabs } from '@/components/Form'

export const FormProfile = ({ handleSubmit, errors, handleChange, values, loadingCustomer, setFieldValue, setFieldError }) => {
  const [selectForm, setSelectForm] = useState(1)
  const { t } = useTranslation()

  const optionsPix = [
    { value: 2, label: t('global.cpf') },
    { value: 3, label: t('global.cnpj') },
    { value: 5, label: t('global.email') },
    { value: 4, label: t('global.phone') },
    { value: 6, label: t('global.randomKey') },
  ]

  return (
    <main className='flex flex-col'>
      <div className='flex items-center w-full relative'>
        <ButtonsTabs
          onClick={() => {
            setSelectForm(1)
          }}
          text={t('profile.userInfo')}
          form={selectForm}
          value={1}
        />
        <ButtonsTabs
          onClick={() => {
            setSelectForm(2)
          }}
          text={t('profile.btcInfo')}
          form={selectForm}
          value={2}
        />
      </div>
      <form
        className='flex flex-col items-center gap-2 bg-white rounded-b-3 my-4 lg:my-3 p-4 text-primary-700 lg:min-w-[600px]'
        onSubmit={handleSubmit}
      >
        {selectForm === 1 && (
          <PersonalFormProfile
            errors={errors}
            handleChange={handleChange}
            loadingCustomer={loadingCustomer}
            values={values}
            optionsPix={optionsPix}
          />
        )}

        {selectForm === 2 && (
          <WalletFormProfile
            errors={errors}
            handleChange={handleChange}
            loadingCustomer={loadingCustomer}
            optionsPix={optionsPix}
            values={values}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
          />
        )}

        <button type='submit' className='bg-primary-300 text-white font-bold p-4 w-full rounded-2 lg:font-normal lg:p-3'>
          {t('profile.save')}
        </button>
      </form>
    </main>
  )
}
