import { Fragment } from 'react'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { useTranslation } from 'react-i18next'
import { useViewport } from '@/hooks'
import classNames from 'classnames'
import { Loader } from '@/components/Common'

export const BookOffersLastNegotiations = ({ bids, lastTradesData, loadingLastTrades, loadingOffers }) => {
  const { t } = useTranslation()
  const { isMoreXl } = useViewport()
  return (
    isMoreXl && (
      <div className='w-[25%] h-auto border-l border-gray-500'>
        <div className='h-[50%] p-3 border-b border-gray-500'>
          <p className='font-bold mb-3'>{t('arbitrage.orderBook')}</p>
          <div className='grid grid-cols-3 text-xs md:text-sm text-center'>
            <span className='whitespace-nowrap text-start mb-2'>{t('arbitrage.Price')}</span>
            <span className='whitespace-nowrap text-end mb-2'>{t('arbitrage.Amount')}</span>
            <span className='whitespace-nowrap text-end mb-2'>{t('arbitrage.total')}</span>
            {loadingOffers && (
              <span className='flex w-full justify-center items-center col-span-3'>
                <Loader className='my-5 inline-block' />
              </span>
            )}
            {bids.map((item, index) => (
              <Fragment key={index}>
                <span
                  className={classNames('whitespace-nowrap text-start', {
                    'text-positive': item.type === 'buy',
                    'text-negative': item.type === 'sell',
                  })}
                >
                  <MoneyAsText value={item.priceBtc} />{' '}
                </span>
                <span
                  className={classNames('whitespace-nowrap text-end', {
                    'text-positive': item.type === 'buy',
                    'text-negative': item.type === 'sell',
                  })}
                >
                  {item.amount.toFixed(8)}
                </span>
                <span
                  className={classNames('whitespace-nowrap text-end', {
                    'text-positive': item.type === 'buy',
                    'text-negative': item.type === 'sell',
                  })}
                >
                  {item.count}
                </span>
              </Fragment>
            ))}
          </div>
        </div>

        <div className='h-[50%] p-3'>
          <p className='font-bold mb-3'>{t('arbitrage.lastNegotiations')}</p>
          <div className='grid grid-cols-3 text-xs md:text-sm text-center'>
            <span className='whitespace-nowrap mb-2 text-start'>{t('arbitrage.Price')}</span>
            <span className='whitespace-nowrap mb-2 text-end'>{t('arbitrage.Amount')}</span>
            <span className='whitespace-nowrap mb-2 text-end'>{t('arbitrage.Hour')}</span>
            {loadingLastTrades && (
              <span className='flex w-full justify-center items-center col-span-3'>
                <Loader className='my-5 inline-block' />
              </span>
            )}
            {lastTradesData.map((item, index) => (
              <Fragment key={index}>
                <span
                  className={classNames('whitespace-nowrap text-start', {
                    'text-positive': item.type === 'buy',
                    'text-negative': item.type === 'sell',
                  })}
                >
                  <MoneyAsText value={item.price} />{' '}
                </span>
                <span
                  className={classNames('whitespace-nowrap text-end', {
                    'text-positive': item.type === 'buy',
                    'text-negative': item.type === 'sell',
                  })}
                >
                  {item.amount.toFixed(8)}
                </span>
                <span
                  className={classNames('whitespace-nowrap text-end', {
                    'text-positive': item.type === 'buy',
                    'text-negative': item.type === 'sell',
                  })}
                >
                  {item.time}
                </span>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    )
  )
}
