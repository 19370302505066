import React, { createContext, useState, useEffect, useContext } from 'react'
import { useApi } from '@/hooks/useApi'
import httpClient from '@/utils/httpClient'
import { useAuth } from '.'
import { useLocation } from 'react-router-dom'

const BalanceContext = createContext({
  balance: 0,
  deposit: 0,
  profit: 0,
  depositWithVoucher: 0,
  arbitrageProfit: 0,
  networkProfit: 0,
  companyCoin: 0,
  loading: false,
  refreshBalance: () => {},
  clearBalance: () => {},
})

export const BalanceProvider = ({ children }) => {
  const [balance, setBalance] = useState(0)
  const [deposit, setDeposit] = useState(0)
  const [depositWithVoucher, setDepositWithVoucher] = useState(0)
  const [arbitrageProfit, setArbitrageProfit] = useState(0)
  const [networkProfit, setNetworkProfit] = useState(0)
  const [companyCoin, setCompanyCoin] = useState(0)
  const [profit, setProfit] = useState(0)
  const { token } = useAuth()
  const { data, makeRequest, loading } = useApi(() => httpClient.get('/financial/customer/balance'))
  const location = useLocation()

  useEffect(() => {
    if (!data) return
    setBalance(data.total.amount)
    setDeposit(data.deposit.amount)
    setDepositWithVoucher(data.depositWithVoucher.amount)
    setProfit(data.profit.amount)
    setNetworkProfit(data.networkProfit?.amount)
    setArbitrageProfit(data.arbitrageProfit?.amount)
    setCompanyCoin(data.companyCoin?.amount)
  }, [data])

  const clearBalance = () => setBalance(0)
  const refreshBalance = () => {
    makeRequest()
  }

  useEffect(() => {
    if (!token || location.pathname === '/impersonate') return
    makeRequest()
  }, [token])

  return (
    <BalanceContext.Provider
      value={{
        balance,
        loading,
        deposit,
        profit,
        arbitrageProfit,
        networkProfit,
        companyCoin,
        depositWithVoucher,
        refreshBalance,
        clearBalance,
      }}
    >
      {children}
    </BalanceContext.Provider>
  )
}

export const useBalance = () => {
  return { ...useContext(BalanceContext) }
}
