import { useTranslation } from 'react-i18next'

export const EN = 1
export const PTBR = 2
export const ES = 3
export const FR = 4
export const DE = 5
export const IT = 6
export const RU = 7
export const JA = 8
export const ZH = 9
export const AR = 10

export const getLanguage = language => {
  switch (language) {
    case 'pt-br':
      return PTBR
    case 'en' || 'en-US':
      return EN
    case 'es':
      return ES
    case 'fr-FR':
      return FR
    case 'de-DE':
      return DE
    case 'ja-JP':
      return JA
    case 'zh-CN':
      return ZH
    case 'ru-RU':
      return RU
    case AR:
      return AR
    default:
      return PTBR
  }
}

export const getLanguageText = language => {
  const { t } = useTranslation()
  switch (language) {
    case PTBR:
      return t('global.languages.portuguese')
    case EN:
      return t('global.languages.english')
    case ES:
      return t('global.languages.spanish')
    case FR:
      return t('global.languages.french')
    default:
      return t('global.languages.portuguese')
  }
}

export const getLanguageOptions = () => [
  { value: PTBR, label: getLanguageText(PTBR) },
  { value: EN, label: getLanguageText(EN) },
  { value: ES, label: getLanguageText(ES) },
  { value: FR, label: getLanguageText(FR) },
]
