import { DateAsText } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FiAlertCircle } from 'react-icons/fi'
import { getDepositType } from '@/pages/Deposit/constants'
import { StatusWithdrawBadge } from '.'

export const HistoricCard = ({ data }) => {
  const { t } = useTranslation()

  if (data?.length === 0)
    return (
      <div className='flex text-sm mt-3 gap-1 justify-center'>
        <FiAlertCircle />
        <p>{t('arbitrage.notRecordsFound')}</p>
      </div>
    )

  return data.map((item, index) => (
    <main
      key={index}
      className='my-2 text-sm py-2 md:py-0 bg-white px-4 rounded md:flex md:items-center md:justify-between text-primary-700'
    >
      <section className='flex justify-between my-3 md:gap-5'>
        <div className='flex flex-col'>
          <p className='text-primary-200'>{t('deposit.withdrawNumber')}</p>
          <span>{item.number}</span>
        </div>
      </section>

      <section className='flex justify-between md:gap-5'>
        <div className='flex flex-col'>
          <p className='text-primary-200'>{t('extract.value')}</p>
          <span>
            <MoneyAsText value={item.amount} />
          </span>
        </div>
      </section>

      <section className='flex justify-between my-3 md:gap-5'>
        <div className='flex flex-col'>
          <p className='text-primary-200'>{t('deposit.requestedAmount')}</p>
          <span>{<MoneyAsText value={item.requestedAmount} />}</span>
        </div>
      </section>

      <section className='flex justify-between my-3 md:gap-5'>
        <div className='flex flex-col'>
          <p className='text-primary-200'>{t('deposit.requestedTax')}</p>
          <span>{<MoneyAsText value={item.taxAmount} />}</span>
        </div>
      </section>

      <section className='flex justify-between my-3 md:gap-5'>
        <div className='flex flex-col'>
          <p className='text-primary-200'>{t('extract.type')}</p>
          <span>{getDepositType(item.type)}</span>
        </div>
      </section>

      <section className='flex justify-between my-3 md:gap-5'>
        <div className='flex flex-col'>
          <p className='text-primary-200'>{t('deposit.requestDate')}</p>
          <span>{<DateAsText type='datetime' date={item.createdAt} />}</span>
        </div>
      </section>

      <section className='flex justify-between'>
        <div className='flex flex-col'>
          <p className='text-primary-200'>{t('deposit.status')}</p>
          <span className='text-white rounded-2'>
            <StatusWithdrawBadge status={item.status} />
          </span>
        </div>
      </section>
    </main>
  ))
}
