import { BsFillCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs'
import {
  CheckedModal,
  // EditPassword,
  EditPhoneEmail,
  ModalDocuments,
  SecurityStatus,
} from './components'
// import password from '@/assets/images/password-icon.png'
import documents from '@/assets/images/documents.png'
import email from '@/assets/images/email-icon.png'
import phone from '@/assets/images/phone-icon.png'
import { useAuth } from '@/contexts'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { twMerge } from 'tailwind-merge'
import { BadgeDocument } from './components/BadgeDocument'
import { APPROVED, UNDER_ANALYSIS } from './constants'
import { useSendVerificationEmailPhoneToken, useVerificationEmailPhone } from './hooks'

export const Security = () => {
  const { user, refresh, setNoLoading } = useAuth()
  const [typeState, setTypeState] = useState()
  const [sendEmail, setSendEmail] = useState(false)
  const [sendPhone, setSendPhone] = useState(false)
  const [modalDocuments, setModalDocuments] = useState(false)
  const [verificationModalIsOpen, setVerificationModalIsOpen] = useState(false)
  // const [editModalPassword, setEditModalPassword] = useState(false)
  const [editModalPhoneEmail, setEditModalPhoneEmail] = useState(false)
  const [isPhoneEmail, setIsPhoneEmail] = useState()
  const { t } = useTranslation()
  const {
    dataVerificationEmailPhone,
    errorVerificationEmailPhone,
    verificationEmailPhoneRequest,
    loadingVerificationEmailPhone,
  } = useVerificationEmailPhone()
  const {
    dataUseSendVerificationEmailPhoneToken,
    errorUseSendVerificationEmailPhoneToken,
    useSendVerificationEmailPhoneTokenRequest,
    loadingUseSendVerificationEmailPhoneToken,
  } = useSendVerificationEmailPhoneToken()

  const handleVerificationModalIsOpen = type => {
    setTypeState(type)
    setIsPhoneEmail(type)
    setVerificationModalIsOpen(!verificationModalIsOpen)
  }

  const handleSendEmail = () => {
    if (typeState === 'email' && !user.emailVerified && verificationModalIsOpen) {
      verificationEmailPhoneRequest(typeState)
    }
    if (typeState === 'phone' && !user.phoneVerified && verificationModalIsOpen) {
      verificationEmailPhoneRequest(typeState)
    }
  }

  const handleSubmitVerify = (type, numbers) => {
    useSendVerificationEmailPhoneTokenRequest(type, numbers)
  }

  const handleChangeModalDocuments = () => {
    setModalDocuments(!modalDocuments)
  }

  useEffect(() => {
    if (!dataVerificationEmailPhone) return
    if (typeState === 'email') {
      toast.success(t('global.sendEmail'))
      window.localStorage.setItem('sendEmail', true)
      setSendEmail(true)
    }
    if (typeState === 'phone') {
      toast.success(t('global.sendPhone'))
      window.localStorage.setItem('sendPhone', true)
      setSendPhone(true)
    }
    setNoLoading(true)
    refresh().finally(() => {
      setNoLoading(false)
    })
  }, [dataVerificationEmailPhone])

  useEffect(() => {
    if (!errorVerificationEmailPhone) return
    if (typeState === 'email') toast.success(t('global.errorEmail'))
    if (typeState === 'phone') toast.success(t('global.errorPhone'))
  }, [errorVerificationEmailPhone])

  useEffect(() => {
    if (!errorUseSendVerificationEmailPhoneToken) return
    if (errorUseSendVerificationEmailPhoneToken.invalidToken === 'invalidToken') return toast.error(t('global.tokenInvalid'))
    if (isPhoneEmail === 'email') toast.error(t('global.errorSendEmail'))
    if (isPhoneEmail === 'phone') toast.error(t('global.errorSendPhone'))
  }, [errorUseSendVerificationEmailPhoneToken])

  useEffect(() => {
    if (!dataUseSendVerificationEmailPhoneToken) return
    setNoLoading(true)
    refresh().finally(() => {
      setNoLoading(false)
    })
    if (isPhoneEmail === 'email') {
      toast.success(t('global.successSendEmail'))
      window.localStorage.removeItem('sendEmail')
    }
    if (isPhoneEmail === 'phone') {
      toast.success(t('global.successSendPhone'))
      window.localStorage.removeItem('sendPhone')
    }
    setVerificationModalIsOpen(false)
  }, [dataUseSendVerificationEmailPhoneToken])

  useEffect(() => {
    const email = window.localStorage.getItem('sendEmail')
    const phone = window.localStorage.getItem('sendPhone')
    setSendEmail(email)
    setSendPhone(phone)
  }, [])

  // const handleEditPassword = () => {
  //   setEditModalPassword(!editModalPassword)
  // }

  const handleEditPhoneEmail = type => {
    setIsPhoneEmail(type)
    setEditModalPhoneEmail(!editModalPhoneEmail)
  }

  return (
    <>
      <div className='md:px-12 lg:px-24'>
        <h1 className='font-bold text-3xl mb-4 md:mb-0'>{t('security.security')}</h1>
        <SecurityStatus />
        <section className='flex flex-col md:grid md:grid-cols-6 mt-5 mb-12 text-sm md:text-sm lg:text-base'>
          <div className='md:col-span-3'>
            <div className='flex flex-col justify-center md:justify-start items-center md:flex-row gap-3'>
              <img src={email} className='w-6 h-6 md:w-12 md:h-12' alt={t('global.email')} />
              <span>
                <h3>{t('global.email')}</h3>
                <p className='text-sm font-Kaleko-thin'>{t('security.emailPhoneText')}</p>
              </span>
            </div>
          </div>
          <div className='md: col-span-2 flex items-center justify-center md:justify-start gap-2 mt-2'>
            {user.emailVerified ? <BsFillCheckCircleFill color='green' /> : <BsFillXCircleFill color='red' />}
            <p className='break-all'>{user.email}</p>
          </div>
          {user.emailVerified ? (
            <div className='flex items-center justify-center mt-4 md:mt-0'>
              <button
                type='button'
                className='bg-primary-300 py-2 px-3 rounded-full text-sm hover:bg-primary transition duration-200'
                onClick={() => handleEditPhoneEmail('email')}
              >
                {t('global.edit')}
              </button>
            </div>
          ) : (
            <div className='flex items-center justify-center mt-4 md:mt-0'>
              <button
                type='button'
                className='bg-primary-300 py-2 px-3 rounded-full text-sm hover:bg-primary transition duration-200'
                onClick={() => handleVerificationModalIsOpen('email')}
              >
                {t('security.verify')}
              </button>
            </div>
          )}
          <hr className='mt-3 md:hidden' />
        </section>
        <section className='flex flex-col md:grid md:grid-cols-6 mb-12 text-sm md:text-sm lg:text-base'>
          <div className='md:col-span-3'>
            <div className='flex flex-col justify-center md:justify-start items-center md:flex-row gap-3'>
              <img src={phone} className='w-6 h-6 md:w-12 md:h-12' alt={t('global.phone')} />
              <span>
                <h3>{t('global.phone')}</h3>
                <p className='text-sm font-Kaleko-thin'>{t('security.emailPhoneText')}</p>
              </span>
            </div>
          </div>
          <div className='md:col-span-2 flex items-center justify-center md:justify-start gap-2 mt-2'>
            {user.phoneVerified ? <BsFillCheckCircleFill color='green' /> : <BsFillXCircleFill color='red' />}
            <p>{user.phone}</p>
          </div>
          {user.phoneVerified ? (
            <div className='flex items-center justify-center mt-4 md:mt-0'>
              <button
                type='button'
                className='bg-primary-300 py-2 px-3 rounded-full text-sm hover:bg-primary transition duration-200'
                onClick={() => handleEditPhoneEmail('phone')}
              >
                {t('global.edit')}
              </button>
            </div>
          ) : (
            <div className='flex items-center justify-center mt-4 md:mt-0'>
              <button
                type='button'
                className='bg-primary-300 py-2 px-3 rounded-full text-sm hover:bg-primary transition duration-200'
                onClick={() => handleVerificationModalIsOpen('phone')}
              >
                {t('security.verify')}
              </button>
            </div>
          )}
          <hr className='mt-3 md:hidden' />
        </section>
        <section className='flex flex-col md:grid md:grid-cols-6 mb-12 text-sm md:text-sm lg:text-base'>
          <div className='md:col-span-3'>
            <div className='flex flex-col justify-center md:justify-start items-center md:flex-row gap-3'>
              <img src={documents} className='w-6 h-6 md:w-12 md:h-12' alt={t('global.phone')} />
              <span>
                <h3>{t('security.documents')}</h3>
                <p className='text-sm font-Kaleko-thin'>{t('security.emailPhoneText')}</p>
              </span>
            </div>
          </div>
          <div className='md:col-span-2 flex items-center justify-center md:justify-start gap-2 mt-2'>
            <BadgeDocument status={user.documents.status} />
          </div>
          <div className='flex items-center justify-center mt-4 md:mt-0'>
            <button
              type='button'
              disabled={user.documents.status === UNDER_ANALYSIS}
              className={twMerge(
                classNames('bg-primary-300 py-2 px-3 rounded-full text-sm hover:bg-primary transition duration-200', {
                  'bg-primary cursor-not-allowed opacity-70': user.documents.status === UNDER_ANALYSIS,
                })
              )}
              onClick={() => handleChangeModalDocuments()}
            >
              {user.documents.status !== APPROVED ? t('security.verify') : t('global.edit')}
            </button>
          </div>
          <hr className='mt-3 md:hidden' />
        </section>
        {/* <section className='flex flex-col md:grid md:grid-cols-6 mt-5 mb-12 text-sm md:text-sm lg:text-base'>
          <div className='md:col-span-5'>
            <div className='flex flex-col justify-center md:justify-start items-center md:flex-row gap-3'>
              <img src={password} className='w-6 h-6 md:w-12 md:h-12' alt='' />
              <span>
                <h3>{t('security.accessPassword')}</h3>
                <p className='text-sm font-Kaleko-thin'>{t('security.accessPasswordText')}</p>
              </span>
            </div>
          </div>
          <div className='flex items-center justify-center mt-4 md:mt-0'>
            <button
              type='button'
              className='bg-primary-300 py-2 px-3 rounded-full text-sm hover:bg-primary transition duration-200'
              onClick={() => handleEditPassword()}
            >
              {t('security.change')}
            </button>
          </div>
        </section> */}
      </div>
      <CheckedModal
        handleSendEmail={handleSendEmail}
        verificationModalIsOpen={verificationModalIsOpen}
        setVerificationModalIsOpen={setVerificationModalIsOpen}
        isPhoneEmail={isPhoneEmail}
        handleSubmitVerify={handleSubmitVerify}
        loading={loadingUseSendVerificationEmailPhoneToken}
        loadingVerificationEmailPhone={loadingVerificationEmailPhone}
        sendEmail={sendEmail}
        sendPhone={sendPhone}
      />
      <EditPhoneEmail
        setEditModalPhoneEmail={setEditModalPhoneEmail}
        handleEditPhoneEmail={handleEditPhoneEmail}
        editModalPhoneEmail={editModalPhoneEmail}
        isPhoneEmail={isPhoneEmail}
        verificationEmailPhoneRequest={verificationEmailPhoneRequest}
      />
      <ModalDocuments handleChangeModalDocuments={handleChangeModalDocuments} modalDocuments={modalDocuments} />
      {/* <EditPassword editModalPassword={editModalPassword} handleEditPassword={handleEditPassword} /> */}
    </>
  )
}
