import { useTranslation } from 'react-i18next'

//CommissionType
export const MATRIX = 1
export const RECOMMENDATION = 2

export const getCommissionType = type => {
  const { t } = useTranslation()
  switch (type) {
    case 1:
      return t('commission.matrix')
    case 2:
      return t('commission.recommendation')
  }
}

export const getCommissionOptions = () => [
  { value: MATRIX, label: getCommissionType(MATRIX) },
  { value: RECOMMENDATION, label: getCommissionType(RECOMMENDATION) },
]
