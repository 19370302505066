import { Loader, PercentAsText } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { IconSymbol } from '.'
import { TableVariant } from '../animations'

export const TableCriptoMarket = ({ cryptos, loadingCryptos }) => {
  if (loadingCryptos)
    return (
      <div className='my-5 flex justify-center items-center'>
        <Loader />
      </div>
    )
  const { t } = useTranslation()
  return (
    <motion.div
      variants={TableVariant}
      initial='hidden'
      whileInView='visible'
      viewport={{ once: true }}
      className='overflow-x-auto'
    >
      <table className='w-full bg-white text-black table-auto font-sans font-normal '>
        <thead>
          <tr className='text-sm'>
            <th className='text-center p-3 w-14 font-normal'>#</th>
            <th className='text-start p-3 font-normal'>{t('global.name')}</th>
            <th className='text-start p-3 w-32 font-normal'>{t('global.price')}</th>
            <th className='text-center p-3 w-32 font-normal'>{t('global.change')}</th>
            <th className='text-center p-3 w-36 font-normal'>{t('global.trade')}</th>
          </tr>
        </thead>
        <tbody>
          {cryptos.map((item, index) => (
            <tr key={index} className='whitespace-nowrap font-medium border-t'>
              <td className='text-center p-3'>{index + 1}</td>
              <td className='text-start p-3'>
                <div className='flex gap-2 items-center'>
                  <IconSymbol src={item.iconUrl} />
                  {item.name}
                  <span className='font-Kaleko-light'>{item.symbol}</span>
                </div>
              </td>
              <td className='text-start p-3'>
                <MoneyAsText value={item.price} />
              </td>
              <td className='text-center p-3'>
                {
                  <PercentAsText
                    className={classNames({ 'text-positive': item.change >= 0, 'text-negative': item.change < 0 })}
                    value={item.change}
                  />
                }
              </td>
              <td className='text-center p-3'>
                <Link
                  to='/arbitrage'
                  className='bg-green-700 text-white rounded-1 px-3 py-2 hover:bg-green-700/80 transition duration-200'
                >
                  {t('home.buy')}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </motion.div>
  )
}
