import React from 'react'
import PropTypes from 'prop-types'
import { RoundedSpinnerIcon } from '@/components/Common'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

export const Button = ({ text, type, className, isDisabled, isLoading, children, onClick, iconLeft, iconRight, ...rest }) => {
  const buttonClasses = classNames('py-2 px-5 bg-primary text-white rounded-full text-center', className, {
    'transition ease-in-out duration-300 delay-50 hover:opacity-80': !isDisabled,
    'opacity-40 cursor-not-allowed': isDisabled,
    'flex gap-2 items-center justify-center': isLoading || iconRight || iconLeft,
  })

  return (
    <button type={type} className={twMerge(buttonClasses)} onClick={onClick} disabled={isDisabled} {...rest}>
      {isLoading && <RoundedSpinnerIcon stroke='#fff' />}
      {iconLeft !== '' && iconLeft}
      {text !== '' && !isLoading ? text : children}
      {iconRight !== '' && iconRight}
    </button>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
}

Button.defaultProps = {
  text: '',
  iconLeft: '',
  iconRight: '',
  type: 'button',
  isLoading: false,
  isDisabled: false,
  onClick: () => {},
}
