import lock from '@/assets/images/security.png'
import { useAuth } from '@/contexts'
import { useTranslation } from 'react-i18next'
import { BsFillCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs'
import { APPROVED } from '../constants'

export const SecurityStatus = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  return (
    <div className='w-full grid md:grid-cols-6 items-center'>
      <div className='md:col-span-5 rounded-2 grid grid-cols-1 sm:grid-cols-3 px-1 gap-2 sm:gap-0 py-2 md:p-4 w-full bg-gray text-center text-xs md:text-sm lg:text-base'>
        <span className='flex lg:justify-center items-center gap-1 lg:gap-2'>
          {user.emailVerified ? <BsFillCheckCircleFill color='green' /> : <BsFillXCircleFill color='red' />}
          <h3>{t('security.verifiedEmail')}</h3>
        </span>
        <span className='flex lg:justify-center items-center gap-1 lg:gap-2'>
          {user.phoneVerified ? <BsFillCheckCircleFill color='green' /> : <BsFillXCircleFill color='red' />}
          <h3>{t('security.verifiedPhone')}</h3>
        </span>
        <span className='flex lg:justify-center items-center gap-1 lg:gap-2 whitespace-nowrap'>
          {user.documents.status === APPROVED ? <BsFillCheckCircleFill color='green' /> : <BsFillXCircleFill color='red' />}
          <h3>{t('security.verifiedDocuments')}</h3>
        </span>
      </div>
      <div className='hidden md:flex justify-center'>
        <img src={lock} alt={t('security.security')} className='md:w-20 lg:w-28' />
      </div>
    </div>
  )
}
