import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useConvertAmount = () => {
  const { data, loading, makeRequest, error } = useApi(params => {
    const { route, method } = getEndpoint('convertAmount')
    return httpClient[method](route, { ...params })
  })

  return {
    dataConvertAmount: data,
    loadingConvertAmount: loading,
    errorConvertAmount: error,
    convertAmountRequest: makeRequest,
  }
}
