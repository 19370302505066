import { useTranslation } from 'react-i18next'
import cripto from '@/assets/images/undraw_coin.svg'
import logoIcon from '/images/logo.png'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { TableVariant, TitleVariant } from '../animations'

export const DesktopSection = () => {
  const { t } = useTranslation()
  return (
    <section className='hidden lg:grid lg:grid-cols-2 max-w-[1440px] mx-auto'>
      <div className='hidden lg:flex justify-center items-center'>
        <motion.img
          variants={TableVariant}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true }}
          src={cripto}
          alt='criptomoedas'
          width='400'
        />
      </div>

      <main className='px-4 py-11'>
        <motion.h2
          variants={TitleVariant}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true }}
          className='text-3xl font-Kaleko-serif-bold mb-2 origin-left'
        >
          {t('home.startYourPortfolio')}
        </motion.h2>
        <p className='text-base lg:my-3'>{t('home.startYourPortfolioDescription')}</p>

        <div className='lg:flex lg:justify-center lg:items-center lg:gap-7'>
          <div className='w-full flex flex-col mt-3 lg:w-1/2'>
            <Link
              to='/sign-up'
              className='text-base mt-1 rounded-full p-3 bg-white text-primary-700 text-center font-Kaleko-bold hover:bg-white/80 transition duration-200'
            >
              {t('home.openAccount')}
            </Link>
            <p className='text-center mt-2 text-sm pb-2'>
              {t('home.alreadyHaveAnAccount')}{' '}
              <Link to='/sign-in' className='underline'>
                {t('home.toEnter')}
              </Link>
            </p>
          </div>
          <div className='hidden lg:flex flex-col justify-center items-center my-3'>
            <img src={logoIcon} width='100' />
            <p className=' font-Kaleko-bold text-2xl'>botfy.app</p>
          </div>
        </div>
      </main>
    </section>
  )
}
