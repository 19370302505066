import { BiPencil } from 'react-icons/bi'

export const EditAvatarButton = ({ handleChangeAvatar }) => {
  return (
    <div className='bg-primary-200 text-white rounded-full relative cursor-pointer'>
      <div className='p-2'>
        <BiPencil className='w-[14px] h-[14px]' />
      </div>
      <input
        type='file'
        multiple={false}
        className='opacity-0 w-full h-full absolute top-0 left-0 file:h-full file:w-full file:cursor-pointer'
        onChange={e => handleChangeAvatar(e)}
      />
    </div>
  )
}
