import { useTranslation } from 'react-i18next'
import {
  //  SearchAffiliate,
  TableAffiliates,
} from './components'
import { useState, useEffect } from 'react'
import { useNetwork, useNetworkId } from './hooks'
import { Loading } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
// import { useAuth } from '@/contexts'

export const Network = () => {
  const { t } = useTranslation()
  const [data, setData] = useState([])
  const [id, setId] = useState()
  // const { user } = useAuth()
  const [cachedData, setCachedData] = useState([])
  const [loadingFlow, setLoadingFlow] = useState(false)
  const [activeLevel, setActiveLevel] = useState(1)

  const [numbers, setNumbers] = useState({
    direct: 0,
    indirect: 0,
    totalNetwork: 0,
  })
  const [networkFlow, setNetworkFlow] = useState([])
  const { dataNetwork, loadingNetwork, networkRequest } = useNetwork()
  const { dataNetworkId, loadingNetworkId, networkIdRequest } = useNetworkId()

  const handleOpenNetwork = (id, name, level) => {
    setId(id)
    networkIdRequest(id).then(() => {
      setNetworkFlow(prev => [...prev, { name: `${name} - ${'Lv.'} ${level}`, id: id }])
      setActiveLevel(activeLevel + 1)
    })
  }

  useEffect(() => {
    if (!dataNetwork || dataNetwork.length === 0) return
    setNumbers({
      direct: dataNetwork.directCount,
      indirect: dataNetwork.indirectCount,
      totalNetwork: dataNetwork.networkCount,
      volumeSum: dataNetwork.networkVolumeSum,
    })
    setData(dataNetwork.children)
    setCachedData(prev => [...prev, dataNetwork.children])
  }, [dataNetwork])

  useEffect(() => {
    if (!dataNetworkId || !dataNetworkId.length === 0) return
    setData(dataNetworkId.children)
    setNumbers({
      direct: dataNetworkId.directCount,
      indirect: dataNetworkId.indirectCount,
      totalNetwork: dataNetworkId.networkCount,
      volumeSum: dataNetworkId.networkVolumeSum,
    })
    setCachedData(prev => [...prev, dataNetworkId.children])
  }, [dataNetworkId])

  // useEffect(() => {
  //   if (!data.length === 0) return
  // }, [data])

  useEffect(() => {
    networkRequest()
  }, [])

  if (loadingNetwork) return <Loading />

  return (
    <div className='flex flex-col'>
      <div className='w-full flex justify-between items-center'>
        <h1 className='font-extrabold text-3xl mb-2'>{t('network.affiliateNetwork')}</h1>

        <div className='flex items-center gap-2'>
          <span className='text-sm bg-badge-records rounded p-2 px-3'>
            {t('network.volumeSum')} <MoneyAsText value={numbers.volumeSum} />
          </span>
          <span className='text-sm bg-badge-records rounded p-2 px-3'>
            {t('network.direct')} {numbers.direct}
          </span>
          <span className='text-sm bg-badge-records rounded p-2 px-3'>
            {t('network.indirect')} {numbers.indirect}
          </span>
          <span className='text-sm bg-badge-records rounded p-2 px-3'>
            {t('network.yourEntireNetwork')} {numbers.totalNetwork}
          </span>
        </div>
      </div>

      {/* <section className='mt-3'>
        <SearchAffiliate />
      </section> */}

      <section className='mt-3'>
        <TableAffiliates
          id={id}
          loadingFlow={loadingFlow}
          setLoadingFlow={setLoadingFlow}
          loadingNetworkId={loadingNetworkId}
          handleOpenNetwork={handleOpenNetwork}
          data={data}
          cachedData={cachedData}
          networkFlow={networkFlow}
          setData={setData}
          setCachedData={setCachedData}
          setNetworkFlow={setNetworkFlow}
          networkIdRequest={networkIdRequest}
          networkRequest={networkRequest}
          setId={setId}
          activeLevel={activeLevel}
          setActiveLevel={setActiveLevel}
        />
      </section>
    </div>
  )
}
