import { Label } from '@/components/Form'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const BadgeRecords = ({ count, text }) => {
  const { t } = useTranslation()
  return (
    <Label className='bg-badge-records rounded-2 px-2 py-1 w-full text-center whitespace-nowrap'>
      {text ?? t('extract.allRecords')} {count}
    </Label>
  )
}
