import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useCotation = () => {
  const { data, loading, error, makeRequest } = useApi(() => {
    const { route, method } = getEndpoint('cotation')
    return httpClient[method](route)
  })
  return {
    bitcoinValue: data?.btcUsd,
    dollarValue: data?.usdBrl,
    ethereumValue: data?.ethUsd,
    loadingCotation: loading,
    errorCotation: error,
    cotation: makeRequest,
  }
}
