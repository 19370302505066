import { Loader, PercentAsText } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { CheckBox, InputCpfCnpj, Label, MoneyAsInput } from '@/components/Form'
import { FieldMessage, SelectField, TextField } from '@/components/Form/Fields'
import { useAuth } from '@/contexts'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiAlertCircle } from 'react-icons/fi'
import { RiErrorWarningFill } from 'react-icons/ri'
import { useSearchParams } from 'react-router-dom'
import { getOptionPixType } from '../constants'

export const PixFormWithdraw = ({ handleSubmit, values, errors, handleChange, loading, setFieldValue, dollarValue }) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [searchParams] = useSearchParams()
  const tokenParam = searchParams.get('token')
  const [convertedValue, setConvertedValue] = useState(0)

  useEffect(() => {
    const clearNumber = Number(values.amountConverted.replace(/[^0-9.]/g, ''))
    const percentValue = user.settings.WITHDRAWAL_TAX_AMOUNT / 100
    setConvertedValue((clearNumber * percentValue).toFixed(2))
  }, [values.amountConverted])

  const changeValueInput = e => {
    if (!e.numberValue) return setFieldValue('amount', '')
    const conversion = e.numberValue * dollarValue
    const valueConverted = conversion
    setFieldValue('amount', `≈ ${valueConverted.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Label text={t('withdraw.withdrawalValue')} isInvalid={errors.amountConverted} />

      <MoneyAsInput
        name='amountConverted'
        isDisabled={loading}
        errorMessage={errors.amountConverted}
        value={values.amountConverted}
        placeholder={t('global.placeholderValue')}
        label={t('withdraw.withdrawalValue')}
        onChange={e => {
          handleChange(e)
          changeValueInput(e)
        }}
        isInvalid={errors.amountConverted}
      />
      {errors.amountConverted && <FieldMessage message={errors.amountConverted} />}

      <input
        name='amount'
        placeholder={t('withdraw.conversionToUSD')}
        disabled
        onChange={handleChange}
        className='w-full my-3 outline-none text-base bg-zinc-50 rounded-sm p-3 md:py-2 block border border-positive'
        value={values.amount}
      />

      <TextField
        name='receiverName'
        value={values.receiverName}
        onChange={handleChange}
        placeholder={t('profile.placeholderName')}
        label={t('global.name')}
        errorMessage={errors.receiverName}
      />

      <TextField
        label={t('global.email')}
        name='receiverEmail'
        value={values.receiverEmail}
        onChange={handleChange}
        placeholder={t('profile.placeholderEmail')}
        errorMessage={errors.receiverEmail}
      />

      <Label text={t('global.cpf')} isInvalid={errors.receiverCpf} />
      <InputCpfCnpj
        name='receiverCpf'
        value={values.receiverCpf}
        onChange={handleChange}
        placeholder={t('profile.placeholderCpf')}
        isDisabled={loading}
        isInvalid={errors.receiverCpf}
      />
      {errors.receiverCpf && <FieldMessage message={errors.receiverCpf} />}

      <SelectField
        label={t('withdraw.keyType')}
        name='type'
        value={values.type}
        onChange={handleChange}
        options={getOptionPixType()}
        className='mt-3'
      />

      <TextField
        label=''
        name='pix'
        value={values.pix}
        onChange={handleChange}
        placeholder={t('profile.placeholderCpf')}
        errorMessage={errors.pix}
      />

      <CheckBox
        label={t('withdraw.sameOwner')}
        onChange={e => {
          setFieldValue('sameOwnership', e.target.checked)
        }}
        value={values.sameOwnership}
        name='sameOwnership'
        isDisabled={loading}
        checked={values.sameOwnership}
      />

      <div className='flex text-xs border border-primary rounded p-2 gap-2 mt-3 items-center'>
        <FiAlertCircle className='h-6 w-6 md:h-3 md:w-3' />
        <p>
          {t('withdraw.pleaseWait')}
          <strong>{t('withdraw.period')}</strong>
          {t('withdraw.forNextOperation')}
        </p>
      </div>

      <p className='mt-3 text-sm text-yellow-500 font-bold flex items-center gap-1'>
        <RiErrorWarningFill className='h-3 w-3 mb-[2px]' /> {t('withdraw.withdrawTax')}{' '}
        <span>
          (<PercentAsText value={user.settings.WITHDRAWAL_TAX_AMOUNT} />)
        </span>{' '}
        <span className='text-black'>
          ≈ <MoneyAsText value={convertedValue} />
        </span>
      </p>

      <div className='flex justify-center mt-3'>
        <button
          type='submit'
          disabled={loading || new Date().getDay() === 6 || new Date().getDay() === 0 || tokenParam}
          className={classNames('bg-primary-300 text-white text-center font-bold p-4 w-full rounded-2', {
            'opacity-60': new Date().getDay() === 6 || new Date().getDay() === 0 || tokenParam,
            'hover:bg-primary-400': new Date().getDay() !== 6 || new Date().getDay() !== 0 || tokenParam,
          })}
        >
          {loading ? <Loader className='inline-block' /> : t('withdraw.withdrawalRequest')}
        </button>
      </div>

      <p className='text-xs mt-2'>{t('withdraw.resourceRelease')}</p>
    </form>
  )
}
