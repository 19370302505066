import { Badge } from '@/components/Common'
import { APPROVED, AWAITING_PAYMENT, CREATED, EXPIRED, REFUSED, REVERSED, VOUCHER, getDepositStatusText } from '../constants'

export const StatusDepositBadge = ({ status }) => {
  let color
  switch (status) {
    case CREATED:
      color = 'bg-gray-400'
      break
    case AWAITING_PAYMENT:
      color = 'bg-yellow-400'
      break
    case APPROVED:
      color = 'bg-positive'
      break
    case VOUCHER:
      color = 'bg-blue-500'
      break
    case REFUSED:
      color = 'bg-negative'
      break
    case REVERSED:
      color = 'bg-orange-400'
      break
    case EXPIRED:
      color = 'bg-purple-400'
      break
  }
  return <Badge text={getDepositStatusText(status)} textColor={color} />
}
