import { useAxios } from '@/hooks'
import axios from 'axios'

export const useFetchCrypto = () => {
  const httpClient = axios.create({
    headers: {
      'x-access-token': 'coinranking6092844f3d49ba92dc296c1d61d67aa0f6b21484ea64ba7a',
    },
  })
  const { makeRequest, data, loading } = useAxios(() => httpClient.get('https://api.coinranking.com/v2/coins?limit=10'))
  return {
    cryptos: data?.data?.coins || [],
    loadingCryptos: loading,
    fetchCryptos: makeRequest,
  }
}
