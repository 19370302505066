import { Badge } from '@/components/Common'
import { getTransactionTypeText } from '../constants'

export const StatusBadge = ({ status }) => {
  let color

  switch (status) {
    case 1:
      color = 'bg-gray-400'
      break
    case 2:
      color = 'bg-gray-400'
      break
    case 3:
      color = 'bg-positive'
      break
    case 4:
      color = 'bg-negative'
      break
    default:
      break
  }

  return <Badge bg={color}>{getTransactionTypeText(status)}</Badge>
}
