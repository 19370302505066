import MoneyAsText from '@/components/Common/MoneyAsText'
import { useTranslation } from 'react-i18next'
import { AiFillStar } from 'react-icons/ai'
import { BsClock } from 'react-icons/bs'
import { useArbitrage } from '../contexts'
import classNames from 'classnames'
import { Loader } from '@/components/Common'
import { useViewport } from '@/hooks'
import { currencyExhangeList } from '../constants'

export const BuySellOffers = () => {
  const { t } = useTranslation()
  const { purchase, sales, counter, markOfferPurchase, markOfferSales, loadingUseBuyOffers, dataBuySellOffers } = useArbitrage()
  const { isMoreXl } = useViewport()
  return (
    <>
      <section className='flex flex-col mt-4 lg:mt-0 lg:flex-row gap-2 lg:gap-4'>
        {purchase.length !== 0 && (
          <div className='px-2 py-3 flex flex-col w-full h-fit'>
            {loadingUseBuyOffers && !purchase && <Loader />}
            <p className='mb-3'>{t('arbitrage.purchaseOffers')}</p>
            {purchase.map((item, index) => (
              <div
                key={index}
                onClick={() => markOfferPurchase(item, index)}
                className={classNames('flex justify-between items-center p-2 hover:opacity-70 rounded-2 hover:cursor-pointer', {
                  'border-2 border-positive bg-positive/50': item.checked,
                  'border-2 border-positive animate-ping': item.bestBuy,
                })}
              >
                <div className='flex gap-2 items-center'>
                  <img className='w-4' src={currencyExhangeList.find(el => el.name === item.broker?.name).icon} alt='exchange' />
                  <span className='flex gap-2 items-center'>
                    {item.bestBuy && <AiFillStar className='text-yellow-400' />} {item.broker?.name}
                  </span>
                </div>
                <span
                  className={classNames('whitespace-nowrap text-positive font-bold', {
                    'text-white': item.checked,
                  })}
                >
                  <MoneyAsText value={item.price} />
                </span>
              </div>
            ))}
          </div>
        )}
        {!isMoreXl && (
          <div className='text-center mt-4'>
            {loadingUseBuyOffers && <Loader />}
            <p className='text-xl md:text-2xl font-semibold text-negative'>{dataBuySellOffers.bestSaleOffer?.broker.name}</p>
            <p className='text-2xl md:text-3xl font-extrabold text-negative'>
              <MoneyAsText value={dataBuySellOffers.bestSaleOffer?.price} />
            </p>
            <span>{dataBuySellOffers.bestSaleOffer?.price && t('arbitrage.bestSalePrice')}</span>
          </div>
        )}
        {sales.length !== 0 && (
          <div className='px-2 py-3 flex flex-col w-full h-fit'>
            {loadingUseBuyOffers && !sales && <Loader />}
            <p className='mb-3'>{t('arbitrage.salesOffers')}</p>
            {sales.map((item, index) => (
              <div
                key={index}
                onClick={() => markOfferSales(item, index)}
                className={classNames('flex justify-between items-center p-2 hover:opacity-70 rounded-2 hover:cursor-pointer', {
                  'border-2 border-negative bg-negative/50': item.checked,
                  'border-2 border-negative animate-ping': item.bestSell,
                })}
              >
                <div className='flex gap-2 items-center'>
                  <img className='w-4' src={currencyExhangeList.find(el => el.name === item.broker?.name).icon} alt='exchange' />
                  <span className='flex gap-2 items-center'>
                    {item.bestSell && <AiFillStar className='text-yellow-400' />} {item.broker?.name}
                  </span>
                </div>
                <span
                  className={classNames('whitespace-nowrap text-negative font-bold', {
                    'text-white': item.checked,
                  })}
                >
                  <MoneyAsText value={item.price} />
                </span>
              </div>
            ))}
          </div>
        )}
      </section>
      <span className='mt-1 text-sm text-gray-400 flex gap-1 justify-end self-end w-full'>
        <BsClock className='mt-[2px]' /> {t('arbitrage.updatingNewOffers')} {counter} s
      </span>
    </>
  )
}
