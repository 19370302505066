import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useEditCustomer = () => {
  const { data, loading, error, makeRequest } = useApi(params => {
    const { route, method } = getEndpoint('editCustomer')
    return httpClient[method](route, { ...params })
  })
  return { dataCustomer: data, loadingCustomer: loading, errorCustomer: error, editCustomer: makeRequest }
}
