import { useEffect } from 'react'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { Loading } from '@/components/Common/Loading'
import { useAuth } from '@/contexts'
import { useFormProfile } from './hooks/useFormProfile'
import profileImage from '@/assets/images/profileImage.svg'
import { useEditCustomer } from './hooks'
import { toast } from 'react-toastify'
import { FormProfile } from './components'

export const Profile = () => {
  const { user } = useAuth()
  const { t } = useTranslation()

  const { dataCustomer, editCustomer, errorCustomer, loadingCustomer } = useEditCustomer()
  const { handleSubmit, values, handleChange, errors, setFieldValue, setFieldError } = useFormProfile({
    onSubmit: values => {
      editCustomer({
        ...values,
        language: Number(values.language),
        pixKeyType: Number(values.pixKeyType) === 0 || !Number(values.pixKeyType) ? 1 : Number(values.pixKeyType),
        username: values.login,
        bitcoinWallet: values.walletBitcoin ?? '',
        ethereumWallet: values.walletEthereum ?? '',
        usdtWallet: values.walletUstd ?? '',
        cpf: values.cpfCnpj && values.cpfCnpj.replace(/[^0-9]+/g, ''),
        pixKey: values.pixKey === null ? '' : values.pixKey,
      })
    },
  })

  useEffect(() => {
    setFieldValue('firstName', user.firstName)
    setFieldValue('lastName', user.lastName)
    setFieldValue('nickname', user.nickname)
    setFieldValue('email', user.email)
    setFieldValue('cpfCnpj', user.cpf)
    setFieldValue('phone', user.phone)
    setFieldValue('pixKeyType', user.pixKeyType)
    setFieldValue('pixKey', user.pixKey)
    setFieldValue('walletBitcoin', user.bitcoinWallet)
    setFieldValue('walletEthereum', user.ethereumWallet)
    setFieldValue('walletUstd', user.usdtWallet)
    setFieldValue('language', user.language)
  }, [])

  useEffect(() => {
    if (!dataCustomer) return
    Swal.fire({
      title: t('global.successTitle'),
      text: t('profile.saveDataSucess'),
      icon: 'success',
      confirmButtonText: t('global.ok'),
    })
  }, [dataCustomer])

  useEffect(() => {
    if (!errorCustomer) return
    if (errorCustomer.error === 'cpfAlreadyInUse') {
      toast.error(t('global.validation.validationCpfInvalid'))
      return
    }
    toast.error('Erro ao atualizar dados.')
  }, [errorCustomer])

  if (loadingCustomer) return <Loading />

  return (
    <>
      <h1 className='font-extrabold text-3xl mb-[44px] lg:mb-4'>{t('profile.myProfile')}</h1>
      <div className='lg:flex gap-6 justify-between'>
        <div className='hidden lg:flex justify-center items-center'>
          <img src={profileImage} alt={t('profile.imgAltProfile')} />
        </div>
        <FormProfile
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          loadingCustomer={loadingCustomer}
          values={values}
          setFieldValue={setFieldValue}
          setFieldError={setFieldError}
        />
      </div>
    </>
  )
}
