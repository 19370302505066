import VerificationInput from 'react-verification-input'
import { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Loader } from '@/components/Common'
import { useAuth } from '@/contexts'

export const CheckedModal = ({
  setVerificationModalIsOpen,
  verificationModalIsOpen,
  isPhoneEmail,
  handleSubmitVerify,
  loading,
  handleSendEmail,
  sendEmail,
  sendPhone,
  loadingVerificationEmailPhone,
}) => {
  const [checkedNumbers, setCheckedNumbers] = useState()
  const { t } = useTranslation()
  const { user } = useAuth()

  const handleChangeNumbers = e => {
    setCheckedNumbers(e)
  }

  return (
    verificationModalIsOpen && (
      <aside className=' flex justify-center items-center w-full fixed inset-0 z-20 backdrop-blur-md'>
        <div className='bg-gray border rounded shadow-sm max-h-fit'>
          <span className='flex justify-end p-2'>
            <AiOutlineClose className='h-4 w-4 cursor-pointer' onClick={() => setVerificationModalIsOpen(false)} />
          </span>
          <form
            onSubmit={() => handleSubmitVerify(isPhoneEmail, checkedNumbers)}
            className=' px-3 py-16 w-fit flex flex-col justify-center items-center gap-5 '
          >
            {!sendEmail && isPhoneEmail === 'email' ? (
              <div className='text-center'>
                <p className='text-lg flex flex-col'>
                  {t('security.questionVerifyEmail')}
                  <strong>{user.email}</strong>
                </p>
                <p className='text-lg'>{t('security.textVerify')}</p>
              </div>
            ) : null}
            {!sendPhone && isPhoneEmail === 'phone' ? (
              <div className='text-center'>
                <p className='text-lg'>
                  {' '}
                  {t('security.questionVerifyPhone')} <strong>{user.phone}</strong>
                </p>
                <p className='text-lg'>{t('security.textVerify')}</p>
              </div>
            ) : null}
            {sendEmail && isPhoneEmail === 'email' && (
              <>
                <h3 className='text-center'>
                  {isPhoneEmail === 'phone' ? t('security.phoneNumberVerification') : t('security.emailVerification')}
                </h3>
                <div>
                  <VerificationInput
                    name='numbers'
                    onChange={e => handleChangeNumbers(e)}
                    placeholder=''
                    validChars='0-9'
                    classNames={{
                      character: 'text-primary-600',
                    }}
                  />
                  <p className='text-xs mt-1 text-center'>{t('security.verificationCode')}</p>
                </div>
              </>
            )}
            {sendPhone && isPhoneEmail === 'phone' && (
              <>
                <h3 className='text-center'>
                  {isPhoneEmail === 'phone' ? t('security.phoneNumberVerification') : t('security.emailVerification')}
                </h3>
                <div>
                  <VerificationInput
                    name='numbers'
                    onChange={e => handleChangeNumbers(e)}
                    placeholder=''
                    validChars='0-9'
                    classNames={{
                      character: 'text-primary-600',
                    }}
                  />
                  <p className='text-xs mt-1 text-center'>{t('security.verificationCode')}</p>
                </div>
              </>
            )}
            <div className='flex-col flex gap-2'>
              {((sendEmail && isPhoneEmail === 'email') || (sendPhone && isPhoneEmail === 'phone')) && (
                <button
                  type='button'
                  onClick={() => {
                    if (!checkedNumbers || checkedNumbers.length !== 6) return toast.error(t('global.errorVerification'))
                    handleSubmitVerify(isPhoneEmail, checkedNumbers)
                  }}
                  className='bg-primary-300 py-2 rounded-full text-sm min-w-[200px] text-center'
                >
                  {loading ? (
                    <Loader className='inline-block w-[14px] h-[14px]' />
                  ) : isPhoneEmail === 'email' ? (
                    `${t('global.verify')} ${t('global.email')}`
                  ) : (
                    `${t('global.verify')} ${t('global.phone')}`
                  )}
                </button>
              )}
              <button
                type='button'
                className='border border-white py-2 rounded-full text-sm min-w-[200px] text-center'
                onClick={handleSendEmail}
              >
                {loadingVerificationEmailPhone ? (
                  <Loader className='inline-block w-[14px] h-[14px]' />
                ) : (sendEmail && isPhoneEmail === 'email') || (sendPhone && isPhoneEmail === 'phone') ? (
                  t('security.resendToken')
                ) : (
                  t('global.sendEmailRequest')
                )}
              </button>
            </div>
          </form>
        </div>
      </aside>
    )
  )
}
