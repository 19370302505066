import notificationIcon from '@/assets/images/notification.png'

export const dataNotifications = [
  {
    icon: notificationIcon,
    title: 'Bem-vindo!',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    date: '01/01/2023',
  },
  {
    icon: notificationIcon,
    header: 'Notificações',
    title: 'Novo Afiliado!',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    date: '01/01/2023',
  },
  {
    icon: notificationIcon,
    title: 'Você subiu de Graduação!',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    date: '01/01/2023',
  },
  {
    icon: notificationIcon,
    title: 'Saque Realizado!',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    date: '01/01/2023',
  },
  {
    icon: notificationIcon,
    title: 'Depósito Realizado!',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    date: '01/01/2023',
  },
]
