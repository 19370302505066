import MoneyAsText from '@/components/Common/MoneyAsText'
import { useTranslation } from 'react-i18next'
import { useArbitrage } from '../contexts'
import { Loader } from '@/components/Common'
import { useViewport } from '@/hooks'

export const BestBuySellOffers = () => {
  const { t } = useTranslation()
  const { dataBuySellOffers, loadingUseBuyOffers } = useArbitrage()
  const { isMoreXl } = useViewport()
  return (
    <section className='flex flex-col lg:flex-row lg:justify-around lg:mb-2'>
      <div className='text-center mt-4'>
        {loadingUseBuyOffers && !dataBuySellOffers && <Loader />}
        <p className='text-xl md:text-2xl font-semibold text-positive'>{dataBuySellOffers?.bestBuyOffer?.broker.name}</p>
        <p className='text-2xl md:text-3xl font-extrabold text-positive'>
          <MoneyAsText value={dataBuySellOffers?.bestBuyOffer?.price} />
        </p>
        <span>{dataBuySellOffers?.bestBuyOffer?.price && t('arbitrage.bestBuyPrice')}</span>
      </div>
      {isMoreXl && (
        <div className='text-center mt-4'>
          {loadingUseBuyOffers && !dataBuySellOffers && <Loader />}
          <p className='text-xl md:text-2xl font-semibold text-negative'>{dataBuySellOffers.bestSaleOffer?.broker.name}</p>
          <p className='text-2xl md:text-3xl font-extrabold text-negative'>
            <MoneyAsText value={dataBuySellOffers.bestSaleOffer?.price} />
          </p>
          <span>{dataBuySellOffers.bestSaleOffer?.price && t('arbitrage.bestSalePrice')}</span>
        </div>
      )}
    </section>
  )
}
