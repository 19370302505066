import { getEndpoint } from '@/endpoints'
import { useApi } from '@/hooks'
import httpClient from '@/utils/httpClient'

export const useFetchCommission = () => {
  const { data, loading, makeRequest, error } = useApi(() => {
    const { route, method } = getEndpoint('fetchCommission')
    return httpClient[method](route)
  })

  return {
    dataCommission: data || [],
    loadingCommission: loading,
    errorCommission: error,
    fetchCommissions: makeRequest,
  }
}
