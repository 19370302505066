import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const useCandlesChart = () => {
  let channelId = null
  const maxDate = new Date()
  const minDate = new Date(new Date().setDate(maxDate.getDate() - 90))
  const rangeDate = maxDate.getTime() - minDate.getTime()

  const { t } = useTranslation()
  const [dataChart, setDataChart] = useState({
    series: [{ data: [] }],

    options: {
      title: {
        text: t('global.chartTitle'),
        align: 'left',
        style: {
          fontSize: '18px',
          // color: textColor,
        },
      },
      xaxis: {
        type: 'datetime',
        // labels: { style: { colors: textColor } },
        range: rangeDate,
      },
      yaxis: {
        tooltip: { enabled: true },
        decimalsInFloat: 2,
        // labels: { style: { colors: textColor } },
      },
      tooltip: {
        theme: 'dark',
      },
      chart: {
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4,
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1,
            },
          },
        },
        toolbar: {
          show: true,
          autoSelected: 'pan',
          tools: {
            reset: false,
          },
        },
        events: {
          beforeZoom: ctx => {
            ctx.w.config.xaxis.range = undefined
          },
        },
      },
      candlestick: {
        wick: {
          useFillColor: true,
        },
      },
      grid: {
        borderColor: '#90A4AE',
      },
    },
  })

  const consumeCandleChartWebSocket = message => {
    if (message.event && message.event === 'subscribed' && message.channel === 'candles') {
      channelId = message.chanId
      return
    }

    if (channelId !== message[0]) return
    if (message[1] === 'hb' || !Array.isArray(message[1])) return

    if (message[1].length > 150) {
      const collection = message[1].map(data => {
        const [timestamp, open, close, high, low] = data
        return {
          x: new Date(timestamp),
          y: [open.toFixed(2), high.toFixed(2), low.toFixed(2), close.toFixed(2)],
        }
      })
      setDataChart(prevState => ({
        ...prevState,
        series: [
          {
            data: collection,
          },
        ],
      }))
      return
    }

    const [timestamp, open, close, high, low] = message[1]
    setDataChart(prevState => ({
      ...prevState,
      series: [
        {
          data: [
            ...prevState.series[0].data,
            {
              x: new Date(timestamp),
              y: [open.toFixed(2), high.toFixed(2), low.toFixed(2), close.toFixed(2)],
            },
          ],
        },
      ],
    }))
  }

  return {
    dataChart,
    consumeCandleChartWebSocket,
  }
}
