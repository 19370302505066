import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { InputText } from '@/components/Form/InputText'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { twMerge } from 'tailwind-merge'

export const InputPassword = ({ name, className, value, onChange, darkMode, isInvalid, ...rest }) => {
  const [visible, setVisible] = useState(false)
  const classes = classNames('text-cyan-950', className, { 'text-white': darkMode, 'text-negative': isInvalid })
  return (
    <div className='flex relative'>
      <InputText
        type={visible ? 'text' : 'password'}
        name={name}
        value={value}
        onChange={onChange}
        className={twMerge(classes)}
        isInvalid={isInvalid}
        themeDark={darkMode}
        {...rest}
      />
      <button
        type='button'
        onClick={() => setVisible(!visible)}
        className={classNames('absolute cursor-pointer right-[8px] top-[10px]')}
      >
        {visible ? (
          <AiOutlineEye className={twMerge(classes)} size={25} />
        ) : (
          <AiOutlineEyeInvisible className={twMerge(classes)} size={25} />
        )}
      </button>
    </div>
  )
}

InputPassword.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  darkMode: PropTypes.bool,
}
