import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { FaRegMoon } from 'react-icons/fa'
import { FiSun } from 'react-icons/fi'
import { useLocation } from 'react-router-dom'

export const ThemeSwitch = ({ isSidebar }) => {
  const [theme, setTheme] = useState(document.documentElement.classList.contains('dark') ? 'dark' : 'light')
  const { pathname } = useLocation()

  const handleTheme = () => {
    document.documentElement.classList.toggle('dark')
    document.documentElement.classList.toggle('light')

    if (theme === 'dark') {
      setTheme('light')
      localStorage.setItem('app:theme', 'light')
    } else {
      setTheme('dark')
      localStorage.setItem('app:theme', 'dark')
    }
  }

  useEffect(() => {
    if (isSidebar) {
      return
    }

    if (localStorage.getItem('app:theme') === 'light') {
      document.documentElement.classList.add('light')
      setTheme('light')
      return
    }

    if (!document.documentElement.classList.contains('dark')) {
      document.documentElement.classList.add('dark')
      document.documentElement.classList.remove('light')
      setTheme('dark')
    }
  }, [])

  if (pathname !== '/arbitrage') {
    return null
  }

  return (
    <button
      onClick={handleTheme}
      className={classNames('p-2 hover:bg-primary-50/25 rounded-2', { 'hidden sm:block': !isSidebar })}
    >
      {theme === 'dark' ? <FiSun size={20} /> : <FaRegMoon size={20} />}
    </button>
  )
}
