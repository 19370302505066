import { MoneyAsInput } from '@/components/Form'
import PropTypes from 'prop-types'
import { useState } from 'react'

export const SelectField = ({ onChange, value = 20, skyt }) => {
  const initialValue = parseFloat(value)
  const [selectedValue, setSelectedValue] = useState(initialValue)
  const minimumValue = 20

  const incrementValue = () => {
    const newValue = selectedValue + 20
    setSelectedValue(newValue)
    onChange(newValue)
  }

  const decrementValue = () => {
    if (selectedValue > minimumValue) {
      const newValue = selectedValue - 20
      setSelectedValue(newValue)
      onChange(newValue)
    }
  }

  return (
    <div className='grid grid-cols-[50px_180px_50px] gap-2 py-3'>
      <button
        type='button'
        className='border py-2 rounded-2xl bg-primary-300 text-white font-bold flex justify-center items-center'
        onClick={decrementValue}
      >
        -
      </button>
      <MoneyAsInput
        value={selectedValue}
        onChange={e => {
          onChange(e.numberValue)
          setSelectedValue(e.numberValue)
        }}
        className='text-center'
        currencyCode={skyt ? 'SKYT' : 'USD'}
      />
      <button
        type='button'
        className='border rounded-2xl bg-primary-300 text-white font-bold flex justify-center items-center'
        onClick={incrementValue}
      >
        +
      </button>
    </div>
  )
}

SelectField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}
