import { Loader, PercentAsText } from '@/components/Common'
import MoneyAsText from '@/components/Common/MoneyAsText'
import { Label, MoneyAsInput } from '@/components/Form'
import { FieldMessage, TextField } from '@/components/Form/Fields'
import { useAuth } from '@/contexts'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FiAlertCircle } from 'react-icons/fi'
import { RiErrorWarningFill } from 'react-icons/ri'
import { useSearchParams } from 'react-router-dom'

export const CriptoFormWithdraw = ({ valuesCrypto, handleSubmitCrypto, handleChangeCrypto, errorsCrypto, loading, usdt }) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [searchParams] = useSearchParams()
  const tokenParam = searchParams.get('token')
  const [convertedValue, setConvertedValue] = useState(0)

  useEffect(() => {
    const clearNumber = Number(valuesCrypto.amountCrypto.replace(/[^0-9.]/g, ''))
    const percentValue = user.settings.WITHDRAWAL_TAX_AMOUNT / 100
    setConvertedValue((clearNumber * percentValue).toFixed(2))
  }, [valuesCrypto.amountCrypto])

  return (
    <form onSubmit={handleSubmitCrypto}>
      <Label text={t('withdraw.withdrawalValue')} isInvalid={errorsCrypto.amountCrypto} />

      <MoneyAsInput
        name='amountCrypto'
        value={valuesCrypto.amountCrypto}
        onChange={handleChangeCrypto}
        placeholder={t('global.placeholderValue')}
        isInvalid={errorsCrypto.amountCrypto}
      />
      {errorsCrypto.amountCrypto && <FieldMessage message={errorsCrypto.amountCrypto} />}

      <TextField
        name='cryptoCurrency'
        value={valuesCrypto.cryptoCurrency}
        onChange={handleChangeCrypto}
        errorMessage={errorsCrypto.cryptoCurrency}
        placeholder={t('profile.placeholderBitcoinWallet')}
        label={t('global.cryptocurrencyWallet')}
        className='mt-3'
      />

      <div className='flex text-xs border border-primary rounded p-2 gap-2 mt-3 items-center'>
        <FiAlertCircle className='h-7 w-7 md:h-3 md:w-3' />
        <p>
          {t('withdraw.pleaseWait')}
          <strong>{t('withdraw.period')}</strong>
          {t('withdraw.forNextOperation')}
        </p>
      </div>
      <p className='mt-3 text-sm text-yellow-500 font-bold flex items-center gap-1'>
        <RiErrorWarningFill className='h-3 w-3 mb-[2px]' /> {t('withdraw.withdrawTax')}{' '}
        <span>
          (<PercentAsText value={user.settings.WITHDRAWAL_TAX_AMOUNT} />)
        </span>{' '}
        <span className='text-black'>
          ≈ <MoneyAsText value={convertedValue} />
        </span>
      </p>
      <div className='flex flex-col justify-center mt-3'>
        <button
          type='submit'
          className={classNames('bg-primary-300 text-white text-center font-bold p-4 w-full rounded-2', {
            'opacity-60': new Date().getDay() === 6 || new Date().getDay() === 0 || tokenParam,
            'hover:bg-primary-400': new Date().getDay() !== 6 || new Date().getDay() !== 0 || tokenParam,
          })}
          disabled={loading || new Date().getDay() === 6 || new Date().getDay() === 0 || tokenParam}
        >
          {loading ? <Loader className='inline-block' /> : t('withdraw.withdrawalRequest')}
        </button>
        {usdt && (
          <p className='flex gap-2 mt-3 text-sm text-yellow-500 font-bold items-center justify-center'>
            <RiErrorWarningFill className='h-3 w-3' /> {t('withdraw.networkUSDTWithdraw')}
          </p>
        )}
      </div>

      <p className='text-xs mt-2'>{t('withdraw.resourceRelease')}</p>
    </form>
  )
}
