import bank from '@/assets/images/bank.png'
import criptoTeam from '@/assets/images/bitcointeam.png'
import gain from '@/assets/images/gain.png'
import user from '@/assets/images/user.png'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiSolidBrain } from 'react-icons/bi'
import { BsShieldLockFill } from 'react-icons/bs'
import { SiHiveBlockchain } from 'react-icons/si'
import { Link } from 'react-router-dom'
import { CardsItemVariant, CardsVariant, TableVariant, TitleVariant } from './animations'
import { DesktopSection, TableCriptoMarket } from './components'
import { Opening } from './components/Opening'
import { useFetchCrypto } from './hooks/useFetchCrypto'
import logoIcon from '/images/logo.png'

export const Home = () => {
  const { t } = useTranslation()
  const [cachedData, setCachedData] = useState([])
  const { cryptos, fetchCryptos, loadingCryptos } = useFetchCrypto()

  useEffect(() => {
    const cachedValue = localStorage.getItem('cachedData')
    if (cachedValue) {
      const { data, expiration } = JSON.parse(cachedValue)
      if (Date.now() < expiration) {
        setCachedData(data)
      } else {
        localStorage.removeItem('cachedData')
        fetchCryptos()
      }
      return
    }
    fetchCryptos()
  }, [])

  useEffect(() => {
    if (cryptos.length !== 0 && JSON.stringify(cryptos) !== JSON.stringify(cachedData)) {
      setCachedData(cryptos)
    }
  }, [cryptos])

  useEffect(() => {
    if (cachedData.length !== 0 && cryptos.length !== 0) {
      const expiration = Date.now() + 5 * 60 * 1000
      localStorage.setItem('cachedData', JSON.stringify({ data: cryptos, expiration }))
    }
  }, [cachedData])

  return (
    <div className='w-full'>
      <div className='flex flex-col justify-center items-center bg-home relative'>
        <video
          className='absolute w-full h-full pointer-events-none object-cover object-center opacity-25'
          id='background-video'
          disablePictureInPicture
          controlsList='nodownload'
          poster='/images/bg-2.png'
          playsInline={true}
          muted={true}
          autoPlay={true}
          loop={true}
          controls={false}
          preload='auto'
          width='100%'
          height='100%'
        >
          <source src='/chartAnimation.mp4' type='video/mp4' />
        </video>
        <Opening />
      </div>
      <div className='md:flex md:flex-col'>
        <section className='bg-white text-primary'>
          <div className='px-4 py-11 mx-auto max-w-[1440px]'>
            <motion.h2
              variants={TitleVariant}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true }}
              className='text-2xl md:text-3xl font-Kaleko-serif-bold mb-2 origin-left'
            >
              {t('home.whyBecomeSkyOperator')}
            </motion.h2>

            <motion.div
              variants={CardsVariant}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true }}
              className='flex flex-col gap-3 mt-3 md:grid md:grid-cols-3 lg:grid-cols-3'
            >
              <motion.div variants={CardsItemVariant} className='border border-primary rounded py-5 px-2 origin-center'>
                <h3 className='flex gap-2 text-base items-center justify-center'>
                  <BiSolidBrain className='text-xl' />
                  <strong className='text-center'>{t('home.innovationAndTechnology')}</strong>
                </h3>
                <p className='text-xs mt-3 text-center'>{t('home.innovationStep')}</p>
              </motion.div>

              <motion.div variants={CardsItemVariant} className='border border-primary rounded py-5 px-2 origin-center'>
                <h3 className='flex gap-2 text-base items-center justify-center'>
                  <SiHiveBlockchain className='text-xl' />
                  <strong className='text-center'>{t('home.transparency')}</strong>
                </h3>
                <p className='text-xs mt-3 text-center'>{t('home.transparencyStep')}</p>
              </motion.div>

              <motion.div variants={CardsItemVariant} className='border border-primary rounded py-5 px-2 origin-center'>
                <h3 className='flex gap-2 text-base items-center justify-center'>
                  <BsShieldLockFill className='text-xl' />
                  <strong className='text-center'>{t('home.security')}</strong>
                </h3>
                <p className='text-xs mt-3 text-center'>{t('home.securityStep')}</p>
              </motion.div>
            </motion.div>
            <div className='mt-4 flex justify-center'>
              <Link
                to='/sign-up'
                className='text-base w-full rounded-full p-3 text-white bg-primary-600 text-center font-Kaleko-bold lg:w-1/2 hover:bg-primary-700 transition duration-200'
              >
                {t('home.openMyAccount')}
              </Link>
            </div>
          </div>
        </section>

        <section className='bg-primary'>
          <div className='px-4 py-11 mx-auto max-w-[1440px]'>
            <motion.h2
              variants={TitleVariant}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true }}
              className='text-2xl md:text-3xl font-Kaleko-serif-bold mb-2 origin-left'
            >
              {t('home.buyCryptoAtRealCost')}
            </motion.h2>
            <p className='text-base mb-3'>{t('home.buyCryptoAtRealCostDescription')}</p>
            <TableCriptoMarket cryptos={cachedData} loadingCryptos={loadingCryptos} />
          </div>
        </section>

        {/* <section className='w-full'>
          <motion.div variants={TableVariant} initial='hidden' whileInView='visible' viewport={{ once: true }} className='w-full'>
            <img className='mx-auto max-h-[560px] object-contain' src='/images/roadmap1.png' alt='botfy roadmap' />
          </motion.div>
        </section> */}

        <section className='bg-white'>
          <div className='px-4 py-11 mx-auto max-w-[1440px] text-center text-primary'>
            <motion.h2
              variants={TitleVariant}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true }}
              className='text-2xl md:text-3xl font-Kaleko-serif-bold mb-2 origin-center'
            >
              {t('home.titleStartInAFewMinutes')}
            </motion.h2>
            <p className='text-base mb-5'>{t('home.textStartInAFewMinutes')}</p>
            <motion.div
              variants={CardsVariant}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true }}
              className='grid gap-5 md:gap-0 md:grid-cols-5 items-center max-w-6xl mx-auto'
            >
              <motion.span variants={CardsItemVariant} className='flex flex-col justify-center gap-2 items-center'>
                <img src={user} width={60} alt={t('home.accountIcon')} />
                <h4>{t('home.createAnAccount')}</h4>
              </motion.span>

              <hr className='hidden md:flex' />

              <motion.span variants={CardsItemVariant} className='flex flex-col justify-center gap-2 items-center'>
                <img src={bank} width={70} alt={t('home.bankIcon')} />
                <h4>{t('home.linkYourBankAccount')}</h4>
              </motion.span>

              <hr className='hidden md:flex' />

              <motion.span variants={CardsItemVariant} className='flex flex-col justify-center gap-2 items-center'>
                <img src={gain} width={60} alt={t('home.salesIcon')} />
                <h4 className='lg:whitespace-nowrap'>{t('home.startBuyingAndSelling')}</h4>
              </motion.span>
            </motion.div>
          </div>
        </section>

        <section className='bg-primary'>
          <div className='max-w-xl lg:grid lg:grid-cols-2 px-4 py-11 mx-auto lg:max-w-[1440px]'>
            <div className='lg:p-24'>
              <motion.h2
                variants={TitleVariant}
                initial='hidden'
                whileInView='visible'
                viewport={{ once: true }}
                className='text-2xl md:text-3xl font-Kaleko-serif-bold mb-2 origin-left'
              >
                {t('home.titleEarnCrypto')}
              </motion.h2>
              <p className='text-base lg:my-3'>{t('home.textEarnCrypto')}</p>
              <div className='flex items-center justify-center mt-5'>
                <Link
                  to='/sign-up'
                  className='text-sm md:text-base mt-1 rounded-full p-3 bg-white text-primary-700 text-center font-Kaleko-bold md:w-4/5 w-3/5 hover:bg-white/80 transition duration-200'
                >
                  {t('home.startEarning')}
                </Link>
              </div>
            </div>
            <div className='hidden lg:flex justify-center'>
              <motion.img
                variants={TableVariant}
                initial='hidden'
                whileInView='visible'
                viewport={{ once: true }}
                src={criptoTeam}
                alt={t('home.cryptocurrencyMarket')}
              />
            </div>
          </div>
        </section>

        <div className='flex flex-col justify-center items-center my-3 lg:hidden'>
          <img src={logoIcon} width='80' />
          <p className=' font-Kaleko-bold text-xl'>botfy.app</p>
        </div>
        <DesktopSection />
      </div>
    </div>
  )
}
